import React from "react";

// Components
import Box from "./../../_quarks/box/Box";

// Style
import "./Table.css";

class Table extends React.Component {
  render() {
    return (
      <Box className={this.props.className}>
        <table className="m-table">{this.props.children}</table>
      </Box>
    );
  }
}

export default Table;

import React from "react";
import styled from "styled-components";
import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
import CloudUpload from "@material-ui/icons/CloudUpload";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import Menu from "@material-ui/icons/Menu";
import People from "@material-ui/icons/People";
import Settings from "@material-ui/icons/Settings";
import InsertChart from "@material-ui/icons/InsertChart";
import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Help from "@material-ui/icons/Help";
import SvgIcon from "@material-ui/core/SvgIcon";
import AudioTrack from "@material-ui/icons/Audiotrack";
import PlayArrow from "@material-ui/icons/PlayArrow";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import Image from "@material-ui/icons/Image";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";
import ViewStream from "@material-ui/icons/ViewStream";
import ViewModule from "@material-ui/icons/ViewModule";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import CalendarToday from "@material-ui/icons/CalendarToday";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Collections from "@material-ui/icons/Collections";
import PhotoSizeSelectLarge from "@material-ui/icons/PhotoSizeSelectLarge";
import GroupOutlined from "@material-ui/icons/GroupOutlined";
import PublicOutlined from "@material-ui/icons/PublicOutlined";
import { CircularProgress } from "@material-ui/core";
import { Home, CollectionsOutlined, Delete, Add } from "@material-ui/icons";

export const getIcon = name => {
  switch (name) {
    case "cloud-upload":
      return CloudUpload;
    case "account-circle":
      return AccountCircleOutlined;
    case "expand-more":
      return ExpandMore;
    case "expand-less":
      return ExpandLess;
    case "menu":
      return Menu;
    case "people":
      return People;
    case "settings":
      return Settings;
    case "insert-chart":
      return InsertChart;
    case "person":
      return Person;
    case "exit-to-app":
      return ExitToApp;
    case "help":
      return Help;
    case "audiotrack":
      return AudioTrack;
    case "play-arrow":
      return PlayArrow;
    case "insert-drive-file":
      return InsertDriveFile;
    case "picture-as-pdf":
      return PictureAsPdf;
    case "image":
      return Image;
    case "clear":
      return Clear;
    case "search":
      return Search;
    case "view-stream":
      return ViewStream;
    case "view-module":
      return ViewModule;
    case "navigate-before":
      return NavigateBefore;
    case "navigate-next":
      return NavigateNext;
    case "calendar-today":
      return CalendarToday;
    case "cloud-download":
      return CloudDownload;
    case "collections":
      return Collections;
    case "collections-outline":
      return CollectionsOutlined;
    case "photo-size-select-large":
      return PhotoSizeSelectLarge;
    case "group":
      return GroupOutlined;
    case "public":
      return PublicOutlined;
    case "delete":
      return Delete;
    case "circular-progress":
      return CircularProgress;
    case "home":
      return Home;
    case "add":
      return Add;
    case "custom-fields":
      return props => (
        <SvgIcon {...props} viewBox="0 0 1000 600">
          <path d="M711 663l-16-47c-10-25-17-46-17-46l-49 8-49 9s-38-66-38-67l28-34 32-38 4-4-32-37-32-39s37-65 38-66l48 6 49 9a258 258 0 0 0 18-47l16-46h78l16 46 17 47 49-9 49-8s38 67 38 68-9 12-32 39l-32 37 0 2c9 8 64 74 64 74s-38 67-38 67l-49-9-48-8s-8 21-18 46l-16 47h-78z m45-146a71 71 0 0 0 45-21 72 72 0 0 0 21-42 83 83 0 0 0 0-20 73 73 0 0 0-54-60 81 81 0 0 0-28-2 73 73 0 0 0-60 54 78 78 0 0 0 0 35 75 75 0 0 0 28 42 72 72 0 0 0 48 14z m59-459h-671a102 102 0 0 0-102 102v255a102 102 0 0 0 102 102h339a21 21 0 0 0 0-42h-339a60 60 0 0 1-61-60v-255a60 60 0 0 1 61-60h671a60 60 0 0 1 60 60v80a21 21 0 0 0 42 0v-80a102 102 0 0 0-102-102z" />
        </SvgIcon>
      );
    case "filter":
      return props => (
        <SvgIcon {...props} viewBox="0 0 1000 800">
          <path d="M898 706c-4 9-13 14-22 14h-752c-9 0-18-5-22-14-4-8-2-18 4-26l288-350v-323c0-9 5-16 12-21 4-2 8-3 13-3 3 0 7 0 10 2l163 81c8 4 13 12 13 21l1 243 289 350c5 8 7 18 3 26z m-335-352c-4-4-6-10-6-15l0-237-114-56v293c0 5-2 11-6 15l-261 318h648l-261-318z" />
        </SvgIcon>
      );

    default:
      return ErrorOutline;
  }
};

const Icon = props => {
  let Icon = getIcon(props.name);
  return <Icon {...props} />;
};

export default styled(Icon)`
  && {
    color: ${props => props.theme.palette.grey};
  }
`;

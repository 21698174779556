const brazilian_portuguese_default = {
  PlaceholderInputEmail: "E-mail",
  PlaceholderInputPassword: "Senha",
  LabelButtonLogin: "Entrar",
  LabelButtonLoginWithGoogle: "Entrar com Google",
  LabelButtonSeeAllCollections: "Ver todas",
  TitleAlertIncorrectData: "Dados não conferem",
  ContentAlertIncorrectData:
    "Verifique seu <strong>e-mail e senha</strong> e tente novamente.",
  SearchPlaceholder: "O que você está procurando?",
  Collections: "Coleções",
  CollectionItemViewButton: "Ver",
  EmptyMessageTags: "Nenhuma tag encontrada.",
  EmptyMessageCollections: "Nenhuma coleção encontrada.",
  EmptyMessageAssets: "Nenhum asset encontrado.",
  AssetTypeAudio: "Áudio",
  AssetTypeVideo: "Vídeo",
  AssetTypeImage: "Imagem",
  AssetTypePDF: "PDF",
  MoreFilters: "Mais filtros",
  Filters: "Filtros",
  FilterDateStart: "Data inicial:",
  FilterDateEnd: "Data final:",
  ActionApplyFilters: "Aplicar filtros",
  Logout: "Sair",
  Profile: "Perfil",
  ContentSmartSearchImage: "Informações extraídas da imagem:",
  ContentSmartSearchAudio: "Informações extraídas do áudio:",
  ContentSmartSearchVideo: "Informações extraídas do vídeo:",
  CreateAt: "Criado em:",
  AssetDetailFileExtension: "Extensão:",
  AssetDetailFileSize: "Tamanho:",
  AssetDetailCollections: "Coleções:",
  AssetDetailGroups: "Grupos:",
  AssetDetailUpload: "Upload",
  AssetDetailModified: "Modificado em:",
  AssetDetailHasSmartSearch: "Pesquisa inteligente",
  AssetDetailIsPublicMode: "Modo público",
  AssetDetailIsPublicDownload: "Download público",
  TitleUpload: "Enviar assets",
  FeedbackAllAssetsSent: "Todos os assets foram enviados",
  TitleAlertCollectionDeleted: "Coleção excluída com sucesso!",
  ContentAlertCollectionDeletedKeepingAssets:
    "A Coleção foi excluída mas seus Assets foram mantidos.",
  ContentAlertCollectionDeletedRemovingAssets:
    "A Coleção e seus Assets foram excluídos.",
  TitleAlertWhatDoWantToDo: "O que você deseja fazer?",
  CantUndoThisAction: "Não será possível desfazer essa ação.",
  TitleAlertAssetDeleted: "Asset excluído com sucesso!",
  TitleAlertDoYouWantExcludeTheAsset: "Excluir o asset?",
  TitleAlertAssetRemovedFromCollection: "Asset removido da Coleção",
  UploadMoreAssets: "Subir mais assets",
  UploadPageSaveSuccessAlertTitle: "Tudo certo!",
  UserFirstName: "Nome:",
  UserLastName: "Sobrenome:",
  Password: "Senha:",
  PasswordNew: "Nova senha:",
  PasswordNewConfirm: "Confirmar nova senha:",
  PasswordOld: "Senha atual",
  EditPassword: "Alterar senha",
  InvalidOldPasswordConfirmation: "Senha antiga não confere",
  InvalidNewPasswordConfirmation:
    "Senha informada não confere com a confirmação",
  Language: "Idioma:",
  ConfirmUserDeletion: "Excluir o usuário?",
  WarningNotPossibleToUndoTheAction: "Não será possível desfazer essa ação",
  UserDeletedSuccessfully: "Usuário excluído com sucesso!",
  DeleteUser: "Excluir usuário",
  Cancel: "Cancelar",
  Users: "Usuários",
  CreateNewUserButtonLabel: "Criar novo usuário",
  ManageUsers: "Gerenciamento de usuários",
  ManageGroups: "Gerenciamento de grupos",
  ConfirmGroupDeletion: "Excluir o grupo?",
  Groups: "Grupos",
  CreateNewGroupButtonLabel: "Criar novo grupo",
  TitleAlertProfileIsChanged: "Perfil alterado com sucesso",
  TitleCreateNewUser: "Criar novo usuário",
  TitleCreateNewGroup: "Criar novo grupo",
  TitleUserCreatedSuccessfully: "Usuário criado com sucesso!",
  TitleGroupCreatedSuccessfully: "Grupo criado com sucesso!",
  GroupDeletedSuccessfully: "Grupo excluído com sucesso!",
  GroupName: "Nome do grupo:",
  TitleAlertForgotPasswordOk: "Instruções enviadas para seu e-mail",
  ContentAlertForgotPasswordOk:
    "Confira seu e-mail <strong>%{email}</strong> e siga as instruções para criar uma nova senha.",
  FillTheFieldsBelow: "Preencha os campos abaixo.",
  TitleAlertPasswordChanged: "Senha alterada com sucesso!",
  ContentAlertPasswordChanged:
    "Você já pode acessar o Assetify com a nova senha criada.",
  AssetDetailContent: "Conteúdo do asset",
  TitleSearchInPdf: "Buscar no PDF",
  SearchTextContent: "Buscar conteúdo de texto",
  IndexResultsFound:
    "<strong>%{currentIndex}/%{totalResults}</strong> resultados",
  FoundInThePdf: "Encontrado no PDF",
  FoundInVideoImage: "Encontrado na imagem do vídeo",
  TooltipConfirm: "Entendi",
  TooltipSearchContent:
    'Para buscas com mais de uma palavra, coloque-as "entre aspas" para restulados mais precisos.',
  TooltipSearchTitle: "Dica!",
  LabelMediaQuality: "Qualidade",
  Analytics: "Estatísticas",
  LabelFilterByUploadDate: "Data de upload",
  AnalyticsLegendStorage: "Storage",
  AnalyticsLegendAssetTypeVideo: "Vídeos",
  AnalyticsLegendAssetTypeAudio: "Áudios",
  AnalyticsLegendAssetTypeImage: "Imagens",
  AnalyticsLegendAssetTypeOther: "Outros",
  TitleAnalyticsAmount: "Quantidade",
  AnalyticsDataAmountTotal:
    '<strong class="heading-1 color-default-dark">%{amount}</strong><br/> assets cadastrados',
  AnalyticsDataAmountVideo: "<strong>%{amount}</strong><br/> vídeos",
  AnalyticsDataAmountAudio: "<strong>%{amount}</strong><br/> áudios",
  AnalyticsDataAmountImage: "<strong>%{amount}</strong><br/> imagens",
  AnalyticsDataAmountOther: "<strong>%{amount}</strong><br/> PDFs e outros",
  TitleAnalyticsStorage: "Volume",
  AnalyticsDataStorageTotal:
    '<strong class="heading-1 color-default-dark">%{storage}</strong><br/> de assets cadastrados',
  AnalyticsDataStorageVideo: "<strong>%{storage}</strong><br/> de vídeos",
  AnalyticsDataStorageAudio: "<strong>%{storage}</strong><br/> de áudios",
  AnalyticsDataStorageImage: "<strong>%{storage}</strong><br/> de imagens",
  AnalyticsDataStorageOther:
    "<strong>%{storage}</strong><br/> de PDFs e outros",
  TitleAnalyticsTime: "Duração total de assets multimídia",
  AnalyticsDataTimeTotal:
    '<strong class="heading-1 color-default-dark">%{time}</strong><br/> de áudio e vídeo',
  AnalyticsDataTimeVideo: "<strong>%{time}</strong><br/> de vídeos",
  AnalyticsDataTimeAudio: "<strong>%{time}</strong><br/> de áudios",
  TitleAnalyticsBilling: "Dados consolidados para fins de cobrança",
  NoteAnalyticsBilling:
    "são considerados apenas os Assets processados pela inteligência artificial",
  AnalyticsDataBillingStorage:
    'Volume total do storage:<br/><strong class="heading-1 color-default-dark">%{storage}</strong>',
  AnalyticsDataBillingAmountImage:
    'Quantidade de imagens:<br/><strong class="heading-1 color-default-dark">%{amount}</strong>',
  AnalyticsDataBillingTimeVideoIntelligence:
    'Análise de imagem:<br/><strong class="heading-1 color-default-dark">%{time}</strong>',
  AnalyticsDataBillingTimeAudioInVideo:
    'Transcrição de áudio:<br/><strong class="heading-1 color-default-dark">%{time}</strong>',
  AnalyticsDataBillingTimeAudio:
    'Transcrição de áudio:<br/><strong class="heading-1 color-default-dark">%{time}</strong>',
  AnalyticsFilterNameThisMonth: "Mês atual",
  AnalyticsFilterNameLastMonth: "Mês anterior",
  AnalyticsFilterNameToday: "Hoje",
  AnalyticsFilterNameFromBegin: "Desde o início",
  AnalyticsFilterNameCustom: "Customizado",
  AnalyticsFilterNameUntilTheEnd: "Até o fim",
  LabelFilterByTags: "Tags",
  LabelFilterByCollections: "Coleções",
  AlertImageDownload: "Selecione uma resolução",
  AlertNotice: "Aviso!",
  LabelImageSizeOriginal: "Resolução Original",
  LabelImageSizeFullHD: "Resolução Alta",
  LabelImageSizeHD: "Resolução Média",
  LabelImageSizeSD: "Resolução Baixa",
  LabelProcessing: "Gerando miniatura",
  TitleVideoImage: "Imagem do vídeo",
  LoadingOriginalImage: "Carregando imagem em seu tamanho original",
  CustomFields: "Campos personalizados",
  CustomField: "Campo personalizado",
  CustomFieldTitle: "Título do campo",
  CustomFieldTitleEmpty: "Sem título",
  CustomFieldType: "Tipo do campo",
  CustomFieldTypeText: "Texto/Número",
  CustomFieldTypeInt: "Número inteiro",
  CustomFieldTypeFloat: "Número decimal",
  CustomFieldTypeDate: "Data",
  CustomFieldTypeMultiselect: "Seleção múltipla",
  CustomFieldTypeUniqueselect: "Seleção única",
  CustomFieldAdvancedInformation: "Informações avançadas",
  CustomFieldPreview: "Prévia do campo",
  CustomFieldValidation: "Validação (expressão regular)",
  CustomFieldSupportMessage: "Mensagem de apoio",
  CustomFieldOptionSelect: "Opção %{index}",
  Categories: "Categorias",
  ConfirmCategoryDeletion: "Excluir a categoria?",
  CategoryDeletedSuccessfully: "Categoria excluída com sucesso!",
  CreateNewCategoryButtonLabel: "Criar nova categoria",
  TitleCreateNewCategory: "Criar nova categoria",
  CategoryName: "Nome da categoria:",
  Fields: "Campos:",
  CreateNewFieldCategoryButtonLabel: "Novo Campo",
  TitleCategoryCreatedSuccessfully: "Categoria criada com sucesso!",
  EmptyStateCustomField:
    'Você ainda não criou nenhum campo personalizado.<br/> Clique no botão "%{buttonTitle}" para começar.',
  EmptyStateCategory:
    'Você ainda não criou nenhuma categoria.<br/> Clique no botão "%{buttonTitle}" para começar.',
  Category: "Categoria",
  NoFieldRegistered: "Nenhum campo cadastrado",
  EmptyCategoryFields: "Categorias precisam de um ou mais campos",
  TitleAlertEditCustomFieldWithSuccess: "Campos atualizados com sucesso",
  AlertFormError: "Verificar os campos marcados em vermelho",
  ErrorDeleteCategoryBound: "Não é possível excluir, há dados vinculados a ele",
  ErrorUpdateFieldBound:
    "Não é possível atualizar o campo, há dados vinculados a ele",
  ConfirmTitleLeavePage: "Atenção!",
  ConfirmDescriptionLeavePage:
    "Existem alterações não salvas, se você sair sem salvar elas serão perdidas.",
  NoFieldFilledRegistered: "Nenhum campo personalizado preenchido",
  HelpMsgIntField:
    "Este campo não é pesquisável pela busca principal do sistema, somente por filtros.",
  ConfirmRemoveCustomField:
    "Você está removendo campos personalizados desse asset. Essa alteração não poderá ser desfeita.",
  AssetName: "Nome",
  Results: "assets encontrados",
  Group: "Grupo",
  Field: "Campo",
  Collection: "Coleção",
  FromDate: "de",
  ToDate: "até",
  Type: "Tipo",
  OrderByNameAsc: "Nome do Asset (A-Z)",
  OrderByNameDesc: "Nome do Asset (Z-A)",
  OrderByRelevance: "Relevância",
  OrderByDateDesc: "Data de Upload (Mais recente)",
  OrderByDateAsc: "Date de Upload (Mais antiga)",
  OrderBySizeDesc: "Tamanho (Maiores primeiro)",
  OrderBySizeAsc: "Tamanho (Menores primeiro)",
  ClearFilters: "LIMPAR FILTROS",
  Permissions: "Permissões",
  "Can Download": "Download",
  "Can Edit/Delete": "Editar",
  "Can Delete": "Excluir",
  "Can Share": "Compartilhar",
  "Can Upload": "Upload",
  "Can View": "Visualizar assets",
  "Can View Confidence": "Nível de confiança",
  "Can Admin Category": "Campos personalizados",
  Help: "Ajuda e Suporte",
  Error: "Erro",
  ErrorShareCollection: "Não foi possível compartilhar a coleção.",
  TitleShareCollection: "Coleção compartilhada com sucesso!",
  DescriptionShareCollection: "Deseja abrir a página da coleção?",
  Open: "Abrir",
  CopyLink: "Copiar link",
  ErrorConfirmDeleteCustomField:
    "Este campo possui informações cadastradas em {count} assets. Se você excluí-lo ou mudar o seu tipo, essas informações serão perdidas.",
  Continue: "Continuar",
  ResultsCollections: "coleções encontradas",
  EmptyFieldsTitle: "Campos precisam possuir um título",
  DuplicateFieldsTitle: "Este título de campo já existe",
  DuplicateFieldsOptions: "Opções precisam possuir valores únicos",
  ConfirmSelectedUserDeletion: "Excluir o usuário selecionado?",
  DeleteSelectedUser: "Excluir usuário",
  ConfirmSelectedUsersDeletion: "Excluir todos os usuários selecionados?",
  DeleteSelectedUsers: "Excluir usuários",
  RowsPerPage: "Linhas por página",
  Of: "de",
  Sort: "Ordenar",
  Selected: "selecionados",
  Delete: "Excluir",
  Add: "Adicionar",
  ExpiredSession: "Sessão expirada, faça novamente o login.",
  NewUser: "Novo usuário",
  SinceDate: "a partir de"
};

export { brazilian_portuguese_default };

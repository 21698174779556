import AxiosHelper from "./../helpers/AxiosHelper";

const RESOURCE = "analytics/management";

const getDataAnalytics = params => {
  //   const url = `/${RESOURCE}`;
  const url = `/${RESOURCE}/?start=${params.dateStart}&end=${params.dateEnd}`;

  return AxiosHelper.doGet(url);
};

export { getDataAnalytics };

import React from "react";

// Styles
import "./LinkText.css";

class LinkText extends React.Component {
  render() {
    return (
      <span
        className={"a-linkText " + this.props.className}
        onClick={this.props.onClick}
      >
        {this.props.text}
      </span>
    );
  }
}

export default LinkText;

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setSearchValue,
  applyFilters
} from "../../pages/search-results/_actionsReducers";
// Components
import Loader from "./../../_quarks/loader/Loader";
import Button from "./../../atoms/button/Button";
import TitleSection from "./../../molecules/title-section/TitleSection";

// Translate
import { I18n } from "react-i18nify";
import { getSearchTags } from "../../../models/TagModel";

class Tags extends Component {
  state = {
    tags: {
      tagsFound: []
    }
  };

  componentDidMount() {
    const { filters } = this.props;
    if (filters.q) {
      this.doSearchTags();
    }
  }

  componentDidUpdate() {
    if (this.props.updateTags) {
      this.props.setSearchValue({ updateTags: false });
      this.doSearchTags();
    }
  }

  doSearchTags = async () => {
    const { tags } = this.state;
    const { isSearchingTags, filters } = this.props;

    if (isSearchingTags) {
      return;
    }

    try {
      this.props.setSearchValue({ isSearchingTags: true });

      let response = await getSearchTags({ query: filters.q });
      tags.tagsFound = response.data.data;
      this.setState({ tags });
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setSearchValue({ isSearchingTags: false });
    }
  };

  onClick = tag => {
    this.props.applyFilters(
      { tags: [{ id: tag.uuid, label: tag.slug }] },
      { updateAssets: true, assetViewModeByList: false },
      true
    );
  };

  render() {
    const { tags } = this.state;
    const { isSearchingTags } = this.props;

    if (!isSearchingTags && tags.tagsFound.length === 0) {
      return null;
    }

    return (
      <section
        className={"o-tags " + this.props.className}
        style={{ marginBottom: 16 }}
      >
        <TitleSection title="Tags" icon="bookmark" />

        {isSearchingTags && <Loader />}

        {!isSearchingTags && (
          <React.Fragment>
            {tags.tagsFound.length !== 0 &&
              tags.tagsFound.map(tag => (
                <Button
                  key={tag.slug}
                  size="small"
                  label={tag.slug}
                  onClick={this.onClick.bind(this, tag)}
                  className="mr-xs-2"
                />
              ))}
            {tags.tagsFound.length === 0 && <p>{I18n.t("EmptyMessageTags")}</p>}
          </React.Fragment>
        )}
      </section>
    );
  }
}
const mapStateToProps = state => ({
  filters: state.search.filters,
  updateTags: state.search.updateTags,
  isSearchingTags: state.search.isSearchingTags
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setSearchValue, applyFilters }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tags);

import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { getColor } from "../../theme/Utils";
import PropTypes from "prop-types";

/**
 * Apollo Loading
 * @author Matthaus Sousa
 *
 * @param   {String}    color   Color name of theme or HEX
 */
const Loading = styled(CircularProgress)`
  && {
    color: ${props => getColor(props.color, props.theme)};
  }
`;

Loading.propTypes = {
  color: PropTypes.string
};

export default Loading;

import React from "react";
import PropTypes from "prop-types";

import "./Row.css";

const optionsAlignY = ["top", "middle", "bottom"];
const optionsAlignX = ["start", "center", "end"];

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.classXsAlignX = this.props.xsAlignX
      ? this.props.xsAlignX + "-xs "
      : ""; // Example: .end-xs
    this.classSmAlignX = this.props.smAlignX
      ? this.props.smAlignX + "-sm "
      : ""; // Example: .end-sm
    this.classMdAlignX = this.props.mdAlignX
      ? this.props.mdAlignX + "-md "
      : ""; // Example: .end-md
    this.classLgAlignX = this.props.lgAlignX
      ? this.props.lgAlignX + "-lg "
      : ""; // Example: .end-lg
    this.classXlgAlignX = this.props.xlgAlignX
      ? this.props.xlgAlignX + "-xlg "
      : ""; // Example: .end-xlg
    this.classesAlignX =
      this.classXsAlignX +
      this.classSmAlignX +
      this.classMdAlignX +
      this.classLgAlignX +
      this.classXlgAlignX;

    this.classXsAlignY = this.props.xsAlignY
      ? this.props.xsAlignY + "-xs "
      : ""; // Example: .middle-xs
    this.classSmAlignY = this.props.smAlignY
      ? this.props.smAlignY + "-sm "
      : ""; // Example: .middle-sm
    this.classMdAlignY = this.props.mdAlignY
      ? this.props.mdAlignY + "-md "
      : ""; // Example: .middle-md
    this.classLgAlignY = this.props.lgAlignY
      ? this.props.lgAlignY + "-lg "
      : ""; // Example: .middle-lg
    this.classXlgAlignY = this.props.xlgAlignY
      ? this.props.xlgAlignY + "-xlg "
      : ""; // Example: .middle-xlg
    this.classesAlignY =
      this.classXsAlignY +
      this.classSmAlignY +
      this.classMdAlignY +
      this.classLgAlignY +
      this.classXlgAlignY;

    if (!this.classesAlignX) {
      this.classesAlignX = "";
    }

    if (!this.classesAlignY) {
      this.classesAlignY = "";
    }

    this.classNoPadding = this.props.noGutter ? "noGutter " : " ";

    this.classNoWrap = this.props.noWrap ? "noWrap " : " ";
  }

  static propTypes = {
    /** Set horizontal align (X) in breakpoint XS */
    xsAlignX: PropTypes.oneOf(optionsAlignX),
    /** Set horizontal align (X) in breakpoint SM */
    smAlignX: PropTypes.oneOf(optionsAlignX),
    /** Set horizontal align (X) in breakpoint MD */
    mdAlignX: PropTypes.oneOf(optionsAlignX),
    /** Set horizontal align (X) in breakpoint LG */
    lgAlignX: PropTypes.oneOf(optionsAlignX),
    /** Set horizontal align (X) in breakpoint XLG */
    xlgAlignX: PropTypes.oneOf(optionsAlignX),

    /** Set vertical align (Y) in breakpoint XS */
    xsAlignY: PropTypes.oneOf(optionsAlignY),
    /** Set vertical align (Y) in breakpoint SM */
    smAlignY: PropTypes.oneOf(optionsAlignY),
    /** Set vertical align (Y) in breakpoint MD */
    mdAlignY: PropTypes.oneOf(optionsAlignY),
    /** Set vertical align (Y) in breakpoint LG */
    lgAlignY: PropTypes.oneOf(optionsAlignY),
    /** Set vertical align (Y) in breakpoint XLG */
    xlgAlignY: PropTypes.oneOf(optionsAlignY),

    /** Description soon */
    noGutter: PropTypes.bool,

    /** Description soon */
    noWrap: PropTypes.bool
  };

  render() {
    return (
      <div
        className={
          "row " +
          this.classesAlignY +
          this.classesAlignX +
          this.classNoPadding +
          this.classNoWrap +
          this.props.className
        }
      >
        {this.props.children}
      </div>
    );
  }
}

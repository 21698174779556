import React from "react";

import FilterChildrenSearchable from "./FilterChildrenSearchable";
import FilterChildrenDefault from "./FilterChildrenDefault";
import FilterChildrenDate from "./FilterChildrenDate";
import FilterChildrenNumber from "./FilterChildrenNumber";

import { getCategory } from "./../../../models/CategoryModel";

class FilterPersonalizedField extends React.Component {
  state = {
    fields: []
  };

  componentDidMount = () => {
    this.getCategory();
  };

  getCategory = async () => {
    try {
      let response = await getCategory(this.props.uuid);

      this.setState({
        fields: response.data.fields
      });
    } catch (error) {}
  };

  render() {
    let { fields } = this.state;

    return (
      fields.length > 0 &&
      fields.map(field => (
        <React.Fragment key={field.uuid}>
          {field.kind === "str" && (
            <FilterChildrenSearchable
              key={field.uuid}
              name={this.props.name}
              moreInfos={{
                uuid: field.uuid,
                kind: field.kind
              }}
              label={field.label}
            />
          )}

          {(field.kind === "multiselect" || field.kind === "select") && (
            <FilterChildrenDefault
              key={field.uuid}
              name={this.props.name}
              moreInfos={{
                uuid: field.uuid,
                kind: field.kind
              }}
              label={field.label}
              items={field.options}
            />
          )}

          {field.kind === "date" && (
            <FilterChildrenDate
              key={field.uuid}
              name={this.props.name}
              label={field.label}
              moreInfos={{
                uuid: field.uuid,
                kind: field.kind
              }}
            />
          )}

          {(field.kind === "int" || field.kind === "float") && (
            <FilterChildrenNumber
              key={field.uuid}
              name={this.props.name}
              moreInfos={{
                uuid: field.uuid,
                kind: field.kind
              }}
              label={field.label}
            />
          )}
        </React.Fragment>
      ))
    );
  }
}

export default FilterPersonalizedField;

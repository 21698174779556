import styled from "styled-components";
import { Checkbox as Check } from "@material-ui/core";
import PropTypes from "prop-types";
import { getColor } from "../../theme/Utils";

/**
 * Apollo Checkbox
 * @author Matthaus Sousa
 *
 * @param   {Boolean}   checked
 * @param   {String}    color   Color name of theme or HEX
 */
const Checkbox = styled(Check)`
  && {
    ${props =>
      props.checked
        ? `color: ${getColor(props.color, props.theme, -0.2)} !important;`
        : ""}

    :hover {
      cursor: default;
    }
  }
`;

Checkbox.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string
};

export default Checkbox;

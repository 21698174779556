import React, { Component } from "react";

import "./Box.css";

class Box extends Component {
  render() {
    return (
      <div className={"q-box " + this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export default Box;

import React from "react";
import FormGroup from "../../molecules/form-group/FormGroup";
import Button from "../../atoms/button/Button";
import { I18n } from "react-i18nify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";

const errorClass = "danger";

class Form extends React.Component {
  state = {
    errors: []
  };

  getError = props => {
    return this.getErrorRegex(props) || this.getErrorRequired(props);
  };

  getErrorRegex = ({ validation, type, value }) => {
    let regex = new RegExp(validation);

    if (validation && type !== "virtualized" && !regex.test(value)) {
      return errorClass;
    } else {
      return null;
    }
  };

  getErrorRequired = ({ value, required }) => {
    if (required && (!value || (Array.isArray(value) && value.length === 0))) {
      return errorClass;
    } else {
      return null;
    }
  };

  recursiveCheckError = (children, errors) => {
    React.Children.map(children, (child, index) => {
      if (child) {
        if (child && child.type === FormGroup) {
          errors[this.index] = this.getError(child.props);
          this.error = this.error || errors[this.index];
          this.index++;
        } else if (child.props && child.props.children) {
          this.recursiveCheckError(child.props.children, errors);
        }
      }
    });

    return errors;
  };

  onClick = callback => {
    let { children } = this.props;
    let { errors } = this.state;
    this.index = 0;
    this.error = null;

    this.recursiveCheckError(children, errors);
    this.setState({ errors }, () => {
      if (!this.error && callback) {
        callback();
      } else {
        this.props.addAlert({
          id: "alertFeedbackFormError",
          theme: "danger",
          animation: "default",
          timer: "fast",
          title: I18n.t("AlertFormError")
        });
      }
    });
  };

  recursiveCloneChildren(children, errors) {
    return React.Children.map(children, child => {
      if (child) {
        if (child.type === FormGroup) {
          return React.cloneElement(child, { status: errors[this.index++] });
        } else if (child.type === Button && child.props.submit) {
          return React.cloneElement(child, {
            onClick: params => this.onClick(() => child.props.onClick(params))
          });
        } else if (child.props && child.props.children) {
          return React.cloneElement(child, {
            children: this.recursiveCloneChildren(child.props.children, errors)
          });
        } else {
          return child;
        }
      }
    });
  }

  render() {
    let { children } = this.props;
    let { errors } = this.state;
    this.index = 0;

    if (children) {
      return (
        <React.Fragment>
          {this.recursiveCloneChildren(children, errors)}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...alertActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);

import AxiosHelper from "./../helpers/AxiosHelper";

const RESOURCE = "v2/mediamanager";

class UploadModel {
  getSessionUrl = async (uuid, file_name) => {
    const url = `/${RESOURCE}/assets/upload/session/`;
    let response = await AxiosHelper.doPost(url, { uuid, file_name });
    try {
      return response.data.session_url;
    } catch (error) {
      return null;
    }
  };

  sendToAuris = (assets, assetsOptions) => {
    let url = `/${RESOURCE}/assets/upload/save/`;
    return AxiosHelper.doPost(url, { assets, assetsOptions });
  };

  removeFromStorage = (uuid, file_name) => {
    let url = `/${RESOURCE}/assets/upload/delete/`;
    return AxiosHelper.doPost(url, { uuid, file_name });
  };
}

export default UploadModel;

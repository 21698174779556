import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import {
  addBreadCrumb,
  clearBreadCrumb
} from "./../../molecules/bread-crumb/_actionsReducers";

import {
  getCategory,
  deleteCategory,
  createCategory,
  editCategory
} from "./../../../models/CategoryModel";

// Components
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Container from "./../../_quarks/grid/Container";
import Box from "./../../_quarks/box/Box";

import Button from "./../../atoms/button/Button";
import FieldEditable from "./../../atoms/field-editable/FieldEditable";

import FormGroup from "./../../molecules/form-group/FormGroup";

import UpdateCustomField from "./CategoryPage-UpdateCustomField";
// Styles
import "./CategoryPage.css";

// Authentication
import PermissionHandler from "./../../../helpers/PermissionHelper";
import Loader from "../../_quarks/loader/Loader";
import { removeAccentuation } from "../../../helpers/HumanizeHelper";

// Translate
const { I18n } = require("react-i18nify");

class CategoryPage extends Component {
  constructor(props) {
    super(props);

    let edit = false;

    if (this.props.history && this.props.history.location.state) {
      edit = this.props.history.location.state.edit;
    }

    this.state = {
      category: null,
      isGetting: true,
      isSaving: false,
      permissions: { manage_category: PermissionHandler.can_admin_category() },
      isCreatingEditing:
        edit || typeof this.props.match.params.uuid === "undefined",
      deletedCategories: [],
      openCustomFieldModal: false,
      dataFieldToUpdate: {},
      hasChanges: false
    };
  }

  componentDidMount = async () => {
    if (this.props.history) {
      this.unblock = this.props.history.block(targetLocation => {
        let hasChanges =
          this.state.hasChanges ||
          (Array.isArray(this.state.deletedCategories) &&
            this.state.deletedCategories.length > 0);

        if (hasChanges) {
          this.props.addAlert({
            id: "alertToConfirmLeavePage",
            theme: "warning",
            animation: "default",
            timer: "infinite",
            centralized: true,
            overlay: true,
            title: I18n.t("ConfirmTitleLeavePage"),
            description: I18n.t("ConfirmDescriptionLeavePage"),
            actionButtons: [
              {
                label: I18n.t("ActionLeavePage"),
                icon: "delete",
                theme: "danger",
                onClick: () =>
                  this.setState(
                    { hasChanges: false, deletedCategories: [] },
                    () => {
                      this.props.history.push(targetLocation.pathname);
                      this.props.delAlert("alertToConfirmLeavePage");
                    }
                  )
              },
              {
                label: I18n.t("Cancel"),
                theme: "light",
                kind: "ghost",
                onClick: this.props.delAlert.bind(
                  this,
                  "alertToConfirmLeavePage"
                )
              }
            ]
          });
        }
        return !hasChanges;
      });
    }

    this.props.addBreadCrumb([
      {
        label: I18n.t("CustomFields"),
        link: "/categories"
      },
      {
        label: I18n.t("Categories")
      }
    ]);

    let uuid = this.props.match.params.uuid;
    if (uuid) {
      await this._getCategory(uuid);
    }

    if (this.state.isCreatingEditing) {
      this.props.addBreadCrumb([
        {
          label: I18n.t("CustomFields"),
          link: "/categories"
        },
        {
          label: I18n.t("Categories"),
          link: "/categories"
        },
        {
          label: I18n.t("TitleCreateNewCategory")
        }
      ]);

      this.setState({ isGetting: false });
    }
  };

  componentWillUnmount = () => {
    if (this.unblock) {
      this.unblock();
    }
    this.props.clearBreadCrumb();
  };

  _getCategory = uuid =>
    getCategory(uuid)
      .then(response => {
        let category = response.data;

        this.props.addBreadCrumb([
          {
            label: I18n.t("CustomFields"),
            link: "/categories"
          },
          {
            label: I18n.t("Categories"),
            link: "/categories"
          },
          {
            label: category.name
          }
        ]);

        this.setState({
          category,
          categoryUndo: category,
          isGetting: false
        });
      })
      .catch(error => {
        this.setState({
          isGetting: false
        });
      });

  onSubmit = () => {
    let { deletedCategories } = this.state;
    let category = JSON.parse(JSON.stringify(this.state.category || {}));

    if (category.name) {
      this.setState({ isSaving: true });

      if (Array.isArray(category.fields) && category.fields.length > 0) {
        category.fields = category.fields.filter(
          (v, i) => !deletedCategories.includes(i)
        );
      }

      if (category.uuid) {
        this._editCategory(category);
      } else {
        this._createCategory(category);
      }
    }
  };

  _editCategory = category => {
    editCategory(category, category.uuid)
      .then(response => {
        this.setState(
          {
            isCreatingEditing: false,
            isSaving: false,
            categoryUndo: { ...category, force: false },
            category: { ...category, force: false },
            deletedCategories: [],
            hasChanges: false
          },
          () => this.props.history.push("/categories")
        );
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          let data = error.response.data;
          let description = "";

          if (String(data.detail).includes("There is data inside the field")) {
            description = I18n.t("ErrorConfirmDeleteCustomField");
            description = description.replace("{count}", data.count);

            this.props.addAlert({
              id: "alertToConfirmDelete",
              theme: "warning",
              animation: "default",
              timer: "infinite",
              title: I18n.t("ConfirmTitleLeavePage"),
              description,
              centralized: true,
              overlay: true,
              actionButtons: [
                {
                  label: I18n.t("Continue"),
                  icon: "delete",
                  theme: "danger",
                  actionHasLoading: true,
                  onClick: async () => {
                    this.props.delAlert("alertToConfirmDelete");
                    this._editCategory({ ...category, force: true });
                  }
                },
                {
                  label: I18n.t("Cancel"),
                  theme: "light",
                  kind: "ghost",
                  onClick: this.props.delAlert.bind(
                    this,
                    "alertToConfirmDelete"
                  )
                }
              ]
            });
          } else {
            if (data.message === "Categories need one or more fields") {
              description = I18n.t("EmptyCategoryFields");
            }
            if (
              data.detail === "Can't update field, there is data bound to it"
            ) {
              description = I18n.t("ErrorUpdateFieldBound");
            }
            if (data.message === "Duplicated name") {
              description = I18n.t("CategoryDuplicatedName");
            }

            if (description) {
              this.props.addAlert({
                id: "feedbackChangeProfile",
                theme: "danger",
                animation: "default",
                timer: "fast",
                title: I18n.t("TitleAlertServerError"),
                description
              });
            }
          }
        }

        this.setState({
          isSaving: false
        });
      });
  };

  _createCategory = category => {
    createCategory(category)
      .then(response => {
        this.props.addAlert({
          id: "feedbackCreateGroup",
          theme: "success",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleCategoryCreatedSuccessfully")
        });

        this.setState(
          {
            isSaving: false,
            deletedCategories: [],
            hasChanges: false
          },
          () => this.props.history.push("/categories")
        );
      })
      .catch(error => {
        if (error && error.response && error.response.data) {
          let data = error.response.data;
          let description = "";

          if (data.message === "Duplicated name") {
            description = I18n.t("CategoryDuplicatedName");
          }

          if (data.message === "Categories need one or more fields") {
            description = I18n.t("EmptyCategoryFields");
          }

          this.props.addAlert({
            id: "feedbackChangeProfile",
            theme: "danger",
            animation: "default",
            timer: "fast",
            title: I18n.t("TitleAlertServerError"),
            description
          });
        }
        this.setState({
          isSaving: false
        });
      });
  };

  onUpdateField = indexFieldToUpdate => {
    let dataFieldToUpdate = {};

    if (indexFieldToUpdate !== null) {
      dataFieldToUpdate = this.state.category.fields[indexFieldToUpdate];
    } else {
      dataFieldToUpdate = {
        label: null,
        kind: "str",
        regex: null,
        tooltip: null,
        options: null
      };
    }

    this.setState({
      openCustomFieldModal: true,
      dataFieldToUpdate: { ...dataFieldToUpdate, index: indexFieldToUpdate }
    });
  };

  onClickDeleteField = index => {
    let { deletedCategories } = this.state;
    deletedCategories.push(index);
    this.setState({ deletedCategories });
  };

  onClickUndeleteField = index => {
    let { deletedCategories } = this.state;
    this.setState({
      deletedCategories: deletedCategories.filter(v => v !== index)
    });
  };

  toggleEditMode = focusField => {
    if (PermissionHandler.can_edit()) {
      let { isCreatingEditing, categoryUndo } = this.state;
      this.setState(
        {
          isCreatingEditing: !isCreatingEditing,
          category: { ...categoryUndo }
        },
        () => {
          if (focusField === "name") {
            this.refCategoryName.refs.input.focus();
          }
        }
      );
    }
  };

  onClickDelete = () => {
    this.props.addAlert({
      id: "alertToConfirmDelete",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title: I18n.t("ConfirmCategoryDeletion"),
      description: I18n.t("WarningNotPossibleToUndoTheAction"),
      actionButtons: [
        {
          label: I18n.t("ActionDeleteCategory"),
          icon: "delete",
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onConfirmDelete.bind(this)
        },
        {
          label: I18n.t("Cancel"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDelete")
        }
      ]
    });
  };

  onConfirmDelete = () =>
    deleteCategory([this.state.category.uuid])
      .then(response => {
        this.props.addAlert({
          id: "alertToConfirmDelete",
          theme: "success",
          animation: "default",
          timer: "fast",
          title: I18n.t("CategoryDeletedSuccessfully")
        });

        this.props.history.push("/categories");
      })
      .catch(error => {
        this.props.addAlert({
          id: "alertToConfirmDelete",
          theme: "danger",
          animation: "shake",
          timer: "fast",
          title: I18n.t("TitleAlertCategoryNotRemoved"),
          description: I18n.t("ContentAlertCategoryNotRemoved")
        });
      });

  callbackCloseUpdateCustomField = () => {
    this.setState({
      openCustomFieldModal: false
    });
  };

  callbackSaveUpdateCustomField = dataToUpdate => {
    if (dataToUpdate.label && String(dataToUpdate.label).trim()) {
      let { category } = this.state;

      let indexField = 0;
      let fieldsOptions = category ? category.fields || [] : []; // TODO: Ver se precisa conversão stringfy
      if (
        !fieldsOptions.find(
          (f, i) =>
            removeAccentuation(f.label.trim()) ===
              removeAccentuation(dataToUpdate.label.trim()) &&
            i !== dataToUpdate.index
        )
      ) {
        let optionDuplicate = false;
        if (dataToUpdate.options) {
          for (let i = 0; i < dataToUpdate.options.length; i++) {
            for (let j = 0; j < dataToUpdate.options.length; j++) {
              if (
                i !== j &&
                removeAccentuation(dataToUpdate.options[i].trim()) ===
                  removeAccentuation(dataToUpdate.options[j].trim())
              ) {
                optionDuplicate = true;
                break;
              }
            }
            if (optionDuplicate) {
              break;
            }
          }
        }

        if (!optionDuplicate) {
          if (dataToUpdate.uuid) {
            indexField = fieldsOptions.findIndex(
              item => item.uuid === dataToUpdate.uuid
            );
          } else if (dataToUpdate.index !== null) {
            indexField = dataToUpdate.index;
          } else {
            indexField = fieldsOptions.length;
          }

          fieldsOptions[indexField] = dataToUpdate;

          this.setState({
            category: {
              ...this.state.category,
              fields: fieldsOptions
            },
            openCustomFieldModal: false,
            hasChanges: true
          });
        } else {
          this.props.addAlert({
            id: "feedbackChangeProfile",
            theme: "danger",
            animation: "default",
            timer: "fast",
            title: I18n.t("TitleAlertServerError"),
            description: I18n.t("DuplicateFieldsOptions")
          });
        }
      } else {
        this.props.addAlert({
          id: "feedbackChangeProfile",
          theme: "danger",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleAlertServerError"),
          description: I18n.t("DuplicateFieldsTitle")
        });
      }
    } else {
      this.props.addAlert({
        id: "feedbackChangeProfile",
        theme: "danger",
        animation: "default",
        timer: "fast",
        title: I18n.t("TitleAlertServerError"),
        description: I18n.t("EmptyFieldsTitle")
      });
    }
  };

  renderCreatingEditing() {
    let category = this.state.category || {};
    let { isCreatingEditing, deletedCategories } = this.state;

    return (
      <div>
        <FormGroup
          ref={ref => (this.refCategoryName = ref)}
          type="text"
          name="name"
          label={I18n.t("CategoryName")}
          value={category.name || ""}
          onChange={e => {
            category.name = e.target.value;
            this.setState({ category, hasChanges: true });
          }}
          className="o-assetModal__formGroupAssetName mb-xs-3"
        />

        <Row className="p-categoryPage__actions">
          <Col xs="6" className="p-categoryPage__actions-align-bottom">
            <p className="mb-xs-2">
              <strong>{I18n.t("Fields")}</strong>
            </p>
          </Col>
          <Col xs="6" className="text-xs-right">
            <Button
              type="button"
              onClick={() => this.onUpdateField(null)}
              label={I18n.t("CreateNewFieldCategoryButtonLabel")}
              className="mr-xs-1"
            />
          </Col>
        </Row>

        {Array.isArray(category.fields) &&
          category.fields.map((f, i) => (
            <Box
              key={i}
              className={
                "mb-xs-3 p-xs-3 clearfix " +
                (deletedCategories.includes(i)
                  ? " p-categoryPage__deleted "
                  : "")
              }
            >
              <Row xsAlignY="middle">
                <Col md="9" className="mb-xs-3 mb-md-0">
                  <h2 className="heading-3">{f.label}</h2>
                </Col>
                {!deletedCategories.includes(i) && (
                  <React.Fragment>
                    <Col xs="3" className="text-xs-right">
                      <Button
                        type="button"
                        icon="edit"
                        width="min"
                        theme="primary"
                        className="mr-xs-1"
                        onClick={() => this.onUpdateField(i)}
                      />

                      <Button
                        type="button"
                        icon="delete"
                        width="min"
                        theme="danger"
                        onClick={() => this.onClickDeleteField(i)}
                      />
                    </Col>
                  </React.Fragment>
                )}
                {deletedCategories.includes(i) && (
                  <React.Fragment>
                    <Col xs="3" className="text-xs-right">
                      <Button
                        type="button"
                        icon="undo"
                        width="min"
                        theme="light"
                        onClick={() => this.onClickUndeleteField(i)}
                      />
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </Box>
          ))}

        {(Array.isArray(category.fields) && category.fields.length === 0) ||
          (!category.fields && (
            <div className="mb-xs-3">
              <span
                className="italic lineHeightParagraph"
                dangerouslySetInnerHTML={{
                  __html: I18n.t("EmptyStateCustomField", {
                    buttonTitle: I18n.t("CreateNewFieldCategoryButtonLabel")
                  })
                }}
              />
            </div>
          ))}

        <Button
          type="button"
          onClick={this.onSubmit}
          label={I18n.t("ActionSave")}
          isLoading={this.state.isSaving}
          className="mr-xs-1"
        />

        {isCreatingEditing && category.uuid && (
          <Button
            type="button"
            onClick={() => {
              this.toggleEditMode(null);
              this.setState({ hasChanges: false }, () =>
                this.props.history.push("/categories")
              );
            }}
            label={I18n.t("ActionCancel")}
            theme="light"
          />
        )}

        {this.state.openCustomFieldModal && (
          <UpdateCustomField
            dataToUpdate={this.state.dataFieldToUpdate}
            callbackToSave={this.callbackSaveUpdateCustomField.bind(this)}
            callbackToClose={this.callbackCloseUpdateCustomField.bind(this)}
          />
        )}
      </div>
    );
  }

  renderWatching() {
    let category = this.state.category || {};

    return (
      <Row>
        <Col md="8" lg="7" xlg="8">
          <p className="lineHeightParagraph mb-xs-3">
            <FieldEditable
              type="text"
              onClick={() => this.toggleEditMode("name")}
            >
              {I18n.t("CategoryName")}
              <br />
              <strong className="heading-1">{category.name}</strong>
            </FieldEditable>
          </p>

          <p className="lineHeightParagraph">
            <FieldEditable type="text" onClick={this.toggleEditMode}>
              {I18n.t("Fields")}
              <br />
              {Array.isArray(category.fields) &&
                category.fields.map((f, i) => (
                  <span key={i} className="block-xs">
                    <span className="arrayInfo">{f.label}</span>
                  </span>
                ))}

              {Array.isArray(category.fields) && category.fields.length === 0 && (
                <strong
                  className="italic"
                  dangerouslySetInnerHTML={{
                    __html: I18n.t("EmptyStateCustomField", {
                      buttonTitle: I18n.t("ActionEdit")
                    })
                  }}
                />
              )}
            </FieldEditable>
          </p>
        </Col>

        <Col md="4" lg="5" xlg="4" className="text-xs-right">
          {PermissionHandler.can_edit() && (
            <Button
              type="button"
              icon="edit"
              label={I18n.t("ActionEdit")}
              onClick={() => this.toggleEditMode(null)}
            />
          )}
          {PermissionHandler.can_delete() && (
            <Button
              icon="delete"
              width="min"
              theme="danger"
              className="ml-xs-2"
              onClick={this.onClickDelete}
            />
          )}
        </Col>
      </Row>
    );
  }

  render() {
    let { permissions, isGetting, isCreatingEditing } = this.state;

    if (!permissions.manage_category) return <div>Acesso não autorizado</div>;

    return (
      <Container>
        <Row xsAlignX="center">
          <Col lg="7">
            {isGetting && <Loader centralized={true} />}
            {!isGetting && (
              <Box className="p-xs-4">
                <div>
                  {!isCreatingEditing && this.renderWatching()}
                  {isCreatingEditing && this.renderCreatingEditing()}
                </div>
              </Box>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  searchFilters: state.searchFilters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...alertActions,
      addBreadCrumb,
      clearBreadCrumb
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryPage);

import React from "react";
import PropTypes from "prop-types";

// Components
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Container from "./../../_quarks/grid/Container";

import Box from "./../../atoms/box/Box";
import Button from "./../../atoms/button/Button";

// Style
import "./Modal.css";
import Loader from "../../_quarks/loader/Loader";

class Modal extends React.Component {
  componentDidMount = () => {
    document.querySelector("body").style.overflow = "hidden";
  };

  componentWillUnmount = () => {
    document.querySelector("body").style.overflow = "auto";
  };

  static propTypes = {
    /** Comment soon */
    title: PropTypes.string,
    /** Comment soon */
    xs: PropTypes.string,
    /** Comment soon */
    sm: PropTypes.string,
    /** Comment soon */
    md: PropTypes.string,
    /** Comment soon */
    lg: PropTypes.string,
    /** Comment soon */
    xlg: PropTypes.string
  };

  render() {
    return (
      <div
        className={`m-modal ${
          this.props.showNavNext || this.props.showNavBack
            ? "m-modal__navControls"
            : ""
        } ${this.props.className}`}
      >
        <span onClick={this.props.toClose} className="m-modal__mask" />

        <Button
          type="button"
          kind="ghost"
          size="small"
          theme="negative"
          icon="clear"
          width="min"
          className="m-modal__close"
          onClick={this.props.toClose}
        />

        {!this.props.loading && this.props.showNavNext && (
          <Button
            type="button"
            kind="ghost"
            size="small"
            theme="negative"
            icon="right"
            width="min"
            className="m-modal__next"
            onClick={this.props.toNext}
          />
        )}

        {!this.props.loading && this.props.showNavBack && (
          <Button
            type="button"
            kind="ghost"
            size="small"
            theme="negative"
            icon="right"
            width="min"
            className="m-modal__back"
            onClick={this.props.toBack}
          />
        )}

        {this.props.loading && (
          <Loader className="m-modal__loader" centralized={true} />
        )}
        {!this.props.loading && (
          <Container className="m-modal__fullHeight">
            <Row xsAlignX="center" className="m-modal__fullHeight">
              <Col
                xs={this.props.xs}
                sm={this.props.sm}
                md={this.props.md}
                lg={this.props.lg}
                xlg={this.props.xlg}
              >
                <div className="m-modal__globalBox">
                  <Box ref="boxModal" className="m-modal__box">
                    {this.props.title && (
                      <span className="m-modal__title">{this.props.title}</span>
                    )}
                    {this.props.children}
                  </Box>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

export default Modal;

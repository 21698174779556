import React, { Component } from "react";

// Models
import { getAllGroups, deleteGroup } from "./../../../models/GroupModel";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import {
  addBreadCrumb,
  clearBreadCrumb
} from "./../../molecules/bread-crumb/_actionsReducers";

// Compontents
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Container from "./../../_quarks/grid/Container";
import Loader from "./../../_quarks/loader/Loader";
import Box from "./../../_quarks/box/Box";

import Button from "../../atoms/button/Button";

import { I18n } from "react-i18nify";

import "./GroupsList.css";

// Authentication
import PermissionHandler from "./../../../helpers/PermissionHelper";

class GroupsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: null,
      group_to_delete: null,
      isLoading: false,
      permissions: { manage_user: PermissionHandler.is_admin() }
    };
  }

  componentDidMount = () => {
    this._getGroups();

    this.props.addBreadCrumb([
      {
        label: I18n.t("Groups"),
        link: "/groups"
      }
    ]);
  };

  componentWillUnmount = () => {
    this.props.clearBreadCrumb();
  };

  _getGroups = () => {
    this.setState({ isLoading: true });
    getAllGroups()
      .then(response => {
        this.setState({ groups: response.data.data, isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  };

  onClickEdit = uuid => {
    this.props.history.push({
      pathname: "/group/" + uuid,
      state: { edit: true }
    });
  };

  onClickDelete = uuid => {
    this.setState({ group_to_delete: uuid });

    this.props.addAlert({
      id: "alertToConfirmDelete",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title: I18n.t("ConfirmGroupDeletion"),
      description: I18n.t("WarningNotPossibleToUndoTheAction"),
      actionButtons: [
        {
          label: I18n.t("ActionDeleteGroup"),
          icon: "delete",
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onConfirmDelete.bind(this)
        },
        {
          label: I18n.t("Cancel"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDelete")
        }
      ]
    });
  };

  onConfirmDelete = params => {
    return new Promise(resolve => {
      this.setState({
        isLoading: true
      });

      let uuid = this.state.group_to_delete;

      deleteGroup([uuid])
        .then(response => {
          this.setState({
            isLoading: false,
            group_to_delete: null
          });

          this._getGroups();

          this.props.addAlert({
            id: "alertToConfirmDelete",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("GroupDeletedSuccessfully")
          });
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            group_to_delete: null
          });

          this.props.addAlert({
            id: "alertToConfirmDelete",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertGroupNotRemoved"),
            description: I18n.t("ContentAlertGroupNotRemoved")
          });
        });

      resolve();
    });
  };

  render() {
    if (!this.state.permissions.manage_user)
      return <div>Acesso não autorizado</div>;

    if (this.state.isLoading) return <Loader centralized={true} />;

    if (!this.state.groups) return <div />;

    return (
      <Container>
        <Row xsAlignX="center">
          <Col md="8" lg="6" xlg="5">
            <div className="clearfix">
              <h1 className="heading-1 mb-xs-4 float-xs-left">
                {I18n.t("Groups")}
              </h1>

              <Button
                type="link"
                onClick="/groups/new"
                label={I18n.t("CreateNewGroupButtonLabel")}
                className="float-xs-right"
              />
            </div>

            {this.state.groups.map((group, index) => (
              <Box key={index} className="mb-xs-3 p-xs-3 clearfix">
                <Row xsAlignY="middle">
                  <Col md="9" className="mb-xs-3 mb-md-0">
                    <h2
                      className="heading-3 p-groupList__name"
                      onClick={() =>
                        this.props.history.push("/group/" + group.id)
                      }
                    >
                      {group.name}
                    </h2>
                  </Col>

                  <Col xs="3" className="text-xs-right">
                    <Button
                      type="button"
                      icon="edit"
                      width="min"
                      theme="primary"
                      className="mr-xs-1"
                      onClick={this.onClickEdit.bind(this, group.id)}
                    />

                    <Button
                      type="button"
                      icon="delete"
                      width="min"
                      theme="danger"
                      onClick={this.onClickDelete.bind(this, group.id)}
                    />
                  </Col>
                </Row>
              </Box>
            ))}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...alertActions,
      addBreadCrumb,
      clearBreadCrumb
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(GroupsList);

import AxiosHelper from "./../helpers/AxiosHelper";

const RESOURCE = "users";

const getAllGroups = () => {
  const url = `/${RESOURCE}/group/`;

  return AxiosHelper.doGet(url);
};

const getGroup = uuid => {
  const url = `/${RESOURCE}/group/${uuid}/details/`;

  return AxiosHelper.doGet(url);
};

const editGroup = (data, uuid) => {
  const url = `/${RESOURCE}/group/${uuid}/edit/`;

  return AxiosHelper.doPost(url, data);
};

const createGroup = data => {
  const url = `/${RESOURCE}/group/new/`;

  return AxiosHelper.doPost(url, data);
};

const deleteGroup = data => {
  const url = `/${RESOURCE}/group/delete/`;

  return AxiosHelper.doPost(url, data);
};

export { getAllGroups, getGroup, editGroup, createGroup, deleteGroup };

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import Loader from "./../../_quarks/loader/Loader";
import Icon from "./../../_quarks/icon/Icon";

import Slider from "./../../atoms/slider/Slider";

import gifAudioCallback from "./../../../imgs/a-player/audio-callback.gif";
import "./Player.css";

import { MediaPlayer } from "dashjs";

// Translate
import { I18n } from "react-i18nify";

export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.durationInSeconds = 0;
    this.isMount = false;
    this.lastVolumeProgress = 100;
    this.timeoutToHideNav = 0;
    this.volumeProgress = 100;
    this.isProcessing = true;

    this.state = {
      currentTime: "0:00",
      duration: null,
      iconVolume: null,
      isFullScreen: false,
      isLoading: true,
      isPlaying: false,
      isReady: false,
      showNav: false,
      started: false,
      timeProgress: "0%",
      timeBuffer: "0%",
      togglePlayFromMaskOnMedia: false,
      volume: null,
      //source: this.props.mpdFile[Object.keys(this.props.mpdFile)[0]],
      qualityOptions: [],
      qualityActive: {},
      showListChangeQuality: false
    };
  }

  static propTypes = {
    /** Comment soon */
    mpdFile: PropTypes.object,
    /** Comment soon */
    originalFile: PropTypes.string,
    /** Comment soon */
    kind: PropTypes.string
  };

  static defaultProps = {
    id: "assetMedia"
  };

  componentDidMount = () => {
    this.isMount = true;

    this.media.addEventListener("playing", this.listenerMediaPlaying);
    this.media.addEventListener("pause", this.listenerMediaPause);
    this.media.addEventListener("waiting", this.listenerMediaWaiting);
    this.media.addEventListener("canplay", this.listenerMediaCanPlay);
    this.media.addEventListener("timeupdate", this.listenerMediaTimeUpdate);
    this.media.addEventListener("progress", this.listenerMediaProgress);
    this.player.addEventListener(
      "fullscreenchange",
      this.listenerPlayerFullscreen
    );
    this.player.addEventListener(
      "webkitfullscreenchange",
      this.listenerPlayerFullscreen
    );
    this.player.addEventListener(
      "mozfullscreenchange",
      this.listenerPlayerFullscreen
    );
    this.player.addEventListener(
      "msfullscreenchange",
      this.listenerPlayerFullscreen
    );
    window.addEventListener("keydown", this.handleKeyPress);

    if (this.props.kind === "video")
      this.player.addEventListener("click", this.toggleQualityChange);

    if (Object.keys(this.props.mpdFile).length > 0) {
      this.isProcessing = false;
      this.setQualityOptions();
    }

    this.onChangeVolume(100);
    this.getUserPlayerPreferences();
  };

  componentWillUnmount = () => {
    this.isMount = false;

    if (this.media) {
      this.media.removeEventListener("playing", this.listenerMediaPlaying);
      this.media.removeEventListener("pause", this.listenerMediaPause);
      this.media.removeEventListener("waiting", this.listenerMediaWaiting);
      this.media.removeEventListener("canplay", this.listenerMediaCanPlay);
      this.media.removeEventListener(
        "timeupdate",
        this.listenerMediaTimeUpdate
      );
      this.media.removeEventListener("progress", this.listenerMediaProgress);
    }
    if (this.player) {
      this.player.removeEventListener(
        "fullscreenchange",
        this.listenerPlayerFullscreen
      );
      this.player.removeEventListener(
        "webkitfullscreenchange",
        this.listenerPlayerFullscreen
      );
      this.player.removeEventListener(
        "mozfullscreenchange",
        this.listenerPlayerFullscreen
      );
      this.player.removeEventListener(
        "msfullscreenchange",
        this.listenerPlayerFullscreen
      );

      if (this.props.kind === "video") {
        this.player.removeEventListener(
          "mouseleave",
          this.listenersForToggleNav
        );
        this.player.removeEventListener(
          "mouseenter",
          this.listenersForToggleNav
        );
      }

      if (this.props.kind === "video")
        this.player.removeEventListener("click", this.toggleQualityChange);
    }

    if (this.props.kind === "video" && this.mask) {
      this.mask.removeEventListener("mousemove", this.listenersForToggleNav);
    }

    if (this.props.kind === "video" && this.nav) {
      this.nav.removeEventListener("mouseenter", this.listenerNavMouseEnter);
    }

    window.removeEventListener("keydown", this.handleKeyPress);
    this.setUserPlayerPreferences();
  };

  listenersForToggleNav = event => {
    if (this.isMount) this.toggleNav(event.type);
  };

  listenerMediaProgress = () => {
    if (this.isMount) this.refresTimehBufferState(this.media.buffered.length);
  };

  listenerMediaWaiting = () => {
    if (this.isMount)
      this.setState({
        isPlaying: false,
        isLoading: true
      });
  };

  listenerMediaPlaying = () => {
    if (this.isMount)
      this.setState({
        isPlaying: true,
        started: true,
        isLoading: false
      });
  };

  listenerMediaPause = () => {
    if (this.isMount)
      this.setState({
        isPlaying: false
      });
  };

  listenerMediaTimeUpdate = () => {
    if (this.isMount) {
      this.setState({
        currentTime: this.convertSecondsForHHMMSS(this.media.currentTime),
        timeProgress: this.convertSecondsForPercentage(this.media.currentTime)
      });

      this.refreshMediaTimeStates(this.media.currentTime);

      if (this.props.onTimeChange) {
        this.props.onTimeChange(this.media.currentTime);
      }
    }
  };

  listenerMediaCanPlay = () => {
    if (this.isMount) {
      this.durationInSeconds = this.media.duration;

      if (this.state.continuePlayback) {
        this.refreshMediaTimeStates(this.media.currentTime);
      }

      this.setState({
        duration: this.convertSecondsForHHMMSS(this.durationInSeconds),
        isLoading: false,
        isReady: true,
        showNav:
          this.props.kind === "audio" ||
          !this.media.paused ||
          this.state.continuePlayback
            ? true
            : false,
        continuePlayback: false
      });

      this.refresTimehBufferState(this.media.buffered.length);

      if (this.props.kind === "video") {
        this.player.addEventListener("mouseleave", this.listenersForToggleNav);
        this.player.addEventListener("mouseenter", this.listenersForToggleNav);
        this.mask.addEventListener("mousemove", this.listenersForToggleNav);
        this.nav.addEventListener("mouseenter", this.listenerNavMouseEnter);
      }
    }
  };

  listenerNavMouseEnter = () => {
    if (this.isMount) clearTimeout(this.timeoutToHideNav);
  };

  listenerPlayerFullscreen = () => {
    if (this.isMount)
      this.setState({
        isFullScreen: !this.state.isFullScreen
      });
  };

  toggleQualityChange = e => {
    let nodeRefIconQualityToggle = ReactDOM.findDOMNode(
      this.refIconQualityToggle
    );

    // If clicked on quality toggle
    if (nodeRefIconQualityToggle.contains(e.target)) {
      this.setState({
        showListChangeQuality: !this.state.showListChangeQuality
      });
    }

    // If clicked out quality elements (icon toggle & list)
    if (
      !nodeRefIconQualityToggle.contains(e.target) &&
      (this.listChangeQuality && !this.listChangeQuality.contains(e.target))
    ) {
      this.setState({
        showListChangeQuality: false
      });
    }
  };

  setQualityOptions = () => {
    let qualityOptions = [];
    Object.keys(this.props.mpdFile).map(
      (key, index) =>
        (qualityOptions[index] = {
          name: key,
          source: this.props.mpdFile[key]
        })
    );

    this.setState({
      qualityOptions: qualityOptions
    });

    this.mediaPlayer = MediaPlayer().create();
    this.mediaPlayer.initialize(this.media, qualityOptions[0].source, false);
    this.mediaPlayer.setVolume(1);
    this.setQuality(qualityOptions[0]); // Default quality (720p)
  };

  setQuality = qualityToSet => {
    this.setState({
      qualityActive: qualityToSet,
      showListChangeQuality: false,
      isPlaying: false, // TODO: Rever isso após corrigir tempo do vídeo após troca de qualidade (está voltando pro início)
      started: false // TODO: Rever isso após corrigir tempo do vídeo após troca de qualidade (está voltando pro início)
    });

    this.mediaPlayer.attachSource(qualityToSet.source);
  };

  toggleNav = eventType => {
    if (!this.state.started) return;

    if (eventType === "mouseleave") {
      this.setState({
        showNav: this.media.paused,
        showListChangeQuality: false
      });

      clearTimeout(this.timeoutToHideNav);
    }

    if (eventType === "mouseenter") this.showNav();

    if (eventType === "mousemove") {
      if (this.state.showNav) this.prepareToHideNav();
      else this.showNav();
    }
  };

  showNav = () => {
    this.setState({
      showNav: true
    });

    this.prepareToHideNav();
  };

  prepareToHideNav = () => {
    clearTimeout(this.timeoutToHideNav);

    this.timeoutToHideNav = setTimeout(() => {
      if (this.media) {
        this.setState({
          showNav: this.media.paused,
          showListChangeQuality: false
        });
      }
    }, 3000);
  };

  handleKeyPress = e => {
    if (!e.ctrlKey && !this.props.disableShortcuts && this.state.isReady) {
      switch (e.code) {
        case "KeyM":
          this.toggleMute();
          break;
        case "KeyF":
          this.onFullScreen();
          break;
        case "ArrowUp":
          if ((this.volumeProgress || 0) < 100) {
            this.onChangeVolume((this.volumeProgress || 0) + 1);
          }
          break;
        case "ArrowDown":
          if ((this.volumeProgress || 0) > 0) {
            this.onChangeVolume((this.volumeProgress || 0) - 1);
          }
          break;
        case "ArrowRight":
          if (this.state.currentTime !== this.state.duration && this.media) {
            this.media.currentTime = (this.media.currentTime || 0) + 10;
          }
          break;
        case "ArrowLeft":
          if (this.state.currentTime !== "0:00" && this.media) {
            this.media.currentTime = (this.media.currentTime || 0) - 10;
          }
          break;
        case "Space":
          this.togglePlay();
          break;

        default:
          return;
      }
    }
  };

  getUserPlayerPreferences = () => {
    let player = JSON.parse(localStorage.getItem("Player")) || {};
    let user = JSON.parse(localStorage.getItem("User")) || {};

    if (user && this.media) {
      let user_player = player[user.uuid] || {};
      let user_media = user_player[this.props.uuid] || {};

      this.media.currentTime = user_media.currentTime || 0;
      this.refreshMediaTimeStates(user_media.currentTime || 0);
      this.onChangeVolume(
        user_player.volumeProgress >= 0 ? user_player.volumeProgress : 100
      );

      if (Math.floor(user_media.currentTime) > 0) {
        this.setState({
          showNav: true,
          continuePlayback: true
        });
      }
    }
  };

  setUserPlayerPreferences = () => {
    let player = JSON.parse(localStorage.getItem("Player")) || {};
    let user = JSON.parse(localStorage.getItem("User")) || {};

    if (user) {
      let user_player = player[user.uuid] || {};

      if (this.state.currentTime === this.state.duration) {
        delete user_player[this.props.uuid];
      } else {
        if (this.media) {
          user_player = {
            ...user_player,
            [this.props.uuid]: {
              currentTime: this.media.currentTime
            }
          };
        }
      }

      player[user.uuid] = {
        ...user_player,
        volumeProgress: this.volumeProgress
      };
      localStorage.setItem("Player", JSON.stringify(player));
    }
  };

  refreshMediaTimeStates = newCurrentTime => {
    this.setState({
      currentTime: this.convertSecondsForHHMMSS(newCurrentTime),
      timeProgress: this.convertSecondsForPercentage(newCurrentTime)
    });
  };

  refresTimehBufferState = bufferedLength => {
    if (bufferedLength) {
      let buffedEnd = this.media.buffered.end(bufferedLength - 1);

      this.setState({
        timeBuffer: this.convertSecondsForPercentage(buffedEnd)
      });
    }
  };

  convertSecondsForPercentage = timeInSeconds => {
    let percentage = (timeInSeconds * 100) / this.durationInSeconds;
    return `${percentage}%`;
  };

  convertSecondsForHHMMSS = timeInSeconds => {
    let secondsInt = parseInt(timeInSeconds, 10);
    let converted = null;

    let hours = Math.floor(secondsInt / 3600);
    let minutes = Math.floor((secondsInt - hours * 3600) / 60);
    let seconds = secondsInt - hours * 3600 - minutes * 60;

    if (seconds < 10) seconds = `0${seconds}`;

    if (!hours) {
      converted = `${minutes}:${seconds}`;
    } else {
      if (minutes < 10) minutes = `0${minutes}`;

      converted = `${hours}:${minutes}:${seconds}`;
    }

    return converted;
  };

  changeStateFullscreen = () => {
    this.setState({
      isFullScreen: !this.state.isFullScreen
    });
  };

  onChangeTimeProgress = newTimeProgress => {
    let widthSliderTime = ReactDOM.findDOMNode(
      this.sliderTime
    ).getBoundingClientRect().width; // temp

    let newSeconds =
      (this.durationInSeconds * newTimeProgress) / widthSliderTime;

    this.refreshMediaTimeStates(newSeconds);

    this.media.currentTime = newSeconds;
  };

  onChangeVolume = (newVolumeProgress, lastVolumeProgress) => {
    if (newVolumeProgress < 0) newVolumeProgress = 0;
    else if (newVolumeProgress > 100) newVolumeProgress = 100;

    let iconVolume;
    if (newVolumeProgress === 0) iconVolume = "volume-mute";
    else if (newVolumeProgress < 50) iconVolume = "volume-down";
    else iconVolume = "volume-up";

    this.setState({
      volume: newVolumeProgress + "%",
      iconVolume: iconVolume
    });

    this.volumeProgress = newVolumeProgress;
    this.lastVolumeProgress = lastVolumeProgress
      ? lastVolumeProgress
      : this.lastVolumeProgress;

    this.changePlayerVolume(newVolumeProgress);
  };

  changePlayerVolume = newVolumeProgress => {
    if (this.media) {
      let newVolume = newVolumeProgress / 100;
      this.media.volume = newVolume;
    }
  };

  toggleMute = () => {
    if (this.volumeProgress !== 0) this.onChangeVolume(0, this.volumeProgress);
    else this.onChangeVolume(this.lastVolumeProgress);
  };

  togglePlay = from => {
    if (!this.state.isPlaying) this.media.play();
    else this.media.pause();

    this.setState(
      {
        togglePlayFromMaskOnMedia: from === "maskOnMedia",
        showNav: true
      },
      () => {
        if (from === "maskOnMedia") {
          setTimeout(() => {
            this.setState({
              togglePlayFromMaskOnMedia: false
            });
          }, 900);
        }
      }
    );

    if (from === "maskOnMedia" && this.props.kind === "video") {
      this.prepareToHideNav();
    }
  };

  onFullScreen = () => {
    if (this.state.isFullScreen) {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
    } else {
      if (this.player.requestFullscreen) this.player.requestFullscreen();
      else if (this.player.webkitRequestFullscreen)
        this.player.webkitRequestFullscreen();
      else if (this.player.mozRequestFullScreen)
        this.player.mozRequestFullScreen();
      else if (this.player.msRequestFullscreen)
        this.player.msRequestFullscreen();
    }
  };

  renderVideo = () => {
    return (
      <video
        ref={media => (this.media = media)}
        className="a-player__media"
        style={{ visibility: this.state.isReady ? "visible" : "hidden" }}
      >
        {this.isProcessing && <source src={this.props.originalFile} />}

        {I18n.t("ContentDontSupportVideoMedias")}
      </video>
    );
  };

  renderAudio = () => {
    return (
      <audio
        ref={media => (this.media = media)}
        className="a-player__media"
        controlsList="nodownload"
      >
        {this.isProcessing && <source src={this.props.originalFile} />}

        {I18n.t("ContentDontSupportAudioMedias")}
      </audio>
    );
  };

  render() {
    let videoFinished = this.media
      ? this.media.currentTime === this.media.duration
      : false;

    return (
      <div
        ref={player => (this.player = player)}
        className={`a-player ${this.props.kind}`}
      >
        {this.state.isLoading && (
          <div className="a-player__inMiddle">
            <Loader className="a-player__loader" theme="negative" />
          </div>
        )}

        {this.state.isReady && (
          <div
            ref={mask => (this.mask = mask)}
            className={
              "a-player__maskOnMedia " +
              (videoFinished ? " a-player__maskOnMedia-overlay " : "")
            }
            onClick={this.togglePlay.bind(this, "maskOnMedia")}
          >
            <div className="a-player__inMiddle">
              {!this.state.started && (
                <Icon
                  className="a-player__icon a-player__maskOnMedia-icon"
                  name="video"
                />
              )}

              {this.state.started && this.state.togglePlayFromMaskOnMedia && (
                <div>
                  {this.state.isPlaying && (
                    <Icon
                      className="a-player__icon a-player__maskOnMedia-icon isAnimated"
                      name="video"
                    />
                  )}

                  {!this.state.isLoading && !this.state.isPlaying && (
                    <Icon
                      className="a-player__icon a-player__maskOnMedia-icon isAnimated"
                      name="pause"
                    />
                  )}
                </div>
              )}

              {videoFinished && (
                <Icon
                  className="a-player__icon a-player__maskOnMedia-icon"
                  name="replay"
                />
              )}
            </div>
          </div>
        )}

        {this.state.isReady && (
          <div
            ref={nav => (this.nav = nav)}
            className={`a-player__nav ${this.state.showNav ? "show" : ""}`}
          >
            <div className="a-player__nav-section">
              <Slider
                ref={slider => (this.sliderTime = slider)}
                onSlider={this.onChangeTimeProgress}
                progress={this.state.timeProgress}
                buffer={this.state.timeBuffer}
              />
            </div>
            <div className="a-player__nav-section">
              {!this.state.isPlaying && (
                <Icon
                  className="a-player__icon a-player__nav-action"
                  size="large"
                  name="video"
                  onClick={this.togglePlay.bind(this)}
                />
              )}

              {this.state.isPlaying && (
                <Icon
                  className="a-player__icon a-player__nav-action"
                  size="large"
                  name="pause"
                  onClick={this.togglePlay.bind(this)}
                />
              )}

              <Icon
                onClick={this.toggleMute.bind(this)}
                className="a-player__icon a-player__nav-action"
                size="large"
                name={this.state.iconVolume}
              />

              <Slider
                onSlider={this.onChangeVolume}
                progress={this.state.volume}
                className="a-player__volume mr-xs-3"
              />

              <span className="a-player__time">
                {this.state.currentTime} / {this.state.duration}
              </span>

              {this.props.kind === "video" && (
                <div className="a-player__actionsOnRight">
                  <Icon
                    ref={node => (this.refIconQualityToggle = node)}
                    className={`a-player__icon a-player__nav-action a-player__settings ${
                      this.state.showListChangeQuality ? "spin" : ""
                    }`}
                    size="large"
                    name="settings"
                  />

                  <Icon
                    onClick={this.onFullScreen.bind(this)}
                    className="a-player__icon a-player__nav-action a-player__nav-action--fullscreen"
                    size="large"
                    name={
                      this.state.isFullScreen
                        ? "full-screen-exit"
                        : "full-screen"
                    }
                  />
                </div>
              )}
            </div>

            {this.props.kind === "video" && this.state.qualityOptions && (
              <div
                ref={node => (this.listChangeQuality = node)}
                className={`a-player__changeQuality ${
                  this.state.showListChangeQuality ? "show" : "hide"
                }`}
              >
                <span className="a-player__changeQuality-title">
                  {I18n.t("LabelMediaQuality")}:
                </span>

                <ul>
                  {this.state.qualityOptions.map((quality, index) => (
                    <li key={index} onClick={() => this.setQuality(quality)}>
                      {this.state.qualityActive.name === quality.name ? (
                        <Icon
                          name="check"
                          theme="negative"
                          className="a-player__changeQuality-icon mr-xs-2"
                        />
                      ) : null}
                      {quality.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {this.props.kind === "video" && this.renderVideo()}

        {this.props.kind === "audio" && (
          <div>
            {this.renderAudio()}

            <div className="a-player__inMiddle">
              <img
                className={`a-player__audioCallback ${
                  this.state.isPlaying ? "active" : ""
                }`}
                src={gifAudioCallback}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

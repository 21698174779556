export default function alerts(state = [], action) {
  switch (action.type) {
    case "ADD_ALERT":
      let timeStamp = new Date().getTime();
      let idAlertForAdd = action.id;
      let oldState = state.filter(alert => alert.id !== idAlertForAdd);

      return [
        {
          timeStamp: timeStamp,
          params: action.params
        },
        ...oldState
      ];

    case "DEL_ALERT":
      let idAlertForDel = action.id;
      return state.filter(alert => alert.params.id !== idAlertForDel);

    case "CLEAR_ALERTS":
      return [];

    default:
      return state;
  }
}

import React from "react";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";
import {
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  ListItemIcon,
  Divider
} from "@material-ui/core";

import Icon from "../../Icon/Icon";
import {
  HeaderMenuItem,
  HeaderListItem,
  RootPopper
} from "./DropdownMenu.style";

class DropdownMenu extends React.PureComponent {
  handleClose = e => {
    if (this.props.anchorEl.contains(e.target)) {
      return;
    }

    if (this.props.handleClose) {
      this.props.handleClose();
    }
  };

  handleItemClick(callback) {
    if (this.props.handleClose) {
      this.props.handleClose();
    }
    if (callback) {
      callback();
    }
  }

  getOrigin(placement) {
    if (placement === "bottom-start") {
      return "top-start";
    } else if (placement === "bottom-end") {
      return "top-end";
    }
  }

  render() {
    const fixeds = Array.isArray(this.props.items)
      ? this.props.items.filter(f => f.fixed)
      : [];
    return (
      <RootPopper
        open={this.props.open || false}
        anchorEl={this.props.anchorEl}
        transition
        disablePortal
        placement={this.props.placement}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id={this.props.name}
            style={{ transformOrigin: this.getOrigin(placement) }}
          >
            <Paper>
              <ClickAwayListener onClickAway={this.handleClose}>
                <MenuList disablePadding={true}>
                  {Array.isArray(this.props.items) &&
                    this.props.items
                      .filter(f => !f.fixed)
                      .map(i => {
                        return (
                          <MenuItem
                            onClick={() => this.handleItemClick(i.onClick)}
                            key={i.label}
                          >
                            {i.icon && (
                              <ListItemIcon>
                                <Icon name={i.icon} color="primary" />
                              </ListItemIcon>
                            )}
                            <HeaderListItem
                              inset={i.icon ? true : false}
                              primary={i.label}
                            />
                          </MenuItem>
                        );
                      })}
                  {fixeds.length > 0 && <Divider />}
                  {fixeds.map(i => {
                    return (
                      <HeaderMenuItem
                        onClick={() => this.handleItemClick(i.onClick)}
                        key={i.label}
                        customComponent={i.customComponent}
                        disableGutters={i.customComponent ? true : false}
                        name={this.props.name}
                      >
                        {!i.customComponent && i.icon && (
                          <ListItemIcon>
                            <Icon name={i.icon} color="primary" />
                          </ListItemIcon>
                        )}

                        <HeaderListItem
                          customComponent={i.customComponent}
                          inset={i.icon ? true : false}
                          primary={i.customComponent || i.label}
                        />
                      </HeaderMenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </RootPopper>
    );
  }
}

DropdownMenu.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
  placement: PropTypes.string,
  name: PropTypes.string,
  handleClose: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func,
      fixed: PropTypes.bool,
      customComponent: PropTypes.object
    })
  )
};

DropdownMenu.defaultProps = {
  items: []
};

export default DropdownMenu;

import React from "react";
import styled from "styled-components";
import { Chip } from "@material-ui/core";
import Tooltip from "../Tooltip/Tooltip";

const ChipStyled = styled(Chip)`
  && {
    background-color: #ffffff;
    box-shadow: 2px 2px #cccccc;
    margin-right: ${props => props.theme.spacing.unit}px;
    margin-top: ${props => props.theme.spacing.unit}px;
    margin-bottom: ${props => props.theme.spacing.unit}px;
    ${props => props.theme.typography.subtitle1}
  }
`;

export default ({ label, value, ...other }) => {
  if (String(value).length > 50) {
    return (
      <Tooltip title={value} placement="bottom">
        <ChipStyled
          {...other}
          label={
            (label ? label + ": " : "") + String(value).substring(0, 50) + "..."
          }
        />
      </Tooltip>
    );
  } else {
    return (
      <ChipStyled {...other} label={(label ? label + ": " : "") + value} />
    );
  }
};

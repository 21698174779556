import React, { Component } from "react";
// Translate
import { I18n } from "react-i18nify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import BreadCrumb from "../../molecules/bread-crumb/BreadCrumb";
// Models
import Auth from "./../../../models/AuthModel";
import Alert from "./../../molecules/alert/Alert";
import Header from "./../../organisms/header/Header";
// Components
import Container from "./../../_quarks/grid/Container";
import Loader from "../../_quarks/loader/Loader";
import { getFiltersByURL } from "../../../helpers/FilterHelper";
import {
  applyFilters,
  setSearchValue
} from "../../pages/search-results/_actionsReducers";
import Aside from "./../../organisms/aside/Aside";
import { MuiThemeProvider, withStyles } from "@material-ui/core";

import { AsideTheme } from "../../../theme/AsideTheme";
import NewBreadCrumb from "../../molecules/bread-crumb/NewBreadCrumb";
import { logoutUser } from "../../../Utils";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F7F7F7",
    height: "100vh"
  },
  content: {
    flex: 1,
    display: "flex",
    overflow: "auto"
  },
  contentRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative"
  },
  contentScroll: {
    width: "100%",
    overflow: "auto"
  },
  breadCrumb: {
    marginTop: 1,
    marginBottom: theme.spacing.unit
  }
});

class DefaultTemplate extends Component {
  state = {
    isReady: false
  };

  componentDidMount = async () => {
    let user = Auth.getUser();

    if (Auth.isUserAuthenticated() && user) {
      I18n.setLocale(user.language);

      const { location } = this.props;
      const { pathname } = location;

      if (pathname === "/" || pathname.includes("/search")) {
        let filters = await getFiltersByURL(location.pathname, location.search);
        let hasFilters =
          filters.tags.length > 0 ||
          filters.collections.length > 0 ||
          filters.asset_type.length > 0 ||
          filters.groups.length > 0 ||
          filters.dateStart ||
          filters.dateEnd ||
          filters.personalized_field.length > 0 ||
          filters.q !== null;

        this.props.applyFilters(filters, {
          activeItem: hasFilters ? "filter" : "search",
          assetViewModeByList: filters.q ? true : false
        });
      }
      if (pathname.includes("/collections")) {
        this.props.setSearchValue({
          activeItem: "collections-outline"
        });
      }

      this.setState({
        isReady: true
      });
    } else {
      logoutUser();
    }
  };

  render() {
    const { isReady } = this.state;
    const { classes, location } = this.props;
    const showAside =
      location.pathname.includes("/search") ||
      location.pathname.includes("/collections");

    return (
      <div className={classes.root}>
        {isReady && (
          <>
            <Header />
            <main className={classes.content}>
              {showAside && (
                <MuiThemeProvider theme={AsideTheme}>
                  <Aside />
                </MuiThemeProvider>
              )}

              <div className={classes.contentRoot}>
                {this.props.breadCrumb.new && (
                  <NewBreadCrumb square className={classes.breadCrumb} />
                )}
                <div className={classes.contentScroll}>
                  {this.props.breadCrumb.old && (
                    <Container className="pt-xs-4 mb-xs-3" unlimited>
                      <BreadCrumb items={this.props.breadCrumb.old} />
                    </Container>
                  )}

                  {this.props.children}
                </div>
              </div>
            </main>

            <Alert />
          </>
        )}
        {!isReady && <Loader centralized={true} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  breadCrumb: state.breadCrumb
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ applyFilters, setSearchValue }, dispatch);

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(DefaultTemplate)
  )
);

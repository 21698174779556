import React from "react";
import PropTypes from "prop-types";

// Components
import Icon from "./../../_quarks/icon/Icon";
import Badge from "./../../_quarks/badge/Badge";

// Styles
import "./SquareLabel.css";

class SquareLabel extends React.Component {
  static propTypes = {
    /** Comment soon */
    active: PropTypes.bool,
    /** Comment soon */
    text: PropTypes.string,
    /** Comment soon */
    withIcon: PropTypes.bool
  };

  render() {
    return (
      <div
        ref={squareLabel => (this.squareLabel = squareLabel)}
        className={
          "a-squareLabel " +
          (this.props.active ? "active " : " ") +
          this.props.className +
          (this.props.count ? " a-squareLabel__marginBadge " : "")
        }
        onClick={this.props.onClick}
      >
        {this.props.withIcon && (
          <Icon name="squad" className="a-squareLabel__icon mr-xs-1" />
        )}

        <span className="a-squareLabel__text">{this.props.text}</span>

        {this.props.count && (
          <Badge className="a-squareLabel__badge" text={this.props.count} />
        )}

        {this.props.active && (
          <Icon
            name="clear"
            size="small"
            className="a-squareLabel__icon ml-xs-2"
          />
        )}
      </div>
    );
  }
}

export default SquareLabel;

import React from "react";
import TextField from "@material-ui/core/TextField";

export default props => {
  const { type, onChange, ...other } = props;

  const onKeyPress = e => {
    let key = e.key;
    let value = String(e.target.value);

    if (type === "float") {
      if (
        (key === "," && (value.indexOf(",") !== -1 || !value)) ||
        !/[,0-9]/.test(key)
      ) {
        e.preventDefault();
      }
    } else if (
      type === "int" &&
      (!/^[0-9]/.test(key) || value.replace("-", "").length === 10)
    ) {
      e.preventDefault();
    }
  };

  const onChangeValue = value => {
    if (value > 2147483647) {
      value = "2147483647";
    }
    if (value < -2147483647) {
      value = "-2147483647";
    }

    let valueFormatted = value.replace(",", ".");

    if (onChange) {
      onChange({ value: value, valueFormatted });
    }
    return value;
  };

  return (
    <TextField
      type="text"
      onChange={e => onChangeValue(e.target.value)}
      onKeyPress={onKeyPress}
      {...other}
    />
  );
};

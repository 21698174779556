import React from "react";
import PropTypes from "prop-types";

// Styles
import "./ProgressBar.css";

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inProgress: "0%" // TODO:
    };
  }

  static propTypes = {
    inProgress: PropTypes.number
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.inProgress) {
      let loadedConverted = nextProps.inProgress + "%";

      this.setState({
        inProgress: loadedConverted
      });
    }
  };

  render() {
    return (
      <div className={"a-progressBar " + this.props.className}>
        <span
          className="a-progressBar__progress"
          style={{ width: this.state.inProgress }}
        />
      </div>
    );
  }
}

export default ProgressBar;

export function getAssetDetails(params) {
  return {
    type: "GET_ASSET_DETAILS",
    params
  };
}

export function removeAssetDetails() {
  return {
    type: "REMOVE_ASSET_DETAILS"
  };
}

export function toggleEnableToDeleteAsset() {
  return {
    type: "TOGGLE_ENABLE_TO_DELETE_ASSET"
  };
}

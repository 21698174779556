import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { applyFilters } from "../../pages/search-results/_actionsReducers";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import Tooltip from "../../Tooltip/Tooltip";

class FilterCheckbox extends React.Component {
  onChange = event => {
    let { data, filter, name, index } = this.props;
    const checked = event.target.checked;

    if (name === "personalized_field") {
      let field = {
        uuid: data.moreInfos.uuid,
        kind: data.moreInfos.kind,
        label: this.props.label,
        values: index >= 0 ? filter[index].values : []
      };

      if (checked) {
        field.values.push(data.id);
      } else {
        field.values = field.values.filter(v => v !== data.id);
      }

      if (field.values.length === 0) {
        filter = filter.filter(v => v.uuid !== field.uuid);
      } else if (index >= 0) {
        filter[index] = field;
      } else {
        filter.push(field);
      }
    } else {
      if (checked) {
        filter.push(data);
      } else {
        filter = filter.filter(v => v.id !== data.id);
      }
    }

    this.props.applyFilters({ [name]: filter }, { updateAssets: true });
  };

  render() {
    let { checked, data } = this.props;

    if (String(data.label).length > 20) {
      return (
        <Tooltip title={data.label} placement="bottom">
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={this.onChange}
                color="primary"
              />
            }
            label={String(data.label).substring(0, 20) + "..."}
          />
        </Tooltip>
      );
    } else {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={this.onChange}
              color="primary"
            />
          }
          label={data.label}
        />
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  let filter = state.search.filters[props.name];
  let checked = false;
  let index = null;

  if (props.data.moreInfos) {
    index = filter.findIndex(v => v.uuid === props.data.moreInfos.uuid);
    if (index >= 0) {
      checked = filter[index].values.find(
        v => String(v) === String(props.data.id)
      )
        ? true
        : false;
    }
  } else {
    checked = filter.find(v => String(v.id) === String(props.data.id))
      ? true
      : false;
  }

  return {
    filter,
    checked,
    index
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ applyFilters }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCheckbox);

import React from "react";
import PropTypes from "prop-types";

// Components
import Icon from "./../../_quarks/icon/Icon";

// Style
import "./NewTimestamp.css";
import { removeAccentuation } from "../../../helpers/HumanizeHelper";

class NewTimestamp extends React.Component {
  state = {
    isActive: this.props.isActive,
    maxHeightDropdown: 0,
    statusItems: [],
    formattedConfidences: []
  };

  refItemTimestamp = [];
  timesInSeconds = [];

  static propTypes = {
    amount: PropTypes.number.isRequired,
    callBackTimestamp: PropTypes.func.isRequired,
    icon: PropTypes.string,
    isActive: PropTypes.bool,
    label: PropTypes.string.isRequired,
    times: PropTypes.array.isRequired,
    enableDropdown: PropTypes.bool
  };

  componentDidMount = () => {
    this.setStrongInText();

    if (this.props.enableDropdown && this.props.amount > 1) {
      this.setMaxHeightDropdown();
    }

    if (this.props.enableDropdown) {
      this.convertTimesToSeconds();
    }

    if (this.props.times[0].confidence) {
      this.formatConfidences(this.props.times);
    }
  };

  componentDidUpdate = prevProps => {
    if (prevProps.isActive !== this.props.isActive) {
      this.setState({
        isActive: this.props.isActive
      });
    }

    if (
      this.state.isActive &&
      prevProps.currentTime !== this.props.currentTime
    ) {
      this.setActiveItem();
    }
  };

  setActiveItem = () => {
    let statusItems = this.state.statusItems;

    this.timesInSeconds.map(
      (item, index) =>
        (statusItems[index] =
          this.props.currentTime >= this.timesInSeconds[index].start_time &&
          this.props.currentTime <= this.timesInSeconds[index].end_time)
    );

    this.setState({
      statusItems: statusItems
    });
  };

  convertTimesToSeconds = () => {
    this.props.times.map(
      (item, index) =>
        (this.timesInSeconds[index] = {
          start_time: this.formatHumanTimeToSeconds(item.start_time),
          end_time: this.formatHumanTimeToSeconds(item.end_time)
        })
    );
  };

  formatHumanTimeToSeconds = time => {
    let timeFormated = time.split(":");
    return (
      +timeFormated[0] * 60 * 60 + +timeFormated[1] * 60 + +timeFormated[2]
    );
  };

  onClickTarget = () => {
    if (this.props.amount === 1) {
      this.goToTime(this.props.times[0].start_time);
    } else {
      this.setState({
        isActive: !this.state.isActive
      });

      this.props.callBackTimestamp();
    }
  };

  goToTime = timeToGo => {
    this.props.callBackTimestamp({ time: timeToGo, label: this.props.label });
  };

  setMaxHeightDropdown = () => {
    let maxHeight = 0;

    this.refItemTimestamp.map(
      (item, index) => (maxHeight += this.refItemTimestamp[index].offsetHeight)
    );

    this.setState({
      maxHeightDropdown: `${maxHeight}px`
    });
  };

  formatConfidences = unformattedTimes => {
    let formattedConfidences = unformattedTimes.map(item => {
      let thisConfidenceFormatted = Math.trunc(item.confidence * 100);
      thisConfidenceFormatted = thisConfidenceFormatted + "%";
      return thisConfidenceFormatted;
    });

    this.setState({ formattedConfidences });
  };

  setStrongInText = () => {
    if (Array.isArray(this.props.searchTerm)) {
      let label = this.props.label;
      let matches = [];

      this.props.searchTerm.map(item => {
        let item2 = new RegExp(
          removeAccentuation(String(item)).replace(/"/g, ""),
          "gi"
        ); // For conside all occourrences (g) and case insentive (i)

        label = removeAccentuation(label);
        label = label.replace(item2, (match, index, word) => {
          let diff = word.length - this.props.label.length;
          matches.push({ match, index: index - diff });
          return `<strong>${match}</strong>`;
        });

        return null;
      });

      matches.map(
        m =>
          (label = label.replace(
            m.match,
            this.props.label.substr(m.index, m.match.length)
          ))
      );

      this.setState({ label });
    }
  };

  render() {
    const { label } = this.state;

    return (
      <div
        className={`a-newTimestamp ${this.props.className} ${
          this.props.enableDropdown && this.state.isActive
            ? "active"
            : "inactive"
        }`}
      >
        <div
          className={`a-newTimestamp__target ${
            this.props.amount === 1 &&
            this.state.isActive &&
            this.state.statusItems[0]
              ? "active"
              : ""
          }`}
          onClick={this.onClickTarget.bind(this)}
        >
          {this.props.icon && (
            <Icon
              className="a-newTimestamp__target-icon"
              name={this.props.icon}
            />
          )}

          <span className="a-newTimestamp__target-label">
            <span
              dangerouslySetInnerHTML={{ __html: label || this.props.label }}
            />
            {this.props.amount === 1 && this.state.formattedConfidences[0] && (
              <span className="a-newTimestamp__labelComplement">
                {" "}
                ({this.state.formattedConfidences[0]})
              </span>
            )}
          </span>

          {this.props.amount === 1 && (
            <span className="a-newTimestamp__time">
              {this.props.times[0].start_time}
            </span>
          )}

          {this.props.amount > 1 && (
            <span className="a-newTimestamp__target-badge">
              {this.props.amount}

              {this.props.enableDropdown && (
                <Icon
                  name="expand"
                  className="a-newTimestamp__target-iconExpand"
                />
              )}
            </span>
          )}
        </div>

        {this.props.enableDropdown && this.props.amount > 1 && (
          <ul
            className="a-newTimestamp__dropdown"
            style={{ maxHeight: this.state.maxHeightDropdown }}
          >
            {this.props.times.map((item, index) => (
              <li
                key={index}
                ref={item => (this.refItemTimestamp[index] = item)}
                className={`a-newTimestamp__dropdown-item ${
                  this.state.statusItems[index] ? "active" : ""
                }`}
                onClick={this.goToTime.bind(this, item.start_time)}
              >
                <Icon
                  name="sublevel"
                  size="small"
                  className="a-newTimestamp__dropdown-icon"
                />

                <span>
                  {this.props.label}
                  {this.state.formattedConfidences[0] && (
                    <span className="a-newTimestamp__labelComplement">
                      {" "}
                      ({this.state.formattedConfidences[index]})
                    </span>
                  )}
                </span>

                <span className="a-newTimestamp__time">{item.start_time}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default NewTimestamp;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import "./all.css";
import Analytics from "./components/pages/analytics/Analytics";
import CategoriesList from "./components/pages/categories/CategoriesList";
import CategoryPage from "./components/pages/category-page/CategoryPage";
import Collections from "./components/pages/collections/Collections";
import ForgotPassword from "./components/pages/forgot-password/ForgotPassword";
import GroupPage from "./components/pages/group-page/GroupPage";
import GroupsList from "./components/pages/groups/GroupsList";
import Login from "./components/pages/login/Login";
import NewPassword from "./components/pages/new-password/NewPassword";
import ProfilePage from "./components/pages/profile-page/ProfilePage";
import SearchInitial from "./components/pages/search-initial/SearchInitial";
import SearchResults from "./components/pages/search-results/SearchResults";
import SharedCollections from "./components/pages/SharedCollections";
import Upload from "./components/pages/upload/Upload";
import UserList from "./components/pages/users/UserList";
import CheckinTemplate from "./components/templates/checkin/CheckinTemplate";
import DefaultTemplate from "./components/templates/default/DefaultTemplate";
import history from "./history";
import "./i18n";
import Auth from "./models/AuthModel";
import { register } from "./registerServiceWorker";
import store from "./store";
import theme from "./theme/default";
import ThemeRoot from "./theme/ThemeRoot";
import { logoutUser } from "./Utils";

const PrivateAppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let user = Auth.getUser();
      if (Auth.isUserAuthenticated() && user) {
        if (Layout) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        } else {
          return <Component {...props} />;
        }
      } else {
        logoutUser();
      }
    }}
  />
);

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const HomeRoute = ({ ...rest }) => (
  <Route
    {...rest}
    render={props => {
      let user = Auth.getUser();
      if (Auth.isUserAuthenticated() && user) {
        if (
          user &&
          user.configurations &&
          user.configurations.initial_search_screen === "1"
        ) {
          return <SearchInitial />;
        } else {
          return (
            <DefaultTemplate>
              <SearchResults />
            </DefaultTemplate>
          );
        }
      } else {
        logoutUser();
      }
    }}
  />
);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ThemeRoot>
        <Router history={history}>
          <Switch>
            <AppRoute
              exact
              path="/login"
              component={Login}
              layout={CheckinTemplate}
            />

            <AppRoute
              path="/shared"
              component={SharedCollections}
              layout={React.Fragment}
            />

            <AppRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
              layout={CheckinTemplate}
            />

            <AppRoute
              exact
              path="/new-password/:token"
              component={NewPassword}
              layout={CheckinTemplate}
            />

            <HomeRoute exact path="/" />

            <PrivateAppRoute
              path="/search"
              component={SearchResults}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/collections"
              component={Collections}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/profile/:uuid"
              component={ProfilePage}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/upload"
              component={Upload}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/users"
              component={UserList}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/users/new"
              component={ProfilePage}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/groups"
              component={GroupsList}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/groups/new"
              component={GroupPage}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/group/:uuid"
              component={GroupPage}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/categories"
              component={CategoriesList}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/categories/new"
              component={CategoryPage}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/category/:uuid"
              component={CategoryPage}
              layout={DefaultTemplate}
            />

            <PrivateAppRoute
              exact
              path="/analytics"
              component={Analytics}
              layout={DefaultTemplate}
            />
          </Switch>
        </Router>
      </ThemeRoot>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

register();

import React from "react";
import PropTypes from "prop-types";

// Components
import Icon from "./../../_quarks/icon/Icon";
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";

import Button from "./../../atoms/button/Button";
import ProgressBar from "./../../atoms/progress-bar/ProgressBar";

import AssetItem from "./../../molecules/asset-item/AssetItem";

// Styles
import "./DropFiles.css";

// Translate
import { I18n } from "react-i18nify";

class DropFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contProgress: null,
      dragging: false
    };
  }

  static propTypes = {
    files: PropTypes.array,
    onDeleteUploadFile: PropTypes.func,
    onClickRetry: PropTypes.func,
    callbackOnDrop: PropTypes.func.isRequired
  };

  componentWillUpdate = () => {
    let totalProcessed = 0;

    if (this.props.files) {
      this.props.files.map(asset => (totalProcessed += asset.contProgress));
      let total = this.props.files.length * 100;
      let contProgress = (totalProcessed * 100) / total;

      if (total && totalProcessed && contProgress !== this.state.contProgress) {
        this.setState({ contProgress });
      }
    }
  };

  componentDidMount = () => {
    this.dropZone.addEventListener("drop", this.onDrop);
    this.dropZone.addEventListener("dragover", this.onDragOver);
    this.dropZone.addEventListener("dragleave", this.onDragLeave);
  };

  componentWillUnmount = () => {
    this.dropZone.removeEventListener("drop", this.onDrop);
    this.dropZone.removeEventListener("dragover", this.onDragOver);
    this.dropZone.removeEventListener("dragleave", this.onDragLeave);
  };

  onDrop = event => {
    event.stopPropagation();
    event.preventDefault();

    if (event.dataTransfer.files) {
      this.props.callbackOnDrop(event.dataTransfer.files);

      this.setState({
        dragging: false
      });
    }
  };

  onDragOver = event => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.state.dragging) {
      this.setState({
        dragging: true
      });
    }
  };

  onDragLeave = () => {
    this.setState({
      dragging: false
    });
  };

  render() {
    return (
      <div
        ref={dropZone => (this.dropZone = dropZone)}
        className={`m-dropFiles ${this.state.dragging ? "dragging" : ""} ${
          this.props.className
        }`}
      >
        <Row>
          <Col xs="12" md="9" xlg="7">
            <div className="m-dropFiles__actions px-xs-2">
              <Button
                className="mb-xs-2 mr-xs-3"
                type="file"
                onChange={this.props.onChange}
                label={I18n.t("ActionSelectFiles")}
                icon="upload"
                id="upload"
              />

              {this.state.contProgress !== null &&
                this.state.contProgress < 100 && (
                  <ProgressBar
                    inProgress={this.state.contProgress}
                    className="m-dropFiles__actions-progressBar mt-xs-2"
                  />
                )}

              {this.state.contProgress === 100 && (
                <div className="color-states-success">
                  <Icon name="check" className="mr-xs-1 mt-xs-2" />

                  {I18n.t("FeedbackAllAssetsSent")}
                </div>
              )}
            </div>
          </Col>
        </Row>

        <span className="m-dropFiles__legend">
          <Icon name="upload" className="mr-xs-2" />

          {I18n.t("ActionDropFiles")}
        </span>

        <Row className="m-dropFiles__row">
          {this.props.files.map((asset, index) => (
            <Col key={index} xs="3" lg="2" className="m-dropFiles__col">
              <AssetItem
                data={asset}
                sample
                error={asset.error}
                onClickRetry={this.props.onClickRetry.bind(this)}
                inProgress={asset.contProgress}
                onClickDelete={this.props.onClickDelete.bind(this)}
              />
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

export default DropFiles;

import React, { Component } from "react";

// Components
import Box from "./../../_quarks/box/Box";

// Styles
import "./Dropdown.css";

class Dropdown extends Component {
  render() {
    return (
      <Box
        className={`m-dropdown ${this.props.align} ${this.props.kind} ${
          this.props.className
        }`}
      >
        {this.props.children}
      </Box>
    );
  }
}

export default Dropdown;

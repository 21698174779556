import { typography } from "./typography";
import { palette } from "./palette";
import { spacing } from "./spacing";
import { size } from "./size";
import { shadows } from "./shadows";
import { transitions } from "./transitions";
import { borderRadius } from "./border";
import { zIndex } from "./zIndex";

export default {
  typography,
  palette,
  spacing,
  size,
  shadows,
  transitions,
  borderRadius,
  zIndex
};

import React from "react";
import Waypoint from "react-waypoint";
import { connect } from "react-redux";
import { I18n } from "react-i18nify";

import FilterCheckbox from "./FilterCheckbox";
import Loader from "./../../_quarks/loader/Loader";

import { getSearchCollections } from "./../../../models/CollectionModel";
import { getSearchTags } from "./../../../models/TagModel";
import { getSearchValuesOnStringField } from "./../../../models/CategoryModel";

import {
  InputAdornment,
  TextField,
  withStyles,
  Typography
} from "@material-ui/core";
import Icon from "../../Icon/Icon";

const limitItems = 20;

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit}px 0px`
  },
  boxItems: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 182,
    overflowY: "auto"
  },
  notFound: {
    color: theme.palette.common.white,
    fontStyle: "italic"
  },
  searchIcon: {
    color: theme.palette.grey[500]
  }
});

class FilterChildrenSearchable extends React.Component {
  state = {
    calledSearch: false,
    enablePagination: true,
    isSearchResults: false,
    isLoading: false,
    items: [],
    query: ""
  };

  componentDidMount() {
    this.getItems({ startItem: 0 });
  }

  getItems = async params => {
    this.setState({
      calledSearch: params.startItem === 0,
      isLoading: true
    });

    try {
      let searchItems;
      let paramsToSearch = {
        query: this.state.query,
        start: params.startItem,
        limit: limitItems
      };

      if (this.props.name === "collections") {
        searchItems = getSearchCollections(paramsToSearch);
      }

      if (this.props.name === "tags") {
        searchItems = getSearchTags(paramsToSearch);
      }

      if (this.props.name === "personalized_field") {
        searchItems = getSearchValuesOnStringField(
          this.props.moreInfos.uuid,
          paramsToSearch
        );
      }

      let response = await searchItems;

      let newItems;

      if (this.props.name === "collections") {
        newItems = response.data.data.map(item => ({
          id: item.uuid,
          label: item.name
        }));
      } else if (this.props.name === "tags") {
        newItems = response.data.data.map(item => ({
          id: item.slug,
          label: item.slug
        }));
      } else {
        newItems = response.data.values.map(item => ({
          id: item,
          label: item,
          moreInfos: this.props.moreInfos
        }));
      }

      if (params.startItem !== 0) {
        newItems = this.state.items.concat(newItems);
      }

      this.setState({
        calledSearch: false,
        enablePagination: response.data.count > newItems.length,
        isLoading: false,
        isSearchResults: this.state.query ? true : false,
        items: newItems
      });
    } catch (error) {
      this.setState({
        calledSearch: false,
        isLoading: false
      });
    }
  };

  onChangeSearch = query => {
    this.setState({ query });

    clearTimeout(this.timeoutToSearch);

    this.timeoutToSearch = setTimeout(() => {
      this.getItems({ startItem: 0 });
    }, 500);
  };

  onPaginationGetItems = () => {
    this.getItems({ startItem: this.state.items.length });
  };

  renderItens = () => {
    let { items } = this.state;
    let filterItems = [];

    items.map(item =>
      filterItems.push(
        <React.Fragment key={item.id}>
          <FilterCheckbox
            name={this.props.name}
            data={item}
            label={this.props.label}
          />
        </React.Fragment>
      )
    );

    return filterItems;
  };

  render() {
    let {
      calledSearch,
      enablePagination,
      isLoading,
      isSearchResults,
      items
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.props.label && (
          <Typography variant="subtitle1">{this.props.label}</Typography>
        )}

        <TextField
          fullWidth
          margin="normal"
          placeholder={I18n.t("ActionSearch") + " " + (this.props.label || "")}
          type="text"
          value={this.state.query}
          onChange={e => this.onChangeSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  name="search"
                  size="small"
                  className={classes.searchIcon}
                />
              </InputAdornment>
            )
          }}
        />

        {isSearchResults && !items.length && !isLoading && (
          <span className={classes.notFound}>Nenhum resultado encontrado.</span>
        )}

        {items && (
          <div className={classes.boxItems}>
            {this.renderItens()}
            {enablePagination && !isLoading && !calledSearch && (
              <Waypoint onEnter={this.onPaginationGetItems.bind(this)} />
            )}

            {(enablePagination || calledSearch) && (
              <Loader theme="negative" centralized />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  filters: state.search.filters
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterChildrenSearchable)
);

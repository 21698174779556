import React from "react";
import PropTypes from "prop-types";

import Icon from "./../../_quarks/icon/Icon";

import Button from "./../../atoms/button/Button";

import "./TitleSection.css";

class TitleSection extends React.Component {
  static propTypes = {
    /** Comment soon */
    title: PropTypes.string.isRequired,
    /** Comment soon */
    icon: PropTypes.string,
    /** Comment soon */
    buttonLabel: PropTypes.string,
    /** Comment soon */
    buttonOnClick: PropTypes.string,
    /** Comment soon */
    buttonKind: PropTypes.string,
    /** Comment soon */
    buttonTheme: PropTypes.string
  };

  static defaultProps = {
    buttonKind: "outline",
    buttonTheme: "light"
  };

  render() {
    return (
      <div className="m-titleSection">
        <h2 className="m-titleSection__title">
          {this.props.icon && (
            <Icon className="m-titleSection__icon" name={this.props.icon} />
          )}

          {this.props.title}
        </h2>

        {this.props.buttonLabel && (
          <div>
            <Button
              type="link"
              onClick={this.props.buttonOnClick}
              label={this.props.buttonLabel}
              kind={this.props.buttonKind}
              theme={this.props.buttonTheme}
            />
          </div>
        )}
      </div>
    );
  }
}

export default TitleSection;

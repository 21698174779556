import React, { Component } from "react";
import PropTypes from "prop-types";

import "./DropdownContainer.css";

class DropdownContainer extends Component {
  static propTypes = {
    /** Comment soon */
    align: PropTypes.oneOf(["left", "right"])
  };

  static defaultProps = {
    align: "left"
  };

  handleClick = e => {
    if (this.node.contains(e.target)) {
      if (this.refs.target.contains(e.target)) {
        // If click in target
        this.refs.dropdown.classList.toggle("show");
      }
    } else {
      // If click outside dropdown
      this.refs.dropdown.classList.remove("show");
    }
  };

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClick);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClick);
  };

  render() {
    return (
      <div
        ref={node => (this.node = node)}
        className={"m-dropdownContainer " + this.props.className}
      >
        <div ref="target" className="m-dropdownContainer__target">
          {this.props.children[0]}
        </div>

        <div
          ref="dropdown"
          className={"m-dropdownContainer__dropdown " + this.props.align}
          onClick={() => this.refs.dropdown.classList.remove("show")}
        >
          {this.props.children[1]}
        </div>
      </div>
    );
  }
}

export default DropdownContainer;

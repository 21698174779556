import styled from "styled-components";

export default styled.div`
  [typography="body1"] {
    ${props => props.theme.typography.body1}
  }
  [typography="subtitle1"] {
    ${props => props.theme.typography.subtitle1}
  }
  [typography="subtitle2"] {
    ${props => props.theme.typography.subtitle2}
  }
`;

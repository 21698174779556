import React from "react";

// Style
import "./Timestamp.css";
import { removeAccentuation } from "../../../helpers/HumanizeHelper";

class Timestamp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time: this.props.time,
      text: ""
    };
  }

  componentDidMount = () => {
    this.setStrongInText();
  };

  setStrongInText = () => {
    if (Array.isArray(this.props.searchTerm)) {
      let text = this.props.text;

      let matches = [];

      this.props.searchTerm.map(item => {
        let item2 = new RegExp(
          String(removeAccentuation(item)).replace(/"/g, ""),
          "gi"
        ); // For conside all occourrences (g) and case insentive (i)

        text = removeAccentuation(text);
        text = text.replace(item2, (match, index, word) => {
          let diff = word.length - this.props.text.length;
          matches.push({ match, index: index - diff });
          return `<strong>${match}</strong>`;
        });

        return null;
      });

      matches.map(
        m =>
          (text = text.replace(
            m.match,
            this.props.text.substr(m.index, m.match.length)
          ))
      );

      this.setState({ text });
    }
  };

  render() {
    try {
      let time = this.state.time.toString();
      if (time.indexOf(".") >= 0) time = time.substring(0, time.indexOf("."));
      return (
        <div
          className={
            "a-timestamp " +
            this.props.className +
            (this.props.current ? " a-timestamp__current " : "")
          }
          onClick={this.props.onClick}
        >
          <span
            className={
              "a-timestamp__time " +
              (this.props.current ? " a-timestamp__time-current " : "")
            }
          >
            {time}
          </span>
          <span
            className="a-timestamp__text"
            dangerouslySetInnerHTML={{
              __html: this.state.text || this.props.text
            }}
          />
        </div>
      );
    } catch (e) {
      return <div>erro2</div>;
    }
  }
}

export default Timestamp;

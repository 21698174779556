import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { applyFilters } from "../../pages/search-results/_actionsReducers";
import { withStyles } from "@material-ui/core/styles";

import InputDate from "../../Input/InputDate";
import Icon, { getIcon } from "../../Icon/Icon";
import { Select, MenuItem, Typography, Button } from "@material-ui/core";
import { getDateFormatted } from "../../../helpers/HumanizeHelper";

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit}px 0px`
  },
  applyButton: {
    marginTop: `${theme.spacing.unit}px`,
    marginLeft: "auto",
    display: "flex",
    justifyContent: "flex-end"
  },
  select: {
    margin: `${theme.spacing.unit}px 0px`
  },
  optionSelected: {
    color: theme.palette.common.white
  },
  inputContainer: {
    display: "flex",
    alignItems: "flex-end",
    padding: `${theme.spacing.unit}px 0px`
  },
  dateLabel: {
    marginRight: theme.spacing.unit,
    width: 30
  },
  calendarIcon: {
    color: theme.palette.grey[500]
  },
  calendarIconPosition: {
    padding: 2
  }
});

class FilterChildrenDate extends React.Component {
  constructor(props) {
    super(props);

    let dateStart = getDateFormatted(props.dateStart);
    let dateEnd = getDateFormatted(props.dateEnd);
    let singleDate = getDateFormatted(props.singleDate);

    this.state = {
      showFormBetween: props.operator === "between" ? true : false,
      operator: props.operator,
      dateStart: dateStart.date,
      dateStartFormatted: dateStart.dateFormatted,
      dateEnd: dateEnd.date,
      dateEndFormatted: dateEnd.dateFormatted,
      singleDate: singleDate.date,
      singleDateFormatted: singleDate.dateFormatted,
      options: [
        { value: "between", label: "Intervalo de datas" },
        { value: "e", label: "Data específica" },
        { value: "gte", label: "A partir de" },
        { value: "lte", label: "Até" }
      ]
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  applyFilter = () => {
    const {
      singleDateFormatted,
      dateEndFormatted,
      dateStartFormatted,
      operator
    } = this.state;
    const { moreInfos } = this.props;

    if (
      (singleDateFormatted && operator !== "between") ||
      (dateStartFormatted && dateEndFormatted && operator === "between")
    ) {
      if (this.props.moreInfos) {
        let { personalized_field } = this.props.filters;
        personalized_field = personalized_field.filter(
          v => v.uuid !== moreInfos.uuid
        );

        personalized_field.push({
          uuid: moreInfos.uuid,
          kind: moreInfos.kind,
          operator,
          label: this.props.label,
          values:
            operator === "between"
              ? [dateStartFormatted, dateEndFormatted]
              : [singleDateFormatted]
        });

        this.props.applyFilters(
          { personalized_field, dateLabel: null },
          { updateAssets: true }
        );
      } else {
        if (operator === "lte") {
          this.props.applyFilters(
            { dateStart: null, dateEnd: singleDateFormatted, dateLabel: null },
            { updateAssets: true }
          );
        } else if (operator === "gte") {
          this.props.applyFilters(
            { dateStart: singleDateFormatted, dateEnd: null, dateLabel: null },
            { updateAssets: true }
          );
        } else if (operator === "e") {
          this.props.applyFilters(
            {
              dateStart: singleDateFormatted,
              dateEnd: singleDateFormatted,
              dateLabel: null
            },
            { updateAssets: true }
          );
        } else {
          this.props.applyFilters(
            {
              dateStart: dateStartFormatted,
              dateEnd: dateEndFormatted,
              dateLabel: null
            },
            { updateAssets: true }
          );
        }
      }
    }
  };

  render() {
    let {
      showFormBetween,
      singleDate,
      dateStart,
      dateEnd,
      options,
      operator
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.props.label && (
          <Typography variant="subtitle1">{this.props.label}</Typography>
        )}
        <Select
          fullWidth
          autoWidth
          className={classes.select}
          IconComponent={getIcon("expand-more")}
          value={operator || "e"}
          onChange={e =>
            this.setState({
              showFormBetween: e.target.value === "between" ? true : false,
              operator: e.target.value
            })
          }
          renderValue={value => (
            <Typography variant="body1" className={classes.optionSelected}>
              {options.find(v => v.value === value).label}
            </Typography>
          )}
        >
          {options.map(op => (
            <MenuItem key={op.value} value={op.value}>
              <Typography variant="body1">{op.label}</Typography>
            </MenuItem>
          ))}
        </Select>

        {showFormBetween && (
          <React.Fragment>
            <div className={classes.inputContainer}>
              <Typography variant="body2" className={classes.dateLabel}>
                De:
              </Typography>
              <InputDate
                fullWidth
                value={dateStart}
                onChange={({ value, valueFormatted }) =>
                  this.setState({
                    dateStart: value,
                    dateStartFormatted: valueFormatted
                  })
                }
                KeyboardButtonProps={{
                  className: classes.calendarIconPosition
                }}
                keyboardIcon={
                  <Icon
                    name="calendar-today"
                    className={classes.calendarIcon}
                  />
                }
              />
            </div>
            <div className={classes.inputContainer}>
              <Typography variant="body2" className={classes.dateLabel}>
                Até:
              </Typography>
              <InputDate
                fullWidth
                value={dateEnd}
                onChange={({ value, valueFormatted }) =>
                  this.setState({
                    dateEnd: value,
                    dateEndFormatted: valueFormatted
                  })
                }
                KeyboardButtonProps={{
                  className: classes.calendarIconPosition
                }}
                keyboardIcon={
                  <Icon
                    name="calendar-today"
                    className={classes.calendarIcon}
                  />
                }
              />
            </div>
          </React.Fragment>
        )}
        {!showFormBetween && (
          <div className={classes.inputContainer}>
            <InputDate
              fullWidth
              value={singleDate}
              onChange={({ value, valueFormatted }) =>
                this.setState({
                  singleDate: value,
                  singleDateFormatted: valueFormatted
                })
              }
              KeyboardButtonProps={{
                className: classes.calendarIconPosition
              }}
              keyboardIcon={
                <Icon name="calendar-today" className={classes.calendarIcon} />
              }
            />
          </div>
        )}
        <Button
          className={classes.applyButton}
          variant="contained"
          color="primary"
          size="medium"
          onClick={this.applyFilter.bind(this)}
        >
          Aplicar
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let dateStart = null;
  let dateEnd = null;
  let operator = null;
  let singleDate = null;

  if (props.moreInfos) {
    let field = state.search.filters.personalized_field.find(
      v => v.uuid === props.moreInfos.uuid
    );
    if (field) {
      operator = field.operator;
      if (field.values.length === 1) {
        singleDate = field.values[0];
      } else {
        dateStart = field.values[0];
        dateEnd = field.values[1];
      }
    }
  } else {
    dateStart = state.search.filters.dateStart;
    dateEnd = state.search.filters.dateEnd;
    if (dateEnd === dateStart) {
      operator = "e";
      singleDate = dateStart || dateEnd;
    } else if (!dateEnd && dateStart) {
      operator = "gte";
      singleDate = dateStart || dateEnd;
    } else if (dateEnd && !dateStart) {
      operator = "lte";
      singleDate = dateStart || dateEnd;
    } else if (dateEnd && dateStart && dateEnd !== dateStart) {
      operator = "between";
    }
  }

  return {
    filters: state.search.filters,
    dateStart,
    dateEnd,
    operator: operator || "e",
    singleDate
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ applyFilters }, dispatch);

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterChildrenDate)
);

import React, { Component } from "react";

// Models
import { forgotPassword } from "./../../../models/ForgotPasswordModel";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "./../../molecules/alert/_actionsReducers";

// Components
import Button from "./../../atoms/button/Button";

import FormGroup from "./../../molecules/form-group/FormGroup";

// Translate
import { I18n } from "react-i18nify";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRequestingNewPassword: false
    };
  }

  componentDidMount = () => {
    this.formGroupEmail.refs.input.focus();
  };

  onRequestNewPassword = event => {
    event.preventDefault();

    this.setState({
      isRequestingNewPassword: true
    });

    let emailTyped = this.formGroupEmail.refs.input.value;

    forgotPassword(emailTyped)
      .then(() => {
        this.setState({
          isRequestingNewPassword: false
        });

        this.props.addAlert({
          id: "alertForgotPassword",
          theme: "success",
          animation: "default",
          title: I18n.t("TitleAlertForgotPasswordOk"),
          description: I18n.t("ContentAlertForgotPasswordOk", {
            email: emailTyped
          })
        });
      })
      .catch(error => {
        this.setState({
          isRequestingNewPassword: false
        });

        if (error.response.status === 404) {
          this.props.addAlert({
            id: "alertForgotPassword",
            theme: "danger",
            animation: "shake",
            title: I18n.t("TitleAlertEmailNotFound"),
            description: I18n.t("ContentAlertEmailNotFound", {
              email: emailTyped
            })
          });

          this.formGroupEmail.refs.input.focus();
        }

        if (error.response.status === 500) {
          this.props.addAlert({
            id: "alertLogin",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertServerError"),
            description: I18n.t("ContentAlertServerError")
          });
        }
      });

    // setTimeout(() => {

    // }, 1500)
  };

  backToLogin = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="p-forgotPassword">
        <h1 className="lineHeightParagraph heading-1 mb-xs-2">
          {I18n.t("ActionForgotPassword")}
        </h1>

        <p className="lineHeightParagraph mb-xs-3">
          Insira seu e-mail de cadastro e receba instruções para voltar a
          acessar o Assetify.
        </p>

        <form onSubmit={this.onRequestNewPassword}>
          <FormGroup
            ref={formGroup => (this.formGroupEmail = formGroup)}
            type="email"
            placeholder={I18n.t("PlaceholderInputEmail")}
            className="mb-xs-3"
          />

          <Button
            type="submit"
            size="large"
            label={I18n.t("ActionCreateNewPassword")}
            icon="right"
            iconAlign="right"
            width="fluid"
            isLoading={this.state.isRequestingNewPassword}
            className="mb-xs-2"
          />

          <Button
            label={I18n.t("ActionBackToLogin")}
            theme="light"
            kind="ghost"
            width="fluid"
            onClick={this.backToLogin.bind(this)}
          />
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addAlert
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ForgotPassword);

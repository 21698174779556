import React from "react";

import FilterParent from "./FilterParent";
import { getAllCategories } from "./../../../models/CategoryModel";
import { withStyles } from "@material-ui/core";

const styles = theme => ({});

class Filter extends React.Component {
  state = {
    categories: []
  };

  componentDidMount() {
    this.getCategories();
  }

  getCategories = async () => {
    try {
      let response = await getAllCategories();

      this.setState({
        categories: response.data
      });
    } catch (error) {}
  };

  render() {
    let { categories } = this.state;
    let { classes } = this.props;

    return (
      <div className={classes.root}>
        <FilterParent name="asset_type" label="Tipo de asset" active={true} />

        {categories.length > 0 &&
          categories.map(category => (
            <FilterParent
              key={category.uuid}
              uuid={category.uuid}
              name="personalized_field"
              label={category.name}
              active={categories.length === 1}
            />
          ))}

        <FilterParent name="collections" label="Coleções" active={true} />
        <FilterParent name="tags" label="Tags" active={true} />
        <FilterParent name="date_upload" label="Data de Upload" active={true} />
        <FilterParent name="groups" label="Grupos de usuários" active={true} />
      </div>
    );
  }
}

export default withStyles(styles)(Filter);

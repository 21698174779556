import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { I18n } from "react-i18nify";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  changeAssetViewMode,
  applyFilters
} from "../../pages/search-results/_actionsReducers";

// Components
import Tags from "./../../organisms/tags/Tags";
import Assets from "./../../organisms/assets/Assets";
import CollectionDetails from "./../../organisms/collection-details/CollectionDetails";
import TagDetails from "./../../organisms/tag-details/TagDetails";
import SearchHeader from "./../../organisms/search-header/SearchHeader";

import humanize from "humanize";
import { MenuItem } from "@material-ui/core";
import { getIcon } from "../../Icon/Icon";
import {
  ResultsCount,
  Header,
  OrderBy,
  ChangeView,
  ChangeViewIcon,
  AssetsContainer,
  MenuLabel,
  SelectOrderBy
} from "./SearchResults.style";

class SearchResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFiltering: false,
      optionsOrderBy: [
        { value: "real_name", label: I18n.t("OrderByNameAsc") },
        { value: "-real_name", label: I18n.t("OrderByNameDesc") },
        { value: "-updated_at", label: I18n.t("OrderByDateDesc") },
        { value: "updated_at", label: I18n.t("OrderByDateAsc") },
        {
          value: "-assetinfo__total_file_size",
          label: I18n.t("OrderBySizeDesc")
        },
        {
          value: "assetinfo__total_file_size",
          label: I18n.t("OrderBySizeAsc")
        }
      ]
    };
  }

  onChangeOrderBy = e => {
    this.props.applyFilters(
      {
        orderBy: e.target.value === "relevant" ? null : e.target.value
      },
      { updateAssets: true }
    );
  };

  render() {
    const { filters, assetViewModeByList, totalAssets } = this.props;
    const { optionsOrderBy } = this.state;
    const optionRelevant = filters.q
      ? [
          {
            value: "relevant",
            label: I18n.t("OrderByRelevance")
          }
        ]
      : [];
    let hasFilters =
      filters.tags.length > 0 ||
      filters.collections.length > 0 ||
      filters.asset_type.length > 0 ||
      filters.groups.length > 0 ||
      filters.dateStart ||
      filters.dateEnd ||
      filters.personalized_field.length > 0;

    return (
      <>
        {hasFilters && <SearchHeader />}

        <Header hasFilters={hasFilters}>
          <ResultsCount>
            {humanize.numberFormat(totalAssets, 0, null, ".")}{" "}
            {I18n.t("Results")}
          </ResultsCount>

          <OrderBy>
            <SelectOrderBy
              autoWidth
              disableUnderline
              IconComponent={getIcon("expand-more")}
              value={
                filters.orderBy || (filters.q ? "relevant" : "-updated_at")
              }
              onChange={this.onChangeOrderBy}
            >
              {[...optionRelevant, ...optionsOrderBy].map(op => (
                <MenuItem key={op.value} value={op.value}>
                  <MenuLabel>{op.label}</MenuLabel>
                </MenuItem>
              ))}
            </SelectOrderBy>
          </OrderBy>

          <ChangeView
            disabled={!assetViewModeByList}
            onClick={this.props.changeAssetViewMode}
            grid={1}
          >
            <ChangeViewIcon
              name="view-module"
              active={assetViewModeByList ? 1 : 0}
            />
          </ChangeView>

          <ChangeView
            disabled={assetViewModeByList}
            onClick={this.props.changeAssetViewMode}
          >
            <ChangeViewIcon
              name="view-stream"
              active={!assetViewModeByList ? 1 : 0}
            />
          </ChangeView>
        </Header>

        <div>
          {filters.collections.length === 1 && filters.tags.length === 0 && (
            <CollectionDetails uuid={filters.collections[0].id} />
          )}

          {filters.tags.length === 1 && filters.collections.length === 0 && (
            <TagDetails slug={filters.tags[0].label} />
          )}

          <AssetsContainer>
            {filters.collections.length !== 1 &&
              filters.tags.length !== 1 &&
              filters.q && <Tags />}

            <Assets />
          </AssetsContainer>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  filters: state.search.filters,
  assetViewModeByList: state.search.assetViewModeByList,
  totalAssets: state.search.totalAssets
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeAssetViewMode, applyFilters }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchResults)
);

import React, { Component } from "react";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import {
  addBreadCrumb,
  clearBreadCrumb
} from "./../../molecules/bread-crumb/_actionsReducers";

// Models
import {
  getGroup,
  editGroup,
  createGroup,
  deleteGroup
} from "./../../../models/GroupModel";
import { getAllPermissions } from "./../../../models/UserModel";

// Components
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Container from "./../../_quarks/grid/Container";
import Box from "./../../_quarks/box/Box";

import Button from "./../../atoms/button/Button";
import FieldEditable from "./../../atoms/field-editable/FieldEditable";
import Key from "./../../atoms/key/Key";

import FormGroup from "./../../molecules/form-group/FormGroup";

// Styles
import "./GroupPage.css";

// Authentication
import PermissionHandler from "./../../../helpers/PermissionHelper";
import Loader from "../../_quarks/loader/Loader";

// Translate
const { I18n } = require("react-i18nify");

class GroupPage extends Component {
  constructor(props) {
    super(props);
    //let userAuth = Auth.getUser();

    let edit = false;

    if (this.props.history && this.props.history.location.state) {
      edit = this.props.history.location.state.edit;
    }

    this.state = {
      group: null,
      //permissions: [],
      isGetting: true,
      isEditing: edit || false,
      isSavingCreate: false,
      defaultValueToEdit: {
        groups: []
      },
      permissions: { manage_user: PermissionHandler.is_admin() },
      all_permissions: null,
      group_permissions: null,
      isCreatingNew: typeof this.props.match.params.uuid === "undefined"
    };
    this.formGroupPermissions = [];
  }

  componentDidMount = () => {
    let uuid = this.props.match.params.uuid;
    this._getGroup(uuid);

    if (this.state.isCreatingNew) {
      this.props.addBreadCrumb([
        {
          label: I18n.t("Groups"),
          link: "/groups"
        },
        {
          label: I18n.t("TitleCreateNewGroup")
        }
      ]);
    }
  };

  componentWillUnmount = () => {
    this.props.clearBreadCrumb();
  };

  _getGroup = uuid => {
    getAllPermissions()
      .then(responsePermissions => {
        if (!this.state.isCreatingNew) {
          getGroup(uuid)
            .then(response => {
              let groupPermissionIds = [];
              response.data.permissions.forEach(function(perm) {
                groupPermissionIds.push(perm.id);
              });

              this.setState({
                group: response.data,
                group_permissions: groupPermissionIds
              });

              this.setState({
                allPermissions: responsePermissions.data.data,
                isGetting: false
              });

              this.props.addBreadCrumb([
                {
                  label: I18n.t("Groups"),
                  link: "/groups"
                },
                {
                  label: response.data.name,
                  link: "/group/" + response.data.id
                }
              ]);
            })
            .catch(error => {
              this.setState({
                allPermissions: responsePermissions.data.data,
                isGetting: false
              });
            });
        } else {
          this.setState({
            allPermissions: responsePermissions.data.data,
            isGetting: false
          });
        }
      })
      .catch(error => {
        this.setState({
          isGetting: false
        });
      });
  };

  toggleEditMode = refToEdit => {
    if (!this.state.isEditing) {
      this.setState({
        defaultValueToEdit: {
          groups: []
        }
      });
    }

    // Focus on input
    if (refToEdit && !this.state.isEditing) {
      setTimeout(() => {
        // For put cursor on end value on focus
        if (this[refToEdit].refs.input.type === "text") {
          let inputValue = this[refToEdit].refs.input.value;
          this[refToEdit].refs.input.value = null;
          this[refToEdit].refs.input.value = inputValue;
        }

        this[refToEdit].refs.input.focus();
      }, 0);
    }

    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  onSubmitCreate = () => {
    this.setState({
      isSavingCreate: true
    });

    let permissionsId = [];

    this.formGroupPermissions.forEach(function(perm, index) {
      if (perm.refs.input.checked) {
        permissionsId.push(perm.refs.input.id);
      }
    });

    let dataToCreate = {
      name: this.formGroupGroupName.refs.input.value,
      permissions: permissionsId
    };

    let uuid = this.props.match.params.uuid;

    createGroup(dataToCreate, uuid)
      .then(response => {
        this.setState({
          isSavingCreate: false
        });

        this.props.addAlert({
          id: "feedbackCreateGroup",
          theme: "success",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleGroupCreatedSuccessfully")
        });

        this.props.history.push("/groups");
      })
      .catch(error => {
        this.props.addAlert({
          id: "feedbackChangeProfile",
          theme: "danger",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleAlertServerError")
        });

        this.setState({
          isSavingCreate: false
        });
      });
  };

  onSubmitEdit = () => {
    this.setState({
      isSavingEdit: true
    });

    let permissionsId = [];

    this.formGroupPermissions.forEach(function(perm, index) {
      if (perm.refs.input.checked) {
        permissionsId.push(perm.refs.input.id);
      }
    });

    let dataToEdit = {
      name: this.formGroupGroupName.refs.input.value,
      permissions: permissionsId
    };

    let uuid = this.props.match.params.uuid;

    editGroup(dataToEdit, uuid)
      .then(response => {
        this._getGroup(uuid);

        this.props.history.push("/groups");
      })
      .catch(error => {
        this.props.addAlert({
          id: "feedbackChangeProfile",
          theme: "danger",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleAlertServerError")
        });

        this.setState({
          isEditing: false,
          isSavingEdit: false
        });
      });
  };

  onClickDelete = () => {
    this.props.addAlert({
      id: "alertToConfirmDelete",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title: I18n.t("ConfirmGroupDeletion"),
      description: I18n.t("WarningNotPossibleToUndoTheAction"),
      actionButtons: [
        {
          label: I18n.t("ActionDeleteGroup"),
          icon: "delete",
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onConfirmDelete.bind(this)
        },
        {
          label: I18n.t("Cancel"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDelete")
        }
      ]
    });
  };

  onConfirmDelete = () => {
    return new Promise(resolve => {
      deleteGroup([this.props.match.params.uuid])
        .then(response => {
          this.props.addAlert({
            id: "alertToConfirmDelete",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("GroupDeletedSuccessfully")
          });

          this.props.history.push("/groups");
        })
        .catch(error => {
          this.props.addAlert({
            id: "alertToConfirmDelete",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertGroupNotRemoved"),
            description: I18n.t("ContentAlertGroupNotRemoved")
          });
        });

      resolve();
    });
  };

  renderEditing() {
    return (
      <div>
        <FormGroup
          ref={formGroup => (this.formGroupGroupName = formGroup)}
          type="text"
          name="name"
          label={I18n.t("GroupName")}
          defaultValue={this.state.group.name}
          className="o-assetModal__formGroupAssetName mb-xs-3"
        />

        <p className="mb-xs-2">
          <strong>{I18n.t("Permissions")}:</strong>
        </p>

        <Row>
          {this.state.allPermissions.map((permissionInfo, index) => (
            <Col key={index} md="6" className="mb-xs-3">
              <Key
                ref={formGroup =>
                  (this.formGroupPermissions[permissionInfo.id] = formGroup)
                }
                defaultChecked={
                  this.state.group_permissions.indexOf(permissionInfo.id) > -1
                    ? true
                    : null
                }
                id={String(permissionInfo.id)}
                label={I18n.t(permissionInfo.name)}
              />
            </Col>
          ))}
        </Row>

        <Button
          type="button"
          onClick={this.onSubmitEdit.bind(this)}
          label={I18n.t("ActionSave")}
          isLoading={this.state.isSavingEdit}
          className="mr-xs-1"
        />

        <Button
          type="button"
          onClick={() => this.props.history.push("/groups")}
          label={I18n.t("ActionCancel")}
          theme="light"
        />
      </div>
    );
  }

  renderWatching() {
    return (
      <Row>
        <Col md="8" lg="7" xlg="8">
          <p className="lineHeightParagraph mb-xs-3">
            <FieldEditable
              type="text"
              onClick={this.toggleEditMode.bind(this, "formGroupGroupName")}
            >
              {I18n.t("GroupName")}
              <br />
              <strong className="heading-1">{this.state.group.name}</strong>
            </FieldEditable>
          </p>

          <p className="lineHeightParagraph">
            <FieldEditable
              type="text"
              onClick={this.toggleEditMode.bind(this, null)}
            >
              {I18n.t("Permissions")}:
              <br />
              {this.state.allPermissions.map((permissionInfo, index) => (
                <span key={index} className="block-xs">
                  {this.state.group_permissions.indexOf(permissionInfo.id) >
                    -1 && (
                    <span className="arrayInfo">{permissionInfo.name}</span>
                  )}
                </span>
              ))}
            </FieldEditable>
          </p>
        </Col>

        <Col md="4" lg="5" xlg="4" className="text-xs-right">
          {PermissionHandler.can_edit() && (
            <Button
              type="button"
              icon="edit"
              label={I18n.t("ActionEdit")}
              onClick={this.toggleEditMode.bind(this, null)}
            />
          )}
          {PermissionHandler.can_delete() && (
            <Button
              icon="delete"
              width="min"
              theme="danger"
              className="ml-xs-2"
              onClick={this.onClickDelete.bind(this)}
            />
          )}
        </Col>
      </Row>
    );
  }

  renderCreating() {
    return (
      <div>
        <h1 className="heading-1 mb-xs-4">{I18n.t("TitleCreateNewGroup")}</h1>

        <FormGroup
          ref={formGroup => (this.formGroupGroupName = formGroup)}
          type="text"
          name="name"
          label={I18n.t("GroupName")}
          className="o-assetModal__formGroupAssetName mb-xs-3"
        />

        <p className="mb-xs-2">
          <strong>{I18n.t("Permissions")}:</strong>
        </p>

        <Row>
          {this.state.allPermissions.map((permissionInfo, index) => (
            <Col key={index} md="6" className="mb-xs-3">
              <Key
                ref={formGroup =>
                  (this.formGroupPermissions[permissionInfo.id] = formGroup)
                }
                id={String(permissionInfo.id)}
                label={I18n.t(permissionInfo.name)}
              />
            </Col>
          ))}
        </Row>

        <Button
          type="button"
          onClick={this.onSubmitCreate.bind(this)}
          label={I18n.t("ActionSave")}
          isLoading={this.state.isSavingCreate}
          className="mr-xs-1"
        />
      </div>
    );
  }

  render() {
    if (!this.state.permissions.manage_user)
      return <div>Acesso não autorizado</div>;
    return (
      <Container>
        <Row xsAlignX="center">
          <Col lg="7">
            {(this.state.isGetting || !this.state.allPermissions) && (
              <Loader centralized={true} />
            )}
            {!this.state.isGetting && (
              <Box className="p-xs-4">
                <div>
                  {this.state.isCreatingNew && this.renderCreating()}

                  {!this.state.isEditing &&
                    !this.state.isCreatingNew &&
                    this.renderWatching()}

                  {this.state.isEditing &&
                    !this.state.isCreatingNew &&
                    this.renderEditing()}
                </div>
              </Box>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  searchFilters: state.searchFilters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...alertActions,
      addBreadCrumb,
      clearBreadCrumb
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupPage);

import React, { Component } from "react";

// Models
import {
  validateToken,
  changePassword
} from "./../../../models/ForgotPasswordModel";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addAlert } from "./../../molecules/alert/_actionsReducers";

// Components
import Button from "./../../atoms/button/Button";

import FormGroup from "./../../molecules/form-group/FormGroup";

// Translate
import { I18n } from "react-i18nify";

class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreatingNewPassword: false,
      tokenIsValid: false
    };
  }

  componentWillMount = () => {
    validateToken(this.props.match.params.token)
      .then(() => {
        this.setState({
          tokenIsValid: true
        });
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.addAlert({
            id: "alertLogin",
            theme: "danger",
            animation: "shake",
            timer: "infinite",
            title: I18n.t("TitleAlertExpiredTime"),
            description: I18n.t("ContentAlertExpiredTime", {
              actionForgotPassword: I18n.t("ActionForgotPassword")
            })
          });

          this.props.history.push("/login");
        } else {
          this.props.addAlert({
            id: "alertLogin",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertServerError"),
            description: I18n.t("ContentAlertServerError")
          });

          this.props.history.push("/login");
        }
      });
  };

  componentDidMount = () => {
    if (this.state.tokenIsValid) this.formGroupPasswordNew.refs.input.focus();
  };

  onCreateNewPassword = event => {
    event.preventDefault();

    this.setState({
      isCreatingNewPassword: true
    });

    if (
      this.formGroupPasswordNew.refs.input.value !==
      this.formGroupPasswordNewConfirm.refs.input.value
    ) {
      this.props.addAlert({
        id: "alertNewPassword",
        theme: "warning",
        animation: "shake",
        timer: "fast",
        title: I18n.t("TitleAlertPasswordHasNotBeenChanged"),
        description: I18n.t("TitleAlertPasswordHasNotBeenChangedBecauseTheNew")
      });

      this.setState({
        isCreatingNewPassword: false
      });

      return;
    }

    changePassword(
      this.formGroupPasswordNew.refs.input.value,
      this.props.match.params.token
    )
      .then(() => {
        this.setState({
          isCreatingNewPassword: false
        });

        this.props.addAlert({
          id: "feedbackChangeProfile",
          theme: "success",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleAlertPasswordChanged"),
          description: I18n.t("ContentAlertPasswordChanged")
        });

        this.props.history.push("/");
      })
      .catch(() => {
        this.setState({
          isCreatingNewPassword: false
        });

        this.props.addAlert({
          id: "alertLogin",
          theme: "danger",
          animation: "shake",
          timer: "fast",
          title: I18n.t("TitleAlertServerError"),
          description: I18n.t("ContentAlertServerError")
        });
      });
  };

  render() {
    return (
      <div className="p-newPassword">
        {this.state.tokenIsValid && (
          <div>
            <h1 className="lineHeightParagraph heading-1 mb-xs-2">
              {I18n.t("ActionCreateNewPassword")}
            </h1>

            <p className="lineHeightParagraph mb-xs-3">
              {I18n.t("FillTheFieldsBelow")}
            </p>

            <form onSubmit={this.onCreateNewPassword}>
              <FormGroup
                ref={formGroup => (this.formGroupPasswordNew = formGroup)}
                type="password"
                name="new_password"
                label={I18n.t("PasswordNew")}
                className="mb-xs-3"
              />

              <FormGroup
                ref={formGroup =>
                  (this.formGroupPasswordNewConfirm = formGroup)
                }
                type="password"
                name="new_password_confirm"
                label={I18n.t("PasswordNewConfirm")}
                className="mb-xs-3"
              />

              <Button
                type="submit"
                size="large"
                label="Criar nova senha"
                icon="right"
                iconAlign="right"
                width="fluid"
                isLoading={this.state.isCreatingNewPassword}
              />
            </form>
          </div>
        )}

        {!this.state.tokenIsValid && <p>Invalid token</p>}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addAlert
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(NewPassword);

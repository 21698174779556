import grey from "@material-ui/core/colors/grey";

export const palette = {
  primary: "#4FC8D5",
  secondary: "#4a90e2",
  white: "#FFF",
  grey: grey[500],
  grey2: grey[700],
  grey3: "#7d8084",
  grey4: grey[100],
  greyAside: "#4b4f54"
};

import React, { Component } from "react";
import { I18n } from "react-i18nify";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  clearFilters,
  applyFilters
} from "../../pages/search-results/_actionsReducers";

// Styles
import "./SearchHeader.css";

import RemoveCircle from "@material-ui/icons/RemoveCircleOutline";
import Chip from "../../Chip/Chip";
import { formatDate } from "../../../helpers/HumanizeHelper";

class SearchHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  removeFilter = (name, id, value) => {
    let { filters } = this.props;

    if (name === "date") {
      this.props.applyFilters(
        { dateStart: null, dateEnd: null },
        { updateAssets: true }
      );
      return;
    } else if (value) {
      let index = filters[name].findIndex(f => f.uuid === id);

      if (index >= 0) {
        filters[name][index] = {
          ...filters[name][index],
          values: filters[name][index].values.filter(v => v !== value)
        };
      }
      if (filters[name][index].values.length === 0) {
        filters[name] = filters[name].filter(v => (v.id || v.uuid) !== id);
      }
    } else {
      filters[name] = filters[name].filter(v => (v.id || v.uuid) !== id);
    }
    this.props.applyFilters({ filters }, { updateAssets: true });
  };

  getFiltersActive = (name, label) => {
    const filter = this.props.filters[name];

    return filter.map(c => (
      <Chip
        key={c.id}
        label={label}
        value={c.label}
        onDelete={() => this.removeFilter(name, c.id)}
      />
    ));
  };

  getValueOperator = (op, v) => {
    switch (op) {
      case "e":
        return `=${v[0]}`;
      case "gte":
        return `>=${v[0]}`;
      case "gt":
        return `>${v[0]}`;
      case "lte":
        return `<=${v[0]}`;
      case "lt":
        return `<${v[0]}`;
      case "between":
        return `>=${v[0]} e <=${v[1]}`;
      default:
        return v;
    }
  };

  getFiltersActivePersonalized = () => {
    return this.props.filters.personalized_field.map(c => {
      if (c.kind === "int" || c.kind === "float" || c.kind === "date") {
        return (
          <Chip
            key={c.uuid}
            label={c.label}
            value={this.getValueOperator(c.operator, c.values)}
            onDelete={() => this.removeFilter("personalized_field", c.uuid)}
          />
        );
      } else {
        return c.values.map(v => (
          <Chip
            key={c.uuid + v}
            label={c.label}
            value={v}
            onDelete={() => this.removeFilter("personalized_field", c.uuid, v)}
          />
        ));
      }
    });
  };

  render() {
    const {
      filters,
      isSearchingAssets,
      isSearchingCollections,
      isSearchingTags
    } = this.props;

    let isSearching =
      isSearchingAssets || isSearchingCollections || isSearchingTags;

    return (
      <div
        className={`o-searchHeader ${
          isSearching ? "o-searchHeader-disabled" : ""
        }`}
      >
        <div className="left row">
          {this.getFiltersActive("asset_type", I18n.t("Type"))}
          {this.getFiltersActive("collections", I18n.t("Collection"))}
          {this.getFiltersActive("tags", "Tag")}
          {this.getFiltersActive("groups", I18n.t("Group"))}

          {!filters.dateLabel && filters.dateStart && filters.dateEnd && (
            <Chip
              key={"dateStart"}
              label="Date de Upload"
              value={`${I18n.t("FromDate")} ${formatDate(
                filters.dateStart
              )} ${I18n.t("ToDate")} ${formatDate(filters.dateEnd)}`}
              onDelete={() => this.removeFilter("date")}
            />
          )}
          {!filters.dateLabel && filters.dateStart && !filters.dateEnd && (
            <Chip
              key={"dateStart"}
              label="Date de Upload"
              value={`${I18n.t("SinceDate")} ${formatDate(filters.dateStart)}`}
              onDelete={() => this.removeFilter("date")}
            />
          )}
          {!filters.dateLabel && !filters.dateStart && filters.dateEnd && (
            <Chip
              key={"dateEnd"}
              label="Date de Upload"
              value={`${I18n.t("ToDate")} ${formatDate(filters.dateEnd)}`}
              onDelete={() => this.removeFilter("date")}
            />
          )}
          {filters.dateLabel && (
            <Chip
              key={"dateStart"}
              label="Date de Upload"
              value={filters.dateLabel}
              onDelete={() => this.removeFilter("date")}
            />
          )}

          {this.getFiltersActivePersonalized()}

          <span className="clear" onClick={this.props.clearFilters}>
            <RemoveCircle fontSize="small" />
            <span className="text"> {I18n.t("ClearFilters")} </span>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filters: state.search.filters,
  isSearchingAssets: state.search.isSearchingAssets,
  isSearchingCollections: state.search.isSearchingCollections,
  isSearchingTags: state.search.isSearchingTags
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clearFilters, applyFilters }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchHeader);

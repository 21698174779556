import React, { Component } from "react";

// Models
import {
  getAllCategories,
  deleteCategory
} from "./../../../models/CategoryModel";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import {
  addBreadCrumb,
  clearBreadCrumb
} from "./../../molecules/bread-crumb/_actionsReducers";

// Compontents
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Container from "./../../_quarks/grid/Container";
import Loader from "./../../_quarks/loader/Loader";
import Box from "./../../_quarks/box/Box";

import Button from "../../atoms/button/Button";

import { I18n } from "react-i18nify";

import "./CategoriesList.css";

// Authentication
import PermissionHandler from "./../../../helpers/PermissionHelper";

class CategoriesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      category_to_delete: null,
      isLoading: false,
      permissions: { manage_category: PermissionHandler.can_admin_category() }
    };
  }

  componentDidMount = async () => {
    await this._getCategories();

    this.props.addBreadCrumb([
      {
        label: I18n.t("CustomFields"),
        link: "/categories"
      },
      {
        label: I18n.t("Categories")
      }
    ]);
  };

  componentWillUnmount = () => {
    this.props.clearBreadCrumb();
  };

  _getCategories = async () => {
    this.setState({ isLoading: true });
    try {
      let response = await getAllCategories();
      this.setState({ categories: response.data, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  onClickEdit = uuid => {
    this.props.history.push({
      pathname: "/category/" + uuid,
      state: { edit: true }
    });
  };

  getErrorMessage = error => {
    if (error && error.response && error.response.data) {
      let data = error.response.data;

      if (data.detail === "Can't delete, there is data bound to it") {
        return I18n.t("ErrorDeleteCategoryBound");
      }
    }
  };

  onClickDelete = uuid => {
    this.setState({ category_to_delete: uuid });

    this.props.addAlert({
      id: "alertToConfirmDelete",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title: I18n.t("ConfirmCategoryDeletion"),
      description: I18n.t("WarningNotPossibleToUndoTheAction"),
      actionButtons: [
        {
          label: I18n.t("ActionDeleteCategory"),
          icon: "delete",
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onConfirmDelete.bind(this)
        },
        {
          label: I18n.t("Cancel"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDelete")
        }
      ]
    });
  };

  onConfirmDelete = params => {
    return new Promise(resolve => {
      this.setState({
        isLoading: true
      });

      let uuid = this.state.category_to_delete;

      deleteCategory(uuid)
        .then(response => {
          this.setState({
            isLoading: false,
            category_to_delete: null
          });

          this._getCategories();

          this.props.addAlert({
            id: "alertToConfirmDelete",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("CategoryDeletedSuccessfully")
          });
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            category_to_delete: null
          });

          this.props.addAlert({
            id: "alertToConfirmDelete",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertCategoryNotRemoved"),
            description:
              this.getErrorMessage(error) ||
              I18n.t("ContentAlertCategoryNotRemoved")
          });
        });

      resolve();
    });
  };

  render() {
    let { permissions, categories, isLoading } = this.state;

    if (!permissions.manage_category) return <div>Acesso não autorizado</div>;

    if (isLoading) return <Loader centralized={true} />;

    return (
      <Container>
        <Row xsAlignX="center">
          <Col md="8" lg="6" xlg="5">
            <div className="clearfix">
              <h1 className="heading-1 mb-xs-4 float-xs-left">
                {I18n.t("Categories")}
              </h1>

              <Button
                type="link"
                onClick="/categories/new"
                label={I18n.t("CreateNewCategoryButtonLabel")}
                className="float-xs-right"
              />
            </div>

            {Array.isArray(categories) &&
              categories.map((category, index) => (
                <Box key={index} className="mb-xs-3 p-xs-3 clearfix">
                  <Row xsAlignY="middle">
                    <Col md="9" className="mb-xs-3 mb-md-0">
                      <h2
                        className="heading-3 p-categoriesList__name"
                        onClick={() =>
                          this.props.history.push("/category/" + category.uuid)
                        }
                      >
                        {category.name}
                      </h2>
                    </Col>

                    <Col xs="3" className="text-xs-right">
                      <Button
                        type="button"
                        icon="edit"
                        width="min"
                        theme="primary"
                        className="mr-xs-1"
                        onClick={this.onClickEdit.bind(this, category.uuid)}
                      />

                      <Button
                        type="button"
                        icon="delete"
                        width="min"
                        theme="danger"
                        onClick={this.onClickDelete.bind(this, category.uuid)}
                      />
                    </Col>
                  </Row>
                </Box>
              ))}

            {Array.isArray(categories) && categories.length === 0 && (
              <span
                className="italic lineHeightParagraph"
                dangerouslySetInnerHTML={{
                  __html: I18n.t("EmptyStateCategory", {
                    buttonTitle: I18n.t("CreateNewCategoryButtonLabel")
                  })
                }}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...alertActions,
      addBreadCrumb,
      clearBreadCrumb
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(CategoriesList);

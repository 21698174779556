import React from "react";
import styled from "styled-components";
import { Typography, IconButton, Select } from "@material-ui/core";
import Icon from "../../Icon/Icon";

export const Header = styled.div`
  margin-top: ${props =>
    props.hasFilters
      ? 50 + props.theme.spacing.unit * 3
      : props.theme.spacing.unit * 3}px;
  display: flex;
  align-items: center;
  padding: 0px ${props => props.theme.spacing.unit * 3}px;
`;

export const ResultsCount = styled(Typography)`
  && {
    margin-right: ${props => props.theme.spacing.unit * 2}px;
    ${props => props.theme.typography.body1}
  }
`;

export const OrderBy = styled.div`
  margin-right: ${props => props.theme.spacing.unit * 2}px;
  margin-left: auto;
`;

export const SelectOrderBy = styled(({ children, ...other }) => (
  <Select classes={{ icon: "orderByIcon" }} {...other}>
    {children}
  </Select>
))`
  & .orderByIcon {
    color: ${props => props.theme.palette.grey};
  }
`;

export const ChangeView = styled(IconButton)`
  && {
    padding: 0;
    ${props =>
      props.grid ? `margin-right: ${props.theme.spacing.unit}px;` : ""}
`;

export const ChangeViewIcon = styled(Icon)`
  && {
    color: ${props =>
      props.active ? props.theme.palette.grey : props.theme.palette.primary};
  }
`;

export const AssetsContainer = styled.div`
  padding: ${props => props.theme.spacing.unit * 3}px;
`;

export const MenuLabel = styled(Typography)`
  && {
    ${props => props.theme.typography.body1}
  }
`;

import React, { Component } from "react";
// Translate
import { I18n } from "react-i18nify";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setSearchValue } from "./../../pages/search-results/_actionsReducers";

// Models
import { getTag } from "./../../../models/TagModel";

import Col from "./../../_quarks/grid/Col";
// Components
import Container from "./../../_quarks/grid/Container";
import Row from "./../../_quarks/grid/Row";
// Styles
import "./TagDetails.css";

const humanize = require("humanize");

class TagDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: null,
      name: ""
    };
  }

  componentWillMount = () => {
    this._getTag(this.props.slug);
  };

  _getTag = slug => {
    getTag(slug)
      .then(response => {
        this.setState({
          tag: response.data,
          name: response.data.slug,
          total_assets: response.data.assets.length
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  componentDidUpdate() {
    if (this.props.updateTags) {
      this.props.setSearchValue({ updateTags: false });
      this._getTag(this.props.slug);
    }
  }

  render() {
    if (!this.state.tag) return <div />;
    return (
      <div className="o-tagDetails">
        <div className="o-tagDetails__header py-xs-4">
          <Container>
            <Row xsAlignY="bottom">
              <Col xs="6">
                <h1 className="o-tagDetails__header-title mb-xs-2">
                  {this.state.tag.slug}
                </h1>
                <p className="mb-xs-3">
                  {this.state.total_assets > 1
                    ? this.state.total_assets + " assets "
                    : this.state.total_assets > 0
                    ? "1 asset"
                    : ""}
                </p>
                <p className="mb-xs-2">
                  {I18n.t("CreateAt")}
                  <strong>
                    {" "}
                    {humanize.relativeTime(
                      new Date(this.state.tag.created_at).getTime() / 1000
                    )}
                  </strong>
                </p>
                <p>
                  {I18n.t("AssetDetailModified")}
                  <strong>
                    {" "}
                    {humanize.relativeTime(
                      new Date(this.state.tag.updated_at).getTime() / 1000
                    )}
                  </strong>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  updateTags: state.search.updateTags
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSearchValue
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TagDetails)
);

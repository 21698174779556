import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { applyFilters } from "../../pages/search-results/_actionsReducers";
import { Select, MenuItem, Typography, Button } from "@material-ui/core";
import { getIcon } from "../../Icon/Icon";
import InputNumber from "../../Input/InputNumber";

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit}px 0px`
  },
  applyButton: {
    marginTop: `${theme.spacing.unit}px`,
    marginLeft: "auto",
    display: "flex",
    justifyContent: "flex-end"
  },
  select: {
    margin: `${theme.spacing.unit}px 0px`
  },
  optionSelected: {
    color: theme.palette.common.white
  },
  inputContainer: {
    display: "flex",
    alignItems: "flex-end",
    padding: `${theme.spacing.unit}px 0px`
  },
  numbLabel: {
    marginRight: theme.spacing.unit,
    width: 30
  }
});

class FilterChildrenNumber extends React.Component {
  constructor(props) {
    super(props);
    let { kind } = props.moreInfos || {};
    let { firstNumber, lastNumber, singleNumber, operator } = this.props;

    this.state = {
      showFormBetween: operator === "between" ? true : false,
      kind,
      firstNumber: firstNumber ? firstNumber.replace(".", ",") : "",
      firstNumberFormatted: firstNumber ? firstNumber.replace(",", ".") : "",
      lastNumber: lastNumber ? lastNumber.replace(".", ",") : "",
      lastNumberFormatted: lastNumber ? lastNumber.replace(",", ".") : "",
      singleNumber: singleNumber ? singleNumber.replace(".", ",") : "",
      singleNumberFormatted: singleNumber ? singleNumber.replace(",", ".") : "",
      operator,
      options: [
        { value: "e", label: "Igual a" },
        { value: "gte", label: "Maior ou igual a" },
        { value: "gt", label: "Maior que" },
        { value: "lte", label: "Menor ou igual a" },
        { value: "lt", label: "Menor que" },
        { value: "between", label: "Intervalo de números" }
      ]
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  applyFilter = () => {
    const {
      firstNumberFormatted,
      lastNumberFormatted,
      singleNumberFormatted,
      operator
    } = this.state;
    const { moreInfos } = this.props;
    if (
      (singleNumberFormatted && operator !== "between") ||
      (firstNumberFormatted && lastNumberFormatted && operator === "between")
    ) {
      let { personalized_field } = this.props.filters;
      personalized_field = personalized_field.filter(
        v => v.uuid !== moreInfos.uuid
      );

      let field = {
        uuid: moreInfos.uuid,
        kind: moreInfos.kind,
        operator,
        label: this.props.label,
        values: null
      };

      if (operator === "between") {
        field.values = [firstNumberFormatted, lastNumberFormatted];
        personalized_field.push(field);
      } else {
        field.values = [singleNumberFormatted];
        personalized_field.push(field);
      }

      this.props.applyFilters({ personalized_field }, { updateAssets: true });
    }
  };

  render() {
    let {
      showFormBetween,
      operator,
      options,
      kind,
      singleNumber,
      firstNumber,
      lastNumber
    } = this.state;

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.props.label && (
          <Typography variant="subtitle1">{this.props.label}</Typography>
        )}

        <Select
          fullWidth
          autoWidth
          className={classes.select}
          IconComponent={getIcon("expand-more")}
          value={operator || "e"}
          onChange={e =>
            this.setState({
              showFormBetween: e.target.value === "between" ? true : false,
              operator: e.target.value
            })
          }
          renderValue={value => (
            <Typography variant="body1" className={classes.optionSelected}>
              {options.find(v => v.value === value).label}
            </Typography>
          )}
        >
          {options.map(op => (
            <MenuItem key={op.value} value={op.value}>
              <Typography variant="body1">{op.label}</Typography>
            </MenuItem>
          ))}
        </Select>

        {showFormBetween && (
          <React.Fragment>
            <div className={classes.inputContainer}>
              <Typography variant="body2" className={classes.numbLabel}>
                De:
              </Typography>
              <InputNumber
                type={kind}
                fullWidth
                value={firstNumber}
                onChange={({ value, valueFormatted }) =>
                  this.setState({
                    firstNumber: value,
                    firstNumberFormatted: valueFormatted
                  })
                }
              />
            </div>
            <div className={classes.inputContainer}>
              <Typography variant="body2" className={classes.numbLabel}>
                Até:
              </Typography>
              <InputNumber
                type={kind}
                fullWidth
                value={lastNumber}
                onChange={({ value, valueFormatted }) =>
                  this.setState({
                    lastNumber: value,
                    lastNumberFormatted: valueFormatted
                  })
                }
              />
            </div>
          </React.Fragment>
        )}
        {!showFormBetween && (
          <div className={classes.inputContainer}>
            <InputNumber
              type={kind}
              fullWidth
              value={singleNumber}
              onChange={({ value, valueFormatted }) =>
                this.setState({
                  singleNumber: value,
                  singleNumberFormatted: valueFormatted
                })
              }
            />
          </div>
        )}

        <Button
          className={classes.applyButton}
          variant="contained"
          color="primary"
          size="medium"
          onClick={this.applyFilter.bind(this)}
        >
          Aplicar
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  let firstNumber = null;
  let lastNumber = null;
  let operator = null;
  let singleNumber = null;

  if (props.moreInfos) {
    let field = state.search.filters.personalized_field.find(
      v => v.uuid === props.moreInfos.uuid
    );
    if (field) {
      operator = field.operator;
      if (field.values.length === 1) {
        singleNumber = field.values[0];
      } else {
        firstNumber = field.values[0];
        lastNumber = field.values[1];
      }
    }
  }

  return {
    filters: state.search.filters,
    firstNumber,
    lastNumber,
    operator: operator || "e",
    singleNumber
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ applyFilters }, dispatch);

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterChildrenNumber)
);

import React from "react";

// Components
import Container from "./../../_quarks/grid/Container";
import Box from "./../../atoms/box/Box";
import Key from "./../../atoms/key/Key";
import Button from "./../../atoms/button/Button";

import FormGroup from "./../../molecules/form-group/FormGroup";
import Form from "./../../organisms/form/Form";
import DropFiles from "./../../molecules/drop-files/DropFiles";

// Models
import UploadModel from "../../../models/UploadModel";
import { getAllCategories, getCategory } from "../../../models/CategoryModel";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";

import { I18n } from "react-i18nify";
import uuidv4 from "uuid/v4";

import PermissionHandler from "./../../../helpers/PermissionHelper";
import ChunkModel from "../../../models/ChunkModel";

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploadingAsset: false,
      assets: [],
      categoriesSelecteds: [],
      optionsCategories: [],
      customFields: [],
      permissions: { can_upload: PermissionHandler.can_upload() },
      customFieldsValues: {}
    };
  }

  componentDidMount = async () => {
    await this.getOptionsCategories();
  };

  getOptionsCategories = async () => {
    let categories = await getAllCategories();
    let optionsCategories = [];

    categories.data.map(category =>
      optionsCategories.push({
        label: category.name,
        value: category.uuid
      })
    );

    this.setState({
      optionsCategories
    });
  };

  updateCustomFields = categoriesSelecteds => {
    let updatedCustomFields = categoriesSelecteds.map(
      async categorySelected => {
        let categoryToAdd = await getCategory(categorySelected.value);
        return categoryToAdd.data;
      }
    );

    return Promise.all(updatedCustomFields).then(result => {
      this.setState({
        customFields: result,
        categoriesSelecteds
      });

      this.updateCustomFieldsValues(result);
    });
  };

  updateCustomFieldsValues = selectedCategories => {
    this._clearCustomFieldsValues(selectedCategories);
    this._updateCustomFieldsValues(selectedCategories);
  };

  _clearCustomFieldsValues = selectedCategories => {
    let { customFieldsValues } = this.state;

    if (Object.keys(customFieldsValues).length <= 0) return;

    Object.keys(customFieldsValues).map(idOldCategory => {
      let oldCategoryOnSelecteds = selectedCategories.filter(
        categorySelected => categorySelected.uuid === idOldCategory
      );

      if (oldCategoryOnSelecteds.length <= 0) {
        delete customFieldsValues[idOldCategory];
      }
      return null;
    });

    this.setState({ customFieldsValues });
  };

  _updateCustomFieldsValues = selectedCategories => {
    let { customFieldsValues } = this.state;
    if (selectedCategories.length > 0) {
      selectedCategories.map(category => {
        if (!(category.uuid in customFieldsValues)) {
          let fields = {};
          category.fields.map(field => (fields[field.uuid] = null));
          customFieldsValues[category.uuid] = fields;
        }
        return null;
      });
    } else {
      customFieldsValues = {};
    }

    this.setState({ customFieldsValues });
  };

  renderCustomField = (uuidCategory, field) => {
    let { customFieldsValues } = this.state;
    let category = customFieldsValues[uuidCategory] || {};

    if (field.kind === "int" || field.kind === "float") {
      return (
        <FormGroup
          legend={field.tooltip}
          validation={field.regex}
          type={field.kind === "int" ? "number" : "text"}
          numberType={field.kind}
          step={field.kind === "int" ? "1" : "0.01"}
          label={`${field.label}:`}
          value={
            (category[field.uuid]
              ? String(category[field.uuid]).replace(".", ",")
              : category[field.uuid]) || ""
          }
          onChange={event => {
            customFieldsValues[uuidCategory] = {
              ...customFieldsValues[uuidCategory],
              [field.uuid]: event.target.value
                ? String(event.target.value).replace(",", ".")
                : event.target.value
            };
            this.setState({ customFieldsValues });
          }}
        />
      );
    } else if (field.kind === "date") {
      return (
        <FormGroup
          legend={field.tooltip}
          validation={field.regex}
          type="date"
          label={`${field.label}:`}
          value={category[field.uuid] || ""}
          onChange={event => {
            customFieldsValues[uuidCategory] = {
              ...customFieldsValues[uuidCategory],
              [field.uuid]: event.target.value
            };
            this.setState({ customFieldsValues });
          }}
        />
      );
    } else if (field.kind === "multiselect" || field.kind === "select") {
      let fieldOptions = [];

      field.options.map(option =>
        fieldOptions.push({
          label: option,
          value: option
        })
      );

      return (
        <FormGroup
          legend={field.tooltip}
          validation={field.regex}
          validate={false}
          type="virtualized"
          loadInVirtualized="custom"
          name="custom"
          multi={field.kind === "multiselect" ? true : false}
          async={false}
          options={fieldOptions}
          value={category[field.uuid] || ""}
          label={`${field.label}:`}
          onChange={value => {
            customFieldsValues[uuidCategory] = {
              ...customFieldsValues[uuidCategory],
              [field.uuid]: field.kind === "multiselect" ? value : value.value
            };
            this.setState({ customFieldsValues });
          }}
        />
      );
    } else {
      return (
        <FormGroup
          legend={field.tooltip}
          validation={field.regex}
          type="text"
          label={`${field.label}:`}
          value={category[field.uuid] || ""}
          onChange={event => {
            customFieldsValues[uuidCategory] = {
              ...customFieldsValues[uuidCategory],
              [field.uuid]: event.target.value
            };
            this.setState({ customFieldsValues });
          }}
        />
      );
    }
  };

  onSubmitUploadAsset = () => {
    let getTagNames = () => {
      let arrayTags = [];
      let valueOnTags = this.formGroupTags.refs.input.props.value;

      if (valueOnTags) {
        valueOnTags.map(tag => arrayTags.push(tag.value));
      }

      return arrayTags;
    };

    let getUuidOrNameCollections = () => {
      let arrayIdCollections = [];
      let valueOnCollections = this.formGroupCollections.refs.input.props.value;

      if (valueOnCollections) {
        valueOnCollections.map(collection => {
          let identifierCollection;

          if (collection.id) {
            identifierCollection = collection.id;
          } else {
            identifierCollection = collection.label;
          }

          arrayIdCollections.push(identifierCollection);
          return null;
        });
      }

      return arrayIdCollections;
    };

    let getGroupsIds = () => {
      let arrayIdGroups = [];
      let valueOnGroups = this.formGroupGroups.refs.input.props.value;

      if (valueOnGroups) {
        valueOnGroups.map(group => arrayIdGroups.push(group.id));
      }

      return arrayIdGroups;
    };

    let assetsOptions = {
      tags: getTagNames(),
      collections: getUuidOrNameCollections(),
      groups: getGroupsIds(),
      categories: this.getCustomFieldsValues(),
      smart: this.keySmartSearch
        ? this.keySmartSearch.refs.input.checked
        : true,
      public: this.keyPublicMode.refs.input.checked,
      download: this.keyPublicDownload.refs.input.checked
    };

    this.setState({ isUploadingAsset: true });
    if (this.state.assets) {
      let upload = new UploadModel();
      upload
        .sendToAuris(this.state.assets, assetsOptions)
        .then(response => {
          this.setState({ isUploadingAsset: false });
          this.props.history.push("/");
          this.props.addAlert({
            id: "alertUpload",
            theme: "success",
            timer: "fast",
            title: I18n.t("UploadPageSaveSuccessAlertTitle"),
            description: I18n.t("FeedbackAllAssetsSent"),
            actionPrimary: {
              label: I18n.t("UploadMoreAssets"),
              icon: "upload",
              theme: "light",
              onClick: this.goToUploadPage
            }
          });
        })
        .catch(response => {
          this.setState({ isUploadingAsset: false });
          this.props.addAlert({
            id: "alertUpload",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertServerError"),
            description: I18n.t("ContentAlertServerError")
          });
        });
    }
  };

  goToUploadPage = params => {
    return new Promise(resolve => {
      this.props.history.push("/upload");
      this.props.clearAlerts();
    });
  };

  onClickDelete = (assetUuid, filename) => {
    let { assets } = this.state;
    new UploadModel().removeFromStorage(assetUuid, filename);
    let index = assets.findIndex(a => a.uuid === assetUuid);
    assets.splice(index, 1);
    this.setState({ assets });
  };

  onClickRetry = uuid => {
    this.setState({ isLoading: true });
    let { assets } = this.state;
    let index = assets.findIndex(a => a.uuid === uuid);
    let file = assets[index].file;
    assets.pop(index);
    this.setState({ assets });
    this.handleUpload(file);
  };

  onDropFileChange = event => {
    this.setState({ isLoading: true });
    for (const file of event.target.files) {
      this.handleUpload(file);
    }
    event.target.value = null;
  };

  callbackOnDrop = filesDropped => {
    this.setState({ isLoading: true });
    for (const file of filesDropped) {
      this.handleUpload(file);
    }
  };

  createNewAssetMock = async file => {
    const type = file.type.split("/");
    const name = file.name.split(".");
    let { assets } = this.state;
    let uuid = uuidv4();

    assets.push({
      uuid,
      name: name[0],
      real_name: name[0],
      asset_type: [file.name, type[0], `.${name[1]}`],
      total_file_size: file.size,
      file_size: file.size
    });

    this.setState({ assets });
    return uuid;
  };

  updateAsset = (uuid, data) => {
    let { assets } = this.state;
    let index = assets.findIndex(a => a.uuid === uuid);
    Object.keys(data).map(key => (assets[index][key] = data[key]));

    this.setState({ assets });
  };

  handleUpload = async file => {
    let uuid = await this.createNewAssetMock(file);
    let updateUploaded = data => {
      let { tags, collections } = this.state;

      this.updateAsset(uuid, {
        contProgress: 100,
        uploaded: true,
        url: data.selfLink,
        size: data.size,
        real_name: data.metadata.real_name,
        uuid: data.metadata.interface_uuid,
        content_type: data.name.split("/")[0],
        tags,
        collections
      });
    };

    let url = await new UploadModel().getSessionUrl(uuid, file.name);
    new ChunkModel(
      file,
      url,
      contProgress => this.updateAsset(uuid, { contProgress }),
      error => this.updateAsset(uuid, { error, file }),
      updateUploaded
    ).chunk();
  };

  getCustomFieldsValues = () => {
    let { customFieldsValues } = this.state;

    let getValue = (field, value) => {
      if (Array.isArray(value)) {
        return value.map(f => f.label);
      } else {
        return value;
      }
    };

    return Object.keys(customFieldsValues).map(category => {
      return {
        category,
        values: Object.keys(customFieldsValues[category]).map(field => ({
          field,
          value: getValue(field, customFieldsValues[category][field])
        }))
      };
    });
  };

  render = () => {
    let { permissions, assets, isUploadingAsset } = this.state;

    if (!permissions.can_upload) return <div>Acesso não autorizado</div>;
    return (
      <Container className="p-upload py-xs-4">
        <Form>
          <Box className="p-xs-4">
            <h1 className="heading-1 mb-xs-4">{I18n.t("TitleUpload")}</h1>

            <DropFiles
              files={this.state.assets}
              onClickDelete={this.onClickDelete.bind(this)}
              onClickRetry={this.onClickRetry.bind(this)}
              onChange={this.onDropFileChange.bind(this)}
              callbackOnDrop={this.callbackOnDrop.bind(this)}
              className="mb-xs-4"
            />

            <FormGroup
              ref={formGroup => (this.formGroupTags = formGroup)}
              className="mb-xs-4"
              type="virtualized"
              loadInVirtualized="tags"
              label="Tags:"
              name="tags"
              enableToCreateOption
              value={this.state.selectedTags}
              onChange={selectedTags => this.setState({ selectedTags })}
            />

            <FormGroup
              ref={formGroup => (this.formGroupCollections = formGroup)}
              className="mb-xs-4"
              type="virtualized"
              loadInVirtualized="collections"
              label={I18n.t("AssetDetailCollections")}
              name="collections"
              enableToCreateOption
              value={this.state.selectedCollections}
              onChange={selectedCollections =>
                this.setState({ selectedCollections })
              }
            />

            <FormGroup
              ref={formGroup => (this.formGroupGroups = formGroup)}
              className="mb-xs-4"
              type="virtualized"
              loadInVirtualized="groups"
              label={I18n.t("AssetDetailGroups")}
              name="group"
              value={this.state.selectedGroups}
              onChange={selectedGroups => this.setState({ selectedGroups })}
            />

            <FormGroup
              placeholder={I18n.t("ActionPlaceholderSelectCategories")}
              ref={formGroup => (this.formGroupCustomField = formGroup)}
              className="mb-xs-4"
              type="virtualized"
              loadInVirtualized="custom"
              label={`${I18n.t("CustomFields")}:`}
              name="customFields"
              multi={true}
              async={false}
              options={this.state.optionsCategories}
              value={this.state.categoriesSelecteds}
              onChange={this.updateCustomFields.bind(this)}
            />

            {this.state.customFields.length > 0 && (
              <div className="p-upload__customFields p-xs-3 mb-xs-4 bg-default-2">
                {this.state.customFields.map(category => (
                  <div key={category.uuid} className="mb-xs-4">
                    <h2 className="heading-2 mb-xs-3">{category.name}</h2>

                    {category.fields.map((field, index) => (
                      <div key={index} className="mb-xs-3">
                        {this.renderCustomField(category.uuid, field)}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            <div className="mb-xs-4">
              <Key
                ref={key => (this.keySmartSearch = key)}
                label={I18n.t("AssetDetailHasSmartSearch")}
                id="assetSmartSearch"
                defaultChecked={true}
                className="mr-xs-4"
              />

              <Key
                ref={key => (this.keyPublicMode = key)}
                label={I18n.t("AssetDetailIsPublicMode")}
                id="assetPublicMode"
                className="mr-xs-4"
              />

              <Key
                ref={key => (this.keyPublicDownload = key)}
                label={I18n.t("AssetDetailIsPublicDownload")}
                id="assetPublicDownload"
                className="mr-xs-4"
              />
            </div>

            <div className="clearfix">
              <Button
                submit={true}
                type="button"
                size="large"
                label={I18n.t("ActionSave")}
                onClick={this.onSubmitUploadAsset.bind(this)}
                disabled={
                  assets.length === 0 ||
                  assets.filter(a => a.error > 3).length > 0
                }
                isLoading={
                  (assets.filter(a => !a.uploaded).length > 0 &&
                    assets.filter(a => a.error > 3).length === 0) ||
                  isUploadingAsset
                }
                className="float-xs-right"
              />
            </div>
          </Box>
        </Form>
      </Container>
    );
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...alertActions }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Upload);

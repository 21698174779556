import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as alertActions from "./_actionsReducers";

import Button from "./../../atoms/button/Button";

import "./Alert.css";

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: []
    };
  }

  onClickActionButton = (onClickThisButton, index, actionHasLoading) => {
    if (!actionHasLoading) {
      onClickThisButton();
      return;
    }

    let actualStateIsLoading = this.state.isLoading;
    actualStateIsLoading[index] = true;

    this.setState({
      isLoading: actualStateIsLoading
    });

    onClickThisButton().then(() => {
      actualStateIsLoading = this.state.isLoading;
      actualStateIsLoading[index] = false;

      this.setState({
        isLoading: actualStateIsLoading
      });
    });
  };

  render() {
    // params = {
    // id,
    // theme,
    // animation, (default, shake)
    // title,
    // description,
    // timer, (infinite, fast, ...soon)
    // actionPrimary: {
    // label: 'Excluir asset',
    // icon: 'delete',
    // theme: 'danger',
    // onClick: this.onTesting,
    // },
    // actionSecondary: {
    // label,
    // theme,
    // kind,
    // onClick,
    // },
    // }

    return (
      <ul className="m-alert">
        {this.props.alerts.map(alert => (
          <React.Fragment key={alert.timeStamp}>
            {alert.params.overlay && <div className="m-alert__overlay" />}
            <li
              key={alert.timeStamp}
              className={
                "m-alert__item " +
                alert.params.theme +
                " " +
                alert.params.animation +
                " " +
                alert.params.timer +
                " " +
                (alert.params.centralized ? " centralized " : "")
              }
            >
              <h2 className="m-alert__item-title">{alert.params.title}</h2>

              {alert.params.description && (
                <p
                  className="mt-xs-2"
                  dangerouslySetInnerHTML={{ __html: alert.params.description }}
                />
              )}

              {alert.params.actionButtons &&
                alert.params.actionButtons.map((button, index) => (
                  <span key={index}>
                    {/* {(this.state.isLoading.index = false)} */}

                    <Button
                      key={index}
                      kind={button.kind}
                      label={button.label}
                      icon={button.icon}
                      theme={button.theme}
                      isLoading={this.state.isLoading[index] || false}
                      onClick={this.onClickActionButton.bind(
                        this,
                        button.onClick,
                        index,
                        button.actionHasLoading
                      )}
                      className="mt-xs-2"
                    />
                  </span>
                ))}

              <Button
                className="m-alert__button"
                icon="clear"
                width="min"
                kind="ghost"
                theme="light"
                onClick={this.props.delAlert.bind(this, alert.params.id)}
              />
            </li>
          </React.Fragment>
        ))}
      </ul>
    );
  }
}

const mapStateToProps = state => ({
  alerts: state.alerts
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(alertActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alert);

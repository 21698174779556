export const changeAssetViewMode = savePref => {
  return {
    type: "CHANGE_ASSET_VIEW_MODE",
    savePref
  };
};

export const setFiltersByURL = filters => {
  return {
    type: "SET_FILTERS_BY_URL",
    filters
  };
};

export const applyFilters = (filters, payload, clearBefore) => {
  return {
    type: "APPLY_FILTERS",
    filters: filters || {},
    payload: payload || {},
    clearBefore
  };
};

export const clearFilters = payload => {
  return {
    type: "CLEAR_FILTERS",
    payload: payload || {}
  };
};

export const setSearchValue = value => {
  return {
    type: "SET_SEARCH_VALUE",
    value: value || {}
  };
};

const english_actions = {
  ActionSearch: "Search",
  ActionExplore: "Explore",
  ActionEdit: "Edit",
  ActionSave: "Save",
  ActionShare: "Share",
  ActionCancel: "Cancel",
  ActionPlaceholderSelect: "Select...",
  ActionUpload: "Upload assets",
  ActionSelectFiles: "Select files",
  ActionDropFiles: "Drop files here",
  ActionDeleteCollectionKeepingAssets:
    "Exclude the Collection keeping your Assets",
  ActionDeleteCollectionRemovingAssets:
    "Exclude the Collection and your Assets",
  ActionDontMakeNothing: "Don't make nothing",
  DeleteAsset: "Delete Asset",
  DontDeleteAsset: "Don't delete Asset",
  ActionRemoveAssetOfCollection: "Remove Asset of Collection",
  ActionDeleteAssetPermanently: "Delete Asset permanently",
  ActionDeleteGroup: "Delete group",
  ActionForgotPassword: "Forgot your password?",
  ActionCreateNewPassword: "Create new password",
  ActionBackToLogin: "Back to Login",
  DisplayByGrid: "Grid view",
  DisplayByList: "List view",
  ActionUncheckAll: "Uncheck all",
  ActionSearchContent: "Search content",
  ActionDeleteCategory: "Delete category",
  ActionCreateOptionSelectCustomField: "New option",
  ActionPlaceholderSelectCategories: "Select one or more categories ...",
  ActionLeavePage: "Discard changes",
  ActionRemoveCustomField: "Remove fields",
  ActionUpdate: "Update"
};

export { english_actions };

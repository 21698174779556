const uri_codes = [
  { code: "%3F", value: "\\?", orderDecode: 0, orderEncode: 0 },
  { code: "%2F", value: "/", orderDecode: 1, orderEncode: 1 },
  { code: "%3D", value: "=", orderDecode: 2, orderEncode: 2 },
  { code: "%26", value: "&", orderDecode: 3, orderEncode: 3 },
  { code: "%5F", value: "_", orderDecode: 5, orderEncode: 4 },
  { code: "%20", value: " ", orderDecode: 4, orderEncode: 5 }
];

const encode = text => {
  uri_codes
    .sort((a, b) => a.orderEncode - b.orderEncode)
    .map(
      ({ code, value }) =>
        (text = text.replace(new RegExp(`(${value})`, "g"), code))
    );

  return encodeURI(text);
};

const decode = text => {
  text = decodeURI(text);
  uri_codes
    .sort((a, b) => a.orderDecode - b.orderDecode)
    .map(
      ({ code, value }) =>
        (text = text.replace(new RegExp(`(${code})`, "g"), value))
    );
  return text;
};

export const getURLByFilter = filters => {
  const {
    asset_type,
    collections,
    tags,
    dateStart,
    dateEnd,
    personalized_field,
    groups,
    q,
    dateLabel
  } = filters;
  try {
    let url = "/search";

    if (filters) {
      url +=
        asset_type.length > 0 ? `/${asset_type.map(f => f.id).join("/")}` : "";
      url +=
        collections.length > 0
          ? `/${collections
              .map(f => "collection/" + f.id + "/" + encode(f.label))
              .join("/")}`
          : "";
      url +=
        tags.length > 0
          ? `/${tags.map(f => "tag/" + f.id + "/" + encode(f.label)).join("/")}`
          : "";
      url +=
        groups.length > 0
          ? `/${groups
              .map(f => "group/" + f.id + "/" + encode(f.label))
              .join("/")}`
          : "";
      url += dateStart ? `/date_start/${dateStart}` : "";
      url += dateEnd ? `/date_end/${dateEnd}` : "";
      url += dateLabel ? `/date_label/${encode(dateLabel)}` : "";
      url +=
        personalized_field.length > 0
          ? `/${personalized_field
              .map(
                p =>
                  `field/${p.uuid}/${encode(p.label)}/kind/${p.kind}/${
                    p.operator ? `op/${p.operator}/` : ""
                  }value/${p.values.map(v => encode(v)).join("/value/")}`
              )
              .join("/")}`
          : "";

      url += q ? `?q=${encode(q)}` : "";
      return url;
    }
  } catch (error) {
    console.error("marlformed filter object", error);
  }
};

const getAssetTypeLabel = value => {
  switch (value) {
    case "video":
      return "Vídeo";
    case "audio":
      return "Áudio";
    case "image":
      return "Imagem";
    case "pdf":
      return "PDF";
    default:
      return;
  }
};

export const getFiltersByURL = async (url, search) => {
  if (url) {
    let q = null;
    let dateStart = null;
    let dateEnd = null;
    let dateLabel = null;
    let params = url.split("/");
    let asset_type = [];
    let collections = [];
    let tags = [];
    let groups = [];
    let personalized_field = [];

    try {
      if (search) {
        let queryParms = search.substring(1).split("&");
        queryParms.map(query => {
          let param = query.split("=");

          if (param.length > 0 && param[0] === "q") {
            q = decode(param[1]);
          }
          return null;
        });
      }

      for (let i = 0; i < params.length; i++) {
        if (["image", "video", "pdf", "audio"].includes(params[i])) {
          asset_type.push({
            id: params[i],
            label: getAssetTypeLabel(params[i])
          });
        } else if (params[i] === "collection") {
          collections.push({
            id: params[i + 1],
            label: params[i + 2]
          });
          i += 2;
        } else if (params[i] === "tag") {
          tags.push({
            id: params[i + 1],
            label: decode(params[i + 2])
          });
          i += 2;
        } else if (params[i] === "group") {
          groups.push({
            id: params[i + 1],
            label: decode(params[i + 2])
          });
          i += 2;
        } else if (params[i] === "date_start") {
          dateStart = params[i + 1];
        } else if (params[i] === "date_end") {
          dateEnd = params[i + 1];
        } else if (params[i] === "date_label") {
          dateLabel = decode(params[i + 1]);
        } else if (params[i] === "field") {
          let values = [];
          let operator = null;
          let kind = null;
          let index = i + 3;

          if (params[index] === "kind") {
            kind = params[index + 1];
            index = index + 2;
          }

          if (params[index] === "op") {
            operator = params[index + 1];
            index = index + 2;
          }

          let hasValues = true;
          while (hasValues) {
            if (params[index] === "value") {
              values.push(decode(params[index + 1]));
              index += 2;
            } else {
              hasValues = false;
            }
          }

          if (values.length > 0 && params[i + 1]) {
            personalized_field.push({
              uuid: params[i + 1],
              label: decode(params[i + 2]),
              kind,
              operator,
              values
            });
            i = index - 1;
          }
        }
      }
    } catch (error) {
      console.error("marlformed URL", error);
    }

    return {
      asset_type,
      collections,
      groups,
      dateEnd,
      dateStart,
      q,
      tags,
      personalized_field,
      dateLabel
    };
  }
};

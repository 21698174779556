import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import Icon from "./../../_quarks/icon/Icon";

// Styles
import "./FieldEditable.css";

class FieldEditable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false
    };

    this.configsClass = this.props.type + " " + this.props.theme + " ";
  }

  static propTypes = {
    /** Comment soon */
    type: PropTypes.oneOf(["text"]),
    /** Comment soon */
    theme: PropTypes.oneOf(["primary", "negative"])
  };

  static defaultProps = {
    theme: "primary"
  };

  changeStatus = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  render() {
    return (
      <span
        className={
          "a-fieldEditable " +
          this.configsClass +
          (this.state.isActive ? " active " : "") +
          this.props.className
        }
        onClick={this.props.onClick}
        onMouseEnter={this.changeStatus}
        onMouseLeave={this.changeStatus}
      >
        {this.props.children}

        {this.state.isActive && (
          <Icon
            name="edit"
            size="small"
            className="a-fieldEditable__icon"
            onClick={this.props.onClick}
          />
        )}
      </span>
    );
  }
}

export default FieldEditable;

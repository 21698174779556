import React from "react";
import PropTypes from "prop-types";

import "./Loader.css";

class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.componentClass = "q-loader " + this.props.theme + " ";

    if (this.props.centralized) this.componentClass += "centralized ";
  }

  static propTypes = {
    /** Description soon **/
    theme: PropTypes.oneOf(["colorful", "negative"]),
    /** Numbers of columns in breakpoint XS */
    centralized: PropTypes.bool
  };

  static defaultProps = {
    theme: "colorful"
  };

  render() {
    return (
      <svg
        className={this.componentClass + this.props.className}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className="q-loader__path" fill="none" cx="33" cy="33" r="30" />
      </svg>
    );
  }
}

export default Loader;

import React from "react";

import "./Square.css";

export default class Square extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      draws: [],
      squaresReadyToDraw: []
    };

    this.testingSquaresReadyToDraw = [];
  }

  prepareTheSquares = () => {
    // TODO: CONTINUAR SÓ SE HOUVER NOVOS QUADROS OU RESOLUCAO

    let squaresReady = [];

    this.props.draws.map((draw, index) =>
      squaresReady.push({
        ...draw,
        positions: this.calculateDrawPositions(draw)
      })
    );

    return squaresReady;
  };

  calculatePositions(x, y, w, h) {
    let renderedSize = this.props.renderedSize;
    let w_image = renderedSize.w;
    let h_image = renderedSize.h;

    // Getting the original image size
    let originalSize = this.props.originalSize;
    let w_image_original = originalSize.w;
    let h_image_original = originalSize.h;

    // getting new position
    if (w_image_original !== w_image) {
      x = (w_image * x) / w_image_original;
      w = (w_image * w) / w_image_original;
    }

    // getting new h position
    if (h_image_original !== h_image) {
      y = (w_image * y) / w_image_original;
      h = (w_image * h) / w_image_original;
    }

    return { x: x, y: y, w: w, h: h };
  }

  calculateDrawPositions(draw) {
    let maxX, minX, maxY, minY, x, y, w, h, positions;

    maxX = Math.max(
      draw.vertices[0].x,
      draw.vertices[1].x,
      draw.vertices[2].x,
      draw.vertices[3].x
    );
    minX = Math.min(
      draw.vertices[0].x,
      draw.vertices[1].x,
      draw.vertices[2].x,
      draw.vertices[3].x
    );
    maxY = Math.max(
      draw.vertices[0].y,
      draw.vertices[1].y,
      draw.vertices[2].y,
      draw.vertices[3].y
    );
    minY = Math.min(
      draw.vertices[0].y,
      draw.vertices[1].y,
      draw.vertices[2].y,
      draw.vertices[3].y
    );
    x = minX;
    y = minY;
    h = maxY - minY;
    w = maxX - minX;
    positions = this.calculatePositions(x, y, w, h);

    // Positions for draw
    let squarePadding = 5;
    let squareBorder = this.props.borderIncrement
      ? this.props.borderIncrement * 2
      : 2;
    let extraSpacePosition = squarePadding + squareBorder;
    let extraSpaceMask = squarePadding;

    return {
      x: positions.x - extraSpacePosition,
      y: positions.y - extraSpacePosition,
      w: positions.w,
      h: positions.h,
      maskX: -(positions.x - extraSpaceMask),
      maskY: -(positions.y - extraSpaceMask)
    };
  }

  render() {
    let squaresReadyToDraw = this.prepareTheSquares();

    return (
      <div className="a-square">
        {squaresReadyToDraw.map((draw, index) => (
          <span
            key={index}
            className={
              "a-square__item " +
              (this.props.animation ? " a-square__animation " : "")
            }
            style={{
              left: draw.positions.x,
              top: draw.positions.y,
              width: draw.positions.w,
              height: draw.positions.h,
              borderWidth: this.props.borderIncrement
                ? this.props.borderIncrement * 2
                : 2
            }}
          />
        ))}
      </div>
    );
  }
}

import AxiosHelper from "./../helpers/AxiosHelper";
const RESOURCE = "webview";

const forgotPassword = email => {
  const url = `/${RESOURCE}/forgotpassword/`;

  let data = {
    email: email,
    call_back: window.location.protocol + "//" + window.location.hostname
  };

  return AxiosHelper.doPostWithoutAuth(url, data);
};

const validateToken = token => {
  const url = `/${RESOURCE}/validationcheck/${token}`;

  return AxiosHelper.doGetWithoutAuth(url);
};

const changePassword = (newPassword, hash) => {
  const url = `/${RESOURCE}/changepassword/`;

  let data = {
    password: newPassword,
    hash: hash
  };

  return AxiosHelper.doPostWithoutAuth(url, data);
};

export { forgotPassword, validateToken, changePassword };

import React from "react";
import { GOOGLE_AUTH_CLIENT_ID } from "./../../../Config";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";

// Authentication
import GoogleLogin from "react-google-login";
import {
  getToken,
  doLogin,
  loginWithGoogle
} from "./../../../models/UserModel";
import Auth from "./../../../models/AuthModel";

// Components
import Button from "./../../atoms/button/Button";
import FormGroup from "./../../molecules/form-group/FormGroup";
import GoogleLogo from "../../../imgs/google-login/google_logo.svg";

// Translate
import { I18n } from "react-i18nify";

import "./Login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      isLogging: false
    };
  }

  componentWillMount() {
    I18n.setLocale("pt_br");
  }

  componentDidMount = () => {
    this.formGroupEmail.refs.input.focus();
  };

  loginSubmitHandler = event => {
    this.setState({
      isLogging: true
    });

    const data = {
      username: this.state.email,
      password: this.state.password
    };

    event.preventDefault();

    getToken(data)
      .then(response => {
        const token = response.data.token;
        Auth.authenticateUser(token);

        doLogin(data)
          .then(response => {
            if (response.status === 200) {
              let userData = response.data.user;
              userData.language = response.data.language_option.value;
              Auth.setUserInfo(userData);

              I18n.setLocale(userData.language);

              this.setState({
                isLogging: false
              });

              this.props.history.push("/");
            }

            this.props.clearAlerts();
          })
          .catch(error => {
            this.setState({
              isLogging: false
            });

            this.props.addAlert({
              id: "alertLogin",
              theme: "danger",
              animation: "shake",
              timer: "fast",
              title: I18n.t("TitleAlertServerError"),
              description: I18n.t("ContentAlertServerError")
            });
          });
      })
      .catch(error => {
        if (error.response) {
          let data = error.response.data;
          let incorrectEmailPassword =
            "Unable to log in with provided credentials.";

          if (
            data &&
            Array.isArray(data.non_field_errors) &&
            data.non_field_errors.includes(incorrectEmailPassword)
          ) {
            this.props.addAlert({
              id: "alertLogin",
              theme: "warning",
              animation: "shake",
              timer: "infinite",
              title: I18n.t("TitleIncorrectEmailPassword"),
              description: I18n.t("ContentIncorrectEmailPassword")
            });
          }
        } else {
          this.props.addAlert({
            id: "alertLogin",
            theme: "danger",
            animation: "shake",
            timer: "infinite",
            title: I18n.t("TitleNoConnection"),
            description: I18n.t("ContentNoConnection")
          });
        }

        this.setState({ isLogging: false });
      });
  };

  onClickForgotPassword = () => {
    this.props.history.push("/forgot-password");
  };

  errorLoginGoogle = response => {
    if (response && response.error) {
      let errorDescription = null;

      switch (response.error) {
        case "idpiframe_initialization_failed":
          return;
        case "popup_closed_by_user":
          return;
        case "access_denied":
          errorDescription = I18n.t("ContentAlertGoogleErrorDenied");
          break;
        case "immediate_failed":
          errorDescription = I18n.t("ContentAlertGoogleError");
          break;
        default:
          return;
      }

      if (errorDescription) {
        this.props.addAlert({
          id: "alertLogin",
          theme: "danger",
          animation: "shake",
          timer: "infinite",
          title: I18n.t("TitleAlertServerError"),
          description: errorDescription
        });
      }
    }
  };

  responseLoginGoogle = response => {
    const dadosgoogle = {
      username: response.profileObj.email,
      googleid: response.profileObj.googleId,
      imageurl: response.profileObj.imageUrl,
      first_name: response.profileObj.givenName,
      last_name: response.profileObj.familyName
    };

    loginWithGoogle(dadosgoogle)
      .then(response => {
        if (response.status === 200) {
          const token = response.data.token;
          Auth.authenticateUser(token);
          doLogin(null).then(response => {
            if (response.status === 200) {
              let userData = response.data.user;
              userData.language = response.data.language_option.value;
              Auth.setUserInfo(userData);

              I18n.setLocale(userData.language);

              this.setState({
                isLogging: false
              });

              this.props.history.push("/");
            }
          });
        }
      })
      .catch(error => {
        this.props.addAlert({
          id: "alertLogin",
          theme: "danger",
          animation: "shake",
          timer: "fast",
          title: I18n.t("TitleAlertServerError"),
          description: I18n.t("ContentAlertGoogleErrorAuris")
        });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div className="p-login">
        <form onSubmit={this.loginSubmitHandler}>
          <div className="mb-xs-3">
            <FormGroup
              ref={formGroup => (this.formGroupEmail = formGroup)}
              type="email"
              name="email"
              placeholder={I18n.t("PlaceholderInputEmail")}
              onChange={this.onChange.bind(this)}
            />
          </div>

          <div className="mb-xs-3">
            <FormGroup
              onChange={this.onChange.bind(this)}
              type="password"
              placeholder={I18n.t("PlaceholderInputPassword")}
              name="password"
            />
          </div>

          <div className="mb-xs-2">
            <Button
              id="loginButton"
              type="submit"
              size="large"
              label={I18n.t("LabelButtonLogin")}
              icon="right"
              iconAlign="right"
              width="fluid"
              isLoading={this.state.isLogging}
            />
          </div>

          <div className="mb-xs-2">
            <GoogleLogin
              clientId={GOOGLE_AUTH_CLIENT_ID}
              className="p-login__google-login-button a-button fluid "
              onSuccess={this.responseLoginGoogle}
              onFailure={this.errorLoginGoogle}
            >
              <img
                className="p-login__google-logo"
                src={GoogleLogo}
                alt="Google"
              />
              {I18n.t("LabelButtonLoginWithGoogle")}
            </GoogleLogin>
          </div>

          <div
            onDoubleClick={() =>
              this.props.addAlert({
                id: "alertLoginEgg",
                theme: "danger",
                animation: "shake",
                timer: "infinite",
                title: "Alerta temporário",
                description:
                  "Essa ação deve ser substituída pela mensagem correta."
              })
            }
          >
            <Button
              label={I18n.t("ActionForgotPassword")}
              theme="light"
              kind="ghost"
              width="fluid"
              onClick={this.onClickForgotPassword.bind(this)}
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...alertActions }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Login);

import styled from "styled-components";
import Icon from "../../Icon/Icon";
import { darken } from "@material-ui/core/styles/colorManipulator";

export const AsideContainer = styled.aside`
  display: flex;
  ${props => (props.disabled ? `pointer-events: none;` : "")}
`;

export const AsideNav = styled.ul`
  width: 60px;
  height: 100%;
  background-color: #4b4f54;
  z-index: 1;
`;

export const AsideItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  box-sizing: initial;
  cursor: pointer;
  transition: color 300ms, background 300ms;
  position: relative;

  ${props =>
    props.actived
      ? `  
        background-color: rgba(0, 201, 215, 0.2);        
        &:after {
            content: "";
            width: 2px;
            height: 100%;
            background-color: #00C9D7;
            position: absolute;
            top: 0;
            left: 0px; 
        }
    `
      : ""}

  ${props =>
    !props.actived && !props.disabled
      ? `  
          :hover {
            background-color: ${darken("#4b4f54", 0.1)};
          }
        `
      : ""}
`;

export const AsideIcon = styled(Icon)`
  && {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.7);

    ${props => (props.actived ? ` color: #00c9d7;` : "")}
    ${props => (props.disabled ? ` color: ${props.theme.palette.grey};` : "")}
    ${props => (props.rotate ? `transform: rotate(180deg);` : "")}
  }
`;

export const AsideContent = styled.div`
  position: relative;
  width: 0;
  background-color: #5d6166;
  transition: width 300ms ease-in-out;
  overflow: auto;
  padding-bottom: 60px;

  ${props =>
    props.actived
      ? ` 
  width: 260px;`
      : ""}
`;

export const AsideClear = styled.div`
  position: fixed;
  bottom: -60px;
  width: 230px;
  padding-left: 16px;
  transition: bottom 300ms;
  ${props => (props.actived ? `bottom: 8px;` : "")}
`;

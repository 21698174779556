import React, { Component } from "react";

import "./Badge.css";

class Badge extends Component {
  render() {
    return (
      <div className={"q-badge " + this.props.className}>{this.props.text}</div>
    );
  }
}

export default Badge;

import React, { Component } from "react";

// Components
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Icon from "./../../_quarks/icon/Icon";

import Button from "./../../atoms/button/Button";
import Key from "./../../atoms/key/Key";

import FormGroup from "./../../molecules/form-group/FormGroup";
import Modal from "./../../molecules/modal/Modal";

// Styles
import "./CategoryPage-UpdateCustomField.css";

// Translate
const { I18n } = require("react-i18nify");

class UpdateCustomField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdvancedSettings: false,
      inputTypePreview: "text",
      isReady: false,
      optionsSelectOnPreview: [],
      onFocusFieldType: false
    };
  }

  componentDidMount = async () => {
    this.setState({
      dataToUpdate: this.props.dataToUpdate,
      optionsSelectOnPreview: this.getInitialSelectOnPreview(
        this.props.dataToUpdate.options
      ),
      showAdvancedSettings:
        this.props.dataToUpdate.regex || this.props.dataToUpdate.tooltip,
      isReady: true
    });
  };

  getInitialSelectOnPreview = optionsFromDataFieldToEdit => {
    let initialSelectOnPreview = [];

    if (optionsFromDataFieldToEdit) {
      optionsFromDataFieldToEdit.map((item, index) =>
        initialSelectOnPreview.push({
          id: Math.random(),
          label: item,
          value: item
        })
      );
    }

    return initialSelectOnPreview;
  };

  callbackOnChangeVirtualized = objectValue => {
    let newValue = "";
    let optionsSelectOnPreview = this.state.optionsSelectOnPreview;

    if (objectValue) {
      newValue = objectValue.value;

      if (newValue === "select" || newValue === "multiselect") {
        if (!optionsSelectOnPreview.length) {
          optionsSelectOnPreview = [
            {
              id: Math.random(),
              label: "",
              value: ""
            },
            {
              id: Math.random(),
              label: "",
              value: ""
            }
          ];
        }
      }
    }

    this.setState({
      dataToUpdate: {
        ...this.state.dataToUpdate,
        kind: objectValue.value
      },
      inputTypePreview: this.getInputTypePreview(objectValue.value),
      onFocusFieldType: false,
      optionsSelectOnPreview: optionsSelectOnPreview
    });
  };

  getInputTypePreview = newValue => {
    let newInputTypePreview = "text";

    if (newValue === "int" || newValue === "float") {
      newInputTypePreview = "number";
    } else if (newValue === "date") {
      newInputTypePreview = "date";
    }

    return newInputTypePreview;
  };

  addOptionOnSelect = () => {
    let optionsSelectOnPreview = this.state.optionsSelectOnPreview;

    optionsSelectOnPreview.push({
      id: Math.random(),
      label: "",
      value: ""
    });

    this.setState({
      optionsSelectOnPreview: optionsSelectOnPreview
    });
  };

  removeOptionOnSelect = indexToRemove => {
    let optionsSelectOnPreview = this.state.optionsSelectOnPreview.filter(
      item => item.id !== this.state.optionsSelectOnPreview[indexToRemove].id
    );

    let newOptions = this.state.dataToUpdate.options.filter(
      (item, index) => index !== indexToRemove
    );

    this.setState({
      optionsSelectOnPreview: optionsSelectOnPreview,
      dataToUpdate: {
        ...this.state.dataToUpdate,
        options: newOptions
      }
    });
  };

  changeOptionSelect = (indexOptionChanged, event) => {
    let valueTyped = event.target.value;

    let optionsSelectOnPreview = this.state.optionsSelectOnPreview;
    optionsSelectOnPreview[indexOptionChanged] = {
      id: optionsSelectOnPreview[indexOptionChanged].id,
      label: valueTyped,
      value: valueTyped
    };

    let newOptions = JSON.parse(
      JSON.stringify(this.state.dataToUpdate.options)
    );

    if (!newOptions) {
      newOptions = [];
    }

    newOptions[indexOptionChanged] = valueTyped;

    this.setState({
      optionsSelectOnPreview: optionsSelectOnPreview,
      dataToUpdate: {
        ...this.state.dataToUpdate,
        options: newOptions
      }
    });
  };

  render() {
    if (!this.state.isReady) {
      return <div />;
    } else {
      return (
        <Modal
          className={
            this.state.onFocusFieldType
              ? "p-categoryPage__updateCustomField-modalWithoutScroll"
              : ""
          }
          title={I18n.t("CustomField")}
          toClose={this.props.callbackToClose}
          md="8"
          lg="10"
          xlg="8"
        >
          <div className="py-xs-4 px-xs-3">
            <Row>
              <Col lg="6" className="mb-xs-4 mb-lg-0">
                <FormGroup
                  ref={formGroupFieldTitle =>
                    (this.formGroupFieldTitle = formGroupFieldTitle)
                  }
                  name="formGroupFieldTitle"
                  label={I18n.t("CustomFieldTitle")}
                  defaultValue={this.state.dataToUpdate.label}
                  className="mb-xs-3"
                  onChange={() =>
                    this.setState({
                      dataToUpdate: {
                        ...this.state.dataToUpdate,
                        label: this.formGroupFieldTitle.refs.input.value
                      }
                    })
                  }
                />

                <FormGroup
                  type="virtualized"
                  loadInVirtualized="custom"
                  name="custom"
                  multi={false}
                  async={false}
                  options={[
                    {
                      label: I18n.t("CustomFieldTypeText"),
                      value: "str"
                    },
                    {
                      label: I18n.t("CustomFieldTypeDate"),
                      value: "date"
                    },
                    {
                      label: I18n.t("CustomFieldTypeMultiselect"),
                      value: "multiselect"
                    },
                    {
                      label: I18n.t("CustomFieldTypeUniqueselect"),
                      value: "select"
                    },
                    {
                      label: I18n.t("CustomFieldTypeInt"),
                      value: "int"
                    },
                    {
                      label: I18n.t("CustomFieldTypeFloat"),
                      value: "float"
                    }
                  ]}
                  defaultValue={this.state.dataToUpdate.kind}
                  clearable={false}
                  label={`${I18n.t("CustomFieldType")}:`}
                  onChange={this.callbackOnChangeVirtualized.bind(this)}
                  onFocus={() => this.setState({ onFocusFieldType: true })}
                  onBlur={() => this.setState({ onFocusFieldType: false })}
                  className="mb-xs-3"
                />

                {(this.state.dataToUpdate.kind === "int" ||
                  this.state.dataToUpdate.kind === "float") && (
                  <small className="p-categoryPage__legend">
                    <Icon
                      className="p-categoryPage__legend-icon"
                      name="announcement"
                      size="medium"
                    />
                    {I18n.t("HelpMsgIntField")}
                  </small>
                )}

                {(this.state.dataToUpdate.kind === "select" ||
                  this.state.dataToUpdate.kind === "multiselect") && (
                  <div
                    className="px-xs-2 py-xs-3 p mb-xs-3 bg-default-2"
                    style={{ overflow: "auto", maxHeight: 400 }}
                  >
                    {this.state.optionsSelectOnPreview.map((item, index) => (
                      <div
                        key={item.id}
                        className={`mb-xs-3 ${
                          index > 1 ? "p-categoriesList__optionWithDelete" : ""
                        }`}
                      >
                        <FormGroup
                          label={I18n.t("CustomFieldOptionSelect", {
                            index: index + 1
                          })}
                          className="w-xs-100"
                          defaultValue={item.label}
                          onChange={this.changeOptionSelect.bind(this, index)}
                        />

                        {index > 1 && (
                          <Button
                            icon="delete"
                            type="button"
                            size="large"
                            theme="danger"
                            width="min"
                            className="ml-xs-2"
                            onClick={this.removeOptionOnSelect.bind(
                              this,
                              index
                            )}
                          />
                        )}
                      </div>
                    ))}

                    <Button
                      icon="add"
                      type="button"
                      label={I18n.t("ActionCreateOptionSelectCustomField")}
                      width="fluid"
                      onClick={this.addOptionOnSelect.bind(this)}
                    />
                  </div>
                )}

                <Key
                  label={I18n.t("CustomFieldAdvancedInformation")}
                  id="advancedSettings"
                  block
                  defaultChecked={this.state.showAdvancedSettings}
                  onChange={() =>
                    this.setState({
                      showAdvancedSettings: !this.state.showAdvancedSettings
                    })
                  }
                />

                {this.state.showAdvancedSettings && (
                  <div className="mt-xs-3">
                    <FormGroup
                      ref={formGroupRegex =>
                        (this.formGroupRegex = formGroupRegex)
                      }
                      label={I18n.t("CustomFieldValidation")}
                      className="mb-xs-3"
                      defaultValue={this.state.dataToUpdate.regex}
                      onChange={() =>
                        this.setState({
                          dataToUpdate: {
                            ...this.state.dataToUpdate,
                            regex: this.formGroupRegex.refs.input.value
                          }
                        })
                      }
                    />

                    <FormGroup
                      ref={formGroupFieldLegend =>
                        (this.formGroupFieldLegend = formGroupFieldLegend)
                      }
                      label={I18n.t("CustomFieldSupportMessage")}
                      defaultValue={this.state.dataToUpdate.tooltip}
                      onChange={() =>
                        this.setState({
                          dataToUpdate: {
                            ...this.state.dataToUpdate,
                            tooltip: this.formGroupFieldLegend.refs.input.value
                          }
                        })
                      }
                    />
                  </div>
                )}
              </Col>

              <Col lg="6">
                <span className="block-xs mb-xs-2">
                  <Icon name="visibility" className="mr-xs-1" />
                  {I18n.t("CustomFieldPreview")}
                </span>

                <div className="mb-xs-3 p-xs-3 bg-default-2">
                  {(!this.state.dataToUpdate.kind ||
                    this.state.dataToUpdate.kind === "str" ||
                    this.state.dataToUpdate.kind === "date" ||
                    this.state.dataToUpdate.kind === "int" ||
                    this.state.dataToUpdate.kind === "float") && (
                    <FormGroup
                      type={this.state.inputTypePreview}
                      label={
                        (this.state.dataToUpdate.label
                          ? this.state.dataToUpdate.label
                          : I18n.t("CustomFieldTitleEmpty")) + ":"
                      }
                    />
                  )}

                  {(this.state.dataToUpdate.kind === "multiselect" ||
                    this.state.dataToUpdate.kind === "select") && (
                    <FormGroup
                      type="virtualized"
                      loadInVirtualized="custom"
                      name="custom"
                      multi={
                        this.state.dataToUpdate.kind === "multiselect"
                          ? true
                          : false
                      }
                      async={false}
                      options={this.state.optionsSelectOnPreview}
                      defaultValue={{}} // MULTISELECT
                      // defaultValue={{}}
                      label={
                        (this.state.dataToUpdate.label
                          ? this.state.dataToUpdate.label
                          : I18n.t("CustomFieldTitleEmpty")) + ":"
                      }
                    />
                  )}
                </div>
              </Col>
            </Row>

            <div className="text-xs-right">
              <Button
                type="button"
                size="large"
                kind="ghost"
                label={I18n.t("ActionCancel")}
                onClick={this.props.callbackToClose}
              />

              <Button
                type="button"
                size="large"
                label={I18n.t("ActionUpdate")}
                onClick={this.props.callbackToSave.bind(
                  this,
                  this.state.dataToUpdate
                )}
              />
            </div>
          </div>
        </Modal>
      );
    }
  }
}

export default UpdateCustomField;

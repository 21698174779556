const brazilian_portuguese_errors = {
  TitleAlertServerError: "Ocorreu um erro",
  ContentAlertServerError: "Tente novamente ou volte mais tarde.",
  ContentAlertGoogleError: "Não foi possível fazer o login com o Google.",
  ContentAlertGoogleErrorAuris: "Não foi possível fazer o login.",
  ContentAlertGoogleErrorDenied: "Acesso negado.",
  ContentDontSupportVideoMedias: "Seu navegador não suporta mídias de vídeo.",
  ContentDontSupportAudioMedias: "Seu navegador não suporta mídias de áudio.",
  TitleAlertErrorCollectionDeleted: "Não foi possível excluir a Coleção.",
  ContentAlertErrorCollectionDeleted:
    "Ocorreu um erro inesperado na tentativa de exclusão da Coleção. <strong>Tente novamente.</strong>",
  TitleAlertAssetWasNotDeleted: "Não foi possível excluir o Asset",
  ContentAlertAssetWasNotDeleted:
    "Ocorreu um erro inesperado na tentativa de exclusão do asset. <strong>Tente novamente.</strong>",
  TitleAlertPasswordHasNotBeenChanged: "Não foi possível gerar a nova senha",
  TitleAlertPasswordHasNotBeenChangedBecauseTheNew:
    "A nova senha não confere com a confirmação. Tente novamente.",
  TitleAlertPasswordHasNotBeenChangedBecauseTheOld:
    "A senha atual parece estar incorreta. Tente novamente.",
  TitleAlertUnhangedProfile: "Perfil não alterado",
  ContentAlertUnhangedProfile:
    "Ocorreu um erro ao tentar alterar o perfil. Tente novamente.",
  TitleAlertUserNotCreated: "Usuário não criado",
  ContentAlertCheckEmailInUse: "Verifique se o e-mail não está cadastrado.",
  TitleAlertUserNotRemoved: "Usuário não removido",
  ContentAlertUserNotRemoved:
    "Ocorreu um erro ao tentar excluir o usuário. Tente novamente.",
  TitleAlertGroupNotRemoved: "Grupo não removido",
  ContentAlertGroupNotRemoved:
    "Ocorreu um erro ao tentar excluir o grupo. Tente novamente.",
  TitleAlertEmailNotFound: "E-mail não encontrado",
  ContentAlertEmailNotFound:
    "Não encontramos o e-mail <strong>%{email}</strong> na nossa base de dados. Verifique se digitou corretamente.",
  TitleAlertExpiredTime: "Tempo expirado",
  ContentAlertExpiredTime:
    "Seu pedido de alteração de senha expirou. Clique em <strong>%{actionForgotPassword}</strong> se deseja alterar sua senha.",
  SearchContentNotFound:
    "Nenhum conteúdo encontrado para <strong>%{searchTerm}</strong>.",
  ContentErrorAnalytics:
    "Ocorreu um erro para captar os dados de estatísticas. Tente novamente.",
  TitleAlertCategoryNotRemoved: "Categoria não removida",
  ContentAlertCategoryNotRemoved:
    "Ocorreu um erro ao tentar excluir a categoria. Tente novamente.",
  TitleAlertEditCustomFieldWithError: "Erro ao editar campos personalizados",
  ContentAlertUserWithoutGroup: "O Usuário deve possuir um grupo",
  ContentAlertUserWithoutName: "O Usuário deve possuir um nome",
  TitleNoConnection: "Perda de conexão",
  ContentNoConnection:
    "Verifique sua conexão com a internet e tente novamente mais tarde. Caso o erro persista, por favor, entre em contato com o suporte.",
  TitleIncorrectEmailPassword: "Verifique seu usuário e senha",
  ContentIncorrectEmailPassword:
    "Parece que seu usuário e/ou senha estão incorretos, por favor, verifique os dados e tente novamente.",
  CategoryDuplicatedName: "Este nome de categoria já existe"
};

export { brazilian_portuguese_errors };

export function addAlert(params) {
  return {
    type: "ADD_ALERT",
    params
  };
}

export function delAlert(id) {
  return {
    type: "DEL_ALERT",
    id
  };
}

export function clearAlerts() {
  return {
    type: "CLEAR_ALERTS"
  };
}

import { Card, Typography, CardContent, CardMedia } from "@material-ui/core";
import styled from "styled-components";
import Icon from "../Icon/Icon";

export const CardRoot = styled(Card)`
  && {
    transition: transform ${props => props.theme.transitions.standard};
    :hover {
      box-shadow: ${props => props.theme.shadows.boxItem};
      cursor: pointer;
      transform: scale(1.05);
    }
  }
`;

export const CardRootContent = styled(CardContent)`
  && {
    padding: ${props => props.theme.spacing.unit * 2}px;
    :last-child {
      padding-bottom: ${props => props.theme.spacing.unit * 2}px;
    }
  }
`;

export const CardRootMedia = styled(CardMedia)`
  && {
    height: 0;
    padding-top: 56.25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MediaIcon = styled(Icon)`
  && {
    margin-bottom: 50%;
    width: 50px;
    height: 50px;
    color: ${props => props.theme.palette.secondary};
  }
`;

export const ContentName = styled(Typography)`
  && {
    ${props => props.theme.typography.body1}
    font-weight: bold;
  }
`;

export const ContentDecription = styled(Typography)`
  && {
    ${props => props.theme.typography.subtitle1}
  }
`;

export const DescriptionIcon = styled.div`
  float: right;
  font-size: ${props => props.theme.size.xl};
  margin-left: ${props => props.theme.spacing.unit}px;
`;

import React from "react";
import {
  withStyles,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { applyFilters } from "../../pages/search-results/_actionsReducers";

import FilterChildrenDate from "./FilterChildrenDate";

const styles = theme => ({
  boxItems: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 182,
    overflowY: "auto",
    marginTop: theme.spacing.unit
  }
});

class FilterChildrenDefaultRadio extends React.Component {
  state = {
    items: [],
    showCustomDate: false,
    value: ""
  };

  componentDidMount = () => {
    if (this.props.name === "date_upload") {
      this.setItemsDateUpload();
    }
  };

  setItemsDateUpload = () => {
    this.setState({
      items: [
        {
          id: "today",
          label: "Hoje"
        },
        {
          id: "thisWeek",
          label: "Esta semana"
        },
        {
          id: "thisMonth",
          label: "Este mês"
        },
        {
          id: "last30days",
          label: "Últimos 30 dias"
        },
        {
          id: "custom",
          label: "Personalizado"
        }
      ]
    });
  };

  changeShowCustomDate = booleanShowCustomDate => {
    this.setState({
      showCustomDate: booleanShowCustomDate
    });
  };

  handleChange = event => {
    this.applyDateFilter(event.target.value);
    this.setState({ value: event.target.value });
  };

  applyDateFilter = id => {
    let dateStart, dateEnd;
    const { items } = this.state;

    let dateLabel = null;
    let item = items.find(v => v.id === id);
    if (item) {
      dateLabel = item.label;
    }

    switch (id) {
      case "today":
        dateStart = this.formatDate(new Date());
        dateEnd = dateStart;
        this.props.applyFilters(
          { dateStart, dateEnd, dateLabel },
          { updateAssets: true }
        );

        this.changeShowCustomDate(false);
        break;
      case "thisWeek":
        dateStart = this.getFirstDayWeekFormated(new Date());
        dateEnd = this.formatDate(new Date());
        this.props.applyFilters(
          { dateStart, dateEnd, dateLabel },
          { updateAssets: true }
        );
        this.changeShowCustomDate(false);
        break;
      case "thisMonth":
        dateStart = this.getFirstDayMonthFormated(new Date());
        dateEnd = this.formatDate(new Date());
        this.props.applyFilters(
          { dateStart, dateEnd, dateLabel },
          { updateAssets: true }
        );
        this.changeShowCustomDate(false);
        break;
      case "last30days":
        dateStart = this.getLast30DayFormated(new Date());
        dateEnd = this.formatDate(new Date());
        this.props.applyFilters(
          { dateStart, dateEnd, dateLabel },
          { updateAssets: true }
        );
        this.changeShowCustomDate(false);
        break;
      default:
        this.changeShowCustomDate(true);
    }
  };

  getLast30DayFormated = today => {
    let diff = today.getDate() - 29;
    let dateLast30Day = new Date(today.setDate(diff));
    return this.formatDate(dateLast30Day);
  };

  getFirstDayMonthFormated = today => {
    let year = today.getFullYear();
    let month = today.getMonth();
    let day = 1;
    let dateFirstDayMonth = new Date(year, month, day);
    return this.formatDate(dateFirstDayMonth);
  };

  getFirstDayWeekFormated = today => {
    let day = today.getDay();
    let diff = today.getDate() - day + (day === 0 ? -7 : 0);
    let dateFirstDayWeek = new Date(today.setDate(diff));
    return this.formatDate(dateFirstDayWeek);
  };

  formatDate = date => {
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();

    if (month.toString().length < 2) {
      month = `0${month}`;
    }

    if (day.toString().length < 2) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  render() {
    let { items, showCustomDate } = this.state;
    const { classes } = this.props;

    return (
      <div className={this.props.className}>
        {this.props.label && <h3>{this.props.label}</h3>}

        {items && (
          <div className={classes.boxItems}>
            <RadioGroup
              aria-label={this.props.label}
              name={this.props.label}
              value={this.state.value}
              onChange={this.handleChange}
            >
              {items.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.id}
                  control={<Radio color="primary" />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </div>
        )}

        {showCustomDate && <FilterChildrenDate name={this.props.name} />}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  filters: state.search.filters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ applyFilters }, dispatch);

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterChildrenDefaultRadio)
);

export function assetDetails(state = {}, action) {
  switch (action.type) {
    case "GET_ASSET_DETAILS":
      return {
        id: action.params.id,
        timeshots: action.params.timeshots,
        timestamp: action.params.timestamp,
        searchInPdf: action.params.searchInPdf,
        square: action.params.square,
        showAssetModal: true
      };
    case "REMOVE_ASSET_DETAILS":
      return {};
    default:
      return state;
  }
}

export function enableToDeleteAsset(state = false, action) {
  switch (action.type) {
    case "TOGGLE_ENABLE_TO_DELETE_ASSET":
      return !state;
    default:
      return state;
  }
}

import { english_default } from "./en_default";
import { english_errors } from "./en_errors";
import { english_actions } from "./en_actions";

import { brazilian_portuguese_default } from "./pt-br_default";
import { brazilian_portuguese_errors } from "./pt-br_errors";
import { brazilian_portuguese_actions } from "./pt-br_actions";

const { I18n } = require("react-i18nify");

const en = Object.assign({}, english_default, english_errors, english_actions);
const pt_br = Object.assign(
  {},
  brazilian_portuguese_default,
  brazilian_portuguese_errors,
  brazilian_portuguese_actions
);

I18n.setTranslations({ en, pt_br });

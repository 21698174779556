import React from "react";

import "./Box.css";

export default class Box extends React.Component {
  render() {
    return (
      <div {...this.props} className={"a-box " + this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

import React from "react";
import PropTypes from "prop-types";

// Styles
import "./Key.css";

class Key extends React.Component {
  static propTypes = {
    /** Comment soon */
    id: PropTypes.string.isRequired,
    /** Comment soon */
    label: PropTypes.string,
    /** Comment soon */
    block: PropTypes.bool
  };

  render() {
    return (
      <label
        className={
          "a-key " +
          (this.props.block ? "block-xs " : " ") +
          this.props.className
        }
        htmlFor={this.props.id}
      >
        <input
          ref="input"
          className="a-key__input"
          id={this.props.id}
          type="checkbox"
          defaultChecked={this.props.defaultChecked}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
        />

        <span
          className={"a-key__check " + (this.props.label ? "mr-xs-2 " : " ")}
        />

        {this.props.label}
      </label>
    );
  }
}

export default Key;

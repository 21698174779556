import React from "react";

import FilterChildrenDefault from "./FilterChildrenDefault";
import FilterChildrenDefaultRadio from "./FilterChildrenDefaultRadio";
import FilterChildrenSearchable from "./FilterChildrenSearchable";
import FilterPersonalizedField from "./FilterPersonalizedField";
import { withStyles } from "@material-ui/core";
import Icon from "../../Icon/Icon";

const styles = theme => ({
  root: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  label: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold"
  },
  iconExpand: {
    fontSize: 24,
    marginLeft: "auto",
    color: theme.palette.common.white
  },
  fieldsContainer: {}
});

class FilterParent extends React.Component {
  state = {
    active: this.props.active
  };

  render() {
    let { active } = this.state;
    let { classes } = this.props;

    return (
      <div className={classes.root}>
        <div
          className={classes.labelContainer}
          onClick={() => this.setState({ active: !active })}
        >
          <span className={classes.label}>{this.props.label}</span>

          <Icon
            name={active ? "expand-less" : "expand-more"}
            className={classes.iconExpand}
          />
        </div>

        <div className={classes.fieldsContainer}>
          {active && (
            <React.Fragment>
              {this.props.name === "asset_type" && (
                <FilterChildrenDefault name={this.props.name} />
              )}

              {this.props.name === "personalized_field" && (
                <FilterPersonalizedField
                  name={this.props.name}
                  uuid={this.props.uuid}
                />
              )}

              {this.props.name === "collections" && (
                <FilterChildrenSearchable name={this.props.name} />
              )}

              {this.props.name === "tags" && (
                <FilterChildrenSearchable name={this.props.name} />
              )}

              {this.props.name === "date_upload" && (
                <FilterChildrenDefaultRadio name={this.props.name} />
              )}

              {this.props.name === "groups" && (
                <FilterChildrenDefault name={this.props.name} />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FilterParent);

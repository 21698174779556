import React from "react";
import styled, { withTheme } from "styled-components";
import { Tooltip } from "@material-ui/core";

const Arrow = styled.span`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  ${props =>
    props.placement === "right"
      ? `
        border-width: 0.5em 0.75em 0.5em 0;   
        top: 50%;
        left: -0.75em;
        transform: translate(0, -50%);`
      : ""}

  ${props =>
    props.placement === "bottom"
      ? `
          border-width: 0 0.5em 0.5em 0.75em;
          transform: rotate(-45deg) translate(0, -50%);;
          left: 50%;
          top: 0.2em;
           `
      : ""}
  border-color: transparent ${props => props.theme.palette.greyAside}
  transparent transparent;
`;

const Root = styled(
  ({ placement, arrow, theme, className, title, ...other }) => {
    let delay = parseInt(theme.transitions.standard.replace("ms", ""), 10);
    return (
      <Tooltip
        placement={placement}
        enterDelay={delay}
        title={
          <>
            {arrow && <Arrow placement={placement} />}
            {title}
          </>
        }
        classes={{ popper: className, tooltip: "tooltip" }}
        className={className}
        {...other}
      />
    );
  }
)`
  & .tooltip {
    ${props => props.theme.typography.subtitle2}
    background-color: ${props => props.theme.palette.greyAside};
    word-wrap: break-word;
  }
`;

export default withTheme(Root);

import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { TableRow, TableCell } from "@material-ui/core";
import Tooltip from "../Tooltip/Tooltip";
import PropTypes from "prop-types";
import Checkbox from "../Checkbox/Checkbox";
import { I18n } from "react-i18nify";

/**
 * Head for Apollo Table
 *
 * @param {Function}  onSelectAllClick      Set all rows as selected
 * @param {Function}  onRequestSort         Handle table head click to sort
 * @param {String}    order                 Order to sort (ASC or DESC)
 * @param {String}    orderBy               Key of data to sort
 * @param {Boolean}   allChecked            All page rows selected
 * @param {Array}     labels                List of Labels and configs of columns(Equals of parent Apollo Table)
 *
 */
class TableHeadStyled extends React.Component {
  render() {
    const {
      onSelectAllClick,
      onRequestSort,
      order,
      orderBy,
      labels,
      allChecked
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" width="5%">
            <Checkbox
              checked={allChecked}
              onChange={event => onSelectAllClick(event.target.checked)}
            />
          </TableCell>
          {labels.map(
            label => (
              <TableCell
                typography="subtitle1"
                key={label.key}
                width={label.width}
                align={label.align}
                padding={label.disablePadding ? "none" : "default"}
                sortDirection={orderBy === label.key ? order : false}
              >
                <Tooltip title={I18n.t("Sort")} placement="bottom-start">
                  <TableSortLabel
                    active={orderBy === label.key}
                    direction={order}
                    onClick={() => onRequestSort(label.key)}
                  >
                    {label.value}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

TableHeadStyled.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  allChecked: PropTypes.bool.isRequired,
  labels: PropTypes.array.isRequired
};

export default TableHeadStyled;

import pdfjsLib from "pdfjs-dist-render/build/pdf";
import {
  PDFFindController,
  PDFViewer,
  PDFLinkService
} from "pdfjs-dist-render/web/pdf_viewer";

// Styles
import "pdfjs-dist-render/web/pdf_viewer.css";
import PropTypes from "prop-types";
import React from "react";
import Loader from "./../../_quarks/loader/Loader";
import "./PdfReader.css";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjsLib.version
}/pdf.worker.js`;

class PdfReader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pdfFindController: null,
      changeMatch: null,
      currentIndex: null,
      totalResults: null
    };

    this.pdfIsReadyToRender = false;
    this.timeoutToScrollForTermSelected = 0;
    this.indexSearch = {
      currentIndex: 0,
      totalResults: 0
    };
    this.pdfIsRendered = false;
  }

  static propTypes = {
    /** Comment soon */
    file: PropTypes.string,
    /** Comment soon */
    heightForShow: PropTypes.number,
    /** Comment soon */
    searchTerm: PropTypes.string,
    /** Comment soon */
    changeMatch: PropTypes.number
  };

  componentDidMount = () => {
    let container = this.pdfContainer;

    this.pdfLinkService = new PDFLinkService();
    this.pdfFindController = new PDFFindController({
      linkService: this.pdfLinkService
    });

    this.pdfViewer = new PDFViewer({
      container: container,
      linkService: this.pdfLinkService,
      findController: this.pdfFindController
    });
    this.pdfLinkService.setViewer(this.pdfViewer);

    pdfjsLib.getDocument({ url: this.props.file }).then(pdfDocument => {
      this.pdfIsReadyToRender = true;
      this.setState({ isLoading: false });
      this.pdfViewer.setDocument(pdfDocument);
      this.pdfLinkService.setDocument(pdfDocument, null);
      this.pdfFindController._updateUIResultsCount = this.updateUIResultsCount.bind(
        this
      );

      let pagerendered = e => {
        const { dataset } = e.target;

        if (
          dataset &&
          dataset.loaded === "true" &&
          parseInt(dataset.pageNumber, 10) === pdfDocument._pdfInfo.numPages
        ) {
          this.pdfIsRendered = true;

          if (this.props.searchTerm) {
            this.doSearch(this.props.searchTerm);
          }

          if (this.props.onPdfLoad && this.pdfContainer && this.pdfRoot) {
            this.props.onPdfLoad({
              previewScroll:
                this.pdfContainer.clientHeight > this.props.heightForShow ||
                false,
              previewScrollbarSize:
                this.pdfRoot.offsetWidth - this.pdfRoot.clientWidth
            });
          }

          document.removeEventListener("pagerendered", pagerendered, false);
        }
      };

      document.addEventListener("pagerendered", pagerendered, false);
    });
  };

  componentDidUpdate = prevProps => {
    if (this.pdfIsReadyToRender) {
      if (
        prevProps.searchTerm !== this.props.searchTerm &&
        this.pdfIsRendered
      ) {
        this.doSearch(this.props.searchTerm);
      }

      if (prevProps.changeMatch !== this.props.changeMatch)
        this.doChangeMatch();
    }
  };

  updateUIResultsCount = () => {
    this.indexSearch = {
      currentIndex: 1,
      totalResults: this.pdfFindController._matchesCountTotal
    };

    this.props.callbackSearch({
      indexSearch: {
        currentIndex: this.indexSearch.currentIndex,
        totalResults: this.indexSearch.totalResults
      },
      pdfIsRendered: this.pdfIsRendered
    });
  };

  doSearch = searchTerm => {
    this.props.callbackSearch({
      indexSearch: {
        currentIndex: 0,
        totalResults: 0
      },
      pdfIsRendered: this.pdfIsRendered
    });

    this.pdfFindController.executeCommand("find", {
      caseSensitive: false,
      phraseSearch: true, // exact search
      query: searchTerm,
      highlightAll: true,
      findPrevious: false
    });
  };

  doChangeMatch = () => {
    this.pdfFindController._nextMatch();

    this.indexSearch.currentIndex++;

    if (this.indexSearch.currentIndex > this.indexSearch.totalResults) {
      this.indexSearch.currentIndex = 1;
    }

    this.props.callbackSearch({
      indexSearch: {
        currentIndex: this.indexSearch.currentIndex,
        totalResults: this.indexSearch.totalResults
      },
      pdfIsRendered: this.pdfIsRendered
    });
  };

  render() {
    return (
      <div
        className="a-pdfReader"
        style={{ maxHeight: this.props.heightForShow }}
        ref={pdfRoot => (this.pdfRoot = pdfRoot)}
      >
        {this.state.isLoading && (
          <div>
            <Loader theme="negative" centralized />
          </div>
        )}

        <div
          ref={pdfContainer => (this.pdfContainer = pdfContainer)}
          className="a-pdfReader__container"
        >
          <div
            ref={pdfDOM => (this.pdfDOM = pdfDOM)}
            className="pdfViewer a-pdfReader__viewer"
          />
        </div>
      </div>
    );
  }
}

export default PdfReader;

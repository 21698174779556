import React from "react";
import { I18n } from "react-i18nify";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Loader from "./../../_quarks/loader/Loader";
import FilterCheckbox from "./FilterCheckbox";

// Models
import { getAllGroups } from "./../../../models/GroupModel";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  boxItems: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 182,
    overflowY: "auto",
    marginTop: theme.spacing.unit
  },
  root: {
    padding: `${theme.spacing.unit}px 0px`
  }
});

class FilterChildrenDefault extends React.Component {
  state = {
    isLoading: true,
    items: []
  };

  componentDidMount = () => {
    if (this.props.name === "asset_type") {
      this.setAssetType();
    } else if (this.props.name === "groups") {
      this.getGroups();
    } else if (this.props.name === "personalized_field") {
      this.setItems();
    }
  };

  setAssetType = () => {
    this.setState({
      isLoading: false,
      items: [
        {
          id: "video",
          label: I18n.t("AssetTypeVideo")
        },
        {
          id: "audio",
          label: I18n.t("AssetTypeAudio")
        },
        {
          id: "image",
          label: I18n.t("AssetTypeImage")
        },
        {
          id: "pdf",
          label: I18n.t("AssetTypePDF")
        }
      ]
    });
  };

  getGroups = async () => {
    try {
      let response = await getAllGroups();

      this.setState({
        isLoading: false,
        items: response.data.data.map(group => ({
          id: group.id,
          label: group.name
        }))
      });
    } catch (error) {
      this.setState({
        isLoading: false
      });
    }
  };

  setItems = () => {
    this.setState({
      isLoading: false,
      items: this.props.items.map(item => ({
        id: item,
        label: item,
        moreInfos: this.props.moreInfos
      }))
    });
  };

  renderItens = () => {
    let { items } = this.state;
    let filterItems = [];

    items.map(item =>
      filterItems.push(
        <FilterCheckbox
          key={item.id}
          name={this.props.name}
          data={item}
          label={this.props.label}
        />
      )
    );

    return filterItems;
  };

  render() {
    let { isLoading, items } = this.state;
    let { classes } = this.props;
    if (isLoading) return <Loader theme="negative" centralized />;

    return (
      <div className={classes.root}>
        {this.props.label && (
          <Typography variant="subtitle1">{this.props.label}</Typography>
        )}

        {items && <div className={classes.boxItems}>{this.renderItens()}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  filters: state.search.filters
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FilterChildrenDefault)
);

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Icon from "./../../_quarks/icon/Icon";

import "./Button.css";

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.componentClass =
      "a-button " +
      this.props.size +
      " " +
      this.props.theme +
      " " +
      this.props.width +
      " " +
      (this.props.noPadding ? "noPadding " : " ");
  }

  static propTypes = {
    /** Comment soon */
    label: PropTypes.string,
    /** Comment soon */
    size: PropTypes.oneOf(["small", "medium", "large"]),
    /** Comment soon */
    icon: PropTypes.string,
    /** Comment soon */
    iconAlign: PropTypes.oneOf(["left", "right"]),
    /** Comment soon */
    kind: PropTypes.oneOf(["solid", "outline", "ghost"]),
    /** Comment soon */
    theme: PropTypes.oneOf([
      "primary",
      "light",
      "disabled",
      "negative",
      "danger",
      "google",
      "facebook",
      "twitter",
      "linkedin"
    ]),
    /** Comment soon */
    width: PropTypes.oneOf(["default", "fluid", "min"]),
    /** Comment soon */
    isLoading: PropTypes.bool,
    /** Comment soon */
    noPadding: PropTypes.bool,
    /** Comment soon */
    id: PropTypes.string
  };

  static defaultProps = {
    size: "medium",
    iconAlign: "left",
    kind: "solid",
    theme: "primary",
    type: "button",
    width: "default"
  };

  render() {
    if (this.props.type === "a") {
      return (
        <a
          className={`${this.componentClass} ${this.props.kind} ${
            this.props.className
          } a-button-center`}
          href={this.props.onClick}
          download={this.props.download}
          target={this.props.target}
        >
          <Icon className="a-button__icon" name={this.props.icon} />
        </a>
      );
    }

    if (this.props.type === "link") {
      return (
        <Link
          className={`${this.componentClass} ${this.props.kind} ${
            this.props.className
          } a-button-center`}
          to={this.props.onClick}
        >
          {this.props.icon && this.props.iconAlign === "left" && (
            <Icon
              className={
                "a-button__icon " +
                (this.props.size === "small" ? "mr-xs-1" : "mr-xs-2")
              }
              name={this.props.icon}
              size={this.props.size}
            />
          )}

          {this.props.label && <span>{this.props.label}</span>}
        </Link>
      );
    }

    if (this.props.type === "file") {
      return (
        <label
          htmlFor={this.props.id}
          className={`${this.componentClass} ${this.props.kind} ${
            this.props.className
          } a-button-center`}
        >
          <input
            className="a-button__inputFile"
            id={this.props.id}
            onChange={this.props.onChange}
            type="file"
            multiple
          />

          {this.props.icon && this.props.iconAlign === "left" && (
            <Icon
              className={
                "a-button__icon " +
                (this.props.size === "small" ? "mr-xs-1" : "mr-xs-2")
              }
              name={this.props.icon}
              size={this.props.size}
            />
          )}

          {this.props.label && <span>{this.props.label}</span>}
        </label>
      );
    }

    if (this.props.label) {
      return (
        <button
          id={this.props.id}
          type={this.props.type}
          className={`${this.componentClass} ${this.props.kind} ${
            this.props.className
          }`}
          onClick={this.props.onClick}
          disabled={this.props.disabled || this.props.isLoading ? true : false}
        >
          {this.props.icon &&
            this.props.iconAlign === "left" &&
            !this.props.isLoading && (
              <Icon
                className={
                  "a-button__icon " +
                  (this.props.size === "small" ? "mr-xs-1" : "mr-xs-2")
                }
                name={this.props.icon}
                size={this.props.size}
              />
            )}

          {this.props.isLoading && this.props.iconAlign === "left" && (
            <Icon
              className={
                "a-button__icon loop " +
                (this.props.size === "small" ? "mr-xs-1" : "mr-xs-2")
              }
              name="loop"
              size={this.props.size}
            />
          )}

          {this.props.label}

          {this.props.icon &&
            this.props.iconAlign === "right" &&
            !this.props.isLoading && (
              <Icon
                className="a-button__icon ml-xs-2"
                name={this.props.icon}
                size={this.props.size}
              />
            )}

          {this.props.isLoading && this.props.iconAlign === "right" && (
            <Icon
              className={
                "a-button__icon loop " +
                (this.props.size === "small" ? "ml-xs-1" : "ml-xs-2")
              }
              name="loop"
              size={this.props.size}
            />
          )}
        </button>
      );
    }

    return (
      <button
        type={this.props.type}
        className={`${this.componentClass} ${this.props.kind} ${
          this.props.className
        }`}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        <Icon className="a-button__icon" name={this.props.icon} />
      </button>
    );
  }
}

export default Button;

import { TablePagination } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import React from "react";
import Checkbox from "../Checkbox/Checkbox";
import Loading from "../Loading/Loading";
import { PaginationOption, Row, TableWrapper } from "./Table.style";
import TableHead from "./TableHead";
import TableToolBar from "./TableToolBar";
import { I18n } from "react-i18nify";

/**
 * Apollo Table with check, delete, edit and row pagination rows
 *
 * @version 1.0.0
 * @author Matthaus Sousa
 *
 * @param {String}      title                   Title of table
 * @param {String}      labelAdd                Label for button add new row
 * @param {String}      orderBy                 Default key to sort table
 * @param {Function}    getData                 Gets data of table
 * @param {Array}       labels                  List of Labels and configs of columns
 * @param {String}      labels.key              Key of column label. Same key reference in table data
 * @param {String}      labels.value            Value to display in table head
 * @param {Boolean}     labels.disablePadding   Remove padding from table cell
 * @param {String}      labels.align            Align of table column content(Right,Left,Center)
 * @param {String}      labels.width            Width of column
 * @param {String}      labels.render           Function to render value of column
 * @param {Funcion}     handleClickRow          Handle click on table row. Params(row: {Any} - Row object)
 * @param {Funcion}     handleAddRow            Handle add new row on table.
 * @param {Funcion}     handleDeleteRow         Handle delete row of table. Params(ids: {Array} - Rows id)
 *
 */
class TableStyled extends React.Component {
  initialState = {
    loading: true,
    data: [],
    order: "desc",
    orderBy: this.props.orderBy || "",
    selected: [],
    page: 0,
    rowsPerPage: 10
  };

  state = {
    ...this.initialState
  };

  componentDidMount() {
    this.setData();
  }

  /**
   * !!! Async method !!!
   *
   * Get data from props and set in state.
   */
  async setData() {
    if (this.props.getData) {
      try {
        this.setState({ loading: true, ...this.initialState });
        this.setState({ data: await this.props.getData(), loading: false });
      } catch (error) {
        this.setState({ data: [], loading: false });
      }
    }
  }

  /**
   * @deprecated will be removed in future and changed by new request api
   */
  getSorting() {
    const { data, order, orderBy } = this.state;
    const { labels } = this.props;
    return data.sort((a, b) => {
      let label = null;
      if (Array.isArray(labels)) {
        label = labels.find(v => v.key === orderBy);
      }

      let v1 = a[orderBy];
      let v2 = b[orderBy];

      if (label && label.render instanceof Function) {
        v1 = label.render(a);
        v2 = label.render(b);
      }
      return order === "desc"
        ? String(v1).localeCompare(String(v2), "en", { sensitivity: "base" })
        : String(v2).localeCompare(String(v1), "en", { sensitivity: "base" });
    });
  }

  /**
   * Set key and order to sort
   *
   * @param {String} keyColumn key of column to sort
   */
  handleRequestSort = keyColumn => {
    const orderBy = keyColumn;
    let order = "desc";

    if (this.state.orderBy === keyColumn && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  /**
   * Set/Unset all row as selected
   *
   * @param {Boolean} checked set/unset all
   */
  handleSelectAllClick = checked => {
    const { rowsPerPage, page } = this.state;

    if (checked) {
      this.setState(state => ({
        selected: this.getSorting()
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(n => n.id)
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  /**
   * Set/Unset row as selected
   *
   * @param {Any} id id of row
   */
  handleClick = id => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  /**
   * Change page
   *
   * @param {Number} page number of page
   */
  handleChangePage = page => {
    this.setState({ page });
  };

  /**
   * Change number of rows per page
   *
   * @param {Number} numberRows number of rows per page
   */
  handleChangeRowsPerPage = numberRows => {
    this.setState({ rowsPerPage: numberRows });
  };

  /**
   * Check if row is selected
   *
   * @param {Any} id id of row
   *
   */
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  update = () => {
    this.setData();
  };

  render() {
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      loading
    } = this.state;

    const {
      labels,
      handleClickRow,
      handleAddRow,
      handleDeleteRow,
      title,
      labelAdd
    } = this.props;

    return (
      <Paper>
        <TableToolBar
          title={title}
          numSelected={selected.length}
          labelAdd={labelAdd}
          handleAddRow={handleAddRow}
          handleDeleteRow={() => handleDeleteRow(selected)}
        />
        <TableWrapper>
          <Table>
            <TableHead
              allChecked={
                (selected.length === data.length ||
                  selected.length === rowsPerPage) &&
                Array.isArray(data) &&
                data.length > 0
              }
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              labels={labels}
            />
            <TableBody>
              {!loading &&
                this.getSorting()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <Row
                        hover
                        onClick={event => {
                          if (event.target.type !== "checkbox") {
                            handleClickRow(n);
                          }
                        }}
                        role="checkbox"
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onClick={event => this.handleClick(n.id)}
                          />
                        </TableCell>
                        {labels.map(label => (
                          <TableCell
                            typography="subtitle1"
                            key={label.key}
                            align={label.align}
                            padding={label.disablePadding ? "none" : "default"}
                          >
                            {label.render
                              ? label.render(n)
                              : String(n[label.key])}
                          </TableCell>
                        ))}
                      </Row>
                    );
                  })}

              {loading && (
                <TableRow style={{ height: 49 }}>
                  <TableCell colSpan={6} align="center">
                    <Loading size={32} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        <TablePagination
          typography="subtitle1"
          rowsPerPageOptions={[10, 20, 30]}
          labelRowsPerPage={
            <span typography="subtitle1">{I18n.t("RowsPerPage")}:</span>
          }
          labelDisplayedRows={({ from, to, count }) => (
            <span typography="subtitle1">{`${from}-${to} ${I18n.t(
              "Of"
            )} ${count}`}</span>
          )}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(event, page) => this.handleChangePage(page)}
          onChangeRowsPerPage={event =>
            this.handleChangeRowsPerPage(event.target.value)
          }
          SelectProps={{
            MenuProps: {
              MenuListProps: {
                component: PaginationOption
              }
            }
          }}
        />
      </Paper>
    );
  }
}

TableStyled.propTypes = {
  title: PropTypes.string,
  labelAdd: PropTypes.string,
  orderBy: PropTypes.string,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disablePadding: PropTypes.bool,
      align: PropTypes.oneOf(["right", "left", "center"]),
      width: PropTypes.string,
      render: PropTypes.func,
      handleClickRow: PropTypes.func
    })
  ).isRequired,
  getData: PropTypes.func.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
  handleAddRow: PropTypes.func.isRequired
};

export default TableStyled;

export function addBreadCrumb(params) {
  return {
    type: "ADD_BREAD_CRUMB",
    params
  };
}

export function addNewBreadCrumb(params) {
  return {
    type: "ADD_BREAD_CRUMB_NEW",
    params
  };
}

export function clearBreadCrumb(params) {
  return {
    type: "CLEAR_BREAD_CRUMB"
  };
}

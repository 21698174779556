import React from "react";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HomeLink, PaperRoot, LabelLink, Label } from "./NewBreadCrumb.style";
import { Link } from "@material-ui/core";

const NewBreadCrumb = props => {
  return (
    <PaperRoot className={props.className} square={props.square}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        arial-label="Breadcrumb"
      >
        <Link onClick={() => props.history.push("/")}>
          <HomeLink name="home" />
        </Link>
        {props.breadCrumb.map((item, index) => {
          if (item.link) {
            return (
              <LabelLink
                key={index}
                onClick={() => props.history.push(item.link)}
              >
                {item.label}
              </LabelLink>
            );
          } else {
            return <Label key={index}>{item.label}</Label>;
          }
        })}
      </Breadcrumbs>
    </PaperRoot>
  );
};

const mapStateToProps = state => ({
  breadCrumb: state.breadCrumb.new
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewBreadCrumb)
);

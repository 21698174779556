import React from "react";

import assetifyLogo from "./../../../imgs/assetify.svg";

import "./PoweredBy.css";

export default class PoweredBy extends React.Component {
  render() {
    return (
      <span className={"m-poweredBy " + this.props.className}>
        Powered by
        <a href="http://assetify.com.br/" target="blank" title="Assetify">
          <img
            className="ml-xs-2 m-poweredBy__logo"
            src={assetifyLogo}
            alt="Assetify"
          />
        </a>
      </span>
    );
  }
}

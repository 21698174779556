import React from "react";
import Hammer from "hammerjs";

import Button from "./../../atoms/button/Button";

import "./ImageViewer.css";

//Classes
const imageContainer = ".m-image-viewer";
const displayImage = ".m-image-viewer__image";

export default class ImageViewer extends React.Component {
  constructor(props) {
    super(props);

    this.displayImageX = 0;
    this.displayImageY = 0;
    this.displayImageScale = 1;

    this.rangeMaxX = 0;
    this.rangeMinX = 0;
    this.rangeMaxY = 0;
    this.rangeMinY = 0;

    this.displayImageCurrentX = 0;
    this.displayImageCurrentY = 0;

    this.zoomSize = 0.15;
  }

  state = {
    displayImageScale: 1,
    zoom: false
  };

  componentDidMount() {
    this.imageContainer = document.querySelector(imageContainer);
    this.displayImage = document.querySelector(displayImage);

    window.addEventListener("resize", this.resizeContainer, true);

    this.imageContainer.addEventListener("mouseover", this.disableScroll);
    this.imageContainer.addEventListener("mouseout", this.enableScroll);

    this.displayImage.addEventListener("mousedown", this.prevent);
    this.displayImage.addEventListener("click", this.onClickImage);
    this.displayImage.addEventListener("wheel", this.onMouseWheel);
    this.displayImage.addEventListener("DOMMouseScroll", this.onMouseWheel);

    this.hammertime = new Hammer(this.displayImage);
    this.hammertime.get("pan").set({ direction: Hammer.DIRECTION_ALL });
    this.hammertime.on("pan", this.onPan);
    this.hammertime.on("panend pancancel", this.onPanCancel);
  }

  componentDidUpdate(prevProps) {
    this.resizeContainer();
    if (this.state.zoom && prevProps.disabled && !this.props.disabled) {
      this.setState({ zoom: false });
      this.onMouseWheel({ wheelDelta: 1 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeContainer, true);
    this.displayImage.removeEventListener("mousedown", this.prevent);
    this.displayImage.removeEventListener("wheel", this.onMouseWheel);
    this.displayImage.removeEventListener("DOMMouseScroll", this.onMouseWheel);
    this.displayImage.removeEventListener("click", this.onClickImage);
    this.imageContainer.removeEventListener("mouseover", this.disableScroll);
    this.imageContainer.removeEventListener("mouseout", this.enableScroll);
  }

  enableScroll = () => (window.onwheel = () => true);
  disableScroll = () => (window.onwheel = () => false);
  prevent = e => e.preventDefault();

  onClickImage = () => {
    if (this.state.displayImageScale === 1) {
      this.onMouseWheel({ wheelDelta: 120 });
    }
  };

  onPanCancel = () => {
    this.displayImageX = this.displayImageCurrentX;
    this.displayImageY = this.displayImageCurrentY;
  };

  onPan = ev => {
    this.displayImageCurrentX = this.clampX(this.displayImageX + ev.deltaX);
    this.displayImageCurrentY = this.clampY(this.displayImageY + ev.deltaY);
    this.updateDisplayImage();
  };

  onMouseWheel = e => {
    if (!this.props.disabled) {
      let zoom = 0;

      if (e.wheelDelta && e.wheelDelta < 0) {
        zoom = this.displayImageScale - this.zoomSize;
      } else if (e.wheelDelta && e.wheelDelta > 0) {
        zoom = this.displayImageScale + this.zoomSize;
      } else if (!e.wheelDelta && e.deltaY < 0) {
        zoom = this.displayImageScale + this.zoomSize;
      } else if (!e.wheelDelta && e.deltaY > 0) {
        zoom = this.displayImageScale - this.zoomSize;
      }

      this.displayImageScale = this.clampScale(zoom);
      this.setState({ displayImageScale: this.displayImageScale });
      this.updateRange();
      this.updateDisplayImage();
    }
  };

  resizeContainer = () => {
    this.updateRange();

    this.displayImageCurrentX = this.clampX(this.displayImageX);
    this.displayImageCurrentY = this.clampY(this.displayImageY);
    this.updateDisplayImage();
  };

  clamp = (value, min, max) => {
    return Math.min(Math.max(min, value), max);
  };

  clampX = value => {
    return this.clamp(value, this.rangeMinX, this.rangeMaxX);
  };

  clampY = value => {
    return this.clamp(value, this.rangeMinY, this.rangeMaxY);
  };

  clampScale = newScale => {
    return this.clamp(newScale, 1, 4);
  };

  updateDisplayImage = image => {
    let x = this.displayImageCurrentX;
    let y = this.displayImageCurrentY;
    let scale = this.displayImageScale;

    const transform = `translateX(${x}px) translateY(${y}px) translateZ(0px) scale(${scale},${scale})`;

    if (this.displayImage) {
      this.displayImage.style.transform = transform;
      this.displayImage.style.WebkitTransform = transform;
      this.displayImage.style.msTransform = transform;
    }
  };

  updateRange = () => {
    if (this.displayImage) {
      let imageWidth = this.displayImage.offsetWidth;
      imageWidth = Math.round(imageWidth * this.displayImageScale);

      let imageHeight = this.displayImage.offsetHeight;
      imageHeight = Math.round(imageHeight * this.displayImageScale);

      let containerWidth = this.imageContainer.offsetWidth;
      let containerHeight = this.imageContainer.offsetHeight;

      let rangeX = Math.max(0, imageWidth - containerWidth);
      let rangeY = Math.max(0, imageHeight - containerHeight);

      this.rangeMaxX = Math.round(rangeX / 2);
      this.rangeMinX = 0 - this.rangeMaxX;

      this.rangeMaxY = Math.round(rangeY / 2);
      this.rangeMinY = 0 - this.rangeMaxY;
    }
  };

  render() {
    let cursor =
      this.state.displayImageScale > 1 ? "m-image-viewer__image-move" : "";

    return (
      <div className="m-image-viewer">
        <div className={"m-image-viewer__image " + cursor}>
          {this.props.children}
        </div>

        {this.state.displayImageScale > 1 && (
          <div className="m-image-viewer__controls">
            <Button
              onClick={() => this.onMouseWheel({ wheelDelta: -1 })}
              label="-"
              size="small"
              width="min"
              theme="light"
            />
            <input
              className="m-image-viewer__controls-range"
              type="range"
              min="1"
              max="4"
              step="0.15"
              value={this.state.displayImageScale}
              onChange={e => {
                if (e.target.value < this.displayImageScale) {
                  this.onMouseWheel({ wheelDelta: -1 });
                } else {
                  this.onMouseWheel({ wheelDelta: 1 });
                }
              }}
            />
            <Button
              onClick={() => this.onMouseWheel({ wheelDelta: 1 })}
              label="+"
              size="small"
              width="min"
              theme="light"
            />
          </div>
        )}
        {this.state.displayImageScale === 1 && (
          <Button
            className="m-image-viewer__controls-zoom-in"
            type="button"
            onClick={() => {
              if (this.props.onClickEnableZoom) {
                this.props.onClickEnableZoom();
                this.setState({ zoom: true });
              } else {
                this.onMouseWheel({ wheelDelta: 1 });
              }
            }}
            icon="loupe"
            size="small"
            width="min"
            theme="light"
          />
        )}
      </div>
    );
  }
}

import React, { Component } from "react";
import { I18n } from "react-i18nify";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import {
  addNewBreadCrumb,
  clearBreadCrumb
} from "./../../molecules/bread-crumb/_actionsReducers";
import { getAllUsers, deleteUser } from "./../../../models/UserModel";

import PermissionHandler from "./../../../helpers/PermissionHelper";
import Table from "../../Table/Table";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: [
        {
          key: "first_name",
          value: I18n.t("UserFirstName").replace(":", ""),
          disablePadding: false,
          align: "left",
          width: "20%",
          render: value => value.first_name + " " + value.last_name
        },
        {
          key: "email",
          value: "E-mail",
          disablePadding: false,
          align: "left",
          width: "40%"
        },
        {
          key: "groups",
          value: I18n.t("Groups"),
          disablePadding: false,
          align: "left",
          width: "40%",
          render: value => value.groups.map(v => v.name).join(",")
        }
      ],
      user_to_delete: null,
      permissions: { manage_user: PermissionHandler.is_admin() }
    };
  }

  componentDidMount = () => {
    this.props.addNewBreadCrumb([
      {
        label: I18n.t("ManageUsers"),
        link: "/users"
      }
    ]);
  };

  componentWillUnmount = () => {
    this.props.clearBreadCrumb();
  };

  getUsersData = async () => {
    try {
      const response = await getAllUsers();
      return response.data.data.map(user => ({ id: user.uuid, ...user }));
    } catch (e) {
      return [];
    }
  };
  onClickDelete = ids => {
    this.setState({ user_to_delete: ids });

    this.props.addAlert({
      id: "alertToConfirmDeleteUser",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title:
        ids.length > 1
          ? I18n.t("ConfirmSelectedUsersDeletion")
          : I18n.t("ConfirmSelectedUserDeletion"),
      description: I18n.t("WarningNotPossibleToUndoTheAction"),
      actionButtons: [
        {
          label:
            ids.length > 1
              ? I18n.t("DeleteSelectedUsers")
              : I18n.t("DeleteSelectedUser"),
          icon: "delete",
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onConfirmDelete.bind(this)
        },
        {
          label: I18n.t("Cancel"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDeleteUser")
        }
      ]
    });
  };

  onConfirmDelete = () => {
    return new Promise(resolve => {
      let uuids = this.state.user_to_delete;

      deleteUser(uuids)
        .then(response => {
          this.setState({
            user_to_delete: null
          });

          this.usersTable.update();

          this.props.addAlert({
            id: "alertToConfirmDeleteUser",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("UserDeletedSuccessfully")
          });
        })
        .catch(error => {
          this.setState({
            user_to_delete: null
          });

          this.props.addAlert({
            id: "alertToConfirmDeleteUser",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertUserNotRemoved"),
            description: I18n.t("ContentAlertUserNotRemoved")
          });
        });

      resolve();
    });
  };

  render() {
    const { permissions, labels } = this.state;

    if (!permissions.manage_user) return <div>Acesso não autorizado</div>;

    return (
      <div style={{ margin: 8 }}>
        <Table
          title={I18n.t("Users")}
          labelAdd={I18n.t("NewUser")}
          orderBy="first_name"
          ref={ref => (this.usersTable = ref)}
          getData={this.getUsersData}
          labels={labels}
          handleClickRow={row =>
            this.props.history.push({
              pathname: "/profile/" + row.id,
              state: { edit: true }
            })
          }
          handleAddRow={() => this.props.history.push("users/new")}
          handleDeleteRow={this.onClickDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...alertActions,
      addNewBreadCrumb,
      clearBreadCrumb
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(UserList);

import React, { Component } from "react";
import { withRouter } from "react-router";

// Compontents
import Button from "../../atoms/button/Button";
import Avatar from "./../../atoms/avatar/Avatar";

import Dropdown from "./../../molecules/dropdown/Dropdown";
import DropdownContainer from "./../../molecules/dropdown/DropdownContainer";

// Utils
import { logoutUser } from "./../../../Utils";

// Authentication
import Auth from "./../../../models/AuthModel";
import PermissionHandler from "./../../../helpers/PermissionHelper";

import { I18n } from "react-i18nify";

class MyAccount extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      permissions: {
        manage_user: PermissionHandler.is_admin(),
        manage_group: PermissionHandler.is_admin(),
        manage_category: PermissionHandler.can_admin_category(),
        access_analytics: PermissionHandler.is_admin()
      }
    };
  }

  onClickAvatar = event => {
    let user = Auth.getUser();
    this.props.history.push("/profile/" + user.uuid);
  };

  onClickUserManage = () => {
    this.props.history.push("/users");
  };

  onClickGroupManage = () => {
    this.props.history.push("/groups");
  };

  onClickCategoryManage = () => {
    this.props.history.push("/categories");
  };

  onClickAnalytics = () => {
    this.props.history.push("/analytics");
  };

  onClickLogout = event => {
    logoutUser();
  };

  render() {
    return (
      <div className={this.props.className}>
        <DropdownContainer align="right">
          <Avatar />

          <Dropdown kind="list">
            <Button
              icon="user"
              label={I18n.t("Profile")}
              theme="light"
              kind="ghost"
              onClick={this.onClickAvatar.bind(this)}
            />

            {this.state.permissions.manage_user && (
              <Button
                icon="group"
                label={I18n.t("ManageUsers")}
                theme="light"
                kind="ghost"
                onClick={this.onClickUserManage.bind(this)}
              />
            )}

            {this.state.permissions.manage_group && (
              <Button
                icon="settings"
                label={I18n.t("ManageGroups")}
                theme="light"
                kind="ghost"
                onClick={this.onClickGroupManage.bind(this)}
              />
            )}

            {this.state.permissions.manage_category && (
              <Button
                icon="custom-fields"
                label={I18n.t("CustomFields")}
                theme="light"
                kind="ghost"
                onClick={this.onClickCategoryManage.bind(this)}
              />
            )}

            {this.state.permissions.access_analytics && (
              <Button
                icon="chart"
                label={I18n.t("Analytics")}
                theme="light"
                kind="ghost"
                onClick={this.onClickAnalytics.bind(this)}
              />
            )}

            <Button
              icon="logout"
              label={I18n.t("Logout")}
              theme="light"
              kind="ghost"
              onClick={this.onClickLogout.bind(this)}
            />
          </Dropdown>
        </DropdownContainer>
      </div>
    );
  }
}

export default withRouter(MyAccount);

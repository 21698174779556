const english_errors = {
  TitleAlertServerError: "Error founds",
  ContentAlertServerError: "Please, try again or come back later.",
  ContentAlertGoogleError: "Could not log in with Google.",
  ContentAlertGoogleErrorAuris: "Could not login.",
  ContentAlertGoogleErrorDenied: "Denied access.",
  ContentDontSupportVideoMedias:
    "Your browser does not support the video element.",
  ContentDontSupportAudioMedias:
    "Your browser does not support the audio element.",
  TitleAlertErrorCollectionDeleted: "The Collection could not be deleted",
  ContentAlertErrorCollectionDeleted:
    "An unexpected error occurred while attempting to delete the Collection. <strong>Try again.</strong>",
  TitleAlertAssetWasNotDeleted: "The Asset could not be deleted",
  ContentAlertAssetWasNotDeleted:
    "An unexpected error occurred while attempting to delete the Asset. <strong>Try again.</strong>",
  TitleAlertPasswordHasNotBeenChanged: "Password has not been changed",
  TitleAlertPasswordHasNotBeenChangedBecauseTheNew:
    "The new password does not match. Try again.",
  TitleAlertPasswordHasNotBeenChangedBecauseTheOld:
    "The current password appears to be incorrect. Try again.",
  TitleAlertUnhangedProfile: "Unchanged profile",
  ContentAlertUnhangedProfile:
    "There was an error while trying to change the profile. Try again.",
  TitleAlertUserNotCreated: "User not created",
  ContentAlertCheckEmailInUse: "Check if the email is already included.",
  TitleAlertUserNotRemoved: "User not removed",
  ContentAlertUserNotRemoved:
    "There was an error while trying to remove the user. Try again.",
  TitleAlertGroupNotRemoved: "Grupo não removido",
  ContentAlertGroupNotRemoved:
    "Ocorreu um erro ao tentar excluir o grupo. Tente novamente.",
  TitleAlertEmailNotFound: "Email not found",
  ContentAlertEmailNotFound:
    "We didn't find the email <strong>%{email}</strong> in our database. Make sure you typed correctly.",
  TitleAlertExpiredTime: "Expired time",
  ContentAlertExpiredTime:
    "Your order for change password is expired. Click in <strong>%{actionForgotPassword}</strong> for a new order.",
  SearchContentNotFound:
    "Nothing search content found by <strong>%{searchTerm}</strong>.",
  ContentErrorAnalytics:
    "An error occurred to capture the analytics data. Try again.",
  TitleAlertCategoryNotRemoved: "Category not removed",
  ContentAlertCategoryNotRemoved:
    "There was an error while trying to remove the category. Try again.",
  TitleAlertEditCustomFieldWithError: "Error to edit custom fields",
  ContentAlertUserWithoutGroup: "The user must have a group",
  ContentAlertUserWithoutName: "The user must have a name",
  TitleNoConnection: "Loss of connection",
  ContentNoConnection:
    "Check your internet connection and try again later. If the error persists, please contact support.",
  TitleIncorrectEmailPassword: "Check your username and password",
  ContentIncorrectEmailPassword:
    "It looks like your username and / or password is incorrect, please check the data and try again.",
  CategoryDuplicatedName: "This category name already exists"
};

export { english_errors };

import axios from "axios";
import { humanize } from "humanize";
import React, { Component } from "react";
import domtoimage from "dom-to-image";
import FileSaver from "file-saver";
import ReactDOM from "react-dom";

// Translate
import { I18n } from "react-i18nify";
import { connect } from "react-redux";
// Redux
import { bindActionCreators } from "redux";
import ImageViewer from "../../molecules/image-viewer/ImageViewer";
// Authentication
import PermissionHandler from "./../../../helpers/PermissionHelper";
// Models
import {
  deleteAsset,
  editAsset,
  getAsset,
  searchAssetSquares,
  searchAssetTimeShots,
  getAssetCategories,
  updateAssetCategories
} from "./../../../models/AssetModel";
import { getAllCategories, getCategory } from "../../../models/CategoryModel";
import Button from "./../../atoms/button/Button";
import FieldEditable from "./../../atoms/field-editable/FieldEditable";
import Key from "./../../atoms/key/Key";
import Timestamp from "./../../atoms/timestamp/Timestamp";
import NewTimestamp from "./../../atoms/new-timestamp/NewTimestamp";
import SquareLabel from "./../../atoms/square-label/SquareLabel";
import LinkText from "./../../atoms/link-text/LinkText";
import PdfReader from "./../../atoms/pdf-reader/PdfReader";
import Player from "./../../atoms/player/Player";
import Radio from "./../../atoms/radio/Radio";
import Square from "./../../atoms/square/Square";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import { removeAssetDetails } from "./../../molecules/asset-item/_actionsReducers";
import FormGroup from "./../../molecules/form-group/FormGroup";
import Modal from "./../../molecules/modal/Modal";
import Col from "./../../_quarks/grid/Col";
import Form from "./../../organisms/form/Form";
// Components
import Row from "./../../_quarks/grid/Row";
import Icon from "./../../_quarks/icon/Icon";
import Loader from "./../../_quarks/loader/Loader";
// Styles
import "./AssetModal.css";

class AssetModal extends Component {
  constructor(props) {
    super(props);
    this.source = axios.CancelToken.source();
    this.state = {
      asset: null,
      isGettingAsset: true,
      changeMatchPdf: null,
      searchTermPdf: null,
      activeSquares: [],
      searchedSquares: [],
      searchedTimeStampVideo: [],
      noSquaresFound: false,
      noTimeStampVideoFound: false,
      isSearchingSquares: false,
      timeoutSearchSquares: 0,
      timeoutSearchTimeStampVideo: 0,
      timeoutResize: 0,
      timeoutSearchInPdf: 0,
      isEditing: false,
      isDeletingAsset: false,
      sizeRenderedAssetImage: {},
      isEditingCustomFields: false,
      isLoadingCategoryCustomFields: false,
      isLoadingImage: true,
      isSavingEditCustomFields: false,
      defaultValueToEdit: {
        tags: [],
        collections: [],
        groups: []
      },
      maxHeightAssetPreview: null,
      w: 1280,
      h: 720,
      download_options: [
        { type: ".mp4" }
        //,{ type: ".ogg" }
      ],
      indexActiveTimestamp: null,
      openModalDownloadOptions: false,
      optionsCategoriesCustomFields: [],
      hasDownloadOptions: true,
      indexSearchPdf: {
        currentIndex: 0,
        totalResults: 0
      },
      pdfIsRendered: false,
      permissions: { can_edit: PermissionHandler.can_edit() }
    };

    this.assetImage = null;

    this.refAssetImage = React.createRef();

    this.fromTimestampClick = false;

    this.sizeImageLabel = [
      { key: "original", value: I18n.t("LabelImageSizeOriginal") },
      { key: "large", value: I18n.t("LabelImageSizeFullHD") },
      { key: "medium", value: I18n.t("LabelImageSizeHD") },
      { key: "small", value: I18n.t("LabelImageSizeSD") }
    ];
  }

  componentDidMount = async () => {
    this._ismounted = true;

    await this._getAsset(this.props.uuid);

    if (this.props.square) {
      this.state.activeSquares.push(this.props.square);
      this.changeAction("squares");
    }

    this.setMaxHeightModal();

    window.addEventListener("resize", this.watchResize);
    window.addEventListener("keydown", this.handleKeyPress);
  };

  componentDidUpdate = async prevProps => {
    if (this.props.uuid !== prevProps.uuid) {
      await this._getAsset(this.props.uuid);
      this.setMaxHeightModal();
    }
  };

  componentWillUnmount = () => {
    this._ismounted = false;
    window.removeEventListener("resize", this.watchResize);
    this.source.cancel({ close: true });
    window.removeEventListener("keydown", this.handleKeyPress);
  };

  toNext = async () => {
    const {
      hasMoreAssets,
      getMoreAssets,
      setAssetByIndex,
      index,
      totalAssets
    } = this.props;

    if (!this.state.isGettingAsset && (hasMoreAssets || index < totalAssets)) {
      this.setState({ isGettingAsset: true });
      if (index === totalAssets) {
        await getMoreAssets();
      }
      setAssetByIndex(index + 1);
    }
  };

  toBack = async () => {
    const { setAssetByIndex, index } = this.props;

    if (!this.state.isGettingAsset && index > 0) {
      this.setState({ isGettingAsset: true });
      setAssetByIndex(index - 1);
    }
  };

  handleKeyPress = e => {
    if (!this.state.disableShortcuts) {
      switch (e.key) {
        case "Escape":
          if (this.state.activeAction) {
            this.setState({ activeAction: null });
          } else {
            this.onCloseModal();
          }
          break;
        case "ArrowRight":
          if (
            (this.player && this.player.state.isPlaying) ||
            this.state.isGettingAsset
          ) {
            return;
          } else {
            this.toNext();
          }
          break;
        case "ArrowLeft":
          if (
            (this.player && this.player.state.isPlaying) ||
            this.state.isGettingAsset
          ) {
            return;
          } else {
            this.toBack();
          }
          break;
        default:
          return;
      }
    }
  };

  getTimeFormated = time => {
    let timeFormated = time.split(":");
    return (
      +timeFormated[0] * 60 * 60 + +timeFormated[1] * 60 + +timeFormated[2]
    );
  };

  _getAsset = async uuid => {
    let response = await getAsset(
      uuid,
      this.props.searchTerm,
      this.state.w,
      this.state.h,
      this.source.token
    );
    try {
      this.setState({
        asset: response ? response.data : {},
        isGettingAsset: false
      });

      if (this.props.timestamp) {
        this.changeAction("timestamp");
        this.onTimeStampClick(this.props.timestamp, this.props.timestampText);
      }

      if (this.props.timeshots) {
        this.fromTimestampClick = true;

        this.changeAction("timeshots");
        this.onTimeStampClick(this.props.timeshots, this.props.timeshotsText);
        this.formGroupSearchTimeStampVideo.refs.input.value = this.props.timeshotsText;
      }

      if (this.props.searchInPdf) {
        this.changeAction("pdf");

        this.formGroupSearchInPdf.refs.input.value = this.props.searchInPdf.query;

        this.setState({
          searchTermPdf: this.props.searchInPdf.query
        });
      }
    } catch (error) {
      console.error("error", error);
      if (axios.isCancel(error) && error.message.close) {
        return;
      } else {
        this.setState({
          isGettingAsset: false,
          error: false
        });
      }
    }
  };

  onLoadImage = () => {
    this.setState({
      isLoadingImage: false,
      isLoadingOriginalImage: false,
      msgLoading: null,
      sizeRenderedAssetImage: {
        w: this.refAssetImage
          ? this.refAssetImage.offsetWidth
          : this.state.sizeRenderedAssetImage.w,
        h: this.refAssetImage
          ? this.refAssetImage.offsetHeight
          : this.state.sizeRenderedAssetImage.h
      }
    });
  };

  watchResize = () => {
    clearTimeout(this.timeoutResize);

    this.timeoutResize = setTimeout(() => {
      if (this._ismounted) {
        this.setMaxHeightModal();
        this.refreshSizeRenderedAssetImage();
      }
    }, 500);
  };

  setMaxHeightModal = () => {
    let maxHeightModal = window.innerHeight - 160; // 160px y paddings
    let maxHeightAssetPreview = maxHeightModal - 82; // 80px content below, 2px border

    this.setState({
      maxHeightAssetPreview: maxHeightAssetPreview
    });
  };

  refreshSizeRenderedAssetImage = () => {
    if (this.refAssetImage) {
      this.setState({
        sizeRenderedAssetImage: {
          w: this.refAssetImage.offsetWidth,
          h: this.refAssetImage.offsetHeight
        }
      });
    }
  };

  onCloseModal = () => {
    this.props.delAlert("alertToConfirmDeleteImage");
    const { assetUpdated } = this.state;

    if (this.props.hideAssetModal) {
      this.props.hideAssetModal({ assetUpdated });
      //this.props.removeAssetDetails()
    }
  };

  toggleEditAsset = refToEdit => {
    let defaultValueTags = [],
      defaultValueCollections = [],
      defaultValueGroups = [];

    if (!this.state.isEditing) {
      this.setState({
        defaultValueToEdit: {
          tags: [],
          collections: [],
          groups: []
        }
      });

      this.state.asset.tags.map(tag =>
        defaultValueTags.push({
          label: tag.slug,
          value: tag.slug
        })
      );

      this.state.asset.collections.map(collection =>
        defaultValueCollections.push({
          label: collection.name,
          value: collection.name,
          id: collection.uuid
        })
      );

      this.state.asset.group.map(group =>
        defaultValueGroups.push({
          label: group.name,
          value: group.name,
          id: group.id
        })
      );
    }

    // Focus on input
    if (refToEdit && !this.state.isEditing) {
      setTimeout(() => {
        // For put cursor on end value on focus
        if (this[refToEdit] && this[refToEdit].refs.input.type === "text") {
          let inputValue = this[refToEdit].refs.input.value;
          this[refToEdit].refs.input.value = null;
          this[refToEdit].refs.input.value = inputValue;
        }

        this[refToEdit].refs.input.focus();
      }, 0);
    }

    this.setState({
      isEditing: !this.state.isEditing,
      defaultValueToEdit: {
        tags: defaultValueTags,
        collections: defaultValueCollections,
        groups: defaultValueGroups
      }
    });
  };

  onSubmitEditAsset = () => {
    this.setState({
      isSavingEditAsset: true
    });

    let getIdCollections = () => {
      let arrayIdCollections = [];

      if (this.formGroupCollections) {
        this.formGroupCollections.refs.input.props.value.map(collection => {
          let identifierCollection;

          if (collection.id) {
            identifierCollection = collection.id;
          } else {
            identifierCollection = collection.label;
          }

          arrayIdCollections.push(identifierCollection);
          return null;
        });
      }

      return arrayIdCollections;
    };

    let getIdGroups = () => {
      let arrayIdGroups = [];

      if (this.formGroupGroups) {
        this.formGroupGroups.refs.input.props.value.map(group =>
          arrayIdGroups.push(group.id)
        );
      }

      return arrayIdGroups;
    };

    let getTagsToDelete = () => {
      let oldTags = this.state.defaultValueToEdit.tags;
      let newTags = this.formGroupTags
        ? this.formGroupTags.refs.input.props.value
        : [];

      let arrayTagsToDelete = oldTags.filter(tag => !newTags.includes(tag));

      return arrayTagsToDelete;
    };

    let dataToEdit = {
      assets_uuids: [this.state.asset.uuid],
      description: null, // temp
      real_name: this.formGroupRealName.refs.input.value,
      tags: this.formGroupTags ? this.formGroupTags.refs.input.props.value : [],
      tags_to_delete: getTagsToDelete(),
      collections: getIdCollections(),
      groups: getIdGroups(),
      smart: this.keySmartSearch
        ? this.keySmartSearch.refs.input.checked
        : true,
      public: this.keyPublicMode.refs.input.checked,
      download: this.keyPublicDownload.refs.input.checked
    };

    editAsset(dataToEdit)
      .then(response => {
        this._getAsset(this.props.uuid);

        this.setState({
          isEditing: false,
          isSavingEditAsset: false,
          assetUpdated: true
        });
      })
      .catch(error => {
        this.setState({
          isEditing: false,
          isSavingEditAsset: false
        });
      });
  };

  confirmDeleteAsset = () =>
    deleteAsset(this.props.uuid)
      .then(response => {
        if (response && response.data && response.data.details === "Success") {
          this.props.addAlert({
            id: "alertFeedbackDeleteImage",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("TitleAlertAssetDeleted")
          });

          this.setState({ assetUpdated: true }, () => this.onCloseModal());
        }
      })
      .catch(() => {
        this.props.addAlert({
          id: "alertFeedbackDeleteImage",
          theme: "danger",
          animation: "shake",
          timer: "fast",
          title: I18n.t("TitleAlertAssetWasNotDeleted"),
          description: I18n.t("ContentAlertAssetWasNotDeleted")
        });
      });

  onDeleteAsset = () => {
    this.props.addAlert({
      id: "alertToConfirmDeleteImage",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title: I18n.t("TitleAlertDoYouWantExcludeTheAsset"),
      description: I18n.t("CantUndoThisAction"),
      actionButtons: [
        {
          label: I18n.t("DeleteAsset"),
          icon: "delete",
          theme: "danger",
          onClick: this.confirmDeleteAsset
        },
        {
          label: I18n.t("DontDeleteAsset"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDeleteImage")
        }
      ]
    });
  };

  onTimeStampClick = (time, text) => {
    time = String(time);
    if (time.indexOf(":") >= 0) {
      time = this.getTimeFormated(time);
    }

    if (this.player) {
      this.player.media.currentTime = Number(time) || 0;
      this.player.refreshMediaTimeStates(Number(time) || 0);
      this.player.media.play();
    }

    this.setState({
      timeSelected: Math.trunc(Number(Number(time))) || 0,
      textSelected: text
    });
  };

  setTimestampOpened = () => {
    Object.keys(this.state.searchedTimeStampVideo).map(async (key, index) => {
      if (key === this.props.timeshotsText) {
        this.setState({ indexActiveTimestamp: index });
      }
    });
  };

  clearActiveSquares = () => {
    this.setState({
      activeSquares: []
    });
  };

  toggleSeeSquare = squareClickedInSearch => {
    let isActive = this.theSquareIsActive(squareClickedInSearch);
    let newActiveSquares = this.state.activeSquares;

    if (isActive) {
      newActiveSquares = newActiveSquares.filter(
        item => JSON.stringify(item) !== JSON.stringify(squareClickedInSearch)
      );
    } else {
      let indexToAdd = newActiveSquares.length;
      newActiveSquares[indexToAdd] = squareClickedInSearch;
    }

    this.setState({
      activeSquares: newActiveSquares
    });
  };

  theSquareIsActive = itemSquareSearched => {
    let firstSquareEqual = this.state.activeSquares.find(
      item => JSON.stringify(item) === JSON.stringify(itemSquareSearched)
    );

    if (this.state.activeSquares.length > 0 && firstSquareEqual) {
      return true;
    } else {
      return false;
    }
  };

  downloadFile(src, realName) {
    var a = document.createElement("a");
    a.href = src + "&fileName=" + realName;
    a.download = realName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(src);
    a.remove();
  }

  downloadImage = () => {
    const {
      sizeImageDownload,
      asset,
      imageDownloadMark,
      activeSquares
    } = this.state;

    let name = String(asset.real_name).replace(asset.asset_type[2], "");
    name = name + asset.asset_type[2];

    if (sizeImageDownload) {
      let src =
        sizeImageDownload === "original"
          ? asset.size_url.original
          : asset.size_url[sizeImageDownload];

      if (imageDownloadMark) {
        this.setState({ generatingImage: true });
        let imageDiv = document.createElement("div");
        let image = document.createElement("img");
        let squares = document.createElement("div");

        imageDiv.style.position = "relative";

        imageDiv.appendChild(image);
        imageDiv.appendChild(squares);

        image.onload = () => {
          imageDiv.style.width = image.naturalWidth + "px";
          imageDiv.style.height = image.naturalHeight + "px";

          let incrementSize = Math.max(
            image.naturalWidth / this.refAssetImage.naturalWidth,
            image.naturalHeight / this.refAssetImage.naturalHeight
          );

          ReactDOM.render(
            <Square
              draws={activeSquares}
              originalSize={asset.original_size}
              renderedSize={{
                w: image.offsetWidth,
                h: image.offsetHeight
              }}
              borderIncrement={incrementSize}
            />,
            squares,
            async () => {
              let dataUrl = await domtoimage.toJpeg(imageDiv);
              this.setState({ generatingImage: false });
              FileSaver.saveAs(dataUrl, name);
              imageDiv.remove();
            }
          );
        };

        image.src = src;
        document.body.appendChild(imageDiv);
      } else {
        this.downloadFile(src, name);
      }
    } else {
      this.props.addAlert({
        id: "alertDownloadImage",
        theme: "light",
        animation: "default",
        timer: "fast",
        description: I18n.t("AlertImageDownload"),
        title: I18n.t("AlertNotice")
      });
    }
  };

  formatSquaresFromApi = squaresFromApi => {
    let allSquares = [];

    squaresFromApi.texts.map(text =>
      allSquares.push({
        text: text.text,
        vertices: text.square.vertices
      })
    );

    squaresFromApi.logos.map(logo =>
      allSquares.push({
        text: logo.logo,
        vertices: logo.square.vertices
      })
    );

    squaresFromApi.landmarks.map(landmark =>
      allSquares.push({
        text: landmark.landmark,
        vertices: landmark.square.vertices
      })
    );

    return allSquares;
  };

  doSearchSquares = () => {
    clearTimeout(this.timeoutSearchSquares);

    this.timeoutSearchSquares = setTimeout(() => {
      if (this.formGroupSearchAssetSquares) {
        let searchTerm = this.formGroupSearchAssetSquares.refs.input.value;

        if (searchTerm.replace(/\s/g, "").length) {
          // searchTerm not is null
          this.setState({
            isSearchingSquares: true
          });

          searchAssetSquares(this.props.uuid, searchTerm)
            .then(response => {
              let searchedSquares = this.formatSquaresFromApi(response.data);

              this.setState({
                isSearchingSquares: false,
                searchedSquares: searchedSquares,
                noSquaresFound: searchedSquares.length === 0
              });
            })
            .catch(error => {
              this.setState({
                isSearchingSquares: false
              });
            });
        }
      }
    }, 500);
  };

  doSearchTimeStampVideo = () => {
    clearTimeout(this.timeoutSearchTimeStampVideo);

    this.timeoutSearchTimeStampVideo = setTimeout(() => {
      if (this.formGroupSearchTimeStampVideo) {
        let searchTerm = this.formGroupSearchTimeStampVideo.refs.input.value;

        this.setState({
          isSearchingTimeStampVideo: true
        });

        searchAssetTimeShots(this.props.uuid, searchTerm)
          .then(response => {
            this.setState(
              {
                isSearchingTimeStampVideo: false,
                searchedTimeStampVideo: response.data,
                noTimeStampVideoFound: response.data.length === 0
              },
              () => {
                if (this.fromTimestampClick) {
                  this.setTimestampOpened();

                  this.fromTimestampClick = false;
                }
              }
            );
          })
          .catch(error => {
            this.setState({
              isSearchingTimeStampVideo: false
            });
          });
      }
    }, 500);
  };

  doSearchInPdf = () => {
    clearTimeout(this.timeoutSearchInPdf);

    this.timeoutSearchInPdf = setTimeout(() => {
      if (this.formGroupSearchInPdf) {
        let searchTerm = this.formGroupSearchInPdf.refs.input.value;

        this.setState({
          searchTermPdf: searchTerm
        });
      }
    }, 500);
  };

  changeMatchSearchInPdf = () => {
    let timeOfChange = new Date().getTime();

    this.setState({
      changeMatchPdf: timeOfChange
    });
  };

  getDownloadContent = () => {
    window.open(this.state.asset.subtitle_url);
  };

  changeAction = action => {
    let { activeAction } = this.state;

    if (activeAction !== action && action === "timeshots") {
      this.doSearchTimeStampVideo();
    }

    if (action === "customFields") {
      if (activeAction !== action) {
        this.getAssetCategories();
      } else {
        this.setState({ categoriesSelecteds: [] });
      }
    }

    this.setState({ activeAction: activeAction === action ? null : action });
  };

  getActions = () => {
    const { activeAction } = this.state;
    const asset = this.state.asset || {};
    const {
      content,
      video_content,
      time_stamp,
      squares_length,
      timeshots_length,
      asset_type
    } = asset;

    const getButton = (name, icon) => (
      <li onClick={() => this.changeAction(name)} key={name} id={name}>
        {name !== activeAction && (
          <Icon name={icon} className="o-assetModal__iaActions-icon " />
        )}
        {name === activeAction && (
          <Icon name="clear" className="o-assetModal__iaActions-icon active" />
        )}
      </li>
    );

    let actions = [];

    if (
      (content && content.match(/^ *$/) === null) ||
      (video_content && video_content.match(/^ *$/) === null)
    ) {
      actions.push(getButton("content", "brain"));
    }
    if (Array.isArray(time_stamp) && time_stamp.length > 0) {
      actions.push(getButton("timestamp", "announcement"));
    }
    if (asset_type[1] === "image" && squares_length > 0) {
      actions.push(getButton("squares", "squad"));
    }
    if (asset_type[1] === "video" && timeshots_length > 0) {
      actions.push(getButton("timeshots", "videostamp"));
    }
    if (
      Array.isArray(asset_type) &&
      asset_type[1] === "application" &&
      asset_type[2] === ".pdf"
    ) {
      actions.push(getButton("pdf", "search"));
    }

    actions.push(getButton("customFields", "custom-fields"));

    return actions;
  };

  onClickDownload = type => {
    if (this.state.asset.asset_type[1] === "image") {
      this.setState({ openModalDownloadOptions: true });
    } else {
      let { size_url } = this.state.asset;
      let src = size_url ? size_url.original : null;

      if (!type && this.state.download_options.length <= 1 && src) {

        const { asset } = this.state;
    
        let name = String(asset.real_name).replace(asset.asset_type[2], "");
        name = name + asset.asset_type[2];

        this.downloadFile(src, name);
      }
    }
  };

  onTimeChange = currentTime => {
    const { activeAction } = this.state;

    if (activeAction === "timeshots" || activeAction === "timestamp") {
      this.setState({ currentTime: Math.trunc(currentTime) });
    }
  };

  isCurrent = (start_time, text) => {
    start_time = String(start_time);

    if (start_time.indexOf(":") >= 0) {
      start_time = this.getTimeFormated(start_time);
    }

    return (
      this.state.timeSelected === Math.trunc(Number(start_time)) &&
      this.state.textSelected === text
    );
  };

  callBackTimestamp = (indexTimestamp, paramsFromTimestampComponent) => {
    if (paramsFromTimestampComponent) {
      this.onTimeStampClick(
        paramsFromTimestampComponent.time,
        paramsFromTimestampComponent.label
      );
    }

    this.setState({ indexActiveTimestamp: indexTimestamp });
  };

  loadOriginalImage = () => {
    this.setState(
      {
        isOriginalImage: true,
        isLoadingOriginalImage: true,
        msgLoading: I18n.t("LoadingOriginalImage")
      },
      () => (this.refAssetImage.src = this.state.asset.size_url.original)
    );
  };

  callbackSearchPdf = params => {
    this.setState({
      indexSearchPdf: {
        currentIndex: params.indexSearch ? params.indexSearch.currentIndex : 0,
        totalResults: params.indexSearch ? params.indexSearch.totalResults : 0
      },
      pdfIsRendered: params.pdfIsRendered
    });
  };

  onDisableShortcuts = disableShortcuts => {
    this.setState({ disableShortcuts });
  };

  getAssetCategories = () => {
    this.setState({ loadingCategories: true });
    getAssetCategories(this.props.uuid)
      .then(response => {
        let uuidCategoriesSaved = [];
        if (Array.isArray(response.data)) {
          uuidCategoriesSaved = response.data.map(r => r.uuid);
        }

        this.setState({
          categories: response.data,
          uuidCategoriesSaved,
          loadingCategories: false
        });
      })
      .catch(() =>
        this.setState({
          loadingCategories: false,
          categories: [],
          uuidCategoriesSaved: []
        })
      );

    this.getOptionsCategoriesCustomFields();
  };

  onSubmitEditCustomFields = async () => {
    if (this.state.showMessageRemoveFields) {
      this.props.addAlert({
        id: "alertToConfirmLeavePage",
        theme: "warning",
        animation: "default",
        timer: "infinite",
        centralized: true,
        overlay: true,
        title: I18n.t("ConfirmTitleLeavePage"),
        description: I18n.t("ConfirmRemoveCustomField"),
        actionButtons: [
          {
            label: I18n.t("ActionRemoveCustomField"),
            icon: "delete",
            theme: "danger",
            onClick: () =>
              this.setState({ showMessageRemoveFields: false }, () => {
                this.props.delAlert("alertToConfirmLeavePage");
                this.onSubmitEditCustomFields();
              })
          },
          {
            label: I18n.t("Cancel"),
            theme: "light",
            kind: "ghost",
            onClick: this.props.delAlert.bind(this, "alertToConfirmLeavePage")
          }
        ]
      });
    } else {
      this.setState({ isSavingEditCustomFields: true });
      let formattedCategoriesToEdit = [];
      let getValue = field => {
        if (field.kind === "float" && field.value) {
          return String(field.value).replace(",", ".");
        } else {
          return field.value;
        }
      };
      this.state.categories.map(category =>
        formattedCategoriesToEdit.push({
          category: category.uuid,
          values: category.fields.map(field => ({
            field: field.uuid,
            value: getValue(field)
          }))
        })
      );
      try {
        let response = await updateAssetCategories(
          this.props.uuid,
          formattedCategoriesToEdit
        );
        if (response) {
          this.setState({
            isSavingEditCustomFields: false,
            isEditingCustomFields: false
          });
          this.props.addAlert({
            id: "alertFeedbackEditCustomField",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("TitleAlertEditCustomFieldWithSuccess")
          });
          this.getAssetCategories();
        }
      } catch (error) {
        this.setState({
          isSavingEditCustomFields: false,
          isEditingCustomFields: false
        });
        this.props.addAlert({
          id: "alertFeedbackEditCustomField",
          theme: "danger",
          animation: "shake",
          timer: "fast",
          title: I18n.t("TitleAlertEditCustomFieldWithError"),
          description: I18n.t("ContentAlertServerError")
        });
        this.getAssetCategories();
      }
    }
  };

  onCancelEditCustomFields = () => {
    this.setState({
      isEditingCustomFields: false,
      showMessageRemoveFields: false
    });
    this.getAssetCategories();
  };

  renderEditingCustomFields = () => {
    const categories = this.state.categories;
    const categoriesSelectedsCustomFields = categories.map(
      category => category.uuid
    );

    return (
      <div className="mt-xs-4">
        <Form>
          <FormGroup
            onDisableShortcuts={this.onDisableShortcuts}
            className="mb-xs-4"
            type="virtualized"
            loadInVirtualized="custom"
            label={`${I18n.t("Categories")}:`}
            name="customFields"
            multi={true}
            async={false}
            options={this.state.optionsCategoriesCustomFields}
            defaultValue={categoriesSelectedsCustomFields}
            onChange={this.updateCategoriesCustomFields.bind(this)}
            showBorderOptions
          />

          {this.state.isLoadingCategoryCustomFields && (
            <Loader className="mb-xs-4" centralized={true} />
          )}

          <div id="customFieldsToEdit">
            {categories
              .slice(0)
              .reverse()
              .map((category, indexCategory) => (
                <div key={indexCategory} className="mb-xs-4">
                  <h2 className="heading-3 mb-xs-3">{category.name}</h2>

                  {category.fields.map((field, indexField) => (
                    <div key={field.uuid} className="mb-xs-3">
                      {this.renderCustomField(field, indexField, category.uuid)}
                    </div>
                  ))}
                </div>
              ))}
          </div>

          <Button
            submit={true}
            type="button"
            onClick={this.onSubmitEditCustomFields.bind(this)}
            label={I18n.t("ActionSave")}
            isLoading={this.state.isSavingEditCustomFields}
            className="mr-xs-1"
          />

          <Button
            type="button"
            onClick={this.onCancelEditCustomFields.bind(this)}
            label={I18n.t("ActionCancel")}
            theme="light"
          />
        </Form>
      </div>
    );
  };

  renderCustomField = (field, indexField, categoryUUID) => {
    let categories = this.state.categories;
    let indexCategory = null;

    categories.map((category, index) => {
      if (category.uuid === categoryUUID) {
        indexCategory = index;
      }
      return null;
    });

    if (field.kind === "int" || field.kind === "float") {
      return (
        <FormGroup
          onDisableShortcuts={this.onDisableShortcuts}
          legend={field.tooltip}
          validation={field.regex}
          type={field.kind === "int" ? "number" : "text"}
          numberType={field.kind}
          step={field.kind === "int" ? "1" : "0.01"}
          label={`${field.label}:`}
          value={
            categories[indexCategory].fields[indexField].value
              ? String(
                  categories[indexCategory].fields[indexField].value
                ).replace(".", ",")
              : ""
          }
          onChange={event => {
            categories[indexCategory].fields[indexField].value =
              event.target.value;
            this.setState({ categories });
          }}
        />
      );
    } else if (field.kind === "date") {
      return (
        <FormGroup
          onDisableShortcuts={this.onDisableShortcuts}
          legend={field.tooltip}
          validation={field.regex}
          type="date"
          label={`${field.label}:`}
          value={categories[indexCategory].fields[indexField].value || ""}
          onChange={event => {
            categories[indexCategory].fields[indexField].value =
              event.target.value;
            this.setState({ categories });
          }}
        />
      );
    } else if (field.kind === "multiselect" || field.kind === "select") {
      let fieldOptions = [];
      let fieldValues = [];

      field.options.map(option =>
        fieldOptions.push({
          label: option,
          value: option
        })
      );

      if (Array.isArray(field.value)) {
        field.value.map(value =>
          fieldValues.push({
            label: value,
            value: value
          })
        );
      }

      let onChangeValue = value => {
        let newValue = [];

        if (field.kind === "multiselect") {
          value.map(aValue => newValue.push(aValue.value));
        } else {
          newValue = value ? value.value : null;
        }

        categories[indexCategory].fields[indexField].value = newValue;

        this.setState({ categories });
      };

      return (
        <FormGroup
          onDisableShortcuts={this.onDisableShortcuts}
          legend={field.tooltip}
          validation={field.regex}
          validate={false}
          type="virtualized"
          loadInVirtualized="custom"
          name="custom"
          multi={field.kind === "multiselect" ? true : false}
          async={false}
          options={fieldOptions}
          label={`${field.label}:`}
          defaultValue={
            field.kind === "multiselect" ? fieldValues : field.value
          }
          onChange={value => onChangeValue(value)}
        />
      );
    } else {
      return (
        <FormGroup
          onDisableShortcuts={this.onDisableShortcuts}
          legend={field.tooltip}
          validation={field.regex}
          type="text"
          label={`${field.label}:`}
          value={categories[indexCategory].fields[indexField].value || ""}
          onChange={event => {
            categories[indexCategory].fields[indexField].value =
              event.target.value;
            this.setState({ categories });
          }}
        />
      );
    }
  };

  getOptionsCategoriesCustomFields = async () => {
    let categories = await getAllCategories();
    let optionsCategoriesCustomFields = [];

    categories.data.map(category =>
      optionsCategoriesCustomFields.push({
        label: category.name,
        value: category.uuid
      })
    );

    this.setState({
      optionsCategoriesCustomFields
    });
  };

  updateCategoriesCustomFields = categoriesSelecteds => {
    if (categoriesSelecteds.length > this.state.categories.length) {
      let uuidCategoryToAdd =
        categoriesSelecteds[categoriesSelecteds.length - 1].value;
      this.addCategoryCustomFields(uuidCategoryToAdd);
    } else {
      let uuidsCategoriesSelecteds = categoriesSelecteds.map(
        categorySelected => categorySelected.value
      );

      let uuidCategoryToRemove = [];

      this.state.categories.map(category => {
        if (!uuidsCategoriesSelecteds.includes(category.uuid)) {
          uuidCategoryToRemove.push(category.uuid);

          if (this.state.uuidCategoriesSaved.includes(category.uuid)) {
            this.setState({ showMessageRemoveFields: true });
          }
        }
        return null;
      });

      this.removeCategoryCustomFields(uuidCategoryToRemove);
    }
  };

  addCategoryCustomFields = async uuidCategoryToAdd => {
    this.setState({ isLoadingCategoryCustomFields: true });
    let categories = this.state.categories;
    let dataCategoryToAdd = await getCategory(uuidCategoryToAdd);
    categories.push(dataCategoryToAdd.data);

    this.setState({
      categories,
      isLoadingCategoryCustomFields: false
    });
  };

  removeCategoryCustomFields = uuidCategoryToRemove => {
    let categories = JSON.parse(JSON.stringify(this.state.categories));
    let indexToRemove = [];

    categories.map((category, index) => {
      if (uuidCategoryToRemove.includes(category.uuid)) {
        indexToRemove.push(index);
      }
      return null;
    });

    categories = categories.filter((value, indexCategory) => {
      return !indexToRemove.includes(indexCategory);
    });

    this.setState({
      categories
    });
  };

  getCategories = () => {
    let { categories } = this.state;

    let getValue = field => {
      if (field.kind === "multiselect" && Array.isArray(field.value)) {
        return field.value.join(",");
      } else if (field.kind === "date" && field.value) {
        let date = String(field.value).split("-");

        return date[2] + "/" + date[1] + "/" + date[0];
      } else if (field.kind === "float" && field.value) {
        return String(field.value).replace(".", ",");
      } else {
        return field.value;
      }
    };

    let categoryFields = [];

    if (Array.isArray(categories) && categories.length > 0) {
      categories.map(c => {
        if (Array.isArray(c.fields) && c.fields.find(f => f.value)) {
          categoryFields.push(
            <div key={c.uuid} className="mt-xs-4">
              <FieldEditable
                type="text"
                className="mb-xs-1"
                onClick={() => this.setState({ isEditingCustomFields: true })}
              >
                <h2 className="heading-3">{c.name}</h2>
              </FieldEditable>
              <br />
              {c.fields.map((f, i) => {
                if (f.value) {
                  return (
                    <React.Fragment key={i}>
                      <FieldEditable
                        key={f.uuid}
                        type="text"
                        onClick={() =>
                          this.setState({ isEditingCustomFields: true })
                        }
                        className="o-assetModal__category field"
                      >
                        {f.label}: <b>{getValue(f)}</b>
                      </FieldEditable>
                      <br />
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </div>
          );
        }

        return null;
      });

      if (categoryFields.length > 0) {
        return categoryFields;
      } else {
        return (
          <p className="o-assetModal__category msg">
            {I18n.t("NoFieldFilledRegistered")}.
          </p>
        );
      }
    }
  };

  render() {
    const {
      activeAction,
      asset,
      isLoadingImage,
      sizeRenderedAssetImage,
      activeSquares,
      msgLoading,
      isLoadingOriginalImage,
      categories,
      loadingCategories,
      permissions
    } = this.state;

    if (this.state.error) return <div />;

    return (
      <div>
        <Modal
          loading={this.state.isGettingAsset}
          className="o-assetModal"
          toClose={this.onCloseModal.bind(this)}
          xs="12"
          showNavNext={
            this.props.hasMoreAssets ||
            this.props.index < this.props.totalAssets
          }
          showNavBack={this.props.index > 0}
          toNext={this.toNext}
          toBack={this.toBack}
        >
          {!this.state.isGettingAsset && (
            <Row noGutter>
              <Col xs="9">
                <div className="o-assetModal__assetPreview">
                  {asset &&
                    Array.isArray(asset.asset_type) &&
                    asset.asset_type[1] === "image" && (
                      <ImageViewer
                        onClickEnableZoom={
                          !this.state.isOriginalImage
                            ? this.loadOriginalImage
                            : null
                        }
                        disabled={isLoadingImage || isLoadingOriginalImage}
                      >
                        <div className="o-assetModal__assetPreview-globalSquare">
                          {!isLoadingImage &&
                            activeAction === "squares" &&
                            Object.keys(sizeRenderedAssetImage).length > 0 &&
                            activeSquares.length > 0 && (
                              <Square
                                animation={true}
                                draws={activeSquares}
                                originalSize={asset.original_size}
                                renderedSize={sizeRenderedAssetImage}
                              />
                            )}

                          {isLoadingImage && (
                            <Loader
                              className="o-assetModal__loader-getImagem"
                              centralized={true}
                            />
                          )}

                          {isLoadingOriginalImage && (
                            <div className="o-assetModal__loader-getOriginalImage container">
                              <Loader
                                centralized={true}
                                className="o-assetModal__loader-getOriginalImage loading"
                              />
                              <p className="o-assetModal__loader-getOriginalImage text">
                                {msgLoading}
                              </p>
                            </div>
                          )}

                          <img
                            className="o-assetModal__image"
                            ref={image => (this.refAssetImage = image)}
                            onLoad={this.onLoadImage}
                            src={
                              asset.size_url
                                ? asset.size_url.medium ||
                                  asset.size_url.original
                                : null
                            }
                            alt={asset.real_name}
                          />
                        </div>
                      </ImageViewer>
                    )}

                  {asset &&
                    Array.isArray(asset.asset_type) &&
                    asset.asset_type[1] === "video" && (
                      <Player
                        disableShortcuts={this.state.disableShortcuts}
                        onTimeChange={this.onTimeChange}
                        ref={ref => (this.player = ref)}
                        mpdFile={this.state.asset.mpd_file}
                        originalFile={this.state.asset.upload_to}
                        uuid={this.state.asset.uuid}
                        kind="video"
                      />
                    )}

                  {asset &&
                    Array.isArray(asset.asset_type) &&
                    asset.asset_type[1] === "audio" && (
                      <Player
                        disableShortcuts={this.state.disableShortcuts}
                        onTimeChange={this.onTimeChange}
                        ref={ref => (this.player = ref)}
                        mpdFile={this.state.asset.mpd_file}
                        originalFile={this.state.asset.upload_to}
                        uuid={this.state.asset.uuid}
                        kind="audio"
                      />
                    )}

                  {asset &&
                    Array.isArray(asset.asset_type) &&
                    asset.asset_type[1] === "application" &&
                    asset.asset_type[2] === ".pdf" && (
                      <PdfReader
                        onPdfLoad={params => this.setState({ ...params })}
                        file={this.state.asset.size_url.original}
                        heightForShow={this.state.maxHeightAssetPreview}
                        searchTerm={this.state.searchTermPdf}
                        changeMatch={this.state.changeMatchPdf}
                        callbackSearch={this.callbackSearchPdf}
                      />
                    )}

                  {asset &&
                    Array.isArray(asset.asset_type) &&
                    asset.asset_type[1] === "application" &&
                    asset.asset_type[2] !== ".pdf" && (
                      <p>Visualização indisponível.</p>
                    )}

                  <ul
                    className={"o-assetModal__iaActions"}
                    style={{
                      marginRight: this.state.previewScroll
                        ? this.state.previewScrollbarSize
                        : 0
                    }}
                  >
                    {this.getActions()}
                  </ul>
                </div>

                <div className="o-assetModal__section clearfix">
                  <ul className="float-xs-right">
                    {((asset && asset.public_download) ||
                      PermissionHandler.can_download()) && (
                      <li className="float-xs-left mr-xs-1">
                        <Button
                          onClick={() => this.onClickDownload()}
                          icon="download"
                          width="min"
                          theme="light"
                        />

                        {this.state.hasDownloadOptions &&
                          this.state.openModalDownloadOptions && (
                            <Modal
                              title="Opções de download"
                              md="8"
                              lg="6"
                              xlg="5"
                              toClose={() =>
                                this.setState({
                                  openModalDownloadOptions: false,
                                  sizeImageDownload: null,
                                  imageDownloadMark: null
                                })
                              }
                            >
                              <div className="teste" />
                              <div className="py-xs-4 px-xs-3">
                                {this.state.asset.size_url &&
                                  this.sizeImageLabel.map((label, index) => {
                                    if (this.state.asset.size_url[label.key]) {
                                      return (
                                        <Radio
                                          key={index}
                                          name="downloadOption"
                                          label={label.value}
                                          id={`downloadType-${index}`}
                                          block
                                          className="mb-xs-1"
                                          onChange={() =>
                                            this.setState({
                                              sizeImageDownload: label.key
                                            })
                                          }
                                        />
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}

                                <Key
                                  label="Fixar a marcação realizada na imagem"
                                  id="showMarks"
                                  disabled={
                                    this.state.activeSquares.length === 0
                                  }
                                  block
                                  className="mt-xs-3"
                                  onChange={e =>
                                    this.setState({
                                      imageDownloadMark: e.target.checked
                                    })
                                  }
                                />

                                <div className="text-xs-right mt-xs-3">
                                  <Button
                                    isLoading={this.state.generatingImage}
                                    type="button"
                                    label="Fazer download"
                                    size="large"
                                    onClick={this.downloadImage}
                                  />
                                </div>
                              </div>
                            </Modal>
                          )}
                      </li>
                    )}

                    {PermissionHandler.can_share() && this.state.asset.public && (
                      <li className="float-xs-left mr-xs-1">
                        <Button
                          type="a"
                          target="_blank"
                          onClick={
                            "https://www.facebook.com/dialog/share?app_id=145634995501895&display=popup&href=" +
                            this.state.asset.size_url.original
                          }
                          icon="facebook"
                          theme="facebook"
                          width="min"
                        />
                      </li>
                    )}

                    {PermissionHandler.can_share() && this.state.asset.public && (
                      <li className="float-xs-left mr-xs-1">
                        <Button
                          type="a"
                          target="_blank"
                          onClick={
                            "https://twitter.com/intent/tweet?url=" +
                            this.state.asset.size_url.original
                          }
                          icon="twitter"
                          theme="twitter"
                          width="min"
                        />
                      </li>
                    )}

                    {PermissionHandler.can_share() && this.state.asset.public && (
                      <li className="float-xs-left">
                        <Button
                          type="a"
                          target="_blank"
                          onClick={
                            "https://www.linkedin.com/shareArticle?url=" +
                            this.state.asset.size_url.original
                          }
                          icon="linkedin"
                          theme="linkedin"
                          width="min"
                        />
                      </li>
                    )}
                  </ul>
                </div>
              </Col>

              <Col xs="3">
                <div className="o-assetModal__globalAssetInfos">
                  <div
                    className={
                      "o-assetModal__iaView " +
                      (activeAction === "content" ? "active" : "")
                    }
                  >
                    {asset &&
                    asset.content &&
                    asset.content.match(/^ *$/) === null && ( // For empty spaces
                        <div>
                          {this.state.asset.asset_type[1] === "image" && (
                            <h3 className="o-assetModal__iaView-title">
                              {I18n.t("ContentSmartSearchImage")}
                            </h3>
                          )}

                          {(this.state.asset.asset_type[1] === "audio" ||
                            this.state.asset.asset_type[1] === "video") && (
                            <h3 className="o-assetModal__iaView-title">
                              {I18n.t("ContentSmartSearchAudio")}
                            </h3>
                          )}

                          <p className="mb-xs-4">{this.state.asset.content}</p>
                        </div>
                      )}

                    {asset && asset.video_content && (
                      <div>
                        <h3 className="o-assetModal__iaView-title">
                          {I18n.t("ContentSmartSearchVideo")}
                        </h3>

                        <p>{this.state.asset.video_content}</p>
                      </div>
                    )}

                    <Button
                      onClick={this.getDownloadContent.bind(this)}
                      icon="download"
                      label="Baixar conteúdo"
                      kind="outline"
                    />
                  </div>

                  <div
                    className={
                      "o-assetModal__iaView " +
                      (activeAction === "timestamp" ? "active" : "")
                    }
                  >
                    <h3 className="o-assetModal__iaView-title">Timestamps:</h3>

                    {asset &&
                      asset.time_stamp &&
                      asset.time_stamp.map((item, index) => (
                        <Timestamp
                          key={index}
                          time={String(item.time)}
                          text={item.text}
                          searchTerm={
                            this.props.searchTerm ? this.props.searchTerm : null
                          }
                          onClick={this.onTimeStampClick.bind(
                            this,
                            item.time,
                            item.text
                          )}
                          className={`o-assetModal__timestamp mb-xs-2`}
                          current={this.isCurrent(item.time, item.text)}
                        />
                      ))}
                  </div>

                  <div
                    className={
                      "o-assetModal__iaView " +
                      (activeAction === "timeshots" ? "active" : "")
                    }
                  >
                    <h3 className="o-assetModal__iaView-title">
                      {I18n.t("TitleVideoImage")}:
                    </h3>

                    <FormGroup
                      onDisableShortcuts={this.onDisableShortcuts}
                      ref={formGroup =>
                        (this.formGroupSearchTimeStampVideo = formGroup)
                      }
                      icon="search"
                      type="search"
                      placeholder="Buscar conteúdo"
                      className="o-header__searchTerm mb-xs-2"
                      onChange={this.doSearchTimeStampVideo.bind(this)}
                    />

                    {this.state.isSearchingTimeStampVideo && <Loader />}

                    {!this.state.isSearchingTimeStampVideo && (
                      <div>
                        {Object.keys(this.state.searchedTimeStampVideo).map(
                          (key, index) => (
                            <NewTimestamp
                              key={index}
                              icon="videostamp"
                              label={key}
                              times={this.state.searchedTimeStampVideo[key]}
                              amount={
                                this.state.searchedTimeStampVideo[key].length
                              }
                              enableDropdown
                              callBackTimestamp={this.callBackTimestamp.bind(
                                this,
                                index
                              )}
                              isActive={
                                this.state.indexActiveTimestamp === index
                              }
                              currentTime={this.state.currentTime}
                              className="o-assetModal__timestamp mb-xs-2"
                            />
                          )
                        )}

                        {this.state.noTimeStampVideoFound && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: I18n.t("SearchContentNotFound", {
                                searchTerm: this.formGroupSearchTimeStampVideo
                                  .refs.input.value
                              })
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      "o-assetModal__iaView " +
                      (activeAction === "pdf" ? "active" : "")
                    }
                  >
                    <h3 className="o-assetModal__iaView-title">
                      {I18n.t("TitleSearchInPdf")}
                    </h3>

                    <FormGroup
                      onDisableShortcuts={this.onDisableShortcuts}
                      ref={formGroup => (this.formGroupSearchInPdf = formGroup)}
                      icon="search"
                      type="search"
                      placeholder={I18n.t("SearchTextContent")}
                      className="o-header__searchTerm mb-xs-2"
                      onChange={this.doSearchInPdf.bind(this)}
                    />

                    {!this.state.pdfIsRendered && this.state.searchTermPdf && (
                      <Loader />
                    )}

                    {this.state.indexSearchPdf.totalResults > 0 && (
                      <div>
                        <span className="mr-xs-1">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: I18n.t("IndexResultsFound", {
                                currentIndex: this.state.indexSearchPdf
                                  .currentIndex,
                                totalResults: this.state.indexSearchPdf
                                  .totalResults
                              })
                            }}
                          />
                        </span>

                        <Button
                          icon="right-1"
                          theme="light"
                          kind="outline"
                          size="small"
                          width="min"
                          onClick={this.changeMatchSearchInPdf.bind(this)}
                        />
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      "o-assetModal__iaView " +
                      (activeAction === "squares" ? "active" : "")
                    }
                  >
                    <h3 className="o-assetModal__iaView-title">
                      {I18n.t("AssetDetailContent")}
                    </h3>

                    {this.state.activeSquares.length > 0 && (
                      <div className="mb-xs-4">
                        {this.state.activeSquares.map((item, index) => (
                          <SquareLabel
                            key={index}
                            active={true}
                            text={item.text}
                            withIcon
                            className="my-xs-1 mr-xs-2"
                            onClick={this.toggleSeeSquare.bind(this, item)}
                          />
                        ))}

                        <LinkText
                          className="my-xs-1"
                          text={I18n.t("ActionUncheckAll")}
                          onClick={this.clearActiveSquares.bind(this)}
                        />
                      </div>
                    )}

                    <FormGroup
                      onDisableShortcuts={this.onDisableShortcuts}
                      ref={formGroup =>
                        (this.formGroupSearchAssetSquares = formGroup)
                      }
                      icon="search"
                      type="search"
                      placeholder={I18n.t("ActionSearchContent")}
                      className="o-header__searchTerm mb-xs-2"
                      onChange={this.doSearchSquares.bind(this)}
                    />

                    {this.state.isSearchingSquares && <Loader />}

                    {!this.state.isSearchingSquares && (
                      <div>
                        {this.state.searchedSquares.map((item, index) => (
                          <SquareLabel
                            key={index}
                            active={this.theSquareIsActive(item)}
                            text={item.text}
                            withIcon
                            className="my-xs-1 mr-xs-2"
                            onClick={this.toggleSeeSquare.bind(this, item)}
                          />
                        ))}

                        {this.state.noSquaresFound && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: I18n.t("SearchContentNotFound", {
                                searchTerm: this.formGroupSearchAssetSquares
                                  .refs.input.value
                              })
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      "o-assetModal__iaView " +
                      (activeAction === "customFields" ? "active" : "")
                    }
                  >
                    <h3 className="heading-2">{I18n.t("CustomFields")}:</h3>

                    {this.state.isEditingCustomFields &&
                      this.renderEditingCustomFields()}

                    {!this.state.isEditingCustomFields && (
                      <React.Fragment>
                        {loadingCategories && (
                          <Loader className="o-assetModal__loader" />
                        )}

                        {!loadingCategories &&
                          (!categories ||
                            (Array.isArray(categories) &&
                              categories.length === 0)) && (
                            <p className="o-assetModal__category msg">
                              {I18n.t("NoFieldRegistered")}.
                            </p>
                          )}

                        {!loadingCategories && (
                          <React.Fragment>
                            {this.getCategories()}

                            {permissions.can_edit && (
                              <Button
                                type="button"
                                icon="edit"
                                label={I18n.t("ActionEdit")}
                                className="mr-xs-1 o-assetModal__category button "
                                onClick={() =>
                                  this.setState({ isEditingCustomFields: true })
                                }
                              />
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </div>

                  {this.state.isGettingAsset && (
                    <Loader className="o-assetModal__loader" />
                  )}

                  {!this.state.isGettingAsset && (
                    <div>
                      <div className="o-assetModal__section">
                        <div className="o-assetModal__owner">
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              padding: "5px",
                              backgroundColor: "#EEEEEE",
                              borderRadius: "50%",
                              marginRight: "10px"
                            }}
                          >
                            <Icon name="user" />
                          </div>

                          <strong className="o-assetModal__owner-name">
                            {asset && asset.owner
                              ? asset.owner.first_name + asset.owner.last_name
                              : ""}
                          </strong>
                        </div>
                      </div>

                      <div className="o-assetModal__section">
                        {!this.state.isEditing && (
                          <h2 className="o-assetModal__title">
                            {PermissionHandler.can_edit() && (
                              <FieldEditable
                                type="text"
                                onClick={this.toggleEditAsset.bind(
                                  this,
                                  "formGroupRealName"
                                )}
                              >
                                {String(this.state.asset.real_name).replace(
                                  this.state.asset.asset_type[2],
                                  ""
                                )}
                              </FieldEditable>
                            )}

                            {asset &&
                              !PermissionHandler.can_edit() &&
                              asset.real_name}
                          </h2>
                        )}

                        {this.state.isEditing && (
                          <FormGroup
                            onDisableShortcuts={this.onDisableShortcuts}
                            ref={formGroup =>
                              (this.formGroupRealName = formGroup)
                            }
                            type="text"
                            name="asset_name"
                            defaultValue={String(
                              this.state.asset.real_name
                            ).replace(this.state.asset.asset_type[2], "")}
                            className="o-assetModal__formGroupAssetName mb-xs-2"
                          />
                        )}

                        <p>
                          {I18n.t("AssetDetailFileExtension")}
                          <strong>
                            {" "}
                            {asset &&
                              Array.isArray(asset.asset_type) &&
                              asset.asset_type[2]}
                          </strong>
                        </p>

                        <p>
                          {I18n.t("AssetDetailFileSize")}
                          <strong>
                            {" "}
                            {asset && humanize.filesize(asset.file_size)}
                          </strong>
                        </p>

                        {asset &&
                          Array.isArray(asset.tags) &&
                          asset.tags.length !== 0 &&
                          !this.state.isEditing &&
                          PermissionHandler.can_edit() && (
                            <p>
                              <FieldEditable
                                onClick={this.toggleEditAsset.bind(
                                  this,
                                  "formGroupTags"
                                )}
                              >
                                Tags:
                                {this.state.asset.tags.map((tag, index) => (
                                  <span key={index} className="arrayInfo">
                                    {" "}
                                    {tag.slug}
                                  </span>
                                ))}
                              </FieldEditable>
                            </p>
                          )}

                        {asset &&
                          Array.isArray(asset.tags) &&
                          asset.tags.length !== 0 &&
                          !this.state.isEditing &&
                          !PermissionHandler.can_edit() && (
                            <p>
                              Tags:
                              {this.state.asset.tags.map((tag, index) => (
                                <span key={index} className="arrayInfo">
                                  {" "}
                                  {tag.slug}
                                </span>
                              ))}
                            </p>
                          )}

                        {this.state.isEditing && (
                          <FormGroup
                            onDisableShortcuts={this.onDisableShortcuts}
                            ref={formGroup => (this.formGroupTags = formGroup)}
                            type="virtualized"
                            loadInVirtualized="tags"
                            label="Tags:"
                            name="tags"
                            defaultValue={this.state.defaultValueToEdit.tags}
                            className="mt-xs-3"
                            enableToCreateOption
                          />
                        )}

                        {asset &&
                          Array.isArray(asset.collections) &&
                          asset.collections.length !== 0 &&
                          !this.state.isEditing &&
                          PermissionHandler.can_edit() && (
                            <p>
                              <FieldEditable
                                onClick={this.toggleEditAsset.bind(
                                  this,
                                  "formGroupCollections"
                                )}
                              >
                                {I18n.t("AssetDetailCollections")}
                                {this.state.asset.collections.map(
                                  (collection, index) => (
                                    <span key={index} className="arrayInfo">
                                      {" "}
                                      {collection.name}
                                    </span>
                                  )
                                )}
                              </FieldEditable>
                            </p>
                          )}

                        {asset &&
                          Array.isArray(asset.collections) &&
                          asset.collections.length !== 0 &&
                          !this.state.isEditing &&
                          !PermissionHandler.can_edit() && (
                            <p>
                              {I18n.t("AssetDetailCollections")}
                              {this.state.asset.collections.map(
                                (collection, index) => (
                                  <span key={index} className="arrayInfo">
                                    {" "}
                                    {collection.name}
                                  </span>
                                )
                              )}
                            </p>
                          )}

                        {this.state.isEditing && (
                          <FormGroup
                            onDisableShortcuts={this.onDisableShortcuts}
                            ref={formGroup =>
                              (this.formGroupCollections = formGroup)
                            }
                            className="mt-xs-3"
                            type="virtualized"
                            loadInVirtualized="collections"
                            label={I18n.t("AssetDetailCollections")}
                            name="collections"
                            defaultValue={
                              this.state.defaultValueToEdit.collections
                            }
                            enableToCreateOption
                          />
                        )}

                        {asset &&
                          Array.isArray(asset.group) &&
                          asset.group.length !== 0 &&
                          !this.state.isEditing &&
                          PermissionHandler.can_edit() && (
                            <p>
                              <FieldEditable
                                onClick={this.toggleEditAsset.bind(
                                  this,
                                  "formGroupGroups"
                                )}
                              >
                                {I18n.t("AssetDetailGroups")}
                                {this.state.asset.group.map((group, index) => (
                                  <span key={index} className="arrayInfo">
                                    {" "}
                                    {group.name}
                                  </span>
                                ))}
                              </FieldEditable>
                            </p>
                          )}

                        {asset &&
                          Array.isArray(asset.group) &&
                          asset.group.length !== 0 &&
                          !this.state.isEditing &&
                          !PermissionHandler.can_edit() && (
                            <p>
                              {I18n.t("AssetDetailGroups")}
                              {this.state.asset.group.map((group, index) => (
                                <span key={index} className="arrayInfo">
                                  {" "}
                                  {group.name}
                                </span>
                              ))}
                            </p>
                          )}

                        {this.state.isEditing && (
                          <FormGroup
                            onDisableShortcuts={this.onDisableShortcuts}
                            ref={formGroup =>
                              (this.formGroupGroups = formGroup)
                            }
                            className="my-xs-3"
                            type="virtualized"
                            loadInVirtualized="groups"
                            label={I18n.t("AssetDetailGroups")}
                            name="group"
                            defaultValue={this.state.defaultValueToEdit.groups}
                          />
                        )}

                        <p>
                          {I18n.t("AssetDetailUpload")}
                          <strong>
                            {" "}
                            {asset &&
                              humanize.relativeTime(
                                new Date(asset.created_at).getTime() / 1000
                              )}
                          </strong>
                        </p>

                        <p className="mb-xs-3">
                          {I18n.t("AssetDetailModified")}
                          <strong>
                            {" "}
                            {asset &&
                              humanize.relativeTime(
                                new Date(asset.updated_at).getTime() / 1000
                              )}
                          </strong>
                        </p>

                        {!this.state.isEditing &&
                          PermissionHandler.can_edit() &&
                          !this.state.asset.smart_search && (
                            <p>
                              <FieldEditable
                                onClick={this.toggleEditAsset.bind(this, null)}
                              >
                                {this.state.asset.smart_search && (
                                  <Icon
                                    name="check"
                                    className="mr-xs-1 color-states-success"
                                  />
                                )}

                                {!this.state.asset.smart_search && (
                                  <Icon
                                    name="clear"
                                    className="mr-xs-1 color-states-danger"
                                  />
                                )}

                                {I18n.t("AssetDetailHasSmartSearch")}
                              </FieldEditable>
                            </p>
                          )}

                        {!this.state.isEditing &&
                          (!PermissionHandler.can_edit() ||
                            this.state.asset.smart_search) && (
                            <p>
                              {asset && asset.smart_search && (
                                <Icon
                                  name="check"
                                  className="mr-xs-1 color-states-success"
                                />
                              )}

                              {asset && !asset.smart_search && (
                                <Icon
                                  name="clear"
                                  className="mr-xs-1 color-states-danger"
                                />
                              )}

                              {I18n.t("AssetDetailHasSmartSearch")}
                            </p>
                          )}

                        {this.state.isEditing &&
                          PermissionHandler.can_edit() &&
                          !this.state.asset.smart_search && (
                            <Key
                              ref={key => (this.keySmartSearch = key)}
                              label={I18n.t("AssetDetailHasSmartSearch")}
                              id="assetSmartSearch"
                              block
                              className="mb-xs-2"
                              defaultChecked={this.state.asset.smart_search}
                            />
                          )}

                        {this.state.isEditing &&
                          (!PermissionHandler.can_edit() ||
                            this.state.asset.smart_search) && (
                            <p>
                              {this.state.asset.smart_search && (
                                <Icon
                                  name="check"
                                  className="mr-xs-1 color-states-success"
                                />
                              )}

                              {!this.state.asset.smart_search && (
                                <Icon
                                  name="clear"
                                  className="mr-xs-1 color-states-danger"
                                />
                              )}

                              {I18n.t("AssetDetailHasSmartSearch")}
                            </p>
                          )}

                        {!this.state.isEditing && PermissionHandler.can_edit() && (
                          <p>
                            <FieldEditable
                              onClick={this.toggleEditAsset.bind(this, null)}
                            >
                              {this.state.asset.public && (
                                <Icon
                                  name="check"
                                  className="mr-xs-1 color-states-success"
                                />
                              )}

                              {!this.state.asset.public && (
                                <Icon
                                  name="clear"
                                  className="mr-xs-1 color-states-danger"
                                />
                              )}

                              {I18n.t("AssetDetailIsPublicMode")}
                            </FieldEditable>
                          </p>
                        )}

                        {!this.state.isEditing &&
                          !PermissionHandler.can_edit() && (
                            <p>
                              {asset && asset.public && (
                                <Icon
                                  name="check"
                                  className="mr-xs-1 color-states-success"
                                />
                              )}

                              {asset && !asset.public && (
                                <Icon
                                  name="clear"
                                  className="mr-xs-1 color-states-danger"
                                />
                              )}

                              {I18n.t("AssetDetailIsPublicMode")}
                            </p>
                          )}

                        {this.state.isEditing && (
                          <Key
                            ref={key => (this.keyPublicMode = key)}
                            label={I18n.t("AssetDetailIsPublicMode")}
                            id="assetPublicMode"
                            block
                            className="mb-xs-2"
                            defaultChecked={this.state.asset.public}
                          />
                        )}

                        {!this.state.isEditing && PermissionHandler.can_edit() && (
                          <p className="mb-xs-3">
                            <FieldEditable
                              onClick={this.toggleEditAsset.bind(this, null)}
                            >
                              {this.state.asset.public_download && (
                                <Icon
                                  name="check"
                                  className="mr-xs-1 color-states-success"
                                />
                              )}

                              {!this.state.asset.public_download && (
                                <Icon
                                  name="clear"
                                  className="mr-xs-1 color-states-danger"
                                />
                              )}

                              {I18n.t("AssetDetailIsPublicDownload")}
                            </FieldEditable>
                          </p>
                        )}

                        {!this.state.isEditing &&
                          !PermissionHandler.can_edit() && (
                            <p className="mb-xs-3">
                              {asset && asset.public_download && (
                                <Icon
                                  name="check"
                                  className="mr-xs-1 color-states-success"
                                />
                              )}

                              {asset && !asset.public_download && (
                                <Icon
                                  name="clear"
                                  className="mr-xs-1 color-states-danger"
                                />
                              )}

                              {I18n.t("AssetDetailIsPublicDownload")}
                            </p>
                          )}

                        {this.state.isEditing && (
                          <Key
                            ref={key => (this.keyPublicDownload = key)}
                            label={I18n.t("AssetDetailIsPublicDownload")}
                            id="assetPublicDownload"
                            block
                            className="mb-xs-3"
                            defaultChecked={this.state.asset.public_download}
                          />
                        )}

                        {!this.state.isEditing && (
                          <div>
                            {PermissionHandler.can_edit() && (
                              <Button
                                type="button"
                                icon="edit"
                                label={I18n.t("ActionEdit")}
                                className="mr-xs-1"
                                onClick={this.toggleEditAsset.bind(this, null)}
                              />
                            )}
                            {PermissionHandler.can_delete() && (
                              <Button
                                type="button"
                                icon="delete"
                                width="min"
                                theme="light"
                                kind="outline"
                                onClick={this.onDeleteAsset.bind(this)}
                              />
                            )}
                          </div>
                        )}

                        {this.state.isEditing && (
                          <div>
                            <Button
                              type="button"
                              onClick={this.onSubmitEditAsset.bind(this)}
                              label={I18n.t("ActionSave")}
                              isLoading={this.state.isSavingEditAsset}
                              className="mr-xs-1"
                            />

                            <Button
                              type="button"
                              onClick={this.toggleEditAsset.bind(this, null)}
                              label={I18n.t("ActionCancel")}
                              theme="light"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {/* end !this.state.isGettingAsset */}
                </div>
              </Col>
            </Row>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchTerm: state.search.filters.q,
  indexSearchInPdf: state.indexSearchInPdf
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeAssetDetails,
      ...alertActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetModal);

import React from "react";
import styled from "styled-components";
import { MenuItem, ListItemText, Popper } from "@material-ui/core";

export const HeaderMenuItem = styled(
  ({ customComponent, children, ...other }) => (
    <MenuItem {...other}>{children}</MenuItem>
  )
)`
  && {
    ${props =>
      props.customComponent
        ? ` height: 100%;
    padding: 0;
    border-bottom-right-radius: ${props.theme.borderRadius.default};
    border-bottom-left-radius: ${props.theme.borderRadius.default};`
        : ""}
  }
`;

export const HeaderListItem = styled(({ customComponent, ...other }) => (
  <ListItemText {...other} disableTypography />
))`
  && {
    ${props => (props.customComponent ? ` padding: 0` : "")}
    ${props => props.theme.typography.body1}
  }
`;

export const RootPopper = styled(Popper)`
  z-index: ${props => props.theme.zIndex.drawer};
`;

import React, { Component } from "react";
import { withRouter } from "react-router";
import Auth from "../../../models/AuthModel";
import { Grid, IconButton } from "@material-ui/core";
import Icon from "../../Icon/Icon";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from "react-i18nify";

import {
  changeAssetViewMode,
  applyFilters,
  clearFilters,
  setSearchValue
} from "./../../pages/search-results/_actionsReducers";

import PermissionHandler from "./../../../helpers/PermissionHelper";
import { logoutUser } from "../../../Utils";

import DropdownMenu from "./DropdownMenu";
import {
  RootContainer,
  UploadButton,
  Logo,
  ExpandIcon,
  InputSearch,
  UserData,
  UserName,
  MenuButton
} from "./Header.style";

class Header extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    let user = Auth.getUser() || {};
    let manage_user = PermissionHandler.is_admin();
    let manage_group = PermissionHandler.is_admin();
    let manage_category = PermissionHandler.can_admin_category();
    let access_analytics = PermissionHandler.is_admin();

    let menu = [];

    if (manage_user) {
      menu.push({
        label: I18n.t("ManageUsers"),
        icon: "people",
        onClick: () => this.props.history.push("/users")
      });
    }
    if (manage_group) {
      menu.push({
        label: I18n.t("ManageGroups"),
        icon: "settings",
        onClick: () => this.props.history.push("/groups")
      });
    }
    if (manage_category) {
      menu.push({
        label: I18n.t("CustomFields"),
        icon: "custom-fields",
        onClick: () => this.props.history.push("/categories")
      });
    }
    if (access_analytics) {
      menu.push({
        label: I18n.t("Analytics"),
        icon: "insert-chart",
        onClick: () => this.props.history.push("/analytics")
      });
    }

    menu.push({
      label: I18n.t("Help"),
      icon: "help",
      onClick: () => window.open("https://assetify.zendesk.com/hc/pt-br"),
      fixed: true
    });

    this.state = {
      showDropDownMyAccount: false,
      searchTerm: null,
      menu,
      menuAccount: [
        {
          label: I18n.t("Profile"),
          icon: "person",
          onClick: () => {
            let user = Auth.getUser();
            this.props.history.push("/profile/" + user.uuid);
          }
        },
        {
          label: I18n.t("Logout"),
          icon: "exit-to-app",
          onClick: () => logoutUser()
        },
        {
          label: "UserData",
          onClick: () => {
            let user = Auth.getUser();
            this.props.history.push("/profile/" + user.uuid);
          },
          fixed: true,
          customComponent: (
            <UserData>
              <UserName>{user.first_name}</UserName>
              <span>{user.email}</span>
            </UserData>
          )
        }
      ]
    };
  }

  componentDidUpdate() {
    if (this.props.updateAfterClear) {
      this.props.setSearchValue({ updateAfterClear: false });
      this.setState({ searchTerm: null });
    }
  }

  search = () => {
    const { assetViewModeByList } = this.props;
    const { searchTerm } = this.state;

    if (!assetViewModeByList) {
      this.props.changeAssetViewMode();
    }

    let q = String(searchTerm || "").trim();

    this.props.applyFilters(
      { q: q === "" ? null : q },
      {
        updateAssets: true,
        updateCollections: true,
        updateTags: true,
        activeItem: "filter"
      }
    );
  };

  onKeyPressSearchTerm = event => {
    if (event.charCode === 13) {
      this.search();
    }
  };

  _clearFilters = () => {
    let user = Auth.getUser();
    let redirectInitialScreen = false;
    if (
      user &&
      user.configurations &&
      user.configurations.initial_search_screen === "1"
    ) {
      redirectInitialScreen = true;
    }

    this.props.clearFilters({ redirectInitialScreen });
  };

  onClickIconInput = () => {
    if (this.props.filters.q) {
      this.props.clearFilters();
    } else {
      this.search();
    }
  };

  render() {
    const {
      searchTerm,
      menu,
      menuAccount,
      openAccoutMenu,
      openMenu
    } = this.state;
    const {
      filters,
      isSearchingAssets,
      isSearchingCollections,
      isSearchingTags
    } = this.props;
    return (
      <RootContainer>
        <Grid container spacing={8}>
          <Grid item xs={3} alignItems="center" container>
            <MenuButton
              buttonRef={ref => (this.menuButton = ref)}
              onClick={() => this.setState({ openMenu: !openMenu })}
              aria-owns={openMenu ? "menu-list-grow" : undefined}
              aria-haspopup={true}
            >
              <Icon name="menu" />
            </MenuButton>

            <DropdownMenu
              placement="bottom-start"
              anchorEl={this.menuButton}
              name="menu-list-grow"
              items={menu}
              open={openMenu}
              handleClose={() => this.setState({ openMenu: false })}
            />

            <Logo onClick={this._clearFilters} />
          </Grid>

          <Grid item container xs={6} spacing={8} alignItems="center">
            <Grid item xs={10}>
              <InputSearch
                disabled={
                  isSearchingAssets || isSearchingCollections || isSearchingTags
                }
                fullWidth
                variant="outlined"
                placeholder={I18n.t("SearchPlaceholder")}
                type="text"
                value={searchTerm === null ? filters.q || "" : searchTerm || ""}
                onChange={e => this.setState({ searchTerm: e.target.value })}
                onKeyPress={this.onKeyPressSearchTerm.bind(this)}
                hasFilters={filters.q}
                onClickIconInput={this.onClickIconInput}
              />
            </Grid>
            <Grid item xs={2}>
              {PermissionHandler.can_upload() && (
                <UploadButton
                  onClick={() => this.props.history.push("/upload")}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={3} alignItems="center" justify="flex-end" container>
            <IconButton
              buttonRef={ref => (this.accountButton = ref)}
              onClick={() => this.setState({ openAccoutMenu: !openAccoutMenu })}
              aria-owns={openAccoutMenu ? "account-list-grow" : undefined}
              aria-haspopup={true}
            >
              <Icon name="account-circle" />
            </IconButton>

            <ExpandIcon
              name={openAccoutMenu ? "expand-less" : "expand-more"}
              onClick={() => this.setState({ openAccoutMenu: !openAccoutMenu })}
            />

            <DropdownMenu
              placement="bottom-end"
              anchorEl={this.accountButton}
              name="account-list-grow"
              items={menuAccount}
              open={openAccoutMenu}
              handleClose={() => this.setState({ openAccoutMenu: false })}
            />
          </Grid>
        </Grid>
      </RootContainer>
    );
  }
}

const mapStateToProps = state => ({
  filters: state.search.filters,
  assetViewModeByList: state.search.assetViewModeByList,
  updateAfterClear: state.search.updateAfterClear,
  isSearchingAssets: state.search.isSearchingAssets,
  isSearchingCollections: state.search.isSearchingCollections,
  isSearchingTags: state.search.isSearchingTags
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeAssetViewMode,
      applyFilters,
      clearFilters,
      setSearchValue
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);

import React from "react";

import {
  CardRoot,
  CardRootMedia,
  CardRootContent,
  ContentName,
  ContentDecription,
  MediaIcon
  // IconType,
  // ContentTypes
} from "./CollectionItem.style";

class CollectionItem extends React.Component {
  state = { loading: true };

  async componentDidMount() {
    let { collection } = this.props;

    this.setState({ collection, loading: false });
  }

  renderThumb() {
    const { collection, loading } = this.state;

    if (loading) {
      return <MediaIcon name="circular-progress" />;
    } else {
      if (collection) {
        return <MediaIcon name="collections" />;
      }
    }
  }

  render() {
    let { onClick } = this.props;
    const { collection } = this.state;
    const thumb = this.renderThumb();
    const MediaComponent = props => <div {...props}>{thumb}</div>;
    return (
      <CardRoot
        onClick={() => {
          if (onClick) {
            onClick(collection);
          }
        }}
      >
        <CardRootMedia
          component={thumb instanceof Object ? MediaComponent : "div"}
          image={thumb instanceof Object ? "none" : thumb}
        />
        <CardRootContent>
          <ContentName noWrap>
            {collection && String(collection.name)}
          </ContentName>
          <div>
            <ContentDecription noWrap>
              {collection && collection.total_assets + " assets"}
              {/* <PublicOutlined className={classes.descriptionIcon} />
              <GroupOutlined className={classes.descriptionIcon} /> */}
            </ContentDecription>
          </div>

          {/* <ContentTypes>
            <IconType name="image" />
            <IconType name="audiotrack" />
            <IconType name="play-arrow" />
            <IconType name="picture-as-pdf" />
          </ContentTypes> */}
        </CardRootContent>
      </CardRoot>
    );
  }
}

export default CollectionItem;

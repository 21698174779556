import styled from "styled-components";
import { Paper, Link, Typography } from "@material-ui/core";
import Icon from "../../Icon/Icon";

export const PaperRoot = styled(Paper)`
  && {
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: ${props => props.theme.spacing.unit}px
      ${props => props.theme.spacing.unit * 2}px;
    box-shadow: ${props => props.theme.shadows.boxHeader};
  }
`;

export const HomeLink = styled(Icon)`
  && {
    color: ${props => props.theme.palette.grey2}
    font-size: ${props => props.theme.size.xxl};
    :hover {
      cursor: pointer;
    }
  }
`;

export const LabelLink = styled(Link)`
  && {
    font-size: ${props => props.theme.typography.fontSize.lg};
    color: ${props => props.theme.palette.grey2};
    font-weight: bold;
    :hover {
      cursor: pointer;
    }
  }
`;

export const Label = styled(Typography)`
  && {
    font-size: ${props => props.theme.typography.fontSize.lg};
    color: ${props => props.theme.palette.grey2};
    font-weight: bold;
  }
`;

import { CircularProgress, Typography } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  padding: ${props => props.theme.spacing.unit * 2}px;
`;

export const Header = styled.div`
  margin-bottom: ${props => props.theme.spacing.unit * 2}px;
  display: flex;
  align-items: center;
`;

export const ResultsCount = styled(Typography)`
  && {
    margin-right: ${props => props.theme.spacing.unit * 2}px;
    ${props => props.theme.typography.body1};
  }
`;

// export const OrderBy = styled.div`
//   margin-left: auto;
// `;

export const Loading = styled(CircularProgress)`
  margin-top: ${props => props.theme.spacing.unit * 2}px;
`;

import React from "react";
import PropTypes from "prop-types";

// Styles
import "./Radio.css";

class Radio extends React.Component {
  static propTypes = {
    /** Comment soon */
    id: PropTypes.string.isRequired,
    /** Comment soon */
    name: PropTypes.string.isRequired,
    /** Comment soon */
    label: PropTypes.string,
    /** Comment soon */
    block: PropTypes.bool
  };

  render() {
    return (
      <label
        className={
          "a-radio " +
          (this.props.block ? "block-xs " : " ") +
          this.props.className
        }
        htmlFor={this.props.id}
      >
        <input
          ref="input"
          className="a-radio__input"
          name={this.props.name}
          id={this.props.id}
          type="radio"
          defaultChecked={this.props.defaultChecked}
          onChange={this.props.onChange}
        />

        <span
          className={"a-radio__radio " + (this.props.label ? "mr-xs-2 " : " ")}
        />

        {this.props.label}
      </label>
    );
  }
}

export default Radio;

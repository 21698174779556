import { fontSize } from "./fontSize";
import { palette } from "./palette";

export const typography = {
  fontSize,
  body1: `
      color: ${palette.grey2};
      font-size: ${fontSize.md};
      letter-spacing: 0.00938em;
      line-height: 1.5;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
    `,
  subtitle1: `
      color: ${palette.grey2};
      font-size: ${fontSize.sm};
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    `,
  subtitle2: `
      color: ${palette.white};
      font-size: ${fontSize.tn};
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.00938em;`
};

import { Typography, Toolbar, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getColor } from "../../theme/Utils";

/**
 * Title of toolbar
 *
 * @param {Boolean} selected  Show title for row selection
 */
export const Title = styled(Typography)`
  && {
    ${props => props.theme.typography.body1};
    ${props =>
      props.selected ? `color: ${getColor(null, props.theme, -0.2)};` : ""};
  }
`;

Title.propTypes = {
  selected: PropTypes.bool
};

/**
 * Title Container of toolbar
 */
export const TitleContainer = styled.div`
  flex: 0 0 auto;
`;

/**
 * Toolbar of table
 *
 * @param {Boolean} selected  Change background title for row selection
 */
export const ToolbarRoot = styled(Toolbar)`
  && {
    padding-right: ${props => props.theme.spacing.unit}px;
    ${props =>
      props.selected
        ? `
        background-color: ${getColor(null, props.theme, 0.85)};
    `
        : ""}
  }
`;

ToolbarRoot.propTypes = {
  selected: PropTypes.bool
};

/**
 * Actions container of table head
 */
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

/**
 * Button add new row
 */
export const ButtonAdd = styled(Button)`
  && {
    text-transform: none;
  }
`;

/**
 * Button label
 */
export const ButtonAddLabel = styled.span`
  margin-right: ${props => props.theme.spacing.unit}px;
`;

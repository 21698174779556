import React from "react";
import styled from "styled-components";
import {
  Button,
  InputAdornment,
  TextField,
  IconButton
} from "@material-ui/core";
import Icon from "../../Icon/Icon";
import CompanyLogo from "../../atoms/company-logo/CompanyLogo";
import { darken } from "@material-ui/core/styles/colorManipulator";

export const RootContainer = styled.div`
  padding: ${props => props.theme.spacing.unit}px 0px;
  background: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.shadows.boxHeader};
`;

export const MenuButton = styled(IconButton)`
  && {
    margin-left: 8px;
  }
`;

export const UploadButtonIcon = styled(Icon)`
  && {
    font-size: ${props => props.theme.size.xxl};
    color: ${props => props.theme.palette.white};
  }
`;

export const UploadButton = styled(props => (
  <Button {...props}>
    <UploadButtonIcon name="cloud-upload" />
  </Button>
))`
  && {
    min-width: 0;
    width: 45px;
    height: 35px;
    color: white;
    background-color: ${props => props.theme.palette.primary};
    :hover {
      background-color: ${props => darken(props.theme.palette.primary, 0.2)};
    }
  }
`;

export const Logo = styled(CompanyLogo)`
  width: 100px;
  height: 30px;
  margin-left: ${props => props.theme.spacing.unit}px;
`;

export const ExpandIcon = styled(Icon)`
  && {
    font-size: ${props => props.theme.size.lg};
    :hover {
      cursor: pointer;
    }
  }
`;

export const Adornment = styled(InputAdornment)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

export const InputSearch = styled(
  ({ className, hasFilters, onClickIconInput, ...other }) => (
    <TextField
      {...other}
      InputProps={{
        classes: { focused: "focused", notchedOutline: "notchedOutline" },
        className,
        endAdornment: (
          <Adornment position="end">
            <Icon
              name={hasFilters ? "clear" : "search"}
              onClick={onClickIconInput}
            />
          </Adornment>
        )
      }}
    />
  )
)`
  && {
    font-size: ${props => props.theme.typography.fontSize.md};
    height: 47px;
    :hover {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    }
  }

  &.focused {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }

  & .notchedOutline {
  }

  &.focused .notchedOutline {
    border-color: ${props => props.theme.palette.primary} !important;
  }
`;

export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.unit * 2}px;
  background-color: ${props => props.theme.palette.grey4};
`;

export const UserName = styled.span`
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing.unit}px;
`;

import Auth from "./../models/AuthModel";

class PermissionHandler {
  static getPermissions = () => {
    const user = Auth.getUser();
    if (user) {
      return user.has_permission;
    } else {
      return {};
    }
  };

  static can_edit = () => {
    return PermissionHandler.getPermissions().can_edit;
  };

  static can_delete = () => {
    return PermissionHandler.getPermissions().can_delete;
  };

  static can_upload = () => {
    return PermissionHandler.getPermissions().can_upload;
  };

  static can_share = () => {
    return PermissionHandler.getPermissions().can_share;
  };

  static can_download = () => {
    return PermissionHandler.getPermissions().can_download;
  };

  static can_view = () => {
    return PermissionHandler.getPermissions().can_view;
  };

  static is_admin = () => {
    return PermissionHandler.getPermissions().is_admin;
  };

  static is_staff = () => {
    return PermissionHandler.getPermissions().is_staff;
  };

  static can_admin_category = () => {
    return PermissionHandler.getPermissions().can_admin_category;
  };
}

export default PermissionHandler;

import React from "react";
import PropTypes from "prop-types";

import "./Icon.css";

export default class Icon extends React.Component {
  static propTypes = {
    /** Comment soon */
    name: PropTypes.string.isRequired,
    /** Comment soon */
    size: PropTypes.oneOf(["small", "medium", "large"]).isRequired
  };

  static defaultProps = {
    size: "medium"
  };

  render() {
    return (
      <i
        {...this.props}
        className={`icon icon-${this.props.name} ${this.props.size} ${
          this.props.className
        }`}
      />
    );
  }
}

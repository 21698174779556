import AxiosHelper from "./../helpers/AxiosHelper";

const RESOURCE = "mediamanager/collections";

const getSearchCollections = params => {
  let url = `/${RESOURCE}?`;

  if (params) {
    Object.keys(params).map(
      key =>
        (url += params[key] || params[key] >= 0 ? `${key}=${params[key]}&` : "")
    );
  }

  return AxiosHelper.doGet(url.substring(0, url.length - 1));
};

const getCollection = uuid => {
  const url = `/${RESOURCE}/${uuid}/`;

  return AxiosHelper.doGet(url);
};

const removeAssetsFromCollection = (uuid, data) => {
  const url = `/${RESOURCE}/${uuid}/remove_assets/`;

  return AxiosHelper.doPost(url, data);
};

const editCollection = (uuid, data) => {
  const url = `/${RESOURCE}/${uuid}/editsingle/`;

  return AxiosHelper.doPost(url, data);
};

const deleteCollection = uuid => {
  const url = `/${RESOURCE}/${uuid}/delete/`;

  return AxiosHelper.doDelete(url);
};

const getSharedCollection = uuid => {
  const url = `/v2/mediamanager/collection/public/${uuid}/`;

  return AxiosHelper.doGet(url);
};

const getSharedCollectionAsset = (uuidCollection, uuidAsset) => {
  const url = `/v2/mediamanager/collection/public/${uuidCollection}/asset/${uuidAsset}`;

  return AxiosHelper.doGet(url);
};

export {
  getSearchCollections,
  getCollection,
  removeAssetsFromCollection,
  editCollection,
  deleteCollection,
  getSharedCollection,
  getSharedCollectionAsset
};

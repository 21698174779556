import { lighten, darken } from "@material-ui/core/styles/colorManipulator";

export const getColor = (value, theme, coefficient) => {
  let color = theme.palette[value] || theme.palette.primary;
  coefficient = coefficient || 0;

  if (String(value).includes("#")) {
    color = value;
  }

  if (coefficient >= 0) {
    return lighten(color, coefficient);
  } else {
    return darken(color, coefficient * -1);
  }
};

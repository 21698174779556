import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { applyFilters } from "../../pages/search-results/_actionsReducers";
import { I18n } from "react-i18nify";
import Waypoint from "react-waypoint";
import { getSearchCollections } from "../../../models/CollectionModel";
import { withRouter } from "react-router-dom";
import {
  Grid
  // Select,
  // MenuItem
} from "@material-ui/core";
import humanize from "humanize";
//import { ExpandMore } from "@material-ui/icons";
import {
  addNewBreadCrumb,
  clearBreadCrumb
} from "./../../molecules/bread-crumb/_actionsReducers";

import { setSearchValue } from "../search-results/_actionsReducers";
import { Container, Header, ResultsCount, Loading } from "./Collections.style";
import CollectionItem from "../../CollectionItem/CollectionItem";

class Collections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      limit: 30,
      order_by: "name",
      optionsOrderBy: [
        { value: "real_name", label: I18n.t("OrderByNameAsc") },
        { value: "-real_name", label: I18n.t("OrderByNameDesc") },
        { value: "-updated_at", label: I18n.t("OrderByDateDesc") },
        { value: "updated_at", label: I18n.t("OrderByDateAsc") },
        {
          value: "-assetinfo__total_file_size",
          label: I18n.t("OrderBySizeDesc")
        },
        {
          value: "assetinfo__total_file_size",
          label: I18n.t("OrderBySizeAsc")
        }
      ]
    };

    props.addNewBreadCrumb([
      {
        label: I18n.t("Collections")
      }
    ]);
  }

  componentDidMount() {
    this.doSearchCollections();
  }

  componentWillUnmount = () => {
    this.props.clearBreadCrumb();
  };

  doSearchCollections = async () => {
    let { collections, limit, start, order_by } = this.state;
    const { isSearchingCollections } = this.props;

    if (isSearchingCollections) {
      return;
    }

    try {
      this.props.setSearchValue({ isSearchingCollections: true });
      start = start || 0;

      let response = await getSearchCollections({
        start,
        limit,
        order_by
      });
      this.setState({
        collections: collections.concat(response.data.data),
        count: response.data.count,
        start: start + limit
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setSearchValue({ isSearchingCollections: false });
    }
  };

  onCollectionClick = (uuid, label) => {
    this.props.applyFilters(
      {
        collections: [{ id: uuid, label }]
      },
      { updateAssets: true, assetViewModeByList: false },
      true
    );
  };

  render() {
    const { collections, count } = this.state;
    const { isSearchingCollections } = this.props;

    if (!isSearchingCollections && collections.length === 0) {
      return null;
    }

    return (
      <Container>
        <Header>
          <ResultsCount>
            {collections.length >= 10000
              ? "10.000+"
              : humanize.numberFormat(count, 0, null, ".")}{" "}
            {I18n.t("ResultsCollections")}
          </ResultsCount>

          {/* <OrderBy>
            <Select
              autoWidth
              disableUnderline
              IconComponent={ExpandMore}
              value={"-updated_at"}
              onChange={() => {}}
            >
              {[...optionsOrderBy].map(op => (
                <MenuItem key={op.value} value={op.value}>
                  <Typography variant="body1">{op.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </OrderBy>*/}
        </Header>
        {Array.isArray(collections) && (
          <>
            <Grid container spacing={16}>
              {collections &&
                collections.map((v, i) => {
                  return (
                    <Grid item xs={9} sm={6} md={4} lg={3} key={i}>
                      <CollectionItem
                        collection={v}
                        onClick={collection =>
                          this.onCollectionClick(
                            collection.uuid,
                            collection.name
                          )
                        }
                      />
                    </Grid>
                  );
                })}
            </Grid>

            {!isSearchingCollections && collections.length === 0 && (
              <p>{I18n.t("EmptyMessageCollections")}</p>
            )}
            {collections.length !== 0 && collections.length < count && (
              <Waypoint onEnter={() => this.doSearchCollections()} />
            )}
            {isSearchingCollections && <Loading size={32} />}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  isSearchingCollections: state.search.isSearchingCollections
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { applyFilters, addNewBreadCrumb, clearBreadCrumb, setSearchValue },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Collections)
);

import React from "react";
import PropTypes from "prop-types";

// Styles
import "./ProgressCircle.css";

class ProgressCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {
        size: 26,
        border: 4,
        progressFull: 56, // (size * 2) + border
        progressFullInPercentage: 0
      },
      inProgress: 56 // = progressFull = 0%
    };

    this.state.progressFullInPercentage =
      this.state.settings.progressFull / 100;
  }

  static propTypes = {
    /** Comment soon */
    loaded: PropTypes.number // 0 - 100
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.inProgress) {
      let loaded =
        this.state.settings.progressFull -
        this.state.progressFullInPercentage * nextProps.inProgress;

      this.setState({
        inProgress: loaded
      });
    }
  };

  render() {
    return (
      <svg
        className="a-progress"
        width={this.state.settings.size}
        height={this.state.settings.size}
      >
        <circle
          cx={this.state.settings.size / 2}
          cy={this.state.settings.size / 2}
          r={this.state.settings.size / 2 - this.state.settings.border}
          strokeDasharray={this.state.settings.progressFull}
          strokeWidth={this.state.settings.border}
          fill="none"
          className="a-progress__path--2"
        />

        <circle
          cx={this.state.settings.size / 2}
          cy={this.state.settings.size / 2}
          r={this.state.settings.size / 2 - this.state.settings.border}
          strokeDasharray={this.state.settings.progressFull}
          strokeWidth={this.state.settings.border}
          strokeDashoffset={this.state.inProgress}
          fill="none"
          className="a-progress__path"
        />
      </svg>
    );
  }
}

export default ProgressCircle;

import React from "react";
import PropTypes from "prop-types";
import VirtualizedSelect from "react-virtualized-select";
import { AsyncCreatable } from "react-select";

// Models
import { getSearchTags } from "./../../../models/TagModel";
import { getSearchCollections } from "./../../../models/CollectionModel";
import {
  getAllGroups,
  getAllLanguageOptions
} from "./../../../models/UserModel";

// Components
import Icon from "./../../_quarks/icon/Icon";

// Styles
import "./FormGroup.css";

import { I18n } from "react-i18nify";

export default class FormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legend: null,
      focus: false,
      focusClass: "",
      selectValueVirtualized: null
    };
  }

  static propTypes = {
    /** Comment soon */
    async: PropTypes.bool,
    /** Comment soon */
    clearable: PropTypes.bool,
    /** Comment soon */
    type: PropTypes.string.isRequired,
    /** Comment soon */
    theme: PropTypes.oneOf(["primary", "negative"]),
    /** Comment soon */
    name: PropTypes.string,
    /** Comment soon */
    label: PropTypes.string,
    /** Comment soon */
    icon: PropTypes.string,
    /** Comment soon */
    loadInVirtualized: PropTypes.oneOf([
      "tags",
      "collections",
      "groups",
      "languages",
      "custom"
    ]),
    /** Comment soon */
    enableToCreateOption: PropTypes.bool,
    /** Comment soon */
    selectComponent: PropTypes.func,
    /** Comment soon */
    status: PropTypes.oneOf(["danger"]),
    /** Comment soon */
    readOnly: PropTypes.bool,
    /** Comment soon */
    multi: PropTypes.bool,
    validation: PropTypes.string,
    legend: PropTypes.string,
    required: PropTypes.bool
  };

  static defaultProps = {
    async: true,
    clearable: true,
    theme: "primary",
    type: "text",
    multi: true,
    validation: null,
    legend: null,
    required: false
  };

  onFocus = e => {
    this.setState({
      focus: true
    });

    if (this.props.onDisableShortcuts) {
      this.props.onDisableShortcuts(true);
    }

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  onBlur = e => {
    this.setState({
      focus: false
    });
    if (this.props.onDisableShortcuts) {
      this.props.onDisableShortcuts(false);
    }

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  onChangeVirtualized = array => {
    this.setState({ selectValueVirtualized: array });

    if (this.props.onChange)
      // Maybe temp
      this.props.onChange(array);
  };

  loadOptionsVirtualized = searchTerm => {
    if (this.props.loadInVirtualized === "tags") {
      return getSearchTags({ query: searchTerm })
        .then(response => {
          let allTags = [];

          response.data.data.forEach(tag => {
            allTags.push({ value: tag.slug, label: tag.slug, id: tag.uuid });
          });

          return allTags;
        })
        .then(allTags => {
          return { options: allTags };
        });
    }

    if (this.props.loadInVirtualized === "collections") {
      return getSearchCollections({ query: searchTerm })
        .then(response => {
          let allCollections = [];

          response.data.data.forEach(collection => {
            allCollections.push({
              value: collection.name,
              label: collection.name,
              id: collection.uuid
            });
          });

          return allCollections;
        })
        .then(allCollections => {
          return { options: allCollections };
        });
    }

    if (this.props.loadInVirtualized === "groups") {
      return getAllGroups()
        .then(response => {
          let allGroups = [];

          response.data.data.forEach(group => {
            allGroups.push({
              value: group.name,
              label: group.name,
              id: group.id
            });
          });

          return allGroups;
        })
        .then(allGroups => {
          return { options: allGroups };
        });
    }

    if (this.props.loadInVirtualized === "languages") {
      return getAllLanguageOptions()
        .then(response => {
          let dataArray = [];
          response.data.forEach(function(lang) {
            dataArray.push({ value: lang[0], label: lang[1] });
          });
          return dataArray;
        })
        .then(dataArray => {
          return { options: dataArray };
        });
    }

    if (this.props.loadInVirtualized === "custom") {
      return new Promise(() => {
        let optionsCustom = [
          {
            value: "selecao1",
            label: "Seleção"
          },
          {
            value: "selecao2",
            label: "Seleção2"
          }
        ];

        //debugger;

        return { options: optionsCustom };
        // resolve();
      });
    }
  };

  componentWillMount = () => {
    if (this.props.type === "virtualized") {
      if (this.props.defaultValue) {
        this.setState({ selectValueVirtualized: this.props.defaultValue });
      }
    }
  };

  onKeyPress = e => {
    const { numberType, value } = this.props;

    if (numberType === "float") {
      if (
        (e.key === "," && (String(value).indexOf(",") !== -1 || !value)) ||
        !/[,0-9]/.test(e.key)
      ) {
        e.preventDefault();
      }
    } else if (
      numberType === "int" &&
      (!/^[0-9]/.test(e.key) ||
        String(e.target.value).replace("-", "").length === 10)
    ) {
      e.preventDefault();
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(e);
    }
  };

  onChange = e => {
    if (this.props.type === "number" && e.target.value > 2147483647) {
      e.target.value = "2147483647";
    }
    if (this.props.type === "number" && e.target.value < -2147483647) {
      e.target.value = "-2147483647";
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    let focusClass = "";

    if (this.state.focus) {
      focusClass = "focus ";
    }

    return (
      <div
        className={
          "m-formGroup " +
          this.props.status +
          " " +
          focusClass +
          " " +
          this.props.theme +
          " " +
          this.props.className
        }
        onClick={this.onFocusGlobal}
      >
        {this.props.label && (
          <label className="m-formGroup__label" htmlFor={this.props.name}>
            {this.props.label}
          </label>
        )}

        {this.props.type === "virtualized" && (
          <div
            className={
              "m-formGroup__globalFormField " +
              (this.props.showBorderOptions ? " border " : "")
            }
          >
            <VirtualizedSelect
              clearable={this.props.clearable}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              ref="input"
              placeholder={
                this.props.placeholder || I18n.t("ActionPlaceholderSelect")
              }
              labelLoading="Lolo"
              multi={this.props.multi}
              onChange={this.onChangeVirtualized.bind(this)}
              value={this.state.selectValueVirtualized}
              async={this.props.async}
              options={this.props.options}
              loadOptions={this.loadOptionsVirtualized.bind(this)}
              selectComponent={
                this.props.enableToCreateOption ? AsyncCreatable : null
              }
            />

            <Icon
              className="m-formGroup__icon Select-arrow-down"
              name="expand"
            />
          </div>
        )}

        {this.props.type !== "virtualized" && (
          <div className="m-formGroup__globalFormField">
            {this.props.icon && (
              <Icon className="m-formGroup__icon" name={this.props.icon} />
            )}

            <input
              ref="input"
              className="m-formGroup__formField"
              type={this.props.type}
              key={this.props.name}
              name={this.props.name}
              id={this.props.name}
              placeholder={this.props.placeholder}
              defaultValue={this.props.defaultValue}
              value={this.props.value}
              onFocus={this.onFocus.bind(this)}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
              readOnly={this.props.readOnly}
              step={this.props.step}
            />
          </div>
        )}

        {this.props.status === "danger" && (
          <small className="m-formGroup__legend">{this.props.legend}</small>
        )}
      </div>
    );
  }
}

import AxiosHelper from "./../helpers/AxiosHelper";

const RESOURCE = "users";

const getToken = data => {
  const url = `/api-token-auth/`;

  return AxiosHelper.doGetToken(url, data);
};

const doLogin = data => {
  const url = `/${RESOURCE}/auth/`;

  return AxiosHelper.doPost(url, data);
};

const loginWithGoogle = data => {
  const url = `/${RESOURCE}/google/`;

  return AxiosHelper.doPost(url, data);
};

const getAllGroups = () => {
  const url = `/${RESOURCE}/group/`;

  return AxiosHelper.doGet(url);
};

const getUser = uuid => {
  const url = `/${RESOURCE}/profile/${uuid}/details/`;

  return AxiosHelper.doGet(url);
};

const getAllUsers = () => {
  const url = `/${RESOURCE}/profile/`;

  return AxiosHelper.doGet(url);
};

const editUser = (data, uuid) => {
  const url = `/${RESOURCE}/profile/${uuid}/edit/`;

  return AxiosHelper.doPost(url, data);
};

const editPassword = (data, uuid) => {
  const url = `/${RESOURCE}/profile/${uuid}/edit_password/`;

  return AxiosHelper.doPost(url, data);
};

const getSettings = uuid => {
  const url = `/${RESOURCE}/settings/${uuid}/details/`;

  return AxiosHelper.doGet(url);
};

const editSettings = (data, uuid) => {
  const url = `/${RESOURCE}/settings/${uuid}/edit/`;

  return AxiosHelper.doPost(url, data);
};

const deleteUser = uuid => {
  const url = `/${RESOURCE}/profile/delete/`;

  return AxiosHelper.doPost(url, uuid);
};

const createUser = data => {
  const url = `/${RESOURCE}/profile/new/`;

  return AxiosHelper.doPost(url, data);
};

const getAllPermissions = data => {
  const url = `/${RESOURCE}/users/permissions_assetify/`;

  return AxiosHelper.doGet(url, data);
};

const getAllLanguageOptions = () => {
  const url = `/${RESOURCE}/users/language/`;

  return AxiosHelper.doGet(url);
};

export {
  loginWithGoogle,
  doLogin,
  getToken,
  getAllGroups,
  getAllUsers,
  getUser,
  editUser,
  deleteUser,
  editPassword,
  editSettings,
  getSettings,
  createUser,
  getAllPermissions,
  getAllLanguageOptions
};

import React, { Component } from "react";
import PropTypes from "prop-types";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getAssetDetails } from "./_actionsReducers";
import * as alertActions from "./../../molecules/alert/_actionsReducers";

// Models
import { deleteAsset } from "./../../../models/AssetModel";
import { removeAssetsFromCollection } from "./../../../models/CollectionModel";

// Components
import Box from "./../../_quarks/box/Box";
import Icon from "./../../_quarks/icon/Icon";
import IconText from "./../..//atoms/icon-text/IconText";

import Button from "./../../atoms/button/Button";
import ProgressCircle from "./../../atoms/progress-circle/ProgressCircle";

// Images
import imgBlank from "./../../../imgs/blank-3x2.png";

// Translate
import { I18n } from "react-i18nify";

import { msToTime } from "../../../helpers/HumanizeHelper";

// Styles
import "./AssetItem.css";
const humanize = require("humanize");

class AssetItem extends Component {
  static propTypes = {
    /** Comment soon */
    sample: PropTypes.bool,
    /** Comment soon */
    inProgress: PropTypes.number, // 0 - 100
    /** Comment soon */
    onClickDelete: PropTypes.func,
    /** Comment soon */
    onClickRetry: PropTypes.func
  };

  onAssetClick = params => {
    if (!this.props.sample && this.props.onAssetClick) {
      //this.props.getAssetDetails({ id: this.props.data.uuid })
      this.props.onAssetClick();
    }
  };

  onDeleteAsset = () => {
    let idAssetForRemove = this.props.data.uuid;

    this.props.addAlert({
      id: "alertToConfirmDeleteAsset",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title: I18n.t("TitleAlertWhatDoWantToDo"),
      description: I18n.t("CantUndoThisAction"),
      actionButtons: [
        {
          icon: "delete",
          label: I18n.t("ActionRemoveAssetOfCollection"),
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onRemoveAssetFromCollection.bind(this, idAssetForRemove)
        },
        {
          icon: "delete",
          label: I18n.t("ActionDeleteAssetPermanently"),
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onDeleteAssetPermanently.bind(this, idAssetForRemove)
        },
        {
          label: I18n.t("ActionDontMakeNothing"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDeleteAsset")
        }
      ]
    });
  };

  onDeleteAssetPermanently = idAssetForRemove => {
    return new Promise(resolve => {
      // Is promise because the button loading
      deleteAsset(idAssetForRemove).then(response => {
        document.getElementById(
          "colAssetItem__" + idAssetForRemove
        ).style.display = "none";

        this.props.addAlert({
          id: "alertToConfirmDeleteAsset",
          theme: "success",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleAlertAssetDeleted")
        });

        resolve();
      });
    });
  };

  onRemoveAssetFromCollection = idAssetForRemove => {
    let convertedIdAssetForArray = new Array(idAssetForRemove);
    let idCollectionOfAsset = this.props.searchFilters.filters.collections[0];

    let dataForApi = {
      remove: convertedIdAssetForArray
    };

    return new Promise(resolve => {
      // Is promise because the button loading
      removeAssetsFromCollection(idCollectionOfAsset, dataForApi)
        .then(response => {
          document.getElementById(
            "colAssetItem__" + idAssetForRemove
          ).style.display = "none";

          this.props.addAlert({
            id: "alertToConfirmDeleteAsset",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("TitleAlertAssetRemovedFromCollection")
          });

          resolve();
        })
        .catch(error => {
          resolve();
        });
    });
  };

  onUploadDeleteAsset = params => {
    this.props.onClickDelete(
      this.props.data.uuid,
      this.props.data.asset_type[0]
    );
  };

  render() {
    let thumb = null;

    if (this.props.data.asset_type[1] === "image") {
      const { data } = this.props;

      let srcImage = null;

      if (this.props.sample) {
        //Upload
        thumb = <Icon className="m-assetItem__thumb-media icon" name="image" />;
      } else {
        if (data.size_url && data.resized) {
          srcImage = data.size_url["thumbnail-small"] || data.size_url.original;
          thumb = (
            <img
              className="m-assetItem__thumb-media image"
              src={srcImage}
              alt={this.props.data.name}
            />
          );
        } else {
          thumb = (
            <IconText
              classNameIcon="m-assetItem__thumb-media icon-image"
              icon="image"
              classNameText="m-assetItem__thumb-media text"
              text={I18n.t("LabelProcessing")}
            />
          );
        }
      }
    } else if (this.props.data.asset_type[1] === "audio") {
      thumb = <Icon className="m-assetItem__thumb-media icon" name="audio" />;
    } else if (this.props.data.asset_type[1] === "video") {
      thumb = <Icon className="m-assetItem__thumb-media icon" name="video" />;
    } else if (
      this.props.data.asset_type[1] === "application" &&
      this.props.data.asset_type[2] === ".pdf"
    ) {
      thumb = (
        <Icon className="m-assetItem__thumb-media icon icon-pdf" name="pdf" />
      );
    } else {
      thumb = <Icon className="m-assetItem__thumb-media icon" name="file" />;
    }

    return (
      <div
        className={
          "m-assetItem " +
          (!this.props.sample ? "clickable " : " ") +
          (this.props.enableToDeleteAsset ? "my-xs-3 " : "mb-xs-3 ")
        }
      >
        <Box
          className={
            "m-assetItem__box p-xs-2 " +
            (this.props.sample && this.props.inProgress !== 100
              ? "inactive "
              : " ")
          }
        >
          <div
            className="m-assetItem__thumb"
            onClick={this.onAssetClick.bind(this)}
          >
            {thumb}
            <img className="m-assetItem__thumb-blank" src={imgBlank} alt="" />
          </div>

          <div className="m-assetItem__infos">
            <div className="m-assetItem__text">
              <h3
                className="m-assetItem__title"
                onClick={this.onAssetClick.bind(this)}
              >
                {String(this.props.data.real_name).replace(
                  this.props.data.asset_type[2],
                  ""
                )}
              </h3>

              <span className="m-assetItem__legend">
                {this.props.data.asset_type[2]} -{" "}
                {humanize.filesize(this.props.data.file_size)}
                {(this.props.data.asset_type[1] === "audio" ||
                  this.props.data.asset_type[1] === "video") &&
                this.props.data.duration
                  ? " - " + msToTime(this.props.data.duration)
                  : ""}
              </span>
            </div>

            <div className="m-assetItem__icons">
              {!this.props.sample &&
                this.props.data.smart_search &&
                this.props.data.is_ready && (
                  <Icon className="m-assetItem__icon" name="brain" />
                )}

              {this.props.data.time_stamp &&
                this.props.data.time_stamp !== "[]" && (
                  <Icon
                    className="m-assetItem__icon ml-xs-2"
                    name="announcement"
                  />
                )}

              {this.props.sample && this.props.inProgress !== 100 && (
                <ProgressCircle inProgress={this.props.inProgress} />
              )}

              {this.props.sample && this.props.inProgress === 100 && (
                <Icon name="check" className="color-states-success" />
              )}
            </div>
          </div>
        </Box>

        {(this.props.sample && this.props.inProgress === 100) ||
          (this.props.enableToDeleteAsset && (
            <Button
              type="button"
              icon="delete"
              width="min"
              theme="light"
              size="small"
              className="m-assetItem__remove"
              onClick={this.onDeleteAsset.bind(this, this.props.data.uuid)}
            />
          ))}

        {this.props.sample && this.props.inProgress === 100 && (
          <Button
            type="button"
            icon="delete"
            width="min"
            theme="light"
            size="small"
            className="m-assetItem__remove"
            onClick={this.onUploadDeleteAsset.bind(this)}
          />
        )}

        {this.props.sample && this.props.error > 3 && (
          <Button
            type="button"
            icon="retry"
            width="min"
            theme="light"
            size="small"
            className="m-assetItem__retry"
            onClick={() => this.props.onClickRetry(this.props.data.uuid)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchFilters: state.searchFilters,
  enableToDeleteAsset: state.enableToDeleteAsset
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAssetDetails,
      ...alertActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetItem);

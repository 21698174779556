import React from "react";
import PropTypes from "prop-types";

import "./Col.css";

const optionsNumbersColumns = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12"
];
const optionsOffsetColumns = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11"
];

export default class Col extends React.Component {
  constructor(props) {
    super(props);

    this.classXsColumns = this.props.xs ? "col-xs-" + this.props.xs + " " : ""; // Example: .col-xs-6
    this.classSmColumns = this.props.sm ? "col-sm-" + this.props.sm + " " : ""; // Example: .col-sm-6
    this.classMdColumns = this.props.md ? "col-md-" + this.props.md + " " : ""; // Example: .col-md-6
    this.classLgColumns = this.props.lg ? "col-lg-" + this.props.lg + " " : ""; // Example: .col-lg-6
    this.classXlgColumns = this.props.xlg
      ? "col-xlg-" + this.props.xlg + " "
      : ""; // Example: .col-xlg-6
    this.classesColumns =
      this.classXsColumns +
      this.classSmColumns +
      this.classMdColumns +
      this.classLgColumns +
      this.classXlgColumns;

    this.classXsOffset = this.props.xsOffset
      ? "colOffset-xs-" + this.props.xsOffset + " "
      : ""; // Example: .colOffset-xs-6
    this.classSmOffset = this.props.smOffset
      ? "colOffset-sm-" + this.props.smOffset + " "
      : ""; // Example: .colOffset-sm-6
    this.classMdOffset = this.props.mdOffset
      ? "colOffset-md-" + this.props.mdOffset + " "
      : ""; // Example: .colOffset-md-6
    this.classLgOffset = this.props.lgOffset
      ? "colOffset-lg-" + this.props.lgOffset + " "
      : ""; // Example: .colOffset-lg-6
    this.classXlgOffset = this.props.xlgOffset
      ? "colOffset-xlg-" + this.props.xlgOffset + " "
      : ""; // Example: .colOffset-xlg-6
    this.classesOffset =
      this.classXsOffset +
      this.classSmOffset +
      this.classMdOffset +
      this.classLgOffset +
      this.classXlgOffset;
  }

  static propTypes = {
    /** Numbers of columns in breakpoint XS */
    xs: PropTypes.oneOf(optionsNumbersColumns),
    /** Numbers of columns in breakpoint SM */
    sm: PropTypes.oneOf(optionsNumbersColumns),
    /** Numbers of columns in breakpoint MD */
    md: PropTypes.oneOf(optionsNumbersColumns),
    /** Numbers of columns in breakpoint LG */
    lg: PropTypes.oneOf(optionsNumbersColumns),
    /** Numbers of columns in breakpoint XLG */
    xlg: PropTypes.oneOf(optionsNumbersColumns),
    /** Offset in breakpoint XS */
    xsOffset: PropTypes.oneOf(optionsOffsetColumns),
    /** Offset in breakpoint SM */
    smOffset: PropTypes.oneOf(optionsOffsetColumns),
    /** Offset in breakpoint MD */
    mdOffset: PropTypes.oneOf(optionsOffsetColumns),
    /** Offset in breakpoint LG */
    lgOffset: PropTypes.oneOf(optionsOffsetColumns),
    /** Offset in breakpoint XLG */
    xlgOffset: PropTypes.oneOf(optionsOffsetColumns)
  };

  static defaultProps = {
    xs: "12"
  };

  render() {
    return (
      <div
        className={
          this.classesColumns + this.classesOffset + this.props.className
        }
        id={this.props.id}
      >
        {this.props.children}
      </div>
    );
  }
}

import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-i18nify";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import {
  ActionsContainer,
  Title,
  TitleContainer,
  ToolbarRoot,
  ButtonAdd,
  ButtonAddLabel
} from "./TableToolBar.style";

/**
 * Toolbar for the Apollo Table
 *
 * @param {String}      title               Title of table
 * @param {String}      labelAdd            Label for button add new row
 * @param {Number}      numSelected         Number of selected rows
 * @param {Funcion}     handleAddRow        Handle add new row on table.
 * @param {Funcion}     handleDeleteRow     Handle delete row of table. Params(ids: {Array} - Row ids)
 */
const TableToolbarStyled = props => {
  const { numSelected, handleAddRow, handleDeleteRow, title, labelAdd } = props;

  return (
    <ToolbarRoot selected={numSelected > 0}>
      <TitleContainer>
        {numSelected > 0 ? (
          <Title selected>
            {numSelected} {I18n.t("Selected")}
          </Title>
        ) : (
          <Title>{title}</Title>
        )}
      </TitleContainer>
      <ActionsContainer>
        {numSelected > 0 && (
          <Tooltip title={I18n.t("Delete")}>
            <IconButton onClick={handleDeleteRow}>
              <Icon name="delete" />
            </IconButton>
          </Tooltip>
        )}
        {!numSelected && (
          <Tooltip title={I18n.t("Add")}>
            <ButtonAdd onClick={handleAddRow} typography="body1">
              {labelAdd && <ButtonAddLabel>{labelAdd}</ButtonAddLabel>}
              <Icon name="add" />
            </ButtonAdd>
          </Tooltip>
        )}
      </ActionsContainer>
    </ToolbarRoot>
  );
};

TableToolbarStyled.propTypes = {
  title: PropTypes.string,
  labelAdd: PropTypes.string,
  numSelected: PropTypes.number,
  handleDeleteRow: PropTypes.func,
  handleAddRow: PropTypes.func
};

TableToolbarStyled.defaultProps = {
  handleDeleteRow: () => {},
  handleAddRow: () => {}
};

export default TableToolbarStyled;

import axios from "axios";

class ChunkModel {
  constructor(file, url, updateProgress, updateError, updateUploaded) {
    this.file = file;
    this.url = url;
    this.sliceSize = 2048 * 1024;
    this.totalChunks = Math.ceil(file.size / this.sliceSize);
    this.updateProgress = updateProgress;
    this.updateUploaded = updateUploaded;
    this.updateError = updateError;
    this.error = 0;
    this.nChunks = 0;
  }

  _chunk = async start => {
    let lastChunk = false;
    let end = start + this.sliceSize;
    if (this.file.size - end < 0) {
      end = this.file.size;
      lastChunk = true;
    }
    let slice = this.file.slice(start, end);

    let api = axios.create({
      timeout: 300000,
      headers: {
        "Content-Type": this.file.type,
        "Content-Range":
          "bytes " + start + "-" + (end - 1) + "/" + this.file.size
      }
    });
    let contProgress = (this.nChunks * 100) / this.totalChunks;

    this.nChunks += 1;

    console.log(this.url);

    try {
      let response = await api.put(this.url, slice);

      if (lastChunk || response.status === 200) {
        this.updateUploaded(response.data);
      } else {
        this.updateProgress(contProgress);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 308) {
        this.updateProgress(contProgress);
      } else {
        this.error++;
        this.updateError(error);
        if (this.error <= 3) {
          this._chunk(start);
        }
      }
    }
  };

  chunk = async () => {
    let start = 0;
    for (let i = 0; i < this.totalChunks; i++) {
      await this._chunk(start);
      start += this.sliceSize;
    }
  };
}

export default ChunkModel;

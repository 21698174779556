import React, { Component } from "react";
import Waypoint from "react-waypoint";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as assetItemActions from "./../../molecules/asset-item/_actionsReducers";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import { setSearchValue } from "../../pages/search-results/_actionsReducers";

// Components
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Loader from "./../../_quarks/loader/Loader";
import Icon from "./../../_quarks/icon/Icon";
import IconText from "./../../atoms/icon-text/IconText";
import Timestamp from "./../../atoms/timestamp/Timestamp";
import NewTimestamp from "./../../atoms/new-timestamp/NewTimestamp";
import SquareLabel from "./../../atoms/square-label/SquareLabel";

import TitleSection from "./../../molecules/title-section/TitleSection";
import AssetItem from "./../../molecules/asset-item/AssetItem";
import Table from "./../../molecules/table/Table";

import AssetModal from "./../../organisms/asset-modal/AssetModal";

import { msToTime } from "../../../helpers/HumanizeHelper";
// Style
import "./Assets.css";

// Translate
import { I18n } from "react-i18nify";
import { getSearchAssets } from "../../../models/AssetModel";
import { Zoom } from "@material-ui/core";

const humanize = require("humanize");

class Assets extends Component {
  state = {
    assetDetails: {},
    assets: {
      nextPageAssets: 1,
      assetsPerPage: 30,
      assetsFound: []
    },
    searchTerms: []
  };

  componentDidMount() {
    this.doSearchAssets();
  }

  componentDidUpdate() {
    if (this.props.updateAssets) {
      this.props.setSearchValue({ updateAssets: false });
      this.doSearchAssets();
    }
  }

  onAssetClick = assetDetails => {
    this.setState({
      showAssetModal: true,
      assetDetails
    });
  };

  doSearchAssets = async fetchMore => {
    let totalAssets = 0;
    let { assets } = this.state;
    const { isSearchingAssets, filters } = this.props;

    if (isSearchingAssets) {
      return;
    }

    try {
      this.props.setSearchValue({ isSearchingAssets: true });

      if (!fetchMore) {
        assets.nextPageAssets = 1;
        assets.assetsFound = [];
      }

      let response = await getSearchAssets(
        filters,
        assets.nextPageAssets,
        assets.assetsPerPage
      );

      if (response.data.page) {
        assets.nextPageAssets = response.data.page.next_page;
      }
      totalAssets = response.data.total;

      if (fetchMore) {
        assets.assetsFound = assets.assetsFound.concat(response.data.data);
      } else {
        assets.assetsFound = response.data.data;
      }

      this.setState({
        assets: assets,
        searchTerms: response.data.search_terms
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        assets.assetsFound = [];
        this.setState({ assets: assets });
      } else {
        console.error(error);
      }
    } finally {
      this.props.setSearchValue({ isSearchingAssets: false, totalAssets });
    }
  };

  _nextPageAssets = async () => {
    if (this.state.assets.nextPageAssets) {
      await this.doSearchAssets(true);
    }
  };

  refreshPage = () => {
    const { filters } = this.props;

    let updateCollections = filters.collections.length === 1;
    let updateTags = filters.tags.length === 1;

    this.props.setSearchValue({
      updateAssets: true,
      updateCollections,
      updateTags
    });
  };

  setAssetByIndex = index => {
    const { assets } = this.state;
    try {
      this.setState({
        assetDetails: {
          uuid: assets.assetsFound[index].uuid,
          index
        }
      });
    } catch (error) {
      return error;
    }
  };

  hideAssetModal = ({ assetUpdated }) => {
    this.setState({ showAssetModal: false, assetDetails: {} });
    if (assetUpdated) {
      this.refreshPage();
    }
  };

  callBackTimestamp = (
    indexAssetFound,
    assetId,
    assetVideoTags,
    keyVideoTag
  ) => {
    this.onAssetClick({
      index: indexAssetFound,
      uuid: assetId,
      timeshots: assetVideoTags[keyVideoTag][0].start_time,
      timeshotsText: keyVideoTag
    });
  };

  clearZoomTimeout = () => {
    if (this.zoomTimeout) {
      clearTimeout(this.zoomTimeout);
    }
  };

  render() {
    const { assets, searchTerms } = this.state;
    const {
      filters,
      assetViewModeByList,
      noTitle,
      isSearchingAssets
    } = this.props;

    return (
      <section className={"o-assets " + this.props.className}>
        {this.state.showAssetModal && (
          <AssetModal
            {...this.state.assetDetails}
            setAssetByIndex={this.setAssetByIndex}
            getMoreAssets={this._nextPageAssets}
            hasMoreAssets={assets.nextPageAssets}
            totalAssets={assets.assetsFound.length - 1}
            hideAssetModal={this.hideAssetModal}
          />
        )}

        {!noTitle && <TitleSection title="Assets" icon="copy" />}

        {assets.assetsFound.length !== 0 && (
          <div>
            {!assetViewModeByList && (
              <Row className="o-assets__row">
                {assets.assetsFound.map((asset, indexAssetFound) => (
                  <Col
                    id={"colAssetItem__" + asset.uuid}
                    key={asset.uuid}
                    className="o-assets__col"
                  >
                    <AssetItem
                      data={asset}
                      onAssetClick={() =>
                        this.onAssetClick({
                          index: indexAssetFound,
                          uuid: asset.uuid
                        })
                      }
                    />
                  </Col>
                ))}
              </Row>
            )}

            {assetViewModeByList && (
              <Table className="mb-xs-3">
                <thead>
                  <tr>
                    <th>{I18n.t("AssetName")}</th>

                    {filters.q && (
                      <th className="o-assets__viewModeByList-globalTimestamp">
                        {I18n.t("AssetDetailContent")}
                      </th>
                    )}

                    <th>{I18n.t("AssetDetailUpload")}</th>
                  </tr>
                </thead>

                <tbody>
                  {assets.assetsFound.map((asset, indexAssetFound) => (
                    <tr key={indexAssetFound}>
                      <td>
                        <div
                          className="o-assets__viewModeByList-thumb"
                          onClick={() =>
                            this.onAssetClick({
                              index: indexAssetFound,
                              uuid: asset.uuid
                            })
                          }
                          onMouseOver={() => {
                            if (asset.asset_type[1] === "image") {
                              this.zoomTimeout = setTimeout(
                                () =>
                                  this.setState({
                                    assetZoom: asset.uuid
                                  }),
                                500
                              );
                            }
                          }}
                          onMouseOut={this.clearZoomTimeout}
                        >
                          {asset.asset_type[1] === "image" && (
                            <React.Fragment>
                              {asset.resized &&
                                this.state.assetZoom !== asset.uuid && (
                                  <img
                                    src={
                                      asset.size_url
                                        ? asset.size_url["thumbnail-small"] ||
                                          asset.size_url.original
                                        : asset.size_url.original
                                    }
                                    alt={asset.name}
                                    className="o-assets__viewModeByList-media image"
                                  />
                                )}

                              {asset.resized &&
                                this.state.assetZoom === asset.uuid && (
                                  <Zoom in={true} timeout={300}>
                                    <img
                                      onMouseOut={() => {
                                        this.setState({ assetZoom: null });
                                        this.clearZoomTimeout();
                                      }}
                                      src={
                                        asset.size_url
                                          ? asset.size_url["small"] ||
                                            asset.size_url.original
                                          : asset.size_url.original
                                      }
                                      alt={asset.name}
                                      className="o-assets__viewModeByList-image2"
                                    />
                                  </Zoom>
                                )}
                              {!asset.resized && (
                                <div className="o-assets__viewModeByList-media">
                                  <IconText
                                    classNameIcon="icon-image"
                                    icon="image"
                                    classNameText="text"
                                    text={I18n.t("LabelProcessing")}
                                  />
                                </div>
                              )}
                            </React.Fragment>
                          )}

                          {asset.asset_type[1] === "audio" && (
                            <Icon
                              name="audio"
                              className="o-assets__viewModeByList-media icon"
                            />
                          )}

                          {asset.asset_type[1] === "video" && (
                            <Icon
                              name="video"
                              className="o-assets__viewModeByList-media icon"
                            />
                          )}

                          {asset.asset_type[1] === "application" &&
                            asset.asset_type[2] === ".pdf" && (
                              <Icon
                                name="pdf"
                                className="o-assets__viewModeByList-media icon icon-pdf"
                              />
                            )}

                          {asset.asset_type[1] !== "image" &&
                            asset.asset_type[1] !== "audio" &&
                            asset.asset_type[1] !== "video" &&
                            asset.asset_type[2] !== ".pdf" && (
                              <Icon
                                name="file"
                                className="o-assets__viewModeByList-media icon"
                              />
                            )}
                        </div>

                        <div
                          className="o-assets__viewModeByList-title"
                          onClick={() =>
                            this.onAssetClick({
                              index: indexAssetFound,
                              uuid: asset.uuid
                            })
                          }
                        >
                          <div className="mb-xs-1">
                            <strong>
                              {" "}
                              {String(asset.real_name).replace(
                                asset.asset_type[2],
                                ""
                              )}
                            </strong>
                          </div>

                          <span className="o-assets__viewModeByList-assetLegend">
                            {asset.asset_type[2]} -{" "}
                            {humanize.filesize(asset.file_size)}
                            {(asset.asset_type[1] === "audio" ||
                              asset.asset_type[1] === "video") &&
                            asset.duration
                              ? " - " + msToTime(asset.duration)
                              : ""}
                          </span>
                        </div>
                      </td>

                      {filters.q && (
                        <td className="o-assets__viewModeByList-globalTimestamp">
                          {asset.square_position &&
                            asset.square_position.texts &&
                            asset.square_position.texts.map((text, index) => (
                              <SquareLabel
                                key={index}
                                text={text.text}
                                withIcon
                                className="my-xs-1 mr-xs-2"
                                onClick={() =>
                                  this.onAssetClick({
                                    index: indexAssetFound,
                                    uuid: asset.uuid,
                                    square: {
                                      text: text.text,
                                      vertices: text.square.vertices
                                    }
                                  })
                                }
                              />
                            ))}

                          {asset.square_position &&
                            asset.square_position.logos &&
                            asset.square_position.logos.map((logo, index) => (
                              <SquareLabel
                                key={index}
                                text={logo.logo}
                                className="my-xs-1 mr-xs-2"
                                withIcon
                                onClick={() =>
                                  this.onAssetClick({
                                    index: indexAssetFound,
                                    uuid: asset.uuid,
                                    square: {
                                      text: logo.logo,
                                      vertices: logo.square.vertices
                                    }
                                  })
                                }
                              />
                            ))}

                          {asset.square_position &&
                            asset.square_position.landmarks &&
                            asset.square_position.landmarks.map(
                              (landmark, index) => (
                                <SquareLabel
                                  key={index}
                                  text={
                                    landmark.landmark ||
                                    (Array.isArray(landmark.info) &&
                                    landmark.info.length > 0
                                      ? landmark.info[0]
                                      : "")
                                  }
                                  withIcon
                                  className="my-xs-1 mr-xs-2"
                                  onClick={() =>
                                    this.onAssetClick({
                                      index: indexAssetFound,
                                      uuid: asset.uuid,
                                      square: {
                                        text: landmark.landmark,
                                        vertices: landmark.square.vertices
                                      }
                                    })
                                  }
                                />
                              )
                            )}

                          {asset.asset_type[1] === "application" &&
                            asset.asset_type[2] === ".pdf" &&
                            asset.content_list &&
                            asset.content_list.length > 0 && (
                              <div className="my-xs-2">
                                <p className="mb-xs-1">
                                  {I18n.t("FoundInThePdf")}:
                                </p>

                                {asset.content_list.map((content, index) => (
                                  <SquareLabel
                                    key={index}
                                    text={content.q}
                                    count={content.count}
                                    className="my-xs-1 mr-xs-2"
                                    onClick={() =>
                                      this.onAssetClick({
                                        index: indexAssetFound,
                                        uuid: asset.uuid,
                                        searchInPdf: { query: content.q }
                                      })
                                    }
                                  />
                                ))}
                              </div>
                            )}

                          {asset.time_stamp && asset.time_stamp.length > 0 && (
                            <div className="my-xs-2">
                              <p>Timestamp áudio:</p>

                              <Row>
                                {asset.time_stamp.map((item, index) => (
                                  <Col key={index} lg="6">
                                    <Timestamp
                                      time={item.time}
                                      text={item.text}
                                      searchTerm={searchTerms}
                                      onClick={() =>
                                        this.onAssetClick({
                                          index: indexAssetFound,
                                          uuid: asset.uuid,
                                          timestamp: item.time,
                                          timestampText: item.text
                                        })
                                      }
                                      className="my-xs-1"
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          )}

                          {Object.keys(asset.video_tags).length !== 0 && (
                            <div className="my-xs-2">
                              <p className="mb-xs-1">
                                {I18n.t("FoundInVideoImage")}:
                              </p>

                              <Row>
                                {Object.keys(asset.video_tags).map(
                                  (key, index) => (
                                    <Col key={index} lg="6">
                                      <NewTimestamp
                                        icon="videostamp"
                                        label={key}
                                        searchTerm={searchTerms}
                                        times={asset.video_tags[key]}
                                        amount={asset.video_tags[key].length}
                                        className="my-xs-1"
                                        callBackTimestamp={this.callBackTimestamp.bind(
                                          this,
                                          indexAssetFound,
                                          asset.uuid,
                                          asset.video_tags,
                                          key
                                        )}
                                      />
                                    </Col>
                                  )
                                )}
                              </Row>
                            </div>
                          )}
                        </td>
                      )}

                      <td>
                        {asset.created_at &&
                          humanize.date("d/m/Y", new Date(asset.created_at))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}

        {!isSearchingAssets && assets.assetsFound.length === 0 && (
          <p>{I18n.t("EmptyMessageAssets")}</p>
        )}

        {assets.assetsFound.length !== 0 &&
          !isSearchingAssets &&
          assets.nextPageAssets && <Waypoint onEnter={this._nextPageAssets} />}

        {isSearchingAssets && <Loader />}
      </section>
    );
  }
}

const mapStateToProps = state => ({
  showAssetModal: state.assetDetails.showAssetModal,
  assetDetails: state.assetDetails,
  assetViewModeByList: state.search.assetViewModeByList,
  filters: state.search.filters,
  isSearchingAssets: state.search.isSearchingAssets,
  updateAssets: state.search.updateAssets
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...assetItemActions, ...alertActions, setSearchValue },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Assets);

import React, { Component } from "react";
import { Link } from "react-router-dom";

import Icon from "./../../_quarks/icon/Icon";

// Styles
import "./BreadCrumb.css";

class BreadCrumb extends Component {
  render() {
    return (
      <ul className={"m-breadCrumb " + this.props.className}>
        <li className="m-breadCrumb__item">
          <Link className="m-breadCrumb__link" to="/">
            <Icon name="home" />
          </Link>

          <Icon name="right-1" className="m-breadCrumb__arrow" />
        </li>

        {this.props.items.map((item, index) => (
          <li key={index} className="m-breadCrumb__item">
            {item.link && (
              <Link className="m-breadCrumb__link" to={item.link}>
                {item.label}
              </Link>
            )}

            {!item.link && item.label}

            <Icon name="right-1" className="m-breadCrumb__arrow" />
          </li>
        ))}
      </ul>
    );
  }
}

export default BreadCrumb;

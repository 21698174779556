import { format, parse } from "date-fns";

export const msToTime = duration => {
  if (duration) {
    let seconds = parseInt((duration / 1000) % 60, 10),
      minutes = parseInt((duration / (1000 * 60)) % 60, 10),
      hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return (hours > 0 ? hours + ":" : "") + minutes + ":" + seconds;
  } else {
    return "";
  }
};

export const removeAccentuation = text => {
  text = text.toLowerCase();
  text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
  text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
  text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
  text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
  text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
  text = text.replace(new RegExp("[Ç]", "gi"), "c");
  return text;
};

export const getDateFormatted = value => {
  const formatDateDash = "yyyy-MM-dd";
  const formatDateBar = "yyyy/MM/dd";
  let date = null;
  let dateFormatted = null;
  try {
    if (value) {
      if (value instanceof Date) {
        date = value;
        dateFormatted = format(value, formatDateDash);
      } else {
        if (value.includes("-")) {
          date = parse(value, formatDateDash, new Date());
          dateFormatted = value;
        } else if (value.includes("/")) {
          date = parse(value, formatDateBar, new Date());
          dateFormatted = value.replace(/\//g, "-");
        }
      }
      if (date.toString() === "Invalid Date") {
        date = null;
        dateFormatted = null;
      }
    }
  } catch (error) {
    date = null;
    dateFormatted = null;
  }
  return { date, dateFormatted };
};

export const formatDate = value => {
  try {
    let fields = value.split(value.includes("-") ? "-" : "/");
    if (String(fields[0]).length > 2) {
      return `${fields[2]}/${fields[1]}/${fields[0]}`;
    } else {
      return `${fields[0]}/${fields[1]}/${fields[2]}`;
    }
  } catch (error) {
    return value;
  }
};

import AxiosHelper from "./../helpers/AxiosHelper";

const RESOURCE = "personalizedfield";

const getAllCategories = () => {
  const url = `/v2/${RESOURCE}/categories/`;
  return AxiosHelper.doGet(url);
};

const getCategory = uuid => {
  const url = `/v2/${RESOURCE}/categories/${uuid}/`;

  return AxiosHelper.doGet(url);
};

const editCategory = (data, uuid) => {
  const url = `/v2/${RESOURCE}/categories/${uuid}/`;
  return AxiosHelper.doPut(url, data);
};

const createCategory = data => {
  const url = `/v2/${RESOURCE}/categories/`;
  return AxiosHelper.doPost(url, data);
};

const deleteCategory = uuid => {
  const url = `/v2/${RESOURCE}/categories/${uuid}/`;
  return AxiosHelper.doDelete(url);
};

const getSearchValuesOnStringField = (uuid, params) => {
  let url = `/v2/${RESOURCE}/field/${uuid}/search?`;

  if (params) {
    Object.keys(params).map(
      key => (url += params[key] ? `${key}=${params[key]}&` : "")
    );
  }

  return AxiosHelper.doGet(url.substring(0, url.length - 1));
};

export {
  getAllCategories,
  getCategory,
  editCategory,
  createCategory,
  deleteCategory,
  getSearchValuesOnStringField
};

import React from "react";
import PropTypes from "prop-types";

import "./Container.css";

export default class Container extends React.Component {
  constructor(props) {
    super(props);

    this.classUnlimited = this.props.unlimited ? "unlimited  " : " ";
  }

  static propTypes = {
    /** Use this for a full width container */
    unlimited: PropTypes.bool
  };

  static defaultProps = {
    unlimited: false
  };

  render() {
    return (
      <div
        className={"container " + this.classUnlimited + this.props.className}
      >
        {this.props.children}
      </div>
    );
  }
}

const brazilian_portuguese_actions = {
  ActionSearch: "Buscar",
  ActionExplore: "Explorar",
  ActionEdit: "Editar",
  ActionSave: "Salvar",
  ActionShare: "Compartilhar",
  ActionCancel: "Cancelar",
  ActionPlaceholderSelect: "Selecione...",
  ActionUpload: "Enviar assets",
  ActionSelectFiles: "Selecionar arquivos",
  ActionDropFiles: "Solte arquivos aqui",
  ActionDeleteCollectionKeepingAssets:
    "Excluir a Coleção mas manter seus Assets",
  ActionDeleteCollectionRemovingAssets: "Excluir a Coleção e seus Assets",
  ActionDontMakeNothing: "Não fazer nada",
  DeleteAsset: "Excluir Asset",
  DontDeleteAsset: "Não excluir Asset",
  ActionRemoveAssetOfCollection: "Remover Asset apenas da Coleção",
  ActionDeleteAssetPermanently: "Excluir o Asset permanentemente",
  ActionDeleteGroup: "Excluir grupo",
  ActionForgotPassword: "Esqueceu sua senha?",
  ActionCreateNewPassword: "Criar nova senha",
  ActionBackToLogin: "Voltar para Login",
  DisplayByGrid: "Exibir em grid",
  DisplayByList: "Exibir em lista",
  ActionUncheckAll: "Desmarcar todos",
  ActionSearchContent: "Buscar conteúdo",
  ActionDeleteCategory: "Excluir categoria",
  ActionCreateOptionSelectCustomField: "Nova opção",
  ActionPlaceholderSelectCategories: "Selecione a(s) categoria(s)..",
  ActionLeavePage: "Descartar alterações",
  ActionRemoveCustomField: "Remover campos",
  ActionUpdate: "Atualizar"
};

export { brazilian_portuguese_actions };

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { applyFilters } from "../../pages/search-results/_actionsReducers";

// Compontents
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Container from "./../../_quarks/grid/Container";

import CompanyLogo from "../../atoms/company-logo/CompanyLogo";
import Button from "../../atoms/button/Button";
import FormGroup from "./../../molecules/form-group/FormGroup";
import MyAccount from "./../../organisms/my-account/MyAccount";

import { I18n } from "react-i18nify";

import "./SearchInitial.css";

class SearchInitial extends Component {
  componentDidMount = () => {
    this.formGroupSearch.refs.input.focus();
  };

  onSearch = () => {
    if (this.formGroupSearch.refs.input.value) {
      this.props.applyFilters(
        { q: this.formGroupSearch.refs.input.value },
        {
          updateAssets: true,
          updateCollections: true,
          updateTags: true,
          assetViewModeByList: true
        }
      );
    }
  };

  onKeyPressSearchTerm = event => {
    if (event.charCode === 13)
      // Press enter
      this.onSearch();
  };

  render() {
    return (
      <div className="p-searchInitial">
        <MyAccount className="p-searchInitial__myAccount" />

        <Container>
          <Row
            xsAlignX="center"
            xsAlignY="middle"
            className="text-xs-center p-searchInitial__globalSearch"
          >
            <Col sm="10" lg="7">
              <CompanyLogo className="mb-xs-4" />

              <FormGroup
                ref={formGroup => (this.formGroupSearch = formGroup)}
                onKeyPress={this.onKeyPressSearchTerm.bind(this)}
                icon="search"
                type="search"
                placeholder={I18n.t("SearchPlaceholder")}
                name="search"
                className="mb-xs-3"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  label={I18n.t("ActionSearch")}
                  theme="light"
                  className="mr-xs-2"
                  onClick={this.onSearch.bind(this)}
                />

                <Button
                  label={I18n.t("ActionExplore")}
                  theme="light"
                  type="link"
                  onClick="/search"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      applyFilters
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchInitial)
);

import axios from "axios";
import Auth from "./../models/AuthModel";
import { BASE_URL, BASE_PORT } from "./../Config";
import store from "../store";
import { addAlert } from "../components/molecules/alert/_actionsReducers";
import { logoutUser } from "../Utils";
import { I18n } from "react-i18nify";

const errorInterceptor = error => {
  if (error.response.status === 403) {
    store.dispatch(
      addAlert({
        id: "alertToSessionExpires",
        theme: "warning",
        animation: "default",
        timer: "infinite",
        centralized: true,
        overlay: true,
        title: I18n.t("AlertNotice"),
        description: I18n.t("ExpiredSession"),
        actionButtons: [
          {
            label: "OK",
            theme: "light",
            onClick: logoutUser
          }
        ]
      })
    );
  }
  return Promise.reject(error);
};

class AxiosHelper {
  static doPost = (url, params = null, cancelToken) => {
    const api = axios.create({
      baseURL: BASE_URL + BASE_PORT,
      timeout: 300000,
      headers: {
        Accept: "application/json",
        Authorization: `Token ${Auth.getToken()}`
      }
    });

    api.interceptors.response.use(response => {
      return Promise.resolve(response);
    }, errorInterceptor);

    const response = api.post(url, params, {
      cancelToken
    });
    return response;
  };

  static doPut = (url, params = null, cancelToken) => {
    const api = axios.create({
      baseURL: BASE_URL + BASE_PORT,
      timeout: 300000,
      headers: {
        Accept: "application/json",
        Authorization: `Token ${Auth.getToken()}`
      }
    });

    api.interceptors.response.use(response => {
      return Promise.resolve(response);
    }, errorInterceptor);

    const response = api.put(url, params, {
      cancelToken
    });
    return response;
  };

  static doPostWithoutAuth = (url, params = null) => {
    const api = axios.create({
      baseURL: BASE_URL + BASE_PORT,
      timeout: 300000
    });

    api.interceptors.response.use(
      response => {
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );

    const response = api.post(url, params);
    return response;
  };

  static doGet = (url, params = null) => {
    const api = axios.create({
      baseURL: BASE_URL + BASE_PORT,
      timeout: 300000,
      headers: {
        Accept: "application/json",
        Authorization: `Token ${Auth.getToken()}`
      }
    });

    api.interceptors.response.use(response => {
      return Promise.resolve(response);
    }, errorInterceptor);

    const response = api.get(url, params);
    return response;
  };

  static doGetWithoutAuth = (url, params = null) => {
    const api = axios.create({
      baseURL: BASE_URL + BASE_PORT,
      timeout: 300000
    });

    api.interceptors.response.use(
      response => {
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );

    const response = api.get(url, params);
    return response;
  };

  static doDelete = (url, params = null) => {
    const api = axios.create({
      baseURL: BASE_URL + BASE_PORT,
      timeout: 300000,
      headers: {
        Accept: "application/json",
        Authorization: `Token ${Auth.getToken()}`
      }
    });

    api.interceptors.response.use(response => {
      return Promise.resolve(response);
    }, errorInterceptor);

    const response = api.delete(url, params);
    return response;
  };

  static doGetToken = (url, params) => {
    let api = axios.create({
      baseURL: BASE_URL + BASE_PORT,
      timeout: 300000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46YWRtaW4xMjM0NTY="
      }
    });

    api.interceptors.response.use(
      response => {
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );

    const response = api.post(url, params);
    return response;
  };
}

export default AxiosHelper;

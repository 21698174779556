import React from "react";
import { I18n } from "react-i18nify";
import { Chart } from "react-google-charts";
import { humanize } from "humanize";
import humanizeDuration from "humanize-duration";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addBreadCrumb,
  clearBreadCrumb
} from "./../../molecules/bread-crumb/_actionsReducers";

// Components
import Container from "./../../_quarks/grid/Container";
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Box from "./../../_quarks/box/Box";
import Icon from "./../../_quarks/icon/Icon";
import Loader from "./../../_quarks/loader/Loader";

import Button from "./../../atoms/button/Button";

import Dropdown from "./../../molecules/dropdown/Dropdown";
import DropdownContainer from "./../../molecules/dropdown/DropdownContainer";
import FormGroup from "./../../molecules/form-group/FormGroup";

// Model
import { getDataAnalytics } from "./../../../models/AnalyticsModel";
import Auth from "./../../../models/AuthModel";

// Styles
import "./Analytics.css";

const loader = (
  <div className="py-xs-5">
    <Loader centralized />
  </div>
);
class Analytics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: {
        image: {
          processed: 0,
          total: 0
        },
        audio: {
          total: 0
        },
        video: {
          total: 0
        },
        other: {
          total: 0
        },
        total: {
          total: 0
        }
      },
      storage: {
        video: {
          total: 0,
          totalHumanized: null
        },
        audio: {
          total: 0,
          totalHumanized: null
        },
        image: {
          total: 0,
          totalHumanized: null
        },
        other: {
          total: 0,
          totalHumanized: null
        },
        total: {
          total: 0,
          totalHumanized: null
        }
      },
      time: {
        audio: {
          processedHumanized: null,
          total: 0,
          totalHumanized: null
        },
        video: {
          processedHumanized: null,
          total: 0,
          totalHumanized: null
        },
        total: {
          totalHumanized: null
        }
      },
      filter: {
        name: null,
        dateStart: null,
        dateEnd: null
      },
      openFilterCustom: false,
      canApplyCustomFilterDate: false,
      isLoading: false,
      hasError: false
    };

    this.user = Auth.getUser();
    this.languageHumanizeDuration =
      this.user.language === "pt_br" ? "pt" : this.user.language;

    this.filterDateForAPI = {
      dateStart: null,
      dateEnd: null
    };

    this.filterNames = {
      thisMonth: I18n.t("AnalyticsFilterNameThisMonth"),
      lastMonth: I18n.t("AnalyticsFilterNameLastMonth"),
      today: I18n.t("AnalyticsFilterNameToday"),
      fromBegin: I18n.t("AnalyticsFilterNameFromBegin"),
      custom: I18n.t("AnalyticsFilterNameCustom")
    };
  }

  componentDidMount = () => {
    this.props.addBreadCrumb([
      {
        label: I18n.t("Analytics"),
        link: "/analytics"
      }
    ]);

    this.setDateFilter("thisMonth");
  };

  componentWillUnmount = () => {
    this.props.clearBreadCrumb();
  };

  getGeneralData = () => {
    getDataAnalytics({
      dateStart: this.filterDateForAPI.dateStart,
      dateEnd: this.filterDateForAPI.dateEnd
    })
      .then(response => {
        this.setState({
          amount: {
            image: {
              processed: response.data.assets_amount.image.processed,
              total: response.data.assets_amount.image.total
            },
            audio: {
              total: response.data.assets_amount.audio.total
            },
            video: {
              total: response.data.assets_amount.video.total
            },
            other: {
              total: response.data.assets_amount.other.total
            },
            total: {
              total: response.data.assets_amount.total.total
            }
          },
          storage: {
            video: {
              total: response.data.assets_storage.video,
              totalHumanized: humanize.filesize(
                response.data.assets_storage.video
              )
            },
            audio: {
              total: response.data.assets_storage.audio,
              totalHumanized: humanize.filesize(
                response.data.assets_storage.audio
              )
            },
            image: {
              total: response.data.assets_storage.image,
              totalHumanized: humanize.filesize(
                response.data.assets_storage.image
              )
            },
            other: {
              total: response.data.assets_storage.other,
              totalHumanized: humanize.filesize(
                response.data.assets_storage.other
              )
            },
            total: {
              total: response.data.assets_storage.total,
              totalHumanized: humanize.filesize(
                response.data.assets_storage.total
              )
            }
          },
          time: {
            audio: {
              processedHumanized: humanizeDuration(
                response.data.assets_time.audio.processed,
                {
                  units: ["h", "m"],
                  language: this.languageHumanizeDuration,
                  round: true,
                  delimiter: " e "
                }
              ),
              total: response.data.assets_time.audio.total,
              totalHumanized: humanizeDuration(
                response.data.assets_time.audio.total,
                {
                  units: ["h", "m"],
                  language: this.languageHumanizeDuration,
                  round: true,
                  delimiter: " e "
                }
              )
            },
            video: {
              processedHumanized: humanizeDuration(
                response.data.assets_time.video.processed,
                {
                  units: ["h", "m"],
                  language: this.languageHumanizeDuration,
                  round: true,
                  delimiter: " e "
                }
              ),
              total: response.data.assets_time.video.total,
              totalHumanized: humanizeDuration(
                response.data.assets_time.video.total,
                {
                  units: ["h", "m"],
                  language: this.languageHumanizeDuration,
                  round: true,
                  delimiter: " e "
                }
              )
            },
            total: {
              totalHumanized: humanizeDuration(
                response.data.assets_time.total.total,
                {
                  units: ["h", "m"],
                  language: this.languageHumanizeDuration,
                  round: true,
                  delimiter: " e "
                }
              )
            }
          },
          isLoading: false,
          hasError: false
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
          hasError: true
        })
      );
  };

  setDateFilter = key => {
    this.filterDateForAPI = {
      dateStart:
        key === "custom"
          ? this.filterDateStart.refs.input.value
          : this.getDateFilter("start", key),
      dateEnd:
        key === "custom"
          ? this.filterDateEnd.refs.input.value
          : this.getDateFilter("end", key)
    };

    this.setState(
      {
        filter: {
          name: this.filterNames[key],
          dateStart: this.filterDateForAPI.dateStart
            ? this.formatDateApiForLanguageFormat(
                this.filterDateForAPI.dateStart
              )
            : this.filterNames.fromBegin,
          dateEnd: this.filterDateForAPI.dateEnd
            ? this.formatDateApiForLanguageFormat(this.filterDateForAPI.dateEnd)
            : I18n.t("AnalyticsFilterNameUntilTheEnd")
        },
        openFilterCustom: false,
        canApplyCustomFilterDate: false,
        isLoading: true
      },
      this.getGeneralData()
    );
  };

  getDateFilter = (positionDate, key) => {
    let today = new Date();

    if (key === "thisMonth") {
      if (positionDate === "start") {
        let thisYear = today.getFullYear();
        let thisMonth = today.getMonth();
        let firstDay = new Date(thisYear, thisMonth, 1);

        return this.formatTimeZoneForApiFormat(firstDay);
      }
    } else if (key === "lastMonth") {
      let thisMonth = today.getMonth();
      let thisYear = today.getFullYear();
      let lastMonth;
      let yearLastMonth;

      if (thisMonth !== 0) {
        lastMonth = thisMonth - 1;
        yearLastMonth = thisYear;
      } else {
        // If thisMonth is January (0)
        lastMonth = 11;
        yearLastMonth = thisYear - 1;
      }

      if (positionDate === "start") {
        let firstDayOfLastMonth = new Date(yearLastMonth, lastMonth, 1);

        return this.formatTimeZoneForApiFormat(firstDayOfLastMonth);
      } else {
        // positionDate = 'end'
        lastMonth += 1;
        let lastDayOfLastMonth = new Date(yearLastMonth, lastMonth, 0);

        return this.formatTimeZoneForApiFormat(lastDayOfLastMonth);
      }
    } else if (key === "fromBegin") {
      if (positionDate === "start") {
        let begin = new Date(2016, 0, 1);
        return this.formatTimeZoneForApiFormat(begin);
      }
    }

    return this.formatTimeZoneForApiFormat(today);
  };

  formatTimeZoneForApiFormat = timeZone => {
    let day = timeZone.getDate();
    let month = timeZone.getMonth() + 1; //January is 0!
    let year = timeZone.getFullYear();

    if (day < 10) day = "0" + day;

    if (month < 10) month = "0" + month;

    return `${year}-${month}-${day}`;
  };

  formatDateApiForLanguageFormat = dateYYYYMMDD => {
    let timeZone = dateYYYYMMDD.replace(/-/g, "/");
    timeZone = new Date(timeZone);
    let day = timeZone.getDate();
    let month = timeZone.getMonth() + 1; //January is 0!
    let year = timeZone.getFullYear();

    if (day < 10) day = "0" + day;

    if (month < 10) month = "0" + month;

    if (this.user.language === "pt_br") return `${day}/${month}/${year}`;
    else return `${month}/${day}/${year}`;
  };

  watchCustomFilter = event => {
    let canApply =
      this.filterDateStart.refs.input.value.length === 10 ||
      this.filterDateEnd.refs.input.value.length === 10;

    this.setState({
      canApplyCustomFilterDate: canApply
    });
  };

  render() {
    return (
      <Container className="p-analytics">
        <header className="p-analytics__header mb-xs-3">
          <div className="p-analytics__filterByUploadDate">
            <span className="mr-xs-2">
              {I18n.t("LabelFilterByUploadDate")}:
            </span>

            <DropdownContainer align="right">
              <div className="p-analytics__globalFormGroup">
                <FormGroup
                  type="text"
                  icon="calendar"
                  value={this.state.filter.name ? this.state.filter.name : ""}
                  readOnly={true}
                  className="p-analytics__globalFormGroup-formGroup"
                />

                <Icon
                  name="expand"
                  className="p-analytics__globalFormGroup-icon"
                />
              </div>

              <Dropdown className="p-analytics__dropdownFilterUploadDate pt-xs-4 px-xs-3 py-xs-3">
                <p className="lineHeightParagraph mb-xs-3">
                  <strong>{this.state.filter.name}</strong>
                  <br />
                  {this.state.filter.dateStart} - {this.state.filter.dateEnd}
                </p>

                {Object.keys(this.filterNames)
                  .filter(key => key !== "custom")
                  .map((key, index) => (
                    <Button
                      key={index}
                      theme="primary"
                      size="small"
                      kind={
                        this.state.filter.name === this.filterNames[key] &&
                        !this.state.openFilterCustom
                          ? "solid"
                          : "outline"
                      }
                      label={this.filterNames[key]}
                      onClick={this.setDateFilter.bind(this, key)}
                      className="mr-xs-1"
                    />
                  ))}

                <Button
                  theme="light"
                  size="small"
                  kind={
                    this.state.filter.name === this.filterNames.custom ||
                    this.state.openFilterCustom
                      ? "solid"
                      : "outline"
                  }
                  label={this.filterNames["custom"]}
                  onClick={() =>
                    this.setState({
                      openFilterCustom: !this.state.openFilterCustom
                    })
                  }
                  className="mr-xs-1"
                />

                {this.state.openFilterCustom && (
                  <div className="mt-xs-3">
                    <Row className="mb-xs-2">
                      <Col xs="6">
                        <FormGroup
                          ref={filterDateStart =>
                            (this.filterDateStart = filterDateStart)
                          }
                          onChange={this.watchCustomFilter.bind(this)}
                          label={I18n.t("FilterDateStart")}
                          icon="calendar"
                          type="date"
                        />
                      </Col>

                      <Col xs="6">
                        <FormGroup
                          ref={filterDateEnd =>
                            (this.filterDateEnd = filterDateEnd)
                          }
                          onChange={this.watchCustomFilter.bind(this)}
                          label={I18n.t("FilterDateEnd")}
                          icon="calendar"
                          type="date"
                        />
                      </Col>
                    </Row>

                    <Button
                      disabled={!this.state.canApplyCustomFilterDate}
                      onClick={this.setDateFilter.bind(this, "custom")}
                      theme="primary"
                      label="Aplicar"
                    />
                  </div>
                )}
              </Dropdown>
            </DropdownContainer>
          </div>
        </header>

        {this.state.hasError &&
          !this.state.isLoading && <p>{I18n.t("ContentErrorAnalytics")}</p>}

        {(!this.state.hasError || this.state.isLoading) && (
          <Row>
            <Col className="mb-xs-4">
              <h2 className="mb-xs-2 uppercase">
                {I18n.t("TitleAnalyticsAmount")}
              </h2>

              {this.state.isLoading && <Box className="py-xs-5">{loader}</Box>}

              {!this.state.isLoading && (
                <Box>
                  <div className="p-analytics__sectionVertical flex">
                    <div className="p-analytics__sectionHorizontal p-xs-3">
                      <span
                        className="lineHeightParagraph"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataAmountTotal", {
                            amount: this.state.amount.total.total
                          })
                        }}
                      />
                    </div>

                    <div className="p-analytics__sectionHorizontal flex alignVerticalCenter px-xs-4 py-xs-3">
                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataAmountVideo", {
                            amount: this.state.amount.video.total
                          })
                        }}
                      />

                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataAmountAudio", {
                            amount: this.state.amount.audio.total
                          })
                        }}
                      />

                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataAmountImage", {
                            amount: this.state.amount.image.total
                          })
                        }}
                      />

                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataAmountOther", {
                            amount: this.state.amount.other.total
                          })
                        }}
                      />
                    </div>
                  </div>

                  <div className="p-analytics__sectionVertical">
                    <Row lgAlignX="center">
                      <Col lg="8" xlg="6">
                        {!this.state.isLoading && (
                          <Chart
                            width={"100%"}
                            height={"300px"}
                            chartType="PieChart"
                            loader={loader}
                            data={[
                              ["Asset type", "Total"],
                              [
                                I18n.t("AnalyticsLegendAssetTypeVideo"),
                                this.state.amount.video.total
                              ],
                              [
                                I18n.t("AnalyticsLegendAssetTypeAudio"),
                                this.state.amount.audio.total
                              ],
                              [
                                I18n.t("AnalyticsLegendAssetTypeImage"),
                                this.state.amount.image.total
                              ],
                              [
                                I18n.t("AnalyticsLegendAssetTypeOther"),
                                this.state.amount.other.total
                              ]
                            ]}
                            options={{
                              animation: {
                                startup: true,
                                duration: 1000,
                                easing: "out"
                              }
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Box>
              )}
            </Col>

            <Col className="mb-xs-4">
              <h2 className="mb-xs-2 uppercase">
                {I18n.t("TitleAnalyticsStorage")}
              </h2>

              {this.state.isLoading && <Box className="py-xs-5">{loader}</Box>}

              {!this.state.isLoading && (
                <Box>
                  <div className="p-analytics__sectionVertical flex">
                    <div className="p-analytics__sectionHorizontal p-xs-3">
                      <span
                        className="lineHeightParagraph"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataStorageTotal", {
                            storage: this.state.storage.total.totalHumanized
                          })
                        }}
                      />
                    </div>

                    <div className="p-analytics__sectionHorizontal flex alignVerticalCenter px-xs-4 py-xs-3">
                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataStorageVideo", {
                            storage: this.state.storage.video.totalHumanized
                          })
                        }}
                      />

                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataStorageAudio", {
                            storage: this.state.storage.audio.totalHumanized
                          })
                        }}
                      />

                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataStorageImage", {
                            storage: this.state.storage.image.totalHumanized
                          })
                        }}
                      />

                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataStorageOther", {
                            storage: this.state.storage.other.totalHumanized
                          })
                        }}
                      />
                    </div>
                  </div>

                  <div className="p-analytics__sectionVertical">
                    <Row lgAlignX="center">
                      <Col lg="8" xlg="6">
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={loader}
                          data={[
                            [
                              { type: "string", label: "Asset type" },
                              { type: "string", label: "Storage" },
                              { type: "string", role: "tooltip" }
                            ],
                            [
                              I18n.t("AnalyticsLegendAssetTypeVideo"),
                              this.state.storage.video.total,
                              `${I18n.t("AnalyticsLegendAssetTypeVideo")}: ${
                                this.state.storage.video.totalHumanized
                              }`
                            ],
                            [
                              I18n.t("AnalyticsLegendAssetTypeAudio"),
                              this.state.storage.audio.total,
                              `${I18n.t("AnalyticsLegendAssetTypeAudio")}: ${
                                this.state.storage.audio.totalHumanized
                              }`
                            ],
                            [
                              I18n.t("AnalyticsLegendAssetTypeImage"),
                              this.state.storage.image.total,
                              `${I18n.t("AnalyticsLegendAssetTypeImage")}: ${
                                this.state.storage.image.totalHumanized
                              }`
                            ],
                            [
                              I18n.t("AnalyticsLegendAssetTypeOther"),
                              this.state.storage.other.total,
                              `${I18n.t("AnalyticsLegendAssetTypeOther")}: ${
                                this.state.storage.other.totalHumanized
                              }`
                            ]
                          ]}
                          options={{
                            animation: {
                              startup: true,
                              duration: 1000,
                              easing: "out"
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Box>
              )}
            </Col>

            <Col className="mb-xs-4">
              <h2 className="mb-xs-2 uppercase">
                {I18n.t("TitleAnalyticsTime")}
              </h2>

              {this.state.isLoading && <Box className="py-xs-5">{loader}</Box>}

              {!this.state.isLoading && (
                <Box>
                  <div className="p-analytics__sectionVertical flex">
                    <div className="p-analytics__sectionHorizontal p-xs-3">
                      <span
                        className="lineHeightParagraph"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataTimeTotal", {
                            time: this.state.time.total.totalHumanized
                          })
                        }}
                      />
                    </div>

                    <div className="p-analytics__sectionHorizontal flex alignVerticalCenter px-xs-4 py-xs-3">
                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataTimeVideo", {
                            time: this.state.time.video.totalHumanized
                          })
                        }}
                      />

                      <span
                        className="lineHeightParagraph mr-xs-4"
                        dangerouslySetInnerHTML={{
                          __html: I18n.t("AnalyticsDataTimeAudio", {
                            time: this.state.time.audio.totalHumanized
                          })
                        }}
                      />
                    </div>
                  </div>

                  <div className="p-analytics__sectionVertical">
                    <Row lgAlignX="center">
                      <Col lg="8" xlg="6">
                        <Chart
                          width={"100%"}
                          height={"300px"}
                          chartType="PieChart"
                          loader={loader}
                          data={[
                            [
                              { type: "string", label: "Asset type" },
                              { type: "string", label: "Time" },
                              { type: "string", role: "tooltip" }
                            ],
                            [
                              I18n.t("AnalyticsLegendAssetTypeVideo"),
                              this.state.time.video.total,
                              `${I18n.t("AnalyticsLegendAssetTypeVideo")}: ${
                                this.state.time.video.totalHumanized
                              }`
                            ],
                            [
                              I18n.t("AnalyticsLegendAssetTypeAudio"),
                              this.state.time.audio.total,
                              `${I18n.t("AnalyticsLegendAssetTypeAudio")}: ${
                                this.state.time.audio.totalHumanized
                              }`
                            ]
                          ]}
                          options={{
                            animation: {
                              startup: true,
                              duration: 1000,
                              easing: "out"
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Box>
              )}
            </Col>

            <Col>
              <h2 className="mb-xs-2 uppercase">
                {I18n.t("TitleAnalyticsBilling")} (*)
              </h2>

              {this.state.isLoading && <Box className="py-xs-3">{loader}</Box>}

              {!this.state.isLoading && (
                <Box className="p-analytics__box--billing mb-xs-2">
                  <Row>
                    <Col className="p-analytics__box-col" xs="6" lg="2">
                      <div className="px-xs-3 py-xs-4">
                        <span className="p-analytics__typeLabel mb-xs-2">
                          {I18n.t("AnalyticsLegendStorage")}
                        </span>
                        <br />

                        <span
                          className="lineHeightParagraph"
                          dangerouslySetInnerHTML={{
                            __html: I18n.t("AnalyticsDataBillingStorage", {
                              storage: this.state.storage.total.totalHumanized
                            })
                          }}
                        />
                      </div>
                    </Col>

                    <Col className="p-analytics__box-col" xs="6" lg="2" xlg="3">
                      <div className="px-xs-3 py-xs-4">
                        <span className="p-analytics__typeLabel--image mb-xs-2">
                          {I18n.t("AnalyticsLegendAssetTypeImage")}
                        </span>
                        <br />

                        <span
                          className="lineHeightParagraph"
                          dangerouslySetInnerHTML={{
                            __html: I18n.t("AnalyticsDataBillingAmountImage", {
                              amount: this.state.amount.image.processed
                            })
                          }}
                        />
                      </div>
                    </Col>

                    <Col className="p-analytics__box-col" xs="6" lg="5" xlg="4">
                      <div
                        className="px-xs-3 py-xs-4"
                        style={{ width: "100%" }}
                      >
                        <span className="p-analytics__typeLabel--video mb-xs-2">
                          {I18n.t("AnalyticsLegendAssetTypeVideo")}
                        </span>
                        <br />

                        <Row>
                          <Col xs="6">
                            <span
                              className="lineHeightParagraph"
                              dangerouslySetInnerHTML={{
                                __html: I18n.t(
                                  "AnalyticsDataBillingTimeVideoIntelligence",
                                  {
                                    time: this.state.time.video
                                      .processedHumanized
                                  }
                                )
                              }}
                            />
                          </Col>

                          <Col xs="6">
                            <span
                              className="lineHeightParagraph"
                              dangerouslySetInnerHTML={{
                                __html: I18n.t(
                                  "AnalyticsDataBillingTimeAudioInVideo",
                                  {
                                    time: this.state.time.video
                                      .processedHumanized
                                  }
                                )
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col className="p-analytics__box-col" xs="6" lg="3">
                      <div className="px-xs-3 py-xs-4">
                        <span className="p-analytics__typeLabel--audio mb-xs-2">
                          {I18n.t("AnalyticsLegendAssetTypeAudio")}
                        </span>
                        <br />

                        <span
                          className="lineHeightParagraph"
                          dangerouslySetInnerHTML={{
                            __html: I18n.t("AnalyticsDataBillingTimeAudio", {
                              time: this.state.time.audio.processedHumanized
                            })
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Box>
              )}

              <p className="uppercase">* {I18n.t("NoteAnalyticsBilling")}</p>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addBreadCrumb,
      clearBreadCrumb
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(Analytics);

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import { toggleEnableToDeleteAsset } from "./../../molecules/asset-item/_actionsReducers";
import {
  clearFilters,
  setSearchValue
} from "./../../pages/search-results/_actionsReducers";

// Models
import {
  editCollection,
  deleteCollection,
  getCollection
} from "./../../../models/CollectionModel";
import { deleteAsset } from "./../../../models/AssetModel";

// Components
import Container from "./../../_quarks/grid/Container";
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";
import Loader from "./../../_quarks/loader/Loader";

import PermissionHandler from "./../../../helpers/PermissionHelper";
import Button from "./../../atoms/button/Button";
import FieldEditable from "./../../atoms/field-editable/FieldEditable";
import FormGroup from "./../../molecules/form-group/FormGroup";

// Styles
import "./CollectionDetails.css";

// Translate
import { I18n } from "react-i18nify";

const humanize = require("humanize");

class CollectionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collection: null,
      name: "",
      qtdAssets: 0,
      isEditing: false,
      isDeleting: false,
      defaultValueToEdit: {
        groups: []
      }
    };
  }

  componentWillMount = () => {
    this._getCollection(this.props.uuid);
  };

  componentDidUpdate() {
    if (this.props.updateCollections) {
      this.props.setSearchValue({ updateCollections: false });
      this._getCollection(this.props.uuid);
    }
  }

  _getCollection = uuid => {
    getCollection(uuid)
      .then(response => {
        this.setState({
          collection: response.data,
          isGetting: false,
          name: response.data.name,
          qtdAssets: response.data.assets.length
        });
      })
      .catch(error => {
        this.setState({
          isGetting: false
        });
      });
  };

  completeDeleteCollection = () => {
    this.props.clearFilters();
  };

  toggleEdit = refToEdit => {
    if (!this.state.isEditing) {
      this.setState({
        defaultValueToEdit: {
          groups: []
        }
      });

      this.state.collection.groups.map(group =>
        this.state.defaultValueToEdit.groups.push({
          label: group.name,
          value: group.name,
          id: group.id
        })
      );
    }

    // Focus on input
    if (refToEdit && !this.state.isEditing) {
      setTimeout(() => {
        // For put cursor on end value on focus
        if (this[refToEdit].refs.input.type === "text") {
          let inputValue = this[refToEdit].refs.input.value;
          this[refToEdit].refs.input.value = null;
          this[refToEdit].refs.input.value = inputValue;
        }

        this[refToEdit].refs.input.focus();
      }, 0);
    }

    this.props.toggleEnableToDeleteAsset();

    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  shareCollection = async () => {
    try {
      this.setState({ isSharing: true });
      if (!this.state.collection.public) {
        let response = await editCollection(this.state.collection.uuid, {
          ...this.state.collection,
          public: true
        });
        this.setState({ collection: response.data });
      }

      let url =
        window.location.origin + "/shared/" + this.state.collection.uuid;

      this.props.addAlert({
        id: "alertSucessShareCollection",
        theme: "success",
        animation: "default",
        timer: "infinite",
        title: I18n.t("TitleShareCollection"),
        description: I18n.t("DescriptionShareCollection"),
        actionButtons: [
          {
            label: I18n.t("Open"),
            theme: "light",
            onClick: () => {
              this.props.delAlert("alertSucessShareCollection");
              window.open(url, "_blank");
            }
          },

          {
            label: I18n.t("Cancel"),
            theme: "danger",
            onClick: this.props.delAlert.bind(
              this,
              "alertSucessShareCollection"
            )
          },
          {
            label: I18n.t("CopyLink"),
            theme: "light",
            kind: "ghost",

            onClick: () => {
              const el = document.createElement("textarea");
              el.value = url;
              document.body.appendChild(el);
              el.select();
              document.execCommand("copy");
              document.body.removeChild(el);
              this.props.delAlert("alertSucessShareCollection");
            }
          }
        ]
      });
    } catch (error) {
      this.props.addAlert({
        id: "alertErrorShareCollection",
        theme: "danger",
        animation: "shake",
        timer: "fast",
        title: I18n.t("Error"),
        description: I18n.t("ErrorShareCollection")
      });
    } finally {
      this.setState({ isSharing: false });
    }
  };

  onSubmitEdit = () => {
    this.setState({
      isSavingEdit: true
    });

    let getIdGroups = () => {
      let arrayIdGroups = [];

      if (this.formGroupGroups) {
        this.formGroupGroups.refs.input.props.value.map(group =>
          arrayIdGroups.push(group.id)
        );
      }

      return arrayIdGroups;
    };

    let dataToEdit = {
      collection_uuid: [this.state.collection.uuid],
      name: this.formGroupName.refs.input.value,
      groups: getIdGroups(),
      public: this.state.collection.public
    };

    editCollection(this.state.collection.uuid, dataToEdit)
      .then(response => {
        this._getCollection(this.props.uuid);

        this.props.toggleEnableToDeleteAsset();

        this.setState({
          isEditing: false,
          isSavingEdit: false
        });
      })
      .catch(error => {
        this.props.toggleEnableToDeleteAsset();

        this.setState({
          isEditing: false,
          isSavingEdit: false
        });
      });
  };

  onDeleteCollectionKeepingAssets = () => {
    return new Promise(resolve => {
      // Is promise because the button loading
      deleteCollection(this.props.uuid)
        .then(response => {
          this.completeDeleteCollection();

          this.props.addAlert({
            id: "alertFeedbackDeleteImage",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("TitleAlertCollectionDeleted"),
            description: I18n.t("ContentAlertCollectionDeletedKeepingAssets")
          });

          resolve();
        })
        .catch(() => {
          this.props.addAlert({
            id: "alertFeedbackDeleteImage",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertErrorCollectionDeleted"),
            description: I18n.t("ContentAlertErrorCollectionDeleted")
          });

          resolve();
        });
    });
  };

  onDeleteCollectionRemovingAssets = async () => {
    let assetsUUIDS = this.state.collection.assets;
    let deletes = [];
    let error = false;

    assetsUUIDS.map(uuid =>
      deletes.push(deleteAsset(uuid).catch(() => (error = true)))
    );

    await Promise.all(deletes);

    if (error) {
      this.props.addAlert({
        id: "alertFeedbackDeleteImage",
        theme: "danger",
        animation: "shake",
        timer: "fast",
        title: I18n.t("TitleAlertErrorCollectionDeleted"),
        description: I18n.t("ContentAlertErrorCollectionDeleted")
      });
    } else {
      deleteCollection(this.props.uuid)
        .then(response => {
          this.completeDeleteCollection();

          this.props.addAlert({
            id: "alertFeedbackDeleteImage",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("TitleAlertCollectionDeleted"),
            description: I18n.t("ContentAlertCollectionDeletedRemovingAssets")
          });
        })
        .catch(() => {
          this.props.addAlert({
            id: "alertFeedbackDeleteImage",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertErrorCollectionDeleted"),
            description: I18n.t("ContentAlertErrorCollectionDeleted")
          });
        });
    }
  };

  onDelete = () => {
    this.props.addAlert({
      id: "alertToConfirmDeleteImage",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title: I18n.t("TitleAlertWhatDoWantToDo"),
      description: I18n.t("CantUndoThisAction"),
      actionButtons: [
        {
          icon: "delete",
          label: I18n.t("ActionDeleteCollectionKeepingAssets"),
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onDeleteCollectionKeepingAssets
        },
        {
          icon: "delete",
          label: I18n.t("ActionDeleteCollectionRemovingAssets"),
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onDeleteCollectionRemovingAssets
        },
        {
          label: I18n.t("ActionDontMakeNothing"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDeleteImage")
        }
      ]
    });
  };

  render() {
    if (!this.state.collection) return <div />;
    return (
      <div className="o-collectionDetails">
        <div className="o-collectionDetails__header py-xs-4">
          <Container>
            {this.state.isSearchingCollection && <Loader theme="negative" />}

            {!this.state.isSearchingCollection && (
              <Row xsAlignY="bottom">
                <Col xs="6">
                  {!this.state.isEditing && (
                    <h1 className="o-collectionDetails__header-title mb-xs-2">
                      {PermissionHandler.can_edit() && (
                        <FieldEditable
                          type="text"
                          theme="negative"
                          onClick={this.toggleEdit.bind(this, "formGroupName")}
                        >
                          {this.state.collection.name}
                        </FieldEditable>
                      )}

                      {!PermissionHandler.can_edit() &&
                        this.state.collection.name}
                    </h1>
                  )}

                  {this.state.isEditing && (
                    <FormGroup
                      ref={formGroup => (this.formGroupName = formGroup)}
                      theme="negative"
                      type="text"
                      name="collection_name"
                      label="Name"
                      defaultValue={this.state.collection.name}
                      className="o-collectionDetails__formGroupName mb-xs-3"
                    />
                  )}

                  <p className="mb-xs-3">
                    {this.state.qtdAssets > 1
                      ? this.state.qtdAssets + " assets "
                      : this.state.qtdAssets > 0
                      ? "1 asset"
                      : ""}
                  </p>

                  {!this.state.isEditing &&
                    (this.state.collection.groups.length !== 0 &&
                      PermissionHandler.can_edit()) && (
                      <p className="mb-xs-2">
                        <FieldEditable
                          theme="negative"
                          onClick={this.toggleEdit.bind(
                            this,
                            "formGroupGroups"
                          )}
                        >
                          {I18n.t("AssetDetailGroups")}

                          {this.state.collection.groups.map((group, index) => (
                            <span key={index} className="arrayInfo">
                              {" "}
                              {group.name}
                            </span>
                          ))}
                        </FieldEditable>
                      </p>
                    )}

                  {!this.state.isSearchingCollection &&
                    this.state.isEditing && (
                      <FormGroup
                        ref={formGroup => (this.formGroupGroups = formGroup)}
                        theme="negative"
                        className="my-xs-3"
                        type="virtualized"
                        loadInVirtualized="groups"
                        label={I18n.t("AssetDetailGroups")}
                        name="group"
                        defaultValue={this.state.defaultValueToEdit.groups}
                      />
                    )}

                  <p className="mb-xs-2">
                    {I18n.t("CreateAt")}
                    <strong>
                      {" "}
                      {humanize.relativeTime(
                        new Date(this.state.collection.created_at).getTime() /
                          1000
                      )}
                    </strong>
                  </p>

                  <p>
                    {I18n.t("AssetDetailModified")}
                    <strong>
                      {" "}
                      {humanize.relativeTime(
                        new Date(this.state.collection.updated_at).getTime() /
                          1000
                      )}
                    </strong>
                  </p>
                </Col>

                <Col xs="6" className="text-xs-right">
                  {!this.state.isEditing && (
                    <div>
                      <div>
                        {PermissionHandler.can_share() && (
                          <Button
                            type="button"
                            icon="share"
                            label={I18n.t("ActionShare")}
                            theme="negative"
                            className="mr-xs-2"
                            isLoading={this.state.isSharing}
                            onClick={this.shareCollection.bind(this)}
                          />
                        )}
                        {PermissionHandler.can_edit() && (
                          <span>
                            {PermissionHandler.can_edit() && (
                              <Button
                                type="button"
                                icon="edit"
                                label={I18n.t("ActionEdit")}
                                theme="negative"
                                className="mr-xs-2"
                                onClick={this.toggleEdit.bind(this, null)}
                              />
                            )}
                            {PermissionHandler.can_delete() && (
                              <Button
                                type="button"
                                icon="delete"
                                width="min"
                                kind="outline"
                                theme="negative"
                                onClick={this.onDelete.bind(this)}
                              />
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            )}

            {!this.state.isSearchingCollection &&
              this.state.collection.groups.length !== 0 &&
              !this.state.isEditing &&
              !PermissionHandler.can_edit() && (
                <p>
                  {I18n.t("AssetDetailGroups")}
                  {this.state.collection.groups.map((group, index) => (
                    <span key={index}>{group.name}</span>
                  ))}
                </p>
              )}

            {!this.state.isSearchingCollection && this.state.isEditing && (
              <div className="mt-xs-3">
                <Button
                  theme="negative"
                  type="button"
                  onClick={this.onSubmitEdit.bind(this)}
                  label={I18n.t("ActionSave")}
                  isLoading={this.state.isSavingEdit}
                  className="mr-xs-1"
                />

                <Button
                  theme="negative"
                  kind="ghost"
                  type="button"
                  onClick={this.toggleEdit.bind(this, null)}
                  label={I18n.t("ActionCancel")}
                />
              </div>
            )}
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  updateCollections: state.search.updateCollections
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...alertActions,
      clearFilters,
      toggleEnableToDeleteAsset,
      setSearchValue
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CollectionDetails)
);

import React from "react";

import "./Slider.css";

export default class Slider extends React.Component {
  positionLeftSlider = 0;
  actualProgress = 100;
  lastProgress = 100;
  isActive = false; // For control the drag on this instance

  componentDidMount = () => {
    this.setPositionLeftSlider();

    this.slider.addEventListener("dragstart", e => {
      this.isActive = true;
      this.saveLastProgress();
    });

    document.addEventListener("dragover", e => {
      if (this.isActive) this.onChangeProgress(e);
    });

    this.slider.addEventListener("dragend", e => {
      this.isActive = false;
    });

    window.addEventListener("resize", this.setPositionLeftSlider);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.setPositionLeftSlider);
  };

  setPositionLeftSlider = () => {
    this.positionLeftSlider = this.slider.getClientRects()[0].left;
  };

  onChangeProgress = e => {
    this.actualProgress = e.clientX - this.positionLeftSlider;

    this.props.onSlider(this.actualProgress, this.lastProgress);
  };

  onClickSlider = e => {
    this.saveLastProgress();
    this.onChangeProgress(e);
  };

  saveLastProgress = () => {
    this.lastProgress = this.actualProgress;
  };

  render() {
    return (
      <div
        ref={slider => (this.slider = slider)}
        className={"a-slider " + this.props.className}
        onClick={this.onClickSlider.bind(this)}
      >
        <span
          className="a-slider__active"
          style={{ width: this.props.progress }}
        />

        {this.props.buffer && (
          <span
            className="a-slider__buffer"
            style={{ width: this.props.buffer }}
          />
        )}

        <span className="a-slider__maskDrag" draggable="true" />
      </div>
    );
  }
}

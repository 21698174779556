import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Components
import Container from "./../../_quarks/grid/Container";
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";

import Box from "./../../atoms/box/Box";
import CompanyLogo from "./../../atoms/company-logo/CompanyLogo";

import Alert from "./../../molecules/alert/Alert";
import PoweredBy from "./../../molecules/powered-by/PoweredBy";

// Images
import assetifyTeam from "./../../../imgs/t-checkin/assetify-team.jpg";

// Styles
import "./CheckinTemplate.css";

class CheckinTemplate extends Component {
  render() {
    return (
      <div className="t-checkin">
        <main>
          <div className="t-checkin__globalBox">
            <Container>
              <Row xsAlignX="center">
                <Col sm="8" lg="5">
                  <Box>
                    <header className="p-xs-3 px-sm-5 py-sm-4 t-checkin__header">
                      <h1 className="mb-xs-2">
                        <CompanyLogo />
                      </h1>

                      <PoweredBy />
                    </header>

                    <div className="p-xs-3 p-sm-5 pt-xs-4">
                      {this.props.children}
                    </div>
                  </Box>
                </Col>
              </Row>
            </Container>
          </div>

          {this.props.history.location.pathname === "/login" && (
            <div className="t-checkin__bg">
              <img src={assetifyTeam} alt="Assetify team" />
            </div>
          )}
        </main>

        <Alert />
      </div>
    );
  }
}

export default withRouter(CheckinTemplate);

import React from "react";
import { format } from "date-fns";
import { InlineDatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

export default props => {
  const { onChange, ...other } = props;

  const onChangeValue = value => {
    let valueFormatted = format(value, "yyyy-MM-dd");
    if (onChange) {
      onChange({ value, valueFormatted });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <InlineDatePicker
        keyboard
        onlyCalendar
        format={"dd/MM/yyyy"}
        placeholder="dd/mm/yyyy"
        mask={value =>
          value
            ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
            : []
        }
        onChange={onChangeValue}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};

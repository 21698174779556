import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  changeAssetViewMode,
  clearFilters
} from "../../pages/search-results/_actionsReducers";

import companyLogo from "./../../../imgs/temp/assetify.png";

import "./CompanyLogo.css";

class CompanyLogo extends Component {
  render() {
    return (
      <img
        className={"a-companyLogo " + this.props.className}
        src={companyLogo}
        alt="Company name"
        onClick={() => {
          if (this.props.assetViewModeByList) {
            this.props.changeAssetViewMode();
          }
          if (this.props.onClick) {
            this.props.onClick();
          }
        }}
      />
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeAssetViewMode, clearFilters }, dispatch);

const mapStateToProps = state => ({
  assetViewModeByList: state.search.assetViewModeByList
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyLogo)
);

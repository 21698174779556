class Auth {
  static authenticateUser = token => {
    localStorage.removeItem("token");
    localStorage.setItem("token", token);
  };

  static isUserAuthenticated = () => {
    const token = localStorage.getItem("token");
    return !!token;
  };

  static deauthenticateUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("User");
  };

  static getToken = () => {
    return localStorage.getItem("token");
  };

  static setUserInfo = user => {
    localStorage.setItem("User", JSON.stringify(user));
  };

  static getUser = () => {
    try {
      let user = localStorage.getItem("User");
      if (user) {
        return JSON.parse(user);
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
}

export default Auth;

import { getURLByFilter } from "../../../helpers/FilterHelper";
import history from "../../../history";

const initialState = () => ({
  totalAssets: 0,
  assetViewModeByList: false,
  isSearchingAssets: false,
  isSearchingCollections: false,
  isSearchingTags: false,
  updateAssets: false,
  updateCollections: false,
  updateTags: false,
  updateAfterClear: false,
  activeItem: "search",
  filters: {
    asset_type: [],
    collections: [],
    tags: [],
    dateEnd: null,
    dateStart: null,
    page: null,
    per_page: 9,
    q: null,
    personalized_field: [],
    groups: [],
    orderBy: null
  }
});

export default function assetViewModeByList(state = initialState(), action) {
  switch (action.type) {
    case "CHANGE_ASSET_VIEW_MODE":
      return {
        ...state,
        assetViewModeByList: !state.assetViewModeByList
      };

    case "SET_FILTERS_BY_URL":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case "APPLY_FILTERS":
      let filters = {
        ...(action.clearBefore ? initialState().filters : state.filters),
        ...action.filters
      };

      const url = getURLByFilter(filters);

      if (!history.location.pathname.includes("/search")) {
        history.push(url);
      } else {
        window.history.replaceState(null, null, url);
      }

      return {
        ...state,
        filters,
        ...action.payload
      };
    case "CLEAR_FILTERS":
      let { redirectInitialScreen } = action.payload;

      if (redirectInitialScreen) {
        history.push("/");
      } else if (!history.location.pathname.includes("/search")) {
        history.push("/search");
      } else {
        window.history.replaceState(null, null, "/search");
      }

      return {
        ...state,
        filters: initialState().filters,
        updateAssets: true,
        updateCollections: true,
        updateAfterClear: true,
        activeItem: "search"
      };
    case "SET_SEARCH_VALUE":
      return {
        ...state,
        ...action.value
      };

    default:
      return state;
  }
}

import { createStore, combineReducers } from "redux";

import alerts from "./components/molecules/alert/_reducers";
import {
  assetDetails,
  enableToDeleteAsset
} from "./components/molecules/asset-item/_reducers";
import breadCrumb from "./components/molecules/bread-crumb/_reducers";
import search from "./components/pages/search-results/_reducers";

const reducers = combineReducers({
  alerts,
  assetDetails,
  enableToDeleteAsset,
  breadCrumb,
  search
});

const store = createStore(reducers);

export default store;

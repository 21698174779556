import { TableRow } from "@material-ui/core";
import styled from "styled-components";

/**
 * Table Content Wrapper
 */
export const TableWrapper = styled.div`
  overflow-x: auto;
`;

/**
 * Table Row
 */
export const Row = styled(TableRow)`
  && {
    :hover {
      cursor: pointer;
    }
  }
`;

/**
 * Table rows per page option
 */
export const PaginationOption = styled.ul`
  & li {
    ${props => props.theme.typography.subtitle1};
  }
`;

import React from "react";
import CompanyLogo from "../../atoms/company-logo/CompanyLogo";
import withRouter from "react-router-dom/withRouter";
import {
  Grid,
  Typography,
  withStyles,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton
} from "@material-ui/core";

import { getSharedCollection } from "../../../models/CollectionModel";
import AssetItem from "../../AssetItem/AssetItem";
import Icon from "../../Icon/Icon";

const styles = theme => ({
  root: {
    height: "100vh",
    overflow: "auto"
  },
  companyLogo: {
    width: 100,
    height: 30,
    margin: theme.spacing.unit * 2
  },
  name: {
    textAlign: "center",
    marginBottom: 24
  },
  assets: {
    padding: theme.spacing.unit * 2
  },
  modalActions: {
    margin: `${theme.spacing.unit}px ${theme.spacing.unit}px`
  }
});

class SharedCollections extends React.Component {
  state = {};

  async componentDidMount() {
    let url = this.props.history.location.pathname.split("/");
    let response = await getSharedCollection(url[url.length - 1]);
    this.setState({ collection: response.data });
  }

  downloadFile(src, realName) {
    var a = document.createElement("a");
    a.href = src + "&fileName=" + realName;
    a.download = realName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(src);
    a.remove();
  }

  render() {
    const { classes, theme } = this.props;
    const { collection, asset, openModal } = this.state;

    return (
      <div className={classes.root}>
        <CompanyLogo
          className={classes.companyLogo}
          onClick={() => this.props.history.push("/")}
        />

        <div className={classes.name}>
          <Typography component="h2" variant="h3">
            {collection && collection.name}
          </Typography>
        </div>
        <div className={classes.assets}>
          <Grid
            container
            spacing={theme.spacing.unit * 2}
            justify={
              collection && collection.assets.length > 6
                ? "flex-start"
                : "center"
            }
          >
            {collection &&
              collection.assets.map((v, i) => {
                return (
                  <Grid item xs={9} sm={4} md={3} lg={2} key={i}>
                    <AssetItem
                      uuidAsset={v}
                      uuidCollection={collection.uuid}
                      isPublic
                      onClick={asset => {
                        if (
                          asset &&
                          asset.asset_type[1] === "application" &&
                          asset.asset_type[2] === ".pdf"
                        ) {
                          window.open(asset.size_url.original);
                        } else {
                          this.setState({ openModal: true, asset });
                        }
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </div>

        {asset && openModal && (
          <Dialog
            open={openModal || false}
            onClose={() => this.setState({ openModal: false })}
            maxWidth={"xl"}
          >
            <DialogContent>
              {asset.asset_type[1] === "image" && (
                <img
                  src={asset.size_url.original}
                  alt=""
                  style={{ height: "calc(100vh - 211px)" }}
                />
              )}

              {asset.asset_type[1] === "audio" && (
                <audio controls>
                  <source src={asset.size_url.original} type="audio/mpeg" />
                </audio>
              )}

              {asset.asset_type[1] === "video" && (
                <video style={{ height: "calc(100vh - 211px)" }} controls>
                  <source src={asset.size_url.original} type="video/mp4" />
                </video>
              )}
            </DialogContent>
            <DialogActions className={classes.modalActions}>
              <IconButton
                onClick={() =>
                  this.downloadFile(asset.size_url.original, asset.real_name)
                }
              >
                <Icon name="cloud-download" color="primary" />
              </IconButton>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(SharedCollections)
);

const english_default = {
  PlaceholderInputEmail: "Email",
  PlaceholderInputPassword: "Password",
  LabelButtonLogin: "Login",
  LabelButtonLoginWithGoogle: "Sign in with Google",
  LabelButtonSeeAllCollections: "See all",
  TitleAlertIncorrectData: "Incorrect data",
  ContentAlertIncorrectData:
    "See your <strong>email and password</strong> and try again.",
  SearchPlaceholder: "What are you looking for?",
  Collections: "Collections",
  CollectionItemViewButton: "View",
  EmptyMessageTags: "No Tags found.",
  EmptyMessageCollections: "No Collections found.",
  EmptyMessageAssets: "No Assets found.",
  AssetTypeAudio: "Audio",
  AssetTypeVideo: "Video",
  AssetTypeImage: "Image",
  AssetTypePDF: "PDF",
  MoreFilters: "More filters",
  Filters: "Filters",
  FilterDateStart: "Initial date:",
  FilterDateEnd: "Final date:",
  ActionApplyFilters: "Apply filters",
  Logout: "Sair",
  Profile: "Profile",
  ContentSmartSearchImage: "Information extracted from the image:",
  ContentSmartSearchAudio: "Information extracted from the audio:",
  ContentSmartSearchVideo: "Information extracted from the video:",
  CreateAt: "Create at:",
  AssetDetailFileExtension: "File extension:",
  AssetDetailFileSize: "Size:",
  AssetDetailCollections: "Collections:",
  AssetDetailGroups: "Groups:",
  AssetDetailUpload: "Upload",
  AssetDetailModified: "Modified at:",
  AssetDetailHasSmartSearch: "Smart search",
  AssetDetailIsPublicMode: "Public mode",
  AssetDetailIsPublicDownload: "Public download",
  TitleUpload: "Upload assets",
  FeedbackAllAssetsSent: "All assets have been sent",
  TitleAlertCollectionDeleted: "Collection deleted successfully!",
  ContentAlertCollectionDeletedKeepingAssets:
    "The Collection was deleted but your Assets were retained.",
  ContentAlertCollectionDeletedRemovingAssets:
    "The Collection and your Assets have been removed.",
  TitleAlertWhatDoWantToDo: "What do you want to do?",
  CantUndoThisAction: "You can't undo this action.",
  TitleAlertAssetDeleted: "Asset deleted successfully!",
  TitleAlertDoYouWantExcludeTheAsset: "Do you want exclude the asset?",
  TitleAlertAssetRemovedFromCollection: "Asset removed from Collection",
  UploadMoreAssets: "Upload more assets",
  UploadPageSaveSuccessAlertTitle: "Alright!",
  UserFirstName: "Name:",
  UserLastName: "Last Name:",
  Password: "Password:",
  PasswordNew: "New Password:",
  PasswordNewConfirm: "Confirm new Password:",
  PasswordOld: "Old Password:",
  EditPassword: "Edit Password",
  InvalidOldPasswordConfirmation: "Invalid Old Password Confirmation",
  InvalidNewPasswordConfirmation: "Invalid new Password Confirmation",
  Language: "Language",
  ConfirmUserDeletion: "Delete the user?",
  WarningNotPossibleToUndoTheAction:
    "It will be not possible to undo this action",
  UserDeletedSuccessfully: "User deleted successfully!",
  DeleteUser: "Delete user",
  Cancel: "Cancel",
  Users: "Users",
  CreateNewUserButtonLabel: "Create New User",
  ManageUsers: "Manage Users",
  ManageGroups: "Manage Groups",
  ConfirmGroupDeletion: "Delete the group?",
  Groups: "Groups",
  CreateNewGroupButtonLabel: "Create new Group",
  TitleAlertProfileIsChanged: "Profile successfully changed",
  TitleCreateNewUser: "Create new user",
  TitleCreateNewGroup: "Create new group",
  TitleUserCreatedSuccessfully: "User created successfully!",
  TitleGroupCreatedSuccessfully: "Group created successfully!",
  GroupDeletedSuccessfully: "Group deleted successfully!",
  GroupName: "Group name:",
  TitleAlertForgotPasswordOk: "Instructions sent to your email",
  ContentAlertForgotPasswordOk:
    "Check your email <strong>%{email}</strong> and follow the instructions to create a new password.",
  FillTheFieldsBelow: "Fill the fields below.",
  TitleAlertPasswordChanged: "Password changed successfully!",
  ContentAlertPasswordChanged:
    "You can now access Assetify with the new password you have created.",
  AssetDetailContent: "Asset content",
  TitleSearchInPdf: "Search in PDF",
  SearchTextContent: "Search text content",
  IndexResultsFound: "<strong>%{currentIndex}/%{totalResults}</strong> results",
  FoundInThePdf: "Found in the PDF",
  FoundInVideoImage: "Found in the video image",
  TooltipConfirm: "Got it",
  TooltipSearchContent:
    'For searches with more than one word, place them between "quotation marks" for more precise reset',
  TooltipSearchTitle: "Tip!",
  LabelMediaQuality: "Quality",
  Analytics: "Analytics",
  LabelFilterByUploadDate: "Upload date",
  AnalyticsLegendStorage: "Storage",
  AnalyticsLegendAssetTypeVideo: "Videos",
  AnalyticsLegendAssetTypeAudio: "Audios",
  AnalyticsLegendAssetTypeImage: "Images",
  AnalyticsLegendAssetTypeOther: "Others",
  TitleAnalyticsAmount: "Amount",
  AnalyticsDataAmountTotal:
    '<strong class="heading-1 color-default-dark">%{amount}</strong><br/> assets registered',
  AnalyticsDataAmountVideo: "<strong>%{amount}</strong><br/> videos",
  AnalyticsDataAmountAudio: "<strong>%{amount}</strong><br/> audios",
  AnalyticsDataAmountImage: "<strong>%{amount}</strong><br/> images",
  AnalyticsDataAmountOther: "<strong>%{amount}</strong><br/> PDFs and others",
  TitleAnalyticsStorage: "Storage",
  AnalyticsDataStorageTotal:
    '<strong class="heading-1 color-default-dark">%{storage}</strong><br/> of assets registered',
  AnalyticsDataStorageVideo: "<strong>%{storage}</strong><br/> of videos",
  AnalyticsDataStorageAudio: "<strong>%{storage}</strong><br/> of audios",
  AnalyticsDataStorageImage: "<strong>%{storage}</strong><br/> of images",
  AnalyticsDataStorageOther:
    "<strong>%{storage}</strong><br/> of PDFs and others",
  TitleAnalyticsTime: "Total duration of multimedia assets",
  AnalyticsDataTimeTotal:
    '<strong class="heading-1 color-default-dark">%{time}</strong><br/> of audio and video',
  AnalyticsDataTimeVideo: "<strong>%{time}</strong><br/> of videos",
  AnalyticsDataTimeAudio: "<strong>%{time}</strong><br/> of audios",
  TitleAnalyticsBilling: "Consolidated data for billing",
  NoteAnalyticsBilling:
    "considered only the processed Assets by artificial intelligence",
  AnalyticsDataBillingStorage:
    'Total storage volume:<br/><strong class="heading-1 color-default-dark">%{storage}</strong>',
  AnalyticsDataBillingAmountImage:
    'Number of images:<br/><strong class="heading-1 color-default-dark">%{amount}</strong>',
  AnalyticsDataBillingTimeVideoIntelligence:
    'Image analysis:<br/><strong class="heading-1 color-default-dark">%{time}</strong>',
  AnalyticsDataBillingTimeAudioInVideo:
    'Audio transcription:<br/><strong class="heading-1 color-default-dark">%{time}</strong>',
  AnalyticsDataBillingTimeAudio:
    'Audio transcription:<br/><strong class="heading-1 color-default-dark">%{time}</strong>',
  AnalyticsFilterNameThisMonth: "This month",
  AnalyticsFilterNameLastMonth: "Last month",
  AnalyticsFilterNameToday: "Today",
  AnalyticsFilterNameFromBegin: "From the beginning",
  AnalyticsFilterNameCustom: "Custom",
  AnalyticsFilterNameUntilTheEnd: "Until the end",
  LabelFilterByTags: "Tags",
  LabelFilterByCollections: "Collections",
  AlertImageDownload: "Select a resolution",
  AlertNotice: "Notice!",
  LabelImageSizeOriginal: "Original Resolution",
  LabelImageSizeFullHD: "High Resolution",
  LabelImageSizeHD: "Average Resolution",
  LabelImageSizeSD: "Low Resolution",
  LabelProcessing: "Generating Thumbnail",
  TitleVideoImage: "Video image",
  LoadingOriginalImage: "Loading image in its original size",
  CustomFields: "Custom fields",
  CustomField: "Custom field",
  CustomFieldTitle: "Field title",
  CustomFieldTitleEmpty: "No title",
  CustomFieldType: "Field type",
  CustomFieldTypeText: "Text/Number",
  CustomFieldTypeInt: "Integer number",
  CustomFieldTypeFloat: "Decimal number",
  CustomFieldTypeDate: "Date",
  CustomFieldTypeMultiselect: "Multi select",
  CustomFieldTypeUniqueselect: "Unique select",
  CustomFieldAdvancedInformation: "Advanced information",
  CustomFieldPreview: "Field preview",
  CustomFieldValidation: "Validation (regular expression)",
  CustomFieldSupportMessage: "Support message",
  CustomFieldOptionSelect: "Option %{index}",
  Categories: "Categories",
  ConfirmCategoryDeletion: "Delete the category?",
  CategoryDeletedSuccessfully: "Category deleted successfully!",
  CreateNewCategoryButtonLabel: "Create new Category",
  TitleCreateNewCategory: "Create new Category",
  CategoryName: "Category Name:",
  Fields: "Fields:",
  CreateNewFieldCategoryButtonLabel: "New Field",
  TitleCategoryCreatedSuccessfully: "Category created successfully!",
  EmptyStateCustomField:
    'You still haven\'t any custom field.<br/> Click on button "%{buttonTitle}" for do it.',
  EmptyStateCategory:
    'You still haven\'t any category.<br/> Click on button "%{buttonTitle}" for do it.',
  Category: "Categoria",
  NoFieldRegistered: "No field registered",
  EmptyCategoryFields: "Categories need one or more fields",
  TitleAlertEditCustomFieldWithSuccess: "Fields updated successfully",
  AlertFormError: "Check the fields marked in red",
  ErrorDeleteCategoryBound: "Can't delete, there is data bound to it",
  ErrorUpdateFieldBound: "Can't update field, there is data bound to it",
  ConfirmTitleLeavePage: "Attention!",
  ConfirmDescriptionLeavePage:
    "There are unsaved changes, if you leave without saving, they will be lost.",
  NoFieldFilledRegistered: "No custom fields filled out",
  HelpMsgIntField:
    "This field is not searchable by the main system search, only by filters.",
  ConfirmRemoveCustomField:
    "You are removing custom fields from this asset. This change can not be undone.",
  AssetName: "Name",
  Results: "found assets",
  Group: "Group",
  Field: "Field",
  Collection: "Collection",
  FromDate: "from",
  ToDate: "to",
  Type: "Type",
  OrderByNameAsc: "Asset Name (A-Z)",
  OrderByNameDesc: "Asset Name (Z-A)",
  OrderByRelevance: "Relevance",
  OrderByDateDesc: "Upload Date (Last)",
  OrderByDateAsc: "Upload Date (Older)",
  OrderBySizeDesc: "Size (Bigger First)",
  OrderBySizeAsc: "Size (Smaller First)",
  ClearFilters: "CLEAR FILTERS",
  Permissions: "Permissions",
  "Can Download": "Download",
  "Can Edit/Delete": "Edit",
  "Can Delete": "Delete",
  "Can Share": "Share",
  "Can Upload": "Upload",
  "Can View": "View assets",
  "Can View Confidence": "Confidence level",
  "Can Admin Category": "Custom Fields",
  Help: "Help and Support",
  Error: "Error",
  ErrorShareCollection: "Could not share collection.",
  Open: "Open",
  CopyLink: "Copy link",
  ErrorConfirmDeleteCustomField:
    "This field has information registered {count} in assets. If you delete it or change its type, that information will be lost.",
  Continue: "Continue",
  ResultsCollections: "found collections",
  EmptyFieldsTitle: "Fields must have a title",
  DuplicateFieldsTitle: "This field title already exists",
  DuplicateFieldsOptions: "Options must have unique values",
  ConfirmSelectedUserDeletion: "Delete the selected user?",
  DeleteSelectedUser: "Delete user",
  ConfirmSelectedUsersDeletion: "Delete the selected users?",
  DeleteSelectedUsers: "Delete users",
  RowsPerPage: "Rows per page",
  Of: "of",
  Sort: "Sort",
  Selected: "Selected",
  Delete: "Delete",
  Add: "Add",
  ExpiredSession: "Session expired, please log in again.",
  NewUser: "New user",
  SinceDate: "from"
};

export { english_default };

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as alertActions from "./../../molecules/alert/_actionsReducers";
import {
  addBreadCrumb,
  clearBreadCrumb
} from "./../../molecules/bread-crumb/_actionsReducers";

// Models
import {
  getUser,
  createUser,
  editUser,
  deleteUser,
  editPassword,
  editSettings,
  getSettings
} from "./../../../models/UserModel";

// Components
import Box from "./../../_quarks/box/Box";
import Container from "./../../_quarks/grid/Container";
import Row from "./../../_quarks/grid/Row";
import Col from "./../../_quarks/grid/Col";

import Button from "./../../atoms/button/Button";
import FieldEditable from "./../../atoms/field-editable/FieldEditable";
import Key from "./../../atoms/key/Key";

import FormGroup from "./../../molecules/form-group/FormGroup";

// Styles
import "./ProfilePage.css";

// Authentication
import Auth from "./../../../models/AuthModel";
import PermissionHandler from "./../../../helpers/PermissionHelper";
import Loader from "../../_quarks/loader/Loader";

// Translate
const { I18n } = require("react-i18nify");

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    let edit = false;

    if (this.props.history && this.props.history.location.state) {
      edit = this.props.history.location.state.edit;
    }

    this.state = {
      user: null,
      settings: null,
      isGettingUser: true,
      initialEdit: edit || false,
      isEditing: false,
      isEditingPassword: false,
      isSavingCreateUser: false,
      defaultValueToEdit: {
        groups: []
      },
      permissions: { manage_user: PermissionHandler.is_admin() },
      isTheSameUser: Auth.getUser().uuid === this.props.match.params.uuid,
      isCreatingNewUser: typeof this.props.match.params.uuid === "undefined"
    };
  }

  componentDidMount = () => {
    if (!this.state.isCreatingNewUser) {
      let uuid = this.props.match.params.uuid;
      this._getUser(uuid);
    } else {
      this.props.addBreadCrumb([
        {
          label: I18n.t("ManageUsers"),
          link: "/users"
        },
        {
          label: I18n.t("TitleCreateNewUser")
        }
      ]);
      this.setState({ isGettingUser: false });
    }
  };

  componentWillUnmount = () => {
    this.props.clearBreadCrumb();
  };

  _getUser = uuid => {
    this.props.addBreadCrumb([
      {
        label: I18n.t("ManageUsers"),
        link: "/users"
      }
    ]);

    getUser(uuid)
      .then(response => {
        getSettings(uuid)
          .then(response_settings => {
            this.setState({
              settings: response_settings.data,
              user: response.data,
              isGettingUser: false
            });

            this.props.addBreadCrumb([
              {
                label: I18n.t("ManageUsers"),
                link: "/users"
              },
              {
                label: response.data.first_name + response.data.last_name,
                link: "/profile/" + response.data.uuid
              }
            ]);

            if (this.state.initialEdit) {
              this.toggleEditUser();
            }
          })
          .catch(error => {
            this.setState({
              isGettingUser: false
            });
          });
      })
      .catch(error => {
        this.setState({
          isGettingUser: false
        });
      });
  };

  isValidForm = () => {
    let name = this.formGroupFirstName.refs.input.value;
    if (name === null || name === "") {
      this.props.addAlert({
        id: "feedbackCreateUser",
        theme: "warning",
        animation: "shake",
        timer: "fast",
        title: I18n.t("TitleAlertUserNotCreated"),
        description: I18n.t("ContentAlertUserWithoutName")
      });

      return false;
    }

    let groups = this.formGroupGroups.refs.input.props.value;
    if (groups === null || (Array.isArray(groups) && groups.length === 0)) {
      this.props.addAlert({
        id: "feedbackCreateUser",
        theme: "warning",
        animation: "shake",
        timer: "fast",
        title: I18n.t("TitleAlertUserNotCreated"),
        description: I18n.t("ContentAlertUserWithoutGroup")
      });

      return false;
    }
    return true;
  };

  toggleEditUser = refToEdit => {
    if (!this.state.isEditing) {
      let defaultValueToEdit = {
        groups: []
      };

      this.state.user.groups.map(group =>
        defaultValueToEdit.groups.push({
          label: group.name,
          value: group.name,
          id: group.id
        })
      );

      this.setState({
        defaultValueToEdit,
        isEditingPassword: false
      });
    }

    // Focus on input
    if (refToEdit && !this.state.isEditing) {
      if (refToEdit === "formGroupOldPassword") {
        this.setState({
          isEditingPassword: true
        });
        if (!this.state.isTheSameUser) {
          refToEdit = "formGroupPasswordNew";
        }
      }

      setTimeout(() => {
        // For put cursor on end value on focus
        if (this[refToEdit].refs.input.type === "text") {
          let inputValue = this[refToEdit].refs.input.value;
          this[refToEdit].refs.input.value = null;
          this[refToEdit].refs.input.value = inputValue;
        }

        this[refToEdit].refs.input.focus();
      }, 0);
    }

    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  onSubmitCreateUser = () => {
    if (
      this.formGroupPasswordNew.refs.input.value !==
      this.formGroupPasswordNewConfirm.refs.input.value
    ) {
      this.props.addAlert({
        id: "feedbackCreateUser",
        theme: "warning",
        animation: "shake",
        timer: "fast",
        title: I18n.t("TitleAlertPasswordHasNotBeenChanged"),
        description: I18n.t("TitleAlertPasswordHasNotBeenChangedBecauseTheNew")
      });

      return;
    }

    this.setState({
      isSavingCreateUser: true
    });

    let getIdGroups = () => {
      let arrayIdGroups = [];

      if (this.formGroupGroups) {
        this.formGroupGroups.refs.input.props.value.map(group =>
          arrayIdGroups.push(group.id)
        );
      }

      return arrayIdGroups;
    };

    let dataToCreate = {
      first_name: this.formGroupFirstName.refs.input.value,
      groups: getIdGroups(),
      username: this.formGroupEmail.refs.input.value,
      password: this.formGroupPasswordNew.refs.input.value
    };

    let getLangValue = () => {
      let value = this.formGroupLanguage.refs.input.props.value;
      if (value.hasOwnProperty("value")) {
        return value.value;
      } else {
        return value;
      }
    };

    let dataToEditSettings = {
      language: getLangValue()
    };

    createUser(dataToCreate)
      .then(response => {
        let uuid = response.data.uuid;
        editSettings(dataToEditSettings, uuid)
          .then(responseSettings => {
            this.setState({
              isSavingCreateUser: false
            });

            this.props.addAlert({
              id: "feedbackChangeProfile",
              theme: "success",
              animation: "default",
              timer: "fast",
              title: I18n.t("TitleUserCreatedSuccessfully")
            });

            this.props.history.push("/users");
          })
          .catch(error => {
            this.props.addAlert({
              id: "feedbackChangeProfile",
              theme: "danger",
              animation: "default",
              timer: "fast",
              title: I18n.t("TitleAlertServerError")
            });

            this.setState({
              isSavingCreateUser: false
            });
          });
      })
      .catch(error => {
        this.props.addAlert({
          id: "feedbackChangeProfile",
          theme: "danger",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleAlertUserNotCreated"),
          description: I18n.t("ContentAlertCheckEmailInUse")
        });

        this.setState({
          isSavingCreateUser: false
        });
      });
  };

  onSubmitEditUser = () => {
    try {
      if (this.formGroupEditPassword.refs.input.checked) {
        if (
          this.formGroupPasswordNew.refs.input.value !==
          this.formGroupPasswordNewConfirm.refs.input.value
        ) {
          this.props.addAlert({
            id: "feedbackChangeProfile",
            theme: "warning",
            animation: "shake",
            timer: "infinite",
            title: I18n.t("TitleAlertPasswordHasNotBeenChanged"),
            description: I18n.t(
              "TitleAlertPasswordHasNotBeenChangedBecauseTheNew"
            )
          });

          return;
        }
      }
    } catch (error) {}

    this.setState({
      isSavingEditUser: true
    });

    let getIdGroups = () => {
      let arrayIdGroups = [];

      if (this.formGroupGroups) {
        this.formGroupGroups.refs.input.props.value.map(group =>
          arrayIdGroups.push(group.id)
        );
      }

      return arrayIdGroups;
    };

    let dataToEdit = {
      first_name: this.formGroupFirstName.refs.input.value,
      username: this.state.user.username
    };

    if (this.state.permissions.manage_user) {
      dataToEdit.groups = getIdGroups();
    } else {
      let arrayIdGroups = [];

      this.state.user.groups.map(group => arrayIdGroups.push(group.id));
      dataToEdit.groups = arrayIdGroups;
    }

    let uuid = this.props.match.params.uuid;

    let getLangValue = () => {
      let value = this.formGroupLanguage.refs.input.props.value;
      if (value.hasOwnProperty("value")) {
        return value.value;
      } else {
        return value;
      }
    };

    let dataToEditSettings = {
      language: getLangValue()
    };

    editUser(dataToEdit, uuid)
      .then(response => {
        editSettings(dataToEditSettings, uuid)
          .then(responseSettings => {
            let userData = Auth.getUser();
            userData.language = responseSettings.data.language_option.value;
            Auth.setUserInfo(userData);

            I18n.setLocale(userData.language);

            if (this.formGroupEditPassword.refs.input.checked) {
              let dataToEditPassword = {
                password_new: this.formGroupPasswordNew.refs.input.value,
                password_new_confirm: this.formGroupPasswordNewConfirm.refs
                  .input.value
              };

              if (this.state.isTheSameUser) {
                dataToEditPassword.password_old = this.formGroupOldPassword.refs.input.value;
              }

              editPassword(dataToEditPassword, uuid)
                .then(responsePassword => {
                  this._getUser(uuid);

                  this.props.addAlert({
                    id: "feedbackChangeProfile",
                    theme: "success",
                    animation: "default",
                    timer: "fast",
                    title: I18n.t("TitleAlertProfileIsChanged")
                  });

                  this.props.history.push("/users");
                })
                .catch(error => {
                  this.props.addAlert({
                    id: "feedbackChangeProfile",
                    theme: "warning",
                    animation: "shake",
                    timer: "fast",
                    title: I18n.t("TitleAlertPasswordHasNotBeenChanged"),
                    description: I18n.t(
                      "TitleAlertPasswordHasNotBeenChangedBecauseTheOld"
                    )
                  });

                  this.setState({
                    isSavingEditUser: false
                  });
                });
            } else {
              this._getUser(uuid);

              this.props.addAlert({
                id: "feedbackChangeProfile",
                theme: "success",
                animation: "default",
                timer: "fast",
                title: I18n.t("TitleAlertProfileIsChanged")
              });

              this.props.history.push("/users");
            }
          })
          .catch(error => {
            this.props.addAlert({
              id: "feedbackChangeProfile",
              theme: "danger",
              animation: "default",
              timer: "fast",
              title: I18n.t("TitleAlertUnhangedProfile"),
              description: I18n.t("ContentAlertUnhangedProfile")
            });

            this.setState({
              isSavingEditUser: false
            });
          });
      })
      .catch(error => {
        this.props.addAlert({
          id: "feedbackChangeProfile",
          theme: "danger",
          animation: "default",
          timer: "fast",
          title: I18n.t("TitleAlertUnhangedProfile"),
          description: I18n.t("ContentAlertUnhangedProfile")
        });

        this.setState({
          isSavingEditUser: false
        });
      });
  };

  toggleEditPassword = () => {
    this.setState({
      isEditingPassword: this.formGroupEditPassword.refs.input.checked
    });
  };

  onClickDelete = () => {
    this.props.addAlert({
      id: "alertToConfirmDeleteUser",
      theme: "warning",
      animation: "default",
      timer: "infinite",
      title: I18n.t("ConfirmUserDeletion"),
      description: I18n.t("WarningNotPossibleToUndoTheAction"),
      actionButtons: [
        {
          label: I18n.t("DeleteUser"),
          icon: "delete",
          theme: "danger",
          actionHasLoading: true,
          onClick: this.onConfirmDelete.bind(this)
        },
        {
          label: I18n.t("Cancel"),
          theme: "light",
          kind: "ghost",
          onClick: this.props.delAlert.bind(this, "alertToConfirmDeleteUser")
        }
      ]
    });
  };

  onConfirmDelete = () => {
    return new Promise(resolve => {
      let uuid = this.props.match.params.uuid;

      deleteUser([uuid])
        .then(response => {
          this.setState({
            isLoading: false,
            user_to_delete: null
          });

          this.props.addAlert({
            id: "alertToConfirmDeleteUser",
            theme: "success",
            animation: "default",
            timer: "fast",
            title: I18n.t("UserDeletedSuccessfully")
          });

          this.props.history.push("/users");
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            user_to_delete: null
          });

          this.props.addAlert({
            id: "alertToConfirmDeleteUser",
            theme: "danger",
            animation: "shake",
            timer: "fast",
            title: I18n.t("TitleAlertUserNotRemoved"),
            description: I18n.t("ContentAlertUserNotRemoved")
          });
        });

      resolve();
    });
  };

  renderCreating() {
    return (
      <div>
        <h1 className="heading-1 mb-xs-4">{I18n.t("TitleCreateNewUser")}</h1>

        <FormGroup
          ref={formGroup => (this.formGroupFirstName = formGroup)}
          type="text"
          name="first_name"
          label={I18n.t("UserFirstName")}
          className="mb-xs-3"
        />

        <FormGroup
          ref={formGroup => (this.formGroupGroups = formGroup)}
          className="mb-xs-3"
          type="virtualized"
          loadInVirtualized="groups"
          label={I18n.t("AssetDetailGroups")}
          name="group"
        />

        <FormGroup
          ref={formGroup => (this.formGroupLanguage = formGroup)}
          type="virtualized"
          loadInVirtualized="languages"
          multi={false}
          name="language"
          label={I18n.t("Language")}
          defaultValue="pt_br"
          className="mb-xs-3"
        />

        <FormGroup
          ref={formGroup => (this.formGroupEmail = formGroup)}
          type="input"
          name="email"
          label="E-mail:"
          className="mb-xs-3"
        />

        <FormGroup
          ref={formGroup => (this.formGroupPasswordNew = formGroup)}
          type="password"
          name="new_password"
          label={I18n.t("PasswordNew")}
          className="mb-xs-3"
        />

        <FormGroup
          ref={formGroup => (this.formGroupPasswordNewConfirm = formGroup)}
          type="password"
          name="new_password_confirm"
          label={I18n.t("PasswordNewConfirm")}
          className="mb-xs-3"
        />

        <Button
          type="button"
          label={I18n.t("ActionSave")}
          isLoading={this.state.isSavingCreateUser}
          onClick={() => {
            if (this.isValidForm()) this.onSubmitCreateUser();
          }}
          className="mr-xs-1"
        />
      </div>
    );
  }

  renderEditing() {
    return (
      <div>
        <FormGroup
          ref={formGroup => (this.formGroupFirstName = formGroup)}
          type="text"
          name="first_name"
          label={I18n.t("UserFirstName")}
          defaultValue={
            this.state.user.first_name + " " + this.state.user.last_name
          }
          className="mb-xs-3"
        />

        {this.state.permissions.manage_user && (
          <FormGroup
            ref={formGroup => (this.formGroupGroups = formGroup)}
            className="mb-xs-3"
            type="virtualized"
            loadInVirtualized="groups"
            label={I18n.t("AssetDetailGroups")}
            name="group"
            defaultValue={this.state.defaultValueToEdit.groups}
          />
        )}

        {!this.state.permissions.manage_user &&
          this.state.user.groups.length !== 0 && (
            <p className="lineHeightParagraph mb-xs-3">
              {I18n.t("AssetDetailGroups")}
              <br />

              {this.state.user.groups.map((group, index) => (
                <span key={index} className="arrayInfo">
                  {" "}
                  {group.name}
                </span>
              ))}
            </p>
          )}

        <FormGroup
          ref={formGroup => (this.formGroupLanguage = formGroup)}
          type="virtualized"
          loadInVirtualized="languages"
          multi={false}
          name="language"
          label={I18n.t("Language")}
          defaultValue={this.state.settings.language_option.value}
          className="mb-xs-3"
        />

        <p className="lineHeightParagraph mb-xs-3">
          E-mail:
          <br />
          <strong>{this.state.user.username}</strong>
        </p>

        <div className="mb-xs-3">
          <Key
            ref={key => (this.formGroupEditPassword = key)}
            id="formGroupEditPassword"
            label={I18n.t("EditPassword")}
            onChange={this.toggleEditPassword.bind(this)}
            defaultChecked={this.state.isEditingPassword}
            className="mb-xs-2"
          />
        </div>

        {this.state.isEditingPassword && (
          <div>
            {this.state.isTheSameUser && (
              <FormGroup
                ref={formGroup => (this.formGroupOldPassword = formGroup)}
                type="password"
                name="old_password"
                label={I18n.t("PasswordOld")}
                className="mb-xs-3"
              />
            )}

            <FormGroup
              ref={formGroup => (this.formGroupPasswordNew = formGroup)}
              type="password"
              name="new_password"
              label={I18n.t("PasswordNew")}
              className="mb-xs-3"
            />

            <FormGroup
              ref={formGroup => (this.formGroupPasswordNewConfirm = formGroup)}
              type="password"
              name="new_password_confirm"
              label={I18n.t("PasswordNewConfirm")}
              className="mb-xs-3"
            />
          </div>
        )}

        <Button
          type="button"
          onClick={() => {
            if (this.isValidForm()) this.onSubmitEditUser();
          }}
          label={I18n.t("ActionSave")}
          isLoading={this.state.isSavingEditUser}
          className="mr-xs-1"
        />

        <Button
          type="button"
          onClick={() => this.props.history.push("/users")}
          label={I18n.t("ActionCancel")}
          theme="light"
        />
      </div>
    );
  }

  renderWatching() {
    return (
      <Row>
        <Col md="8" lg="7" xlg="8">
          <p className="lineHeightParagraph mb-xs-3">
            <FieldEditable
              type="text"
              onClick={this.toggleEditUser.bind(this, "formGroupFirstName")}
            >
              {I18n.t("UserFirstName")}:<br />
              <strong className="heading-1">
                {this.state.user.first_name + " " + this.state.user.last_name}
              </strong>
            </FieldEditable>
          </p>

          {this.state.user.groups.length !== 0 &&
            this.state.permissions.manage_user && (
              <p className="lineHeightParagraph mb-xs-3">
                <FieldEditable
                  onClick={this.toggleEditUser.bind(this, "formGroupGroups")}
                >
                  {I18n.t("AssetDetailGroups")}
                  <br />
                  {this.state.user.groups.map((group, index) => (
                    <span key={index} className="arrayInfo">
                      {" "}
                      {group.name}
                    </span>
                  ))}
                </FieldEditable>
              </p>
            )}

          {this.state.user.groups.length !== 0 &&
            !this.state.permissions.manage_user && (
              <p className="lineHeightParagraph mb-xs-3">
                {I18n.t("AssetDetailGroups")}
                <br />

                {this.state.user.groups.map((group, index) => (
                  <span key={index} className="arrayInfo">
                    {" "}
                    {group.name}
                  </span>
                ))}
              </p>
            )}

          <p className="lineHeightParagraph mb-xs-3">
            <FieldEditable
              type="text"
              onClick={this.toggleEditUser.bind(this, "formGroupLanguage")}
            >
              {I18n.t("Language")}
              <br />
              <strong>{this.state.settings.language_option.display}</strong>
            </FieldEditable>
          </p>

          <p className="lineHeightParagraph mb-xs-3">
            E-mail:
            <br />
            <strong>{this.state.user.username}</strong>
          </p>

          <p className="lineHeightParagraph mb-xs-3">
            <FieldEditable
              type="text"
              onClick={this.toggleEditUser.bind(this, "formGroupOldPassword")}
            >
              {I18n.t("Password")}
              <br />
              <strong>*******</strong>
            </FieldEditable>
          </p>
        </Col>

        <Col md="4" lg="5" xlg="4" className="text-xs-right">
          {(this.state.isTheSameUser || PermissionHandler.can_edit()) && (
            <Button
              icon="edit"
              label={I18n.t("ActionEdit")}
              onClick={this.toggleEditUser.bind(this, null)}
            />
          )}

          {!this.state.isTheSameUser && PermissionHandler.can_delete() && (
            <Button
              icon="delete"
              width="min"
              theme="danger"
              className="ml-xs-2"
              onClick={this.onClickDelete.bind(this)}
            />
          )}
        </Col>
      </Row>
    );
  }

  render() {
    if (
      (!this.state.permissions.manage_user && !this.state.isTheSameUser) ||
      (!this.state.permissions.manage_user && this.state.isCreatingNewUser)
    )
      return <div>Acesso não autorizado</div>;
    return (
      <Container>
        <Row xsAlignX="center">
          <Col lg="7">
            {this.state.isGettingUser && <Loader centralized={true} />}
            {!this.state.isGettingUser && (
              <Box className="p-xs-4">
                {!this.state.isGettingUser && (
                  <div>
                    {this.state.user &&
                      !this.state.isEditing &&
                      this.renderWatching()}

                    {this.state.user &&
                      this.state.isEditing &&
                      this.renderEditing()}

                    {this.state.isCreatingNewUser && this.renderCreating()}
                  </div>
                )}
              </Box>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  searchFilters: state.searchFilters
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...alertActions,
      addBreadCrumb,
      clearBreadCrumb
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfilePage)
);

import AxiosHelper from "./../helpers/AxiosHelper";

const RESOURCE = "mediamanager/assets";

const getSearchAssets = (searchFilters, page, per_page) => {
  const url = `/v2/${RESOURCE}/search/`;

  let thisFilters = {
    q: searchFilters.q,
    asset_type: searchFilters.asset_type.map(v =>
      v.id === "pdf" ? "application" : v.id
    ),
    date_start: searchFilters.dateStart,
    date_end: searchFilters.dateEnd,
    tags: searchFilters.tags.map(v => v.label),
    collections: searchFilters.collections.map(v => v.id),
    personalized_field: searchFilters.personalized_field,
    groups: searchFilters.groups.map(v => v.id),
    page: page,
    per_page: per_page,
    order_by: searchFilters.orderBy || (searchFilters.q ? null : "-updated_at")
  };

  const apiFilters = {
    filters: thisFilters
  };

  return AxiosHelper.doPost(url, apiFilters);
};

const getAsset = (uuid, searchTerm, w, h, cancelToken) => {
  const url = `/v2/${RESOURCE}/${uuid}/details/`;
  let thisFilters = {
    q: searchTerm,
    size_url: [w, h]
  };

  return AxiosHelper.doPost(url, thisFilters, cancelToken);
};

const editAsset = data => {
  const url = `/${RESOURCE}/edit/`;

  return AxiosHelper.doPost(url, data);
};

const deleteAsset = uuid => {
  const url = `/${RESOURCE}/${uuid}/delete/`;

  return AxiosHelper.doDelete(url);
};

const searchAssetSquares = (uuid, searchTerm) => {
  const url = `/v2/${RESOURCE}/${uuid}/squares/search/`;

  return AxiosHelper.doPost(url, { q: searchTerm });
};

const searchAssetTimeShots = (uuid, searchTerm) => {
  const url = `/v2/${RESOURCE}/${uuid}/timeshots/search/`;

  return AxiosHelper.doPost(url, { q: searchTerm });
};

const getAssetCategories = uuid => {
  const url = `/v2/${RESOURCE}/${uuid}/categories/`;

  return AxiosHelper.doGet(url);
};

const updateAssetCategories = (uuid, data) => {
  const url = `/v2/${RESOURCE}/${uuid}/categories/`;
  return AxiosHelper.doPut(url, data);
};

export {
  getSearchAssets,
  getAsset,
  editAsset,
  deleteAsset,
  searchAssetSquares,
  searchAssetTimeShots,
  getAssetCategories,
  updateAssetCategories
};

export default function breadCrumb(state = {}, action) {
  switch (action.type) {
    case "ADD_BREAD_CRUMB":
      return { old: action.params };
    case "ADD_BREAD_CRUMB_NEW":
      return { new: action.params };

    case "CLEAR_BREAD_CRUMB":
      return {};

    default:
      return state;
  }
}

import React from "react";

import { getSharedCollectionAsset } from "../../models/CollectionModel";
import { msToTime } from "../../helpers/HumanizeHelper";
import {
  CardRoot,
  CardRootMedia,
  CardRootContent,
  ContentName,
  ContentDecription,
  MediaIcon
} from "./AssetItem.style";

const humanize = require("humanize");

class AssetItem extends React.Component {
  state = { loading: true };

  async componentDidMount() {
    let { isPublic, uuidAsset, uuidCollection, collection, asset } = this.props;

    if (isPublic) {
      let response = await getSharedCollectionAsset(uuidCollection, uuidAsset);
      asset = response.data;
    }

    this.setState({ collection, asset, loading: false });
  }

  renderThumb() {
    const { asset, collection, loading } = this.state;
    const { sample } = this.props;

    if (loading) {
      return <MediaIcon name="circular-progress" />;
    }

    if (asset) {
      if (asset.asset_type[1] === "image") {
        if (sample) {
          return <MediaIcon name="photo-size-select-large" />;
        } else if (!asset.size_url || !asset.resized) {
          return <MediaIcon name="image" />;
        } else {
          return (
            String(asset.size_url["thumbnail-small"]) ||
            String(asset.size_url.original)
          );
        }
      } else if (asset.asset_type[1] === "audio") {
        return <MediaIcon name="audiotrack" />;
      } else if (asset.asset_type[1] === "video") {
        return <MediaIcon name="play-arrow" />;
      } else if (
        asset.asset_type[1] === "application" &&
        asset.asset_type[2] === ".pdf"
      ) {
        return <MediaIcon name="picture-as-pdf" />;
      }
    } else if (collection) {
      return <MediaIcon name="collections" />;
    }
    return <MediaIcon name="insert-drive-file" />;
  }

  render() {
    let { onClick } = this.props;
    const { asset, collection } = this.state;
    const thumb = this.renderThumb();
    const MediaComponent = props => <div {...props}>{thumb}</div>;
    return (
      <CardRoot
        onClick={() => {
          if (onClick) {
            onClick(asset || collection);
          }
        }}
      >
        <CardRootMedia
          component={thumb instanceof Object ? MediaComponent : "div"}
          image={thumb instanceof Object ? "none" : thumb}
        />
        <CardRootContent>
          <ContentName noWrap>
            {asset && String(asset.real_name).replace(asset.asset_type[2], "")}
            {collection && String(collection.name)}
          </ContentName>
          <div>
            <ContentDecription noWrap>
              {collection && collection.total_assets + " assets"}
              {asset && asset.asset_type[2] + " - "}
              {asset && humanize.filesize(asset.file_size)}
              {asset &&
              (asset.asset_type[1] === "audio" ||
                asset.asset_type[1] === "video") &&
              asset.duration
                ? " - " + msToTime(asset.duration)
                : ""}

              {/* <PublicOutlined className={classes.descriptionIcon} />
              <GroupOutlined className={classes.descriptionIcon} /> */}
            </ContentDecription>
          </div>
        </CardRootContent>
      </CardRoot>
    );
  }
}

export default AssetItem;

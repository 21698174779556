import AxiosHelper from "./../helpers/AxiosHelper";

const RESOURCE = "mediamanager/tags";

const getSearchTags = params => {
  let url = `/v2/${RESOURCE}?`;

  if (params) {
    Object.keys(params).map(
      key => (url += params[key] ? `${key}=${params[key]}&` : "")
    );
  }

  return AxiosHelper.doGet(url.substring(0, url.length - 1));
};

const getTag = slug => {
  const url = `v2/${RESOURCE}/${slug}/`;

  return AxiosHelper.doGet(url);
};

export { getSearchTags, getTag };

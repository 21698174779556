import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  clearFilters,
  setSearchValue
} from "../../pages/search-results/_actionsReducers";

import Filter from "../filter/Filter";
import { bindActionCreators } from "redux";
import { Button, Typography } from "@material-ui/core";
import {
  AsideContainer,
  AsideClear,
  AsideNav,
  AsideContent,
  AsideItem,
  AsideIcon
} from "./Aside.style";
import Auth from "../../../models/AuthModel";
import Tooltip from "../../Tooltip/Tooltip";

class Aside extends Component {
  state = {
    items: [
      {
        name: "search",
        tooltip: "Assets",
        onClick: () => {
          this.props.setSearchValue({
            activeItem: "search"
          });
          this.changeActiveItem("search");
          this._clearFiltersRedirect();
        }
      },
      {
        name: "filter",
        tooltip: "Filtros",
        rotate: true,
        onClick: () => this.changeActiveItem("filter")
      },
      {
        name: "collections-outline",
        tooltip: "Coleções",
        onClick: () => {
          this._clearFilters();
          this.props.setSearchValue({
            activeItem: "collections-outline"
          });
          this.props.history.push("/collections");
        }
      }
    ]
  };
  _clearFiltersRedirect = () => {
    let user = Auth.getUser();
    let redirectInitialScreen = false;
    if (
      user &&
      user.configurations &&
      user.configurations.initial_search_screen === "1"
    ) {
      redirectInitialScreen = true;
    }

    this.props.clearFilters({ redirectInitialScreen });
  };

  changeActiveItem = itemToChange => {
    let oldactiveItem = this.props.activeItem;

    this.props.setSearchValue({
      activeItem: oldactiveItem === itemToChange ? null : itemToChange
    });
  };

  hasactivedFilters = () => {
    const { filters } = this.props;

    return (
      filters.dateEnd ||
      filters.dateStart ||
      filters.asset_type.length > 0 ||
      filters.tags.length > 0 ||
      filters.collections.length > 0 ||
      filters.personalized_field.length > 0 ||
      filters.groups.length > 0
    );
  };

  _clearFilters = () => {
    this.props.clearFilters();
  };

  render() {
    const {
      activeItem,
      isSearchingAssets,
      isSearchingCollections,
      isSearchingTags,
      history
    } = this.props;
    const { items } = this.state;

    let showClearFilters = this.hasactivedFilters();
    let isSearching =
      isSearchingAssets || isSearchingCollections || isSearchingTags;

    return (
      <AsideContainer disabled={isSearching}>
        <AsideNav>
          {items.map((item, index) => {
            let actived = activeItem === item.name ? 1 : 0;
            let location = String(history.location.pathname);
            let disabled =
              item.name === "filter" && !location.includes("/search") ? 1 : 0;

            return (
              <Tooltip
                title={item.tooltip}
                key={index}
                placement="right"
                arrow={1}
              >
                <AsideItem
                  actived={actived}
                  onClick={!disabled ? item.onClick : null}
                  disabled={disabled}
                >
                  <AsideIcon
                    actived={actived}
                    disabled={disabled}
                    name={item.name}
                    rotate={item.rotate ? 1 : 0}
                  />
                </AsideItem>
              </Tooltip>
            );
          })}
        </AsideNav>

        <AsideContent actived={activeItem && activeItem === "filter"}>
          {activeItem === "filter" && (
            <>
              <section>
                <Filter />
              </section>

              <AsideClear actived={showClearFilters}>
                <Button
                  size="large"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={this._clearFilters}
                >
                  <Typography>Limpar filtros</Typography>
                </Button>
              </AsideClear>
            </>
          )}
        </AsideContent>
      </AsideContainer>
    );
  }
}

const mapStateToProps = state => ({
  filters: state.search.filters,
  activeItem: state.search.activeItem,
  isSearchingAssets: state.search.isSearchingAssets,
  isSearchingCollections: state.search.isSearchingCollections,
  isSearchingTags: state.search.isSearchingTags
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clearFilters, setSearchValue }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Aside)
);

import PropTypes from "prop-types";
import React, { Component } from "react";
import Button from "./../../atoms/button/Button";

class Avatar extends Component {
  static propTypes = {
    /** Comment soon */
    url: PropTypes.string
  };

  render() {
    if (this.props.url) {
      return (
        <img
          src={this.props.url}
          alt=""
          width="40"
          height="40"
          className={"radiusCircle " + this.props.className}
          onClick={this.props.onClick}
        />
      );
    } else {
      return (
        <Button
          icon="user"
          theme="light"
          width="min"
          className={this.props.className}
          onClick={this.props.onClick}
        />
      );
    }
  }
}

export default Avatar;

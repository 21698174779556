import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

export const AsideTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    body1: {
      color: grey[700],
      fontSize: 14
    },
    body2: {
      color: "#FFF",
      fontSize: 14
    },
    subtitle1: {
      color: "#FFF",
      fontWeight: "bold",
      fontSize: 14
    }
  },
  palette: {
    primary: {
      main: "#4FC8D5"
    },
    secondary: {
      main: "#7d8084"
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white"
      },
      containedSecondary: {
        color: "white"
      }
    },

    MuiInputAdornment: {
      root: {
        "&:hover": {
          cursor: "pointer"
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: 20
      }
    },
    MuiInput: {
      root: {
        fontSize: 14,
        color: "#FFF"
      },
      underline: {
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomColor: grey[500]
        },
        "&:after": {
          borderBottomColor: grey[500]
        },
        "&:before": {
          borderBottomColor: grey[500]
        }
      }
    },
    MuiPickersDay: {
      day: {
        fontSize: 14
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        fontSize: 14
      },
      switchHeader: {
        fontSize: 14
      }
    },
    MuiSelect: {
      icon: {
        color: "#FFF"
      }
    },
    MuiCheckbox: {
      root: {
        padding: "0px 8px 0px 0px",
        color: grey[500]
      }
    },
    MuiRadio: {
      root: {
        padding: "0px 8px 0px 0px",
        color: grey[500]
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        padding: "4px 0px 4px 0px",
        display: "-webkit-inline-box"
      }
    }
  }
});

import { Card, Typography, CardContent, CardMedia } from "@material-ui/core";
import styled from "styled-components";
import Icon from "../Icon/Icon";

export const CardRoot = styled(Card)`
  && {
    display: flex;
    transition: transform ${props => props.theme.transitions.standard};
    :hover {
      box-shadow: ${props => props.theme.shadows.boxItem};
      cursor: pointer;
      transform: scale(1.05);
    }
  }
`;

export const CardRootContent = styled(CardContent)`
  && {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: ${props => props.theme.spacing.unit * 2}px;
    :last-child {
      padding-bottom: ${props => props.theme.spacing.unit * 2}px;
    }
  }
`;

export const CardRootMedia = styled(CardMedia)`
  && {
    width: 50%;
    padding-top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MediaIcon = styled(Icon)`
  && {
    margin-top: -80%;
    transform: translate(0, -50%);
    width: 50px;
    height: 50px;
    color: ${props => props.theme.palette.secondary};
  }
`;

export const ContentTypes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: auto;
`;

export const IconType = styled(Icon)`
  && {
    font-size: ${props => props.theme.size.xxl};
    color: ${props => props.theme.palette.grey2};
  }
`;

export const ContentName = styled(Typography)`
  && {
    ${props => props.theme.typography.body1}
    font-weight: bold;
  }
`;

export const ContentDecription = styled(Typography)`
  && {
    ${props => props.theme.typography.subtitle1}
  }
`;

export const DescriptionIcon = styled.div`
  float: right;
  font-size: ${props => props.theme.size.xl};
  margin-left: ${props => props.theme.spacing.unit}px;
`;
